import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import { getOrdersByOrderGroup } from '../order/orderAPI';
import { getInvoice, getTopUpInvoice } from './invoiceAPI';

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    orderGroup: null,
    status: 'idle',
  },
  reducers: {
    setOrders: (state, action) => {
      state.orderGroup = {
        ...action.payload,
        pickUpDateTime: (action.payload.pickUpDateTime ? new Date(action.payload.pickUpDateTime).toString() : action.payload.pickUpDateTime),
        orders: [
          ...action.payload.orders.map(o => o.deliverDateTime ? {...o, deliverDateTime: new Date(o.deliverDateTime).toString()} : o)
        ]
      };
      state.status = 'success';
    },
  },
});

export const { setOrders } = invoiceSlice.actions;

export const handleGetOrdersByOrderGroup = (id) => async (dispatch, getState) => {
  dispatch(showLoading());
  try {
    const response = await getOrdersByOrderGroup(id);
    dispatch(setOrders(response.data));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetInvoice = (walletId, paymentId) => async (dispatch, getState) => {
  const response = await getInvoice(walletId, paymentId);
  let invoice = await response.data;
  const url = window.URL.createObjectURL(new Blob([invoice], {type: 'application/pdf'}));
  window.open(url, '_blank');
};

export const handleGetTopUpInvoice = (walletId, topUpId) => async (dispatch, getState) => {
  const response = await getTopUpInvoice(walletId, topUpId);
  let invoice = await response.data;
  const url = window.URL.createObjectURL(new Blob([invoice], {type: 'application/pdf'}));
  window.open(url, '_blank');
};

export const selectState = state => state.invoice;

export default invoiceSlice.reducer;
