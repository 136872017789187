import { createSlice } from "@reduxjs/toolkit";
import { getUserInfoFromJwt, logout } from "../../app/utils";
import { createUserWallet, getUserWallet } from "../../features/wallet/walletAPI";

export const topbarSlice = createSlice({
    name: 'topbar',
    initialState: {
        walletBalance: 0,
        showTopUpModal: false
    },
    reducers: {
        setWalletBalance: (state, action) => {
            state.walletBalance = action.payload;
        },
        setShowTopUpModal: (state, action) => {
            state.showTopUpModal = action.payload;
        }
    }
});

export const { setWalletBalance, setShowTopUpModal } = topbarSlice.actions;

export const handleGetUserWallet = () => async (dispatch, getState) => {
    try {
        const userInfo = await getUserInfoFromJwt();
        const response = await getUserWallet(userInfo.user_id);
        if (response.data === '') {
            // create wallet if wallet doesn't exist
            await createUserWallet({ region: 'MY' });
            dispatch(handleGetUserWallet());
        } else {
            dispatch(setWalletBalance(response.data.balanceAmount));
        }
    } catch (error) {
        if (error.message === 'Invalid token specified') {
            logout();
        }
    }
};

export const topbarState = state => state.topbar;

export default topbarSlice.reducer;