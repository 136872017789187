import axiosApiInstance from "../../app/axios";
import { API_BASE_URL, getJwtToken } from "../../app/utils";

export const getUserOrderGroups = async (vendorId, page, size, status) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups?vendorId=${vendorId}&page=${page}&size=${size}&status=${status}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getAllUserOrderGroups = async (vendorId, page, size) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups?vendorId=${vendorId}&page=${page}&size=${size}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getUserUnpaidOrderGroups = async (vendorId, page, size, status) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups?vendorId=${vendorId}&page=${page}&size=${size}&status=${status}&paid=false`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getUserOrderGroupsBetweenDates = async (params) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups?${params}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getOrderGroupReport = async (vendorId, month, year, status) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/reportings?vendorId=${vendorId}&month=${month}&year=${year}&status=${status}`,
    {
      responseType: 'blob',
      headers: { 
        "Authorization": `Bearer ${await getJwtToken()}`,
        "Content-Type": "text/csv"
      }
    }
  );
  
export const getUnpaidOrderGroupReport = async (vendorId, month, year, status) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/reportings?vendorId=${vendorId}&month=${month}&year=${year}&paid=false&status=${status}`,
    {
      responseType: 'blob',
      headers: { 
        "Authorization": `Bearer ${await getJwtToken()}`,
        "Content-Type": "text/csv"
      }
    }
  );

export const createOrderGroup = async (data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/order-groups`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const updateOrderGroup = async (data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const deleteOrderGroup = async (id) =>
  axiosApiInstance.delete(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const cancelOrderGroup = async (id) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}/cancel`,
    {},
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );