import { useEffect, useState } from "react";
import styles from './PlaceOrder.module.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, setHours } from "date-fns";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { filterPassedTime } from "./placeOrderSlice";
import { TealSwitch } from "./common";

import d2dIcon from "../../images/place_order/d2d-icon.png";
import codIcon from "../../images/place_order/cod-icon.png";

export const EnterPickUpDetails = (props) => {
  const { pickUpForm } = props;
  const [unitNumber, setUnitNumber] = useState(pickUpForm.pickUpLocation.unitNumber);
  const [pickUpContactNumber, setPickUpContactNumber] = useState(pickUpForm.pickUpContactNumber.replace("+", ""));
  const [pickUpPersonName, setPickUpPersonName] = useState(pickUpForm.pickUpPersonName);

  return (
    <div className={styles.enterDetailsContainer}>
      {window.innerWidth <= 850 && <div className={styles.modalIndicator} />}
      <input
        id="unitNumber"
        name="unitNumber"
        value={unitNumber}
        onChange={(e) => setUnitNumber(e.target.value)}
        placeholder="Unit number (optional)"
        className={styles.textInput}
      />
      <PhoneInput
        id="pickUpContactNumber"
        name="pickUpContactNumber"
        country={'my'}
        countryCodeEditable={false}
        preferredCountries={['my', 'sg', 'au', 'us']}
        autoFormat={false}
        value={pickUpContactNumber}
        onChange={(e) => setPickUpContactNumber(e)}
        placeholder="Sender's contact number"
        inputProps={{
          required: true
        }}
        inputStyle={{ height: 50, fontSize: 13, fontWeight: "bold" }}
      />
      <input
        id="pickUpPersonName"
        name="pickUpPersonName"
        value={pickUpPersonName}
        onChange={(e) => setPickUpPersonName(e.target.value)}
        placeholder="Sender's name"
        className={styles.textInput}
      />

      <div className={styles.buttonContainer}>
        <div className={styles.button}
          style={{ flex: 1, backgroundColor: "#303334", marginInline: 5, paddingBlock: 10, border: "1px solid #1cadb4", borderRadius: 5 }}
          onClick={() => props.handleCancel()}>
          <p className={styles.lightTealText} style={{ fontWeight: "bold" }}>Cancel</p>
        </div>
        <div className={styles.button}
          style={{ flex: 1, marginInline: 5, paddingBlock: 10, borderRadius: 5 }}
          onClick={() => props.handlePickUpDetails(unitNumber, pickUpContactNumber, pickUpPersonName)}>
          <p className={styles.blackText} style={{ color: "white" }}>Save</p>
        </div>
      </div>
    </div>
  )
}

export const EnterDropOffDetails = (props) => {
  const { dropOffForm } = props;
  const [deliverDateTime, setDeliverDateTime] = useState(dropOffForm.deliverDateTime);
  const [unitNumber, setUnitNumber] = useState(dropOffForm.dropOffLocation.unitNumber);
  const [recipientPhone, setRecipientPhone] = useState(dropOffForm.recipientPhone.replace("+", ""));
  const [recipientName, setRecipientName] = useState(dropOffForm.recipientName);
  const [doorToDoor, setDoorToDoor] = useState(dropOffForm.doorToDoor);
  const [isCodEnabled, setIsCodEnabled] = useState(dropOffForm.isCodEnabled);
  const [codAmount, setCodAmount] = useState(dropOffForm.codAmount);

  // to update details when user select another drop-off location to be edited
  useEffect(() => {
    setDeliverDateTime(dropOffForm.deliverDateTime);
    setUnitNumber(dropOffForm.dropOffLocation.unitNumber);
    setRecipientPhone(dropOffForm.recipientPhone.replace("+", ""));
    setRecipientName(dropOffForm.recipientName);
    setDoorToDoor(dropOffForm.doorToDoor);
    setIsCodEnabled(dropOffForm.isCodEnabled);
    setCodAmount(dropOffForm.codAmount);
  }, [dropOffForm.id]);

  const isPickupDate = (pickupDate, deliveryDate) => {
    return deliveryDate.getDate() === pickupDate.getDate() && deliveryDate.getMonth() === pickupDate.getMonth() && deliveryDate.getFullYear() === pickupDate.getFullYear();
  }

  return (
    <div className={styles.enterDetailsContainer}>
      {window.innerWidth <= 850 && <div className={styles.modalIndicator} />}
      <div className={styles.whiteRow}>
        <DatePicker
          className={styles.dropOffDatepicker}
          selected={deliverDateTime}
          onChange={date => {
            if (date.getHours() === 0) {
              date.setHours(9);
            }
            setDeliverDateTime(date);
          }}
          showTimeSelect
          dateFormat="Pp"
          minDate={dropOffForm.pickUpDateTime && ((new Date()).getTime() <= (new Date(dropOffForm.pickUpDateTime)).getTime()) ? new Date(dropOffForm.pickUpDateTime) : new Date()}
          minTime={
            (dropOffForm.pickUpDateTime && deliverDateTime === "") || isPickupDate(new Date(dropOffForm.pickUpDateTime), new Date(deliverDateTime))
              ? setHours(setMinutes(new Date(), new Date(dropOffForm.pickUpDateTime).getMinutes()), new Date(dropOffForm.pickUpDateTime).getHours())
              : setHours(setMinutes(new Date(), 0), 6)
          }
          maxTime={setHours(setMinutes(new Date(), 0), 23)}
          filterTime={filterPassedTime}
          timeIntervals={15}
          placeholderText={"Preferred drop-off time"}
        />
      </div>
      <input
        id="unitNumber"
        name="unitNumber"
        value={unitNumber}
        onChange={(e) => setUnitNumber(e.target.value)}
        placeholder="Unit number (optional)"
        className={styles.textInput}
        style={{ marginTop: 0 }}
      />
      <PhoneInput
        id="recipientPhone"
        name="recipientPhone"
        country={'my'}
        countryCodeEditable={false}
        preferredCountries={['my', 'sg', 'au', 'us']}
        autoFormat={false}
        value={recipientPhone}
        onChange={(e) => setRecipientPhone(e)}
        placeholder="Recipient's contact number"
        inputProps={{
          required: true
        }}
        inputStyle={{ height: 50, fontSize: 13, fontWeight: "bold" }}
      />
      <input
        id="recipientName"
        name="recipientName"
        value={recipientName}
        onChange={(e) => setRecipientName(e.target.value)}
        placeholder="Recipient's name"
        className={styles.textInput}
      />

      <div className={styles.additionalDetailsContainer}>
        <div className={styles.row}>
          <img className={styles.icon} src={d2dIcon} alt="d2d" />
          <div>
            <p className={styles.blackText}>Door-to-door</p>
            <p className={styles.lightTealText} style={{ marginTop: 2, fontSize: 10 }}>* subjected to additional charges</p>
          </div>
          <div style={{ marginLeft: "auto", marginRight: 5 }}>
            <TealSwitch
              checked={doorToDoor}
              onChange={() => setDoorToDoor(!doorToDoor)}
              size="small"
            />
          </div>
        </div>
        <div className={styles.horizontalDivider} />
        <div className={styles.row}>
          <img className={styles.icon} src={codIcon} alt="cod" />
          <p className={styles.blackText}>COD</p>
          <div style={{ marginLeft: "auto", marginRight: 5 }}>
            <TealSwitch
              checked={isCodEnabled}
              onChange={() => setIsCodEnabled(!isCodEnabled)}
              size="small"
            />
          </div>
        </div>
        <div className={styles.row} style={{ marginTop: -5, paddingTop: 0, paddingInline: 20 }}>
          <div className={styles.whiteInput} style={{ flex: 1, borderTopLeftRadius: 3, borderBottomLeftRadius: 3 }}>
            <p className={styles.lightTealText}>RM</p>
          </div>
          <div className={styles.whiteInput} style={{ flex: 5, borderTopRightRadius: 3, borderBottomRightRadius: 3 }}>
            <input
              id="codAmount"
              name="codAmount"
              value={codAmount}
              onChange={(e) => setCodAmount(e.target.value)}
              placeholder="0.00"
              className={styles.greyText}
              style={{ display: "flex", marginLeft: "auto", padding: 0, textAlign: "right", border: 0 }}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <div className={styles.button}
          style={{ flex: 1, backgroundColor: "#303334", marginInline: 5, paddingBlock: 10, border: "1px solid #1cadb4", borderRadius: 5 }}
          onClick={() => props.handleCancel()}>
          <p className={styles.lightTealText} style={{ fontWeight: "bold" }}>Cancel</p>
        </div>
        <div className={styles.button}
          style={{ flex: 1, marginInline: 5, paddingBlock: 10, borderRadius: 5 }}
          onClick={() => props.handleDropOffDetails(deliverDateTime, unitNumber, recipientPhone, recipientName, doorToDoor, isCodEnabled, codAmount)}>
          <p className={styles.blackText} style={{ color: "white" }}>Save</p>
        </div>
      </div>
    </div>
  )
}