import { Toolbar, Typography, Tooltip, IconButton, Button } from "@material-ui/core";
import { lighten, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import styles from './OrderGroup.module.css';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, createOrderGroup, editOrderGroup, deleteOrderGroup, downloadOrderGroups, bookmarkAddress, orderGroupType } = props;
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Order Group List
                </Typography>
            )}
            {
                (orderGroupType === "OPEN" || orderGroupType === "CONFIRMED") ? (
                    <>
                    {
                        numSelected > 0 ? (
                            <div className={classes.toolTipContainer}>
                                {
                                    numSelected === 1 &&
                                    <>
                                        <Tooltip title="Bookmark Pickup Details" onClick={bookmarkAddress}>
                                            <IconButton aria-label="bookmark">
                                                <BookmarkBorderIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            orderGroupType === "OPEN" &&
                                            <Tooltip title="Edit" onClick={editOrderGroup}>
                                                <IconButton aria-label="edit">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>
                                }
                                <Tooltip title={orderGroupType === "OPEN" ? "Delete" : "Cancel Order"} onClick={deleteOrderGroup}>
                                    <IconButton aria-label="delete">
                                        {
                                            orderGroupType === "OPEN" ? <DeleteIcon /> : <BlockIcon />
                                        }
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : (
                            <>
                                <Tooltip title="Download Order Groups" onClick={downloadOrderGroups}>
                                    <IconButton aria-label="Download Order Groups">
                                        <CloudDownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Button onClick={createOrderGroup} className={styles.gradientButton}>Create Order Group</Button>
                            </>
                        )
                    }
                    </>
                ) : (
                    <>
                        {
                            numSelected > 0 ? (
                                <Tooltip title="Bookmark Pickup Details" onClick={bookmarkAddress}>
                                    <IconButton aria-label="bookmark">
                                        <BookmarkBorderIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Download Order Groups" onClick={downloadOrderGroups}>
                                    <IconButton aria-label="Download Order Groups">
                                        <CloudDownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </>
                )
            }
        </Toolbar>
    );
};