import React, { createContext, useEffect, useState } from 'react';
import styles from './PlaceOrder.module.css';
import { Alert } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { phoneUtil } from '../../app/utils';
import { getAllSavedAddresses, getEstimatedTotal, handleGetOrdersByOrderGroup, handleOnSortOrder, ordersState, setOrderGroup, setSelectedVoucher, setShowImportModal, setShowPaymentModal, setShowTopUpModal, setShowVoucherModal } from './placeOrderSlice';
import { updateOrder } from './orderAPI';
import { InfoTooltip, LoadingOverlay } from './common';
import { Topbar } from '../../components/topbar/Topbar';
import { Order } from './Order';
import { OrderGroup } from './OrderGroup';
import { EnterDropOffDetails, EnterPickUpDetails } from './EnterDetails';
import { ImportModal } from './ImportModal';
import { SavedAddressModal } from './SavedAddressModal';
import { VoucherModal } from './VoucherModal';
import { PaymentModal } from './PaymentModal';
import { TopUpModal } from './TopUpModal';
import { Map } from '../../components/map/Map';
import { PlaceOrderMap } from './MapWithDirectons/PlaceOrderMap';

import importIcon from "../../images/place_order/import-icon.png";
import plusIcon from "../../images/place_order/plus-icon.png";
import voucherIcon from "../../images/place_order/voucher-icon.png";
import closeIcon from "../../images/place_order/close-icon.png";

const SortableItem = SortableElement(({ currIdx, order, editOrderOrdinal, showDropOffModal, handleAddressChange, handleOrders, handleError }) => {
    return (
        <div style={{ backgroundColor: editOrderOrdinal === currIdx ? "#FFFBD4" : "white" }}>
            <div className={styles.horizontalDivider} />
            <OrderContext.Provider value={order}>
                <Order
                    currIdx={currIdx}
                    editOrder={editOrderOrdinal === currIdx ? true : false}
                    showDropOffModal={(ordinal) => showDropOffModal(ordinal)}
                    handleAddressChange={(location) => handleAddressChange(location)}
                    handleOrders={(order) => handleOrders(order)}
                    handleError={(err, errMsg) => handleError(err, errMsg)} />
            </OrderContext.Provider>
            <div className={styles.horizontalDivider} />
        </div>
    )
});

const SortableList = SortableContainer(({ orderListState, editOrderOrdinal, showDropOffModal, handleAddressChange, handleOrders, handleError }) => {
    return (
        <div>
            {
                orderListState.orderGroup.orders.map((order, index) => (
                    <SortableItem
                        key={`order-${order.ordinal}`}
                        index={index}
                        currIdx={index}
                        order={order}
                        editOrderOrdinal={editOrderOrdinal}
                        showDropOffModal={showDropOffModal}
                        handleAddressChange={handleAddressChange}
                        handleOrders={handleOrders}
                        handleError={handleError}
                    />
                ))
            }
        </div>
    )
});

export const OrderGroupContext = createContext();
export const OrderContext = createContext();

export function PlaceOrder() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const orderListState = useSelector(ordersState);

    const [pickUpForm, setPickUpForm] = useState({
        pickUpPersonName: "",
        pickUpContactNumber: "",
        pickUpDateTime: "",
        pickUpLocation: {
            unitNumber: "",
            address: "",
            coordinate: {
                latitude: "",
                longitude: ""
            },
            city: "",
            country: ""
        }
    });
    const [dropOffForm, setDropOffForm] = useState({
        recipientName: "",
        recipientPhone: "",
        deliverDateTime: "",
        dropOffLocation: {
            unitNumber: "",
            address: "",
            coordinate: {
                latitude: "",
                longitude: ""
            },
            city: "",
            country: ""
        },
        doorToDoor: false,
        isCodEnabled: false,
        codAmount: 0,
    });
    const [addStop, setAddStop] = useState(true);
    const [enterPickUpDetails, setEnterPickUpDetails] = useState(false);
    const [enterDropOffDetails, setEnterDropOffDetails] = useState(false);
    const [editOrderOrdinal, setEditOrderOrdinal] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        // retrieve all saved addresses
        dispatch(getAllSavedAddresses());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!localStorage.refresh_token) {
            window.location = "/login";
        }
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(handleGetOrdersByOrderGroup(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (id && orderListState.orderGroup.id !== null) {
            setPickUpForm(prevPickUpForm => ({
                ...prevPickUpForm,
                pickUpDateTime: orderListState.orderGroup.pickUpDateTime,
                pickUpPersonName: orderListState.orderGroup.pickUpPersonName,
                pickUpContactNumber: orderListState.orderGroup.pickUpContactNumber,
                pickUpLocation: orderListState.orderGroup.pickUpLocation
            }));
        }
    }, [id, orderListState]);

    const onPickUpDetailsChange = (unitNumber, pickUpContactNumber, pickUpPersonName) => {
        let error = false;

        if (pickUpContactNumber === "") {
            error = true;
            setError(true);
            setErrorMessage("Please enter the sender's contact number.");
            window.scrollTo(0, 0);
            return;
        }

        var formattedPhoneNumber = "+" + pickUpContactNumber.replace(/\s/g, '').replace(/-/g, '');

        // make sure phone number start with + sign
        if (!formattedPhoneNumber.startsWith("+")) {
            error = true;
            setError(true);
            setErrorMessage("Sender's contact number must start with the + sign followed by the country code (eg. +60).");
            window.scrollTo(0, 0);
            return;
        }

        // validate phone number using google lib
        try {
            let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
            if (!phoneUtil.isValidNumber(phoneNumberProto)) {
                error = true;
                setError(true);
                setErrorMessage("Sender's contact number is invalid.");
                window.scrollTo(0, 0);
                return;
            }
        } catch (e) {
            error = true;
            setError(true);
            setErrorMessage("Sender's contact number is invalid.");
            window.scrollTo(0, 0);
            return;
        }

        if (pickUpPersonName === "") {
            error = true;
            setError(true);
            setErrorMessage("Please enter the sender's name.");
            window.scrollTo(0, 0);
            return;
        }

        if (!error) {
            setPickUpForm(prevPickUpForm => ({
                ...prevPickUpForm,
                pickUpContactNumber: formattedPhoneNumber,
                pickUpPersonName: pickUpPersonName,
                pickUpLocation: {
                    ...prevPickUpForm.pickUpLocation,
                    unitNumber: unitNumber,
                }
            }));
            // Make sure redux state is in sync for smoother experience
            dispatch(setOrderGroup({
                ...orderListState.orderGroup,
                pickUpContactNumber: formattedPhoneNumber,
                pickUpPersonName: pickUpPersonName,
                pickUpLocation: {
                    ...orderListState.orderGroup.pickUpLocation,
                    unitNumber: unitNumber,
                }
            }));
            setEnterPickUpDetails(false);
            setError(false);
            setErrorMessage("");
        }
    }

    const onDropOffDetailsChange = async (deliverDateTime, unitNumber, recipientPhone, recipientName, doorToDoor, isCodEnabled, codAmount) => {
        let error = false;

        if (deliverDateTime === "") {
            error = true;
            setError(true);
            setErrorMessage("Please select the delivery date.");
            window.scrollTo(0, 0);
            return;
        }

        if (new Date(deliverDateTime) - new Date() < 0) {
            error = true;
            setError(true);
            setErrorMessage("Delivery date should not be in the past.");
            window.scrollTo(0, 0);
            return;
        }

        if (new Date(deliverDateTime) - new Date(pickUpForm.pickUpDateTime) < 0) {
            error = true;
            setError(true);
            setErrorMessage("Delivery date should be later than pick-up date.");
            window.scrollTo(0, 0);
            return;
        }

        if (recipientPhone === "") {
            error = true;
            setError(true);
            setErrorMessage("Please enter the recipient's contact number.");
            window.scrollTo(0, 0);
            return;
        }

        var formattedPhoneNumber = "+" + recipientPhone.replace(/\s/g, '').replace(/-/g, '');

        // make sure phone number start with + sign
        if (!formattedPhoneNumber.startsWith("+")) {
            error = true;
            setError(true);
            setErrorMessage("Recipient's contact number must start with the + sign followed by the country code (eg. +60).");
            window.scrollTo(0, 0);
            return;
        }

        // validate phone number using google lib
        try {
            let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
            if (!phoneUtil.isValidNumber(phoneNumberProto)) {
                error = true;
                setError(true);
                setErrorMessage("Recipient's contact number is invalid.");
                window.scrollTo(0, 0);
                return;
            }
        } catch (e) {
            error = true;
            setError(true);
            setErrorMessage("Recipient's contact number is invalid.");
            window.scrollTo(0, 0);
            return;
        }

        if (recipientName === "") {
            error = true;
            setError(true);
            setErrorMessage("Please enter the recipient's name.");
            window.scrollTo(0, 0);
            return;
        }

        if (isCodEnabled && (codAmount === null || isNaN(codAmount) || codAmount <= 0)) {
            setError(true);
            setErrorMessage("COD amount is required.");
            window.scrollTo(0, 0);
            return;
        }

        if (!error) {
            if (enterDropOffDetails && addStop) {
                setDropOffForm(prevDropOffForm => ({
                    ...prevDropOffForm,
                    recipientPhone: formattedPhoneNumber,
                    recipientName: recipientName,
                    deliverDateTime: deliverDateTime,
                    dropOffLocation: {
                        ...prevDropOffForm.dropOffLocation,
                        unitNumber: unitNumber,
                    },
                    doorToDoor: doorToDoor,
                    isCodEnabled: isCodEnabled,
                    codAmount: (codAmount * 100)
                }));

                setEnterDropOffDetails(false);
                setError(false);
                setErrorMessage('');
            } else if (editOrderOrdinal !== "") {
                // Update Order
                const order = orderListState.orderGroup.orders[editOrderOrdinal];
                const orderPayload = {
                    ...order,
                    pickUpDateTime: pickUpForm.pickUpDateTime,
                    recipientPhone: formattedPhoneNumber,
                    recipientName: recipientName,
                    deliverDateTime: new Date(deliverDateTime).toISOString(),
                    dropOffLocation: {
                        ...order.dropOffLocation,
                        unitNumber: unitNumber,
                    },
                    doorToDoor: doorToDoor,
                    isCodEnabled: isCodEnabled,
                    codAmount: isCodEnabled ? (codAmount * 100) : ""
                }
                await updateOrder(orderListState.orderGroup.id, orderPayload, order.id);
                dispatch(handleGetOrdersByOrderGroup(orderListState.orderGroup.id));

                setEditOrderOrdinal("");
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            let orders = [...orderListState.orderGroup.orders].sort((a, b) => a.ordinal - b.ordinal);
            if (newIndex > oldIndex) {
                for (let i = oldIndex; i <= newIndex; i++) {
                    let newOrdinal = i === oldIndex ? newIndex : i - 1;
                    orders[i] = { ...orders[i], ordinal: newOrdinal };
                }
            } else {
                for (let i = oldIndex; i >= newIndex; i--) {
                    let newOrdinal = i === oldIndex ? newIndex : i + 1;
                    orders[i] = { ...orders[i], ordinal: newOrdinal };
                }
            }

            // update backend & calculate new pricing
            dispatch(handleOnSortOrder(oldIndex, newIndex));
        }
    }

    const handleOrders = () => {
        setDropOffForm(prevDropOffForm => ({
            ...prevDropOffForm,
            recipientName: "",
            recipientPhone: "",
            deliverDateTime: "",
            dropOffLocation: {
                unitNumber: "",
                address: "",
                coordinate: {
                    latitude: "",
                    longitude: ""
                },
                city: "",
                country: ""
            },
            doorToDoor: false,
            isCodEnabled: false,
            codAmount: 0,
        }));

        setAddStop(false);
    }

    const handleUseSavedPickUpAddress = (addr) => {
        let error = false;

        if (addr.pickUpContactNumber === "" || addr.pickUpPersonName === "") {
            error = true;
            setError(true);
            setErrorMessage("Please ensure that you have a valid pick-up contact number and pick-up person name in your saved address.");
            window.scrollTo(0, 0);
            return;
        }

        var formattedPhoneNumber = addr.pickUpContactNumber.replace(/\s/g, '').replace(/-/g, '');

        // make sure phone number start with + sign
        if (!formattedPhoneNumber.startsWith("+")) {
            error = true;
            setError(true);
            setErrorMessage("Please ensure that the pick-up contact number in your saved address starts with the + sign followed by the country code (eg. +60).");
            window.scrollTo(0, 0);
            return;
        }

        // validate phone number using google lib
        try {
            let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
            if (!phoneUtil.isValidNumber(phoneNumberProto)) {
                error = true;
                setError(true);
                setErrorMessage("Please ensure that you have a valid pick-up contact number in your saved address.");
                window.scrollTo(0, 0);
                return;
            }
        } catch (e) {
            error = true;
            setError(true);
            setErrorMessage("Please ensure that you have a valid pick-up contact number in your saved address.");
            window.scrollTo(0, 0);
            return;
        }

        if (!error) {
            setPickUpForm(prevPickUpForm => ({
                ...prevPickUpForm,
                pickUpContactNumber: formattedPhoneNumber,
                pickUpPersonName: addr.pickUpPersonName,
                pickUpLocation: {
                    ...prevPickUpForm.pickUpLocation,
                    ...addr.location
                }
            }));

            setError(false);
            setErrorMessage('');
        }
    }

    const handleUseSavedDropOffAddress = (addr) => {
        let error = false;

        if (addr.recipientPhone === "" || addr.recipientName === "") {
            error = true;
            setError(true);
            setErrorMessage("Please ensure that you have a valid recipient contact number and recipient name in your saved address.");
            window.scrollTo(0, 0);
            return;
        }

        var formattedPhoneNumber = addr.recipientPhone.replace(/\s/g, '').replace(/-/g, '');

        // make sure phone number start with + sign
        if (!formattedPhoneNumber.startsWith("+")) {
            error = true;
            setError(true);
            setErrorMessage("Please ensure that the recipient contact number in your saved address starts with the + sign followed by the country code (eg. +60).");
            window.scrollTo(0, 0);
            return;
        }

        // validate phone number using google lib
        try {
            let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
            if (!phoneUtil.isValidNumber(phoneNumberProto)) {
                error = true;
                setError(true);
                setErrorMessage("Please ensure that you have a valid recipient contact number in your saved address.");
                window.scrollTo(0, 0);
                return;
            }
        } catch (e) {
            error = true;
            setError(true);
            setErrorMessage("Please ensure that you have a valid recipient contact number in your saved address.");
            window.scrollTo(0, 0);
            return;
        }

        if (!error) {
            setDropOffForm(prevDropOffForm => ({
                ...prevDropOffForm,
                recipientPhone: formattedPhoneNumber,
                recipientName: addr.recipientName,
                dropOffLocation: {
                    ...prevDropOffForm.dropOffLocation,
                    ...addr.location
                }
            }));
            setEnterDropOffDetails(true);
            setError(false);
            setErrorMessage("");
        }
    }

    const selectedVoucherDiscountAmount = orderListState?.selectedVoucher?.promoCode?.discountAmount;
    const selectedVoucherDiscountPercentage = orderListState?.selectedVoucher?.promoCode?.discountPercentage;

    return (
        <>
            {orderListState.loading && <LoadingOverlay confirmingOrder={orderListState.disableConfirmOrderGroup} />}
            {orderListState.showImportModal && <ImportModal />}
            {orderListState.showSavedPickUpAddrModal && <SavedAddressModal type="PICK_UP" useSavedAddress={(addr) => handleUseSavedPickUpAddress(addr)} />}
            {orderListState.showSavedDropOffAddrModal && <SavedAddressModal type="DROP_OFF" useSavedAddress={(addr) => handleUseSavedDropOffAddress(addr)} />}
            {orderListState.showVoucherModal && <VoucherModal />}
            {orderListState.showPaymentModal && <PaymentModal />}
            {orderListState.showTopUpModal && <TopUpModal closeModal={() => dispatch(setShowTopUpModal(false))} />}

            <div className={styles.container}>
                <Topbar page="Place Order" />

                {/* Order Menu */}
                <div className={styles.orderMenu}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <p className={styles.whiteTitle}>Ready to deliver?</p>
                        <InfoTooltip
                            title="Drag and rearrange the drop-off locations to obtain the cheapest delivery rates."
                            placement="right"
                            arrow>
                            <div style={{ marginLeft: "auto" }}>
                                <i className="fas fa-info-circle" style={{ color: "#53d9e0", fontSize: 20 }} />
                            </div>
                        </InfoTooltip>
                    </div>

                    {/* Error Alert */}
                    {
                        error &&
                        <Alert
                            severity="error"
                            style={{ marginTop: 5, borderRadius: 10, textAlign: "left" }}
                            onClose={() => {
                                setError(false);
                                setErrorMessage('');
                            }}>
                            {errorMessage}
                        </Alert>
                    }

                    {/* Pick-Up */}
                    <OrderGroupContext.Provider value={{ pickUpForm, dropOffForm }}>
                        <OrderGroup
                            handlePickUpLocation={(location) =>
                                setPickUpForm(prevPickUpForm => ({
                                    ...prevPickUpForm,
                                    pickUpLocation: {
                                        ...prevPickUpForm.pickUpLocation,
                                        ...location
                                    }
                                }))
                            }
                            handlePickUpDateTime={(date) => setPickUpForm(prevPickUpForm => ({
                                ...prevPickUpForm,
                                pickUpDateTime: date
                            }))}
                            showPickUpModal={() => {
                                setEnterPickUpDetails(true);
                                setEnterDropOffDetails(false);
                                setEditOrderOrdinal("");
                            }}
                            closePickUpModal={() => setEnterPickUpDetails(false)}
                            handleError={(error) => setError(error)}
                            handleErrorMsg={(msg) => setErrorMessage(msg)}
                        />
                    </OrderGroupContext.Provider>

                    {/* Drop-off */}
                    <div className={styles.dropOffContainer}>
                        {/* Sortable Orders */}
                        {
                            orderListState.orderGroup.orders && orderListState.orderGroup.orders.length > 0 &&
                            <SortableList
                                shouldCancelStart={(e) => {
                                    let cancel = false;
                                    orderListState.orderGroup.orders.forEach((order, index) => {
                                        if (e.target === document.getElementById(`dropOffAutocomplete-${index}`)
                                            || e.target === document.getElementById(`editDetails-${index}`)
                                            || e.target === document.getElementById(`deleteIcon-${index}`)
                                            || e.target === document.getElementById(`addrBookIcon-${index}`)
                                            || e.target === document.getElementById(`editIcon-${index}`)
                                            || e.target === document.getElementById(`detailsString-${index}`)
                                            || e.target === document.getElementById(`enterDetails-${index}`)) {
                                            cancel = true;
                                        }
                                    });
                                    return cancel;
                                }}
                                onSortEnd={onSortEnd}
                                orderListState={orderListState}
                                editOrderOrdinal={editOrderOrdinal}
                                showDropOffModal={(ordinal) => {
                                    setEnterPickUpDetails(false);
                                    setEnterDropOffDetails(false);
                                    setEditOrderOrdinal(ordinal);
                                }}
                                handleAddressChange={(location) =>
                                    setDropOffForm(prevDropOffForm => ({
                                        ...prevDropOffForm,
                                        dropOffLocation: {
                                            ...prevDropOffForm.dropOffLocation,
                                            ...location,
                                        },
                                    }))
                                }
                                handleOrders={handleOrders}
                                handleError={(err, errMsg) => {
                                    setError(err);
                                    setErrorMessage(errMsg);
                                }}
                            />
                        }

                        {/* New Order */}
                        {
                            addStop &&
                            <div style={{ backgroundColor: enterDropOffDetails ? "#FFFBD4" : "white" }}>
                                <div className={styles.horizontalDivider} />
                                <OrderContext.Provider value={dropOffForm}>
                                    <Order
                                        addStop={addStop}
                                        currIdx={orderListState.orderGroup.orders.length}
                                        editOrder={enterDropOffDetails ? true : false}
                                        nextOrdinal={orderListState.orderGroup.nextOrdinal}
                                        showDropOffModal={() => {
                                            setEnterPickUpDetails(false);
                                            setEnterDropOffDetails(true);
                                            setEditOrderOrdinal("");
                                        }}
                                        closeDropOffModal={() => setEnterDropOffDetails(false)}
                                        handleAddressChange={(location) =>
                                            setDropOffForm(prevDropOffForm => ({
                                                ...prevDropOffForm,
                                                dropOffLocation: {
                                                    ...prevDropOffForm.dropOffLocation,
                                                    ...location,
                                                }
                                            }))
                                        }
                                        handleOrders={(order) => handleOrders(order)}
                                        handleError={(err, errMsg) => {
                                            setError(err);
                                            setErrorMessage(errMsg);
                                        }}
                                    />
                                </OrderContext.Provider>
                                <div className={styles.horizontalDivider} />
                            </div>
                        }
                        <div className={styles.horizontalDivider} />
                        <div className={styles.row} style={{ paddingBlock: 15, cursor: "pointer" }} onClick={() => { setAddStop(true); setEditOrderOrdinal(""); }}>
                            <img className={styles.icon} src={plusIcon} alt="plus" />
                            <p className={styles.tealText}>Add Stop</p>
                        </div>
                    </div>

                    {/* Import Bulk Order */}
                    {
                        (orderListState.orderGroup.orders && orderListState.orderGroup.orders.length > 0) ? (
                            <InfoTooltip
                                title="Please remove existing drop-off locations to import bulk order."
                                placement="top"
                                arrow>
                                <div className={styles.importButton} style={{ opacity: 0.5 }}>
                                    <img style={{ width: 10, height: 10, objectFit: "contain" }} src={importIcon} alt="import" />
                                    <p className={styles.whiteText} style={{ marginLeft: 8, fontSize: 11, fontWeight: 400 }}>Import Bulk Order</p>
                                </div>
                            </InfoTooltip>
                        ) : (
                            <div
                                className={styles.importButton}
                                onClick={() => {
                                    if (orderListState.orderGroup.id != null) {
                                        dispatch(setShowImportModal(true))
                                    } else {
                                        setError(true);
                                        setErrorMessage("Please select a pick-up location and fill in all required fields before importing bulk order.");
                                    }
                                }}
                            >
                                <img style={{ width: 10, height: 10, objectFit: "contain" }} src={importIcon} alt="import" />
                                <p className={styles.whiteText} style={{ marginLeft: 8, fontSize: 11, fontWeight: 400 }}>Import Bulk Order</p>
                            </div>
                        )
                    }

                    {/* Bottom Container */}
                    {
                        (orderListState.orderGroup.id && orderListState.orderGroup.orders && orderListState.orderGroup.orders.length > 0) &&
                        <div className={styles.bottomContainer}>
                            {/* Voucher */}
                            <div className={styles.voucherContainer}>
                                <img className={styles.icon} src={voucherIcon} alt="voucher" />
                                {
                                    orderListState.selectedVoucher
                                        ? <p className={styles.blackText}>{orderListState.selectedVoucher.promoCode.title ? orderListState.selectedVoucher.promoCode.title : "Applied Voucher"}</p>
                                        : <p className={styles.greyText}>Enter voucher</p>
                                }
                                <InfoTooltip
                                    title="When using a promo code, please note that the discount amount will only be applied upon the completion of order (subject to promo code availability). Keep this in mind when placing your order to avoid confusion."
                                    placement="right"
                                    arrow>
                                    <div>
                                        <i className="fas fa-info-circle" style={{ marginInline: 10, color: "#e0e0e0", fontSize: 20 }} />
                                    </div>
                                </InfoTooltip>
                                {
                                    orderListState.selectedVoucher ? (
                                        <div style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
                                            <p className={styles.greenText}>
                                                {selectedVoucherDiscountPercentage
                                                    ? (`- ${selectedVoucherDiscountPercentage}%`)
                                                    : (`- ${orderListState.orderGroup.currency} ${(selectedVoucherDiscountAmount !== undefined && selectedVoucherDiscountAmount !== null) ? (selectedVoucherDiscountAmount / 100).toFixed(2) : '0.00'}`)
                                                }
                                            </p>
                                            <img className={styles.icon} style={{ width: 12, cursor: "pointer" }} src={closeIcon} alt="close"
                                                onClick={() => { dispatch(setSelectedVoucher(null)); dispatch(getEstimatedTotal()) }} />
                                        </div>
                                    ) : (
                                        <div className={styles.selectVoucherButton} onClick={() => dispatch(setShowVoucherModal(true))}>
                                            <p style={{ margin: 0 }}>Select</p>
                                        </div>
                                    )
                                }
                            </div>

                            {/* Estimated Total */}
                            <div className={styles.totalContainer}>
                                <div>
                                    <p className={styles.regularTealText}>Subtotal</p>
                                    {
                                        orderListState.selectedVoucher &&
                                        <p className={styles.regularTealText}>Voucher Applied</p>
                                    }
                                    <p className={styles.blackText}>Total</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "auto" }}>
                                    <p className={styles.regularTealText} style={{ textAlign: "right" }}>{orderListState.orderGroup.currency}{(orderListState.orderGroup.subtotal / 100).toFixed(2)}</p>
                                    {
                                        orderListState.selectedVoucher &&
                                        <p className={styles.regularTealText} style={{ textAlign: "right" }}>-{orderListState.orderGroup.currency}{(orderListState.orderGroup.discountAmount / 100).toFixed(2)}</p>
                                    }
                                    <p className={styles.blackText} style={{ fontSize: 13, textAlign: "right" }}>{orderListState.orderGroup.currency}{(orderListState.orderGroup.estimatedTotal / 100).toFixed(2)}</p>
                                </div>
                            </div>

                            {/* Confirm Order */}
                            {
                                !error
                                    ? <div className={styles.button} onClick={() => dispatch(setShowPaymentModal(true))}>
                                        <p className={styles.whiteText}>Confirm Order</p>
                                    </div>
                                    : <div className={styles.button} style={{ backgroundColor: "#dedede", cursor: "default" }}>
                                        <p className={styles.whiteText}>Confirm Order</p>
                                    </div>
                            }
                        </div>
                    }
                </div>

                {/* Map */}
                {
                    window.innerWidth > 850 &&
                    <div className={styles.map}>
                        {
                            enterPickUpDetails &&
                            <EnterPickUpDetails
                                pickUpForm={pickUpForm}
                                handlePickUpDetails={onPickUpDetailsChange}
                                handleCancel={() => {
                                    setEnterPickUpDetails(false);
                                    setError(false);
                                    setErrorMessage('');
                                }}
                            />
                        }
                        {
                            enterDropOffDetails && addStop &&
                            <EnterDropOffDetails
                                dropOffForm={{
                                    ...dropOffForm,
                                    codAmount: (dropOffForm.codAmount / 100).toFixed(2)
                                }}
                                handleDropOffDetails={onDropOffDetailsChange}
                                handleCancel={() => {
                                    setEnterDropOffDetails(false);
                                    setError(false);
                                    setErrorMessage('');
                                }}
                            />
                        }
                        {
                            orderListState.orderGroup.orders && orderListState.orderGroup.orders[editOrderOrdinal] && orderListState.orderGroup.orders.length > 0 && editOrderOrdinal !== "" &&
                            <EnterDropOffDetails
                                dropOffForm={{
                                    ...orderListState.orderGroup.orders[editOrderOrdinal],
                                    pickUpDateTime: pickUpForm.pickUpDateTime,
                                    isCodEnabled: orderListState.orderGroup.orders[editOrderOrdinal].codAmount > 0 ? true : false,
                                    codAmount: orderListState.orderGroup.orders[editOrderOrdinal].codAmount
                                        ? (orderListState.orderGroup.orders[editOrderOrdinal].codAmount / 100).toFixed(2)
                                        : 0
                                }}
                                handleDropOffDetails={onDropOffDetailsChange}
                                handleCancel={() => {
                                    setEditOrderOrdinal("");
                                    setError(false);
                                    setErrorMessage('');
                                }}
                            />
                        }
                        {
                            orderListState.directions.length > 0
                                ? <PlaceOrderMap
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: "100%" }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    dispatch={dispatch}
                                    orderListState={orderListState}
                                    directions={orderListState.directions}
                                    showPickUpModal={() => {
                                        setEnterPickUpDetails(true);
                                        setEditOrderOrdinal('');
                                    }}
                                    showDropOffModal={(ordinal) => {
                                        setEnterPickUpDetails(false);
                                        setEditOrderOrdinal(ordinal);
                                    }}
                                />
                                : <Map
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: "100%" }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                        }
                    </div>
                }

                {/* Responsive UI */}
                {
                    window.innerWidth <= 850 &&
                    <>
                        {
                            enterPickUpDetails &&
                            <EnterPickUpDetails
                                pickUpForm={pickUpForm}
                                handlePickUpDetails={onPickUpDetailsChange}
                                handleCancel={() => {
                                    setEnterPickUpDetails(false);
                                    setError(false);
                                    setErrorMessage('');
                                }}
                            />
                        }
                        {
                            enterDropOffDetails && addStop &&
                            <EnterDropOffDetails
                                dropOffForm={{
                                    ...dropOffForm,
                                    codAmount: (dropOffForm.codAmount / 100).toFixed(2)
                                }}
                                handleDropOffDetails={onDropOffDetailsChange}
                                handleCancel={() => {
                                    setEnterDropOffDetails(false);
                                    setError(false);
                                    setErrorMessage('');
                                }}
                            />
                        }
                        {
                            orderListState.orderGroup.orders && orderListState.orderGroup.orders.length > 0 && editOrderOrdinal !== "" &&
                            <EnterDropOffDetails
                                dropOffForm={{
                                    ...orderListState.orderGroup.orders[editOrderOrdinal],
                                    pickUpDateTime: pickUpForm.pickUpDateTime,
                                    isCodEnabled: orderListState.orderGroup.orders[editOrderOrdinal].codAmount > 0 ? true : false,
                                    codAmount: orderListState.orderGroup.orders[editOrderOrdinal].codAmount
                                        ? (orderListState.orderGroup.orders[editOrderOrdinal].codAmount / 100).toFixed(2)
                                        : 0
                                }}
                                handleDropOffDetails={onDropOffDetailsChange}
                                handleCancel={() => {
                                    setEditOrderOrdinal("");
                                    setError(false);
                                    setErrorMessage('');
                                }}
                            />
                        }
                    </>
                }
            </div>
        </>
    )
}