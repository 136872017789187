import React, { useEffect, useState } from 'react';
import styles from './PlaceOrder.module.css';
import { useDispatch, useSelector } from 'react-redux';

import { InfoTooltip } from './common';
import { handleConfirmOrders, ordersState, setShowPaymentModal, setShowTopUpModal } from './placeOrderSlice';
import { getUserWallet } from '../wallet/walletAPI';

import closeIcon from "../../images/place_order/close-icon.png";
import walletIcon from "../../images/place_order/wallet-icon.png";

export const PaymentModal = () => {
  const dispatch = useDispatch();
  const orderListState = useSelector(ordersState);

  const [walletBalance, setWalletBalance] = useState("");
  const [notesToRider, setNotesToRider] = useState("");

  useEffect(() => {
    const getUserWalletBalance = async () => {
      if (localStorage.user) {
        let user = JSON.parse(localStorage.user);
        const walletResp = await getUserWallet(user.id);
        setWalletBalance(walletResp.data.balanceAmount);
      }
    }
    getUserWalletBalance();
  }, []);

  const confirmOrders = () => {
    if (!orderListState.disableConfirmOrderGroup) {
      dispatch(handleConfirmOrders(notesToRider));
    }
  }

  return (
    <>
      <div className={styles.paymentModal}>
        <div>
          <div style={{ display: "flex" }}>
            <p className={styles.tealText} style={{ textAlign: "left", fontWeight: "500" }}>Notes to rider</p>
            <img className={styles.icon} style={{ width: 10, marginLeft: "auto", marginRight: 0, marginTop: -5, cursor: "pointer" }} src={closeIcon} alt="close" onClick={() => dispatch(setShowPaymentModal(false))} />
          </div>

          <textarea
            id="notesToRider"
            name="notesToRider"
            value={notesToRider}
            onChange={(e) => setNotesToRider(e.target.value)}
            placeholder="Landmarks close to address, amount of goods to be collected or any important intructions"
            className={styles.notesToRiderInput}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className={styles.tealText} style={{ textAlign: "left", fontWeight: "500" }}>Total Charges</p>
                <InfoTooltip
                  title={`Wallet will be charged ${orderListState.orderGroup.currency}${(orderListState.orderGroup.subtotal / 100).toFixed(2)}. ${orderListState.orderGroup.currency}${(orderListState.orderGroup.discountAmount / 100).toFixed(2)} discount will be credited back to wallet once order has been completed.`}
                  placement="top"
                  arrow>
                  <div>
                    <i className="fas fa-info-circle" style={{ marginInline: 8, color: "#117f84", fontSize: 16 }} />
                  </div>
                </InfoTooltip>
              </div>
              <p className={styles.greyText} style={{ marginTop: 3, fontSize: 10 }}>including tax and door-to-door fees</p>
            </div>
            <p className={styles.blackText} style={{ marginLeft: "auto", marginRight: 10, textAlign: "right" }}>{orderListState.orderGroup.currency} {(orderListState.orderGroup.estimatedTotal / 100).toFixed(2)}</p>
          </div>
        </div>

        {/* Pay Button */}
        <div className={styles.payButtonContainer}>
          <img className={styles.walletIcon} src={walletIcon} alt="wallet-icon" />
          <div>
            <p className={styles.tealText} style={{ textAlign: "left" }}>Wallet</p>
            <p className={styles.greyText} style={{ marginTop: 3, fontSize: 10, color: (walletBalance < orderListState.orderGroup.subtotal) && "red" }}>Balance: {orderListState.orderGroup.currency} {(walletBalance / 100).toFixed(2)}</p>
          </div>
          {
            walletBalance < orderListState.orderGroup.subtotal
              ? <div className={styles.payButton} onClick={() => dispatch(setShowTopUpModal(true))}>
                <p className={styles.whiteText} style={{ fontSize: 13 }}>TOP UP</p>
              </div>
              : <div className={styles.payButton} onClick={() => confirmOrders()}>
                <p className={styles.whiteText} style={{ fontSize: 13 }}>PAY</p>
              </div>
          }
        </div>
      </div>
      <div className={styles.overlay} onClick={() => dispatch(setShowPaymentModal(false))}></div>
    </>
  )
}