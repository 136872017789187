import { createSlice } from '@reduxjs/toolkit';
import { getUserAddresses } from './addressAPI';

export const savedAddressSlice = createSlice({
  name: 'savedAddress',
  initialState: {
    pickUpAddressList: [],
    dropOffAddressList: [],
    showCreateAddressModal: false,
    showEditAddressModal: false,
    showDeleteAddressModal: false,
    selectedAddr: null
  },
  reducers: {
    setPickUpAddressList: (state, action) => {
      state.pickUpAddressList = action.payload;
    },
    setDropOffAddressList: (state, action) => {
      state.dropOffAddressList = action.payload;
    },
    setShowCreateAddressModal: (state, action) => {
      state.showCreateAddressModal = action.payload;
    },
    setShowEditAddressModal: (state, action) => {
      state.showEditAddressModal = action.payload;
    },
    setShowDeleteAddressModal: (state, action) => {
      state.showDeleteAddressModal = action.payload;
    },
    setSelectedAddr: (state, action) => {
      state.selectedAddr = action.payload;
    }
  },
});

export const { setPickUpAddressList, setDropOffAddressList, setShowCreateAddressModal, setShowEditAddressModal, setShowDeleteAddressModal, setSelectedAddr } = savedAddressSlice.actions;

export const getSavedAddresses = (addrType) => async (dispatch) => {
  if (addrType === "PICK_UP") {
    const pickUpResp = await getUserAddresses(localStorage.vendorId, "PICK_UP", 0, 500);
    dispatch(setPickUpAddressList(pickUpResp.data.content));
  } else {
    const dropOffResp = await getUserAddresses(localStorage.vendorId, "DROP_OFF", 0, 500);
    dispatch(setDropOffAddressList(dropOffResp.data.content));
  }
}

export const getAllSavedAddresses = () => async (dispatch) => {
  const pickUpResp = await getUserAddresses(localStorage.vendorId, "PICK_UP", 0, 500);
  dispatch(setPickUpAddressList(pickUpResp.data.content));

  const dropOffResp = await getUserAddresses(localStorage.vendorId, "DROP_OFF", 0, 500);
  dispatch(setDropOffAddressList(dropOffResp.data.content));
}

export const getPlaceLongName = (address_components, type) => {
  let component = address_components.find(c => c.types.includes(type));
  if (component) {
    return component.long_name;
  }
  return null;
}

export const savedAddressState = state => state.savedAddress;

export default savedAddressSlice.reducer;