/*global google*/

import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './PlaceOrder.module.css';
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";

import { InfoTooltip } from './common';
import { OrderContext } from './PlaceOrder';
import { createOrder, deleteOrder, updateOrder } from './orderAPI';
import { getPlaceLongName, handleGetOrdersByOrderGroup, ordersState, setDisableCreateOrder, setShowSavedDropOffAddrModal } from './placeOrderSlice';

import mapMarkerIcon from "../../images/place_order/map-marker-icon.png";
import closeIcon from "../../images/place_order/close-icon.png";
import savedAddressIcon from "../../images/place_order/saved-address-icon.png";

export function Order(props) {
  const dispatch = useDispatch();
  const orderContext = useContext(OrderContext);
  const orderListState = useSelector(ordersState);
  const { currIdx, addStop, editOrder: editOrderProps, nextOrdinal, handleOrders, handleAddressChange, closeDropOffModal, showDropOffModal, handleError } = props;

  const [dropOffForm, setDropOffForm] = useState({
    ordinal: "",
    recipientName: orderContext.recipientName ? orderContext.recipientName : "",
    recipientPhone: orderContext.recipientPhone ? orderContext.recipientPhone : "",
    deliverDateTime: orderContext.deliverDateTime ? orderContext.deliverDateTime : "",
    dropOffLocation: orderContext.dropOffLocation,
    isCodEnabled: orderContext.isCodEnabled ? orderContext.isCodEnabled : false,
    codAmount: orderContext.codAmount ? orderContext.codAmount : 0,
    doorToDoor: orderContext.doorToDoor ? orderContext.doorToDoor : "",
  });
  const [autocompleteInit, setAutocompleteInit] = useState(false);
  const [disableDeleteOrder, setDisableDeleteOrder] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setDropOffForm({
      ...dropOffForm,
      recipientName: orderContext.recipientName,
      recipientPhone: orderContext.recipientPhone,
      deliverDateTime: orderContext.deliverDateTime,
      dropOffLocation: orderContext.dropOffLocation,
      isCodEnabled: orderContext.isCodEnabled,
      codAmount: orderContext.codAmount,
      doorToDoor: orderContext.doorToDoor,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderContext]);

  const validateFields = useCallback(() => {
    let error = false;

    if (dropOffForm.dropOffLocation.country === null || dropOffForm.dropOffLocation.country === "") {
      error = true;
      handleError(true, "Please re-select the drop-off address from drop-down list.");
      return error;
    }

    for (var key in dropOffForm) {
      if (key !== "ordinal" && key !== "hadDelivered" && key !== "codAmount" && key !== "assigned" && key !== "receiptNumber" && key !== "deliveryRange" && key !== "doorToDoor" && key !== "noteToDriver" && key !== "doorToDoorFee") {
        if (dropOffForm[key] === null || dropOffForm[key] === "") {
          error = true;
          handleError(true, "Please complete all the fields.");
          return error;
        }
      }
    }
    return error;
  }, [dropOffForm, handleError]);

  const editOrder = useCallback(async (location) => {
    const error = validateFields();
    if (!error) {
      try {
        if (orderContext.id !== "") {
          // Update Order
          let orderPayload = {
            ...orderContext,
            deliverDateTime: new Date(orderContext.deliverDateTime).toISOString(),
            dropOffLocation: location
          }
          await updateOrder(orderListState.orderGroup.id, orderPayload, orderContext.id);
          dispatch(handleGetOrdersByOrderGroup(orderListState.orderGroup.id));
          handleError(false, "");
        }
      } catch (error) {
        handleError(true, error.message);
      }
    }
  }, [dispatch, handleError, orderContext, orderListState, validateFields]);

  const onPlaceChanged = useCallback((autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === `dropOffAutocomplete-${currIdx}`) {
        try {
          let location = {
            address: `${place.name}, ${place.formatted_address}`,
            coordinate: {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            },
            street: getPlaceLongName(place.address_components, "street_address"),
            apartment: getPlaceLongName(place.address_components, "street_address"),
            state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
            postalCode: getPlaceLongName(place.address_components, "postal_code"),
            city: getPlaceLongName(place.address_components, "locality"),
            country: getPlaceLongName(place.address_components, "country")
          };

          setDropOffForm(prevDropOffForm => ({
            ...prevDropOffForm,
            dropOffLocation: {
              ...prevDropOffForm.dropOffLocation,
              ...location
            }
          }));

          if (orderContext.id === undefined) {
            handleAddressChange(location);
          } else {
            editOrder(location);
          }
        } catch (error) {
          handleError(true, "Please select a drop-off address from the drop-down list.");
        }
      }
    }
  }, [currIdx, editOrder, handleAddressChange, handleError, orderContext]);

  const initializeGoogleAutocomplete = useCallback((elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my", "sg"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function () {
      onPlaceChanged(autocomplete, elementId);
    });
  }, [onPlaceChanged]);

  useEffect(() => {
    let dropOffAutocompleteInput = document.getElementById(`dropOffAutocomplete-${currIdx}`);
    if (!autocompleteInit && dropOffAutocompleteInput !== null) {
      initializeGoogleAutocomplete(`dropOffAutocomplete-${currIdx}`);
      setAutocompleteInit(true);
    }
  }, [autocompleteInit, currIdx, initializeGoogleAutocomplete]);

  const submitOrder = useCallback(async () => {
    const error = validateFields();
    if (!error) {
      try {
        if (!orderListState.disableCreateOrder && orderListState.orderGroup.id !== null && addStop) {
          dispatch(setDisableCreateOrder(true));

          // Create Order
          let orderPayload = {
            ...dropOffForm,
            ordinal: nextOrdinal,
            deliverDateTime: new Date(dropOffForm.deliverDateTime).toISOString(),
            codAmount: dropOffForm.isCodEnabled ? dropOffForm.codAmount : ""
          }

          await createOrder(orderListState.orderGroup.id, orderPayload);
          dispatch(setDisableCreateOrder(false));
          dispatch(handleGetOrdersByOrderGroup(orderListState.orderGroup.id));

          handleOrders();
          handleError(false, "");
        }
      } catch (error) {
        handleError(true, error.message);
      }
    }
  }, [addStop, dispatch, dropOffForm, handleError, handleOrders, nextOrdinal, orderListState, validateFields]);

  useEffect(() => {
    if (
      !formSubmitted
      && orderListState.orderGroup.id !== null
      && dropOffForm.recipientName !== ""
      && dropOffForm.recipientPhone !== ""
      && dropOffForm.deliverDateTime !== ""
      && dropOffForm.dropOffLocation.coordinate.latitude !== ""
      && dropOffForm.dropOffLocation.coordinate.longitude !== ""
    ) {
      setFormSubmitted(true);
      submitOrder();
    }
  }, [formSubmitted, dropOffForm, orderListState, submitOrder]);


  const removeOrder = async () => {
    setDisableDeleteOrder(true);

    try {
      if (!disableDeleteOrder && orderContext.id && orderContext.id !== "") {
        // Delete order
        await deleteOrder(orderListState.orderGroup.id, orderContext.id);
        dispatch(handleGetOrdersByOrderGroup(orderListState.orderGroup.id));
        handleError(false, "");
      }
    } catch (error) {
      handleError(true, error.message);
    } finally {
      setDisableDeleteOrder(false);
    }
  }

  return (
    <>
      <div className={styles.row} style={{ paddingBlock: 12 }}>
        {
          addStop
            ? <img className={styles.icon} style={{ width: 28, marginInline: 11 }} src={mapMarkerIcon} alt="map-marker" />
            : <>
              <i className="fas fa-bars" style={{ color: "#d9d9d9", fontSize: 12, cursor: "pointer" }} />
              <img className={styles.icon} style={{ width: 28, marginLeft: 1, marginRight: 11 }} src={mapMarkerIcon} alt="map-marker" />
            </>
        }
        <div className={styles.markerIconIndex}>{currIdx + 1}</div>
        <input
          className={styles.autocomplete}
          style={{ backgroundColor: editOrderProps ? "#FFFBD4" : "white" }}
          id={`dropOffAutocomplete-${currIdx}`}
          name="dropOffAddress"
          value={dropOffForm.dropOffLocation.address}
          onChange={(e) =>
            setDropOffForm({
              ...dropOffForm,
              dropOffLocation: {
                ...dropOffForm.dropOffLocation,
                address: e.target.value
              }
            })
          }
          placeholder="Enter drop-off location"
        />
        {
          !addStop &&
          <img id={`deleteIcon-${currIdx}`}
            className={styles.icon}
            style={{ width: 12, marginLeft: "auto", paddingInline: 6, cursor: "pointer" }}
            src={closeIcon}
            alt="close"
            onClick={() => !disableDeleteOrder && removeOrder()}
          />
        }
        {
          addStop &&
          <InfoTooltip
            title="Click to view saved drop-off addresses."
            placement="top"
            arrow
          >
            <img id={`addrBookIcon-${currIdx}`} className={styles.icon} style={{ marginLeft: "auto", cursor: "pointer" }} src={savedAddressIcon} alt="saved-addr" onClick={() => { closeDropOffModal(); dispatch(setShowSavedDropOffAddrModal(true)); }} />
          </InfoTooltip>
        }
      </div>
      <div id={`editDetails-${currIdx}`} className={styles.descriptionRow} style={{ cursor: "pointer" }} onClick={() => showDropOffModal(currIdx)}>
        {
          dropOffForm.recipientName
            ? <>
              <i id={`editIcon-${currIdx}`} className="far fa-edit" style={{ color: "#979797", fontSize: 15, marginRight: 10 }} />
              <p id={`detailsString-${currIdx}`} className={styles.greyText} style={{ marginRight: 10 }}>{dropOffForm.dropOffLocation.unitNumber !== "" && dropOffForm.dropOffLocation.unitNumber + "\u00a0 • \u00a0"}{dropOffForm.recipientPhone}&nbsp; • &nbsp;{dropOffForm.recipientName}</p>
            </>
            : <p id={`enterDetails-${currIdx}`} className={styles.greyText}>Enter details...</p>
        }
        {
          orderContext.deliveryFee &&
          <div className={styles.deliveryFeeContainer}>
            <p className={styles.blackText} style={{ fontSize: 10, textAlign: "center" }}>{(orderContext.currency === "MYR") ? "RM" : orderContext.currency} {(orderContext.deliveryFee / 100).toFixed(2)}</p>
          </div>
        }
      </div>
      {
        orderContext.doorToDoorFee > 0 &&
        <div className={styles.descriptionRow}>
          <p className={styles.lightTealText}>Door-to-door {(orderContext.currency === "MYR") ? "RM" : orderContext.currency} {(orderContext.doorToDoorFee / 100).toFixed(2)}</p>
        </div>
      }
      {
        orderContext.codAmount > 0 &&
        <div className={styles.descriptionRow}>
          <p className={styles.lightTealText}>COD {(orderContext.currency === "MYR") ? "RM" : orderContext.currency} {(orderContext.codAmount / 100).toFixed(2)}</p>
        </div>
      }
    </>
  )
}