import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeDeleteOrderModal, handleGetOrdersByOrderGroup, setSelectedIds } from './orderSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { deleteOrder } from './orderAPI';

export function ConfirmDelete(props) {
    const { orders, orderGroup } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        setLoading(true);
        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            try {
              const res = await deleteOrder(orderGroup.id, order.id);
              if (res.error) throw res.error;
            } catch (err) {
              alert(`Order ID (${order.id}) hit error: ${err}`);
              setLoading(false);
            }
        }
        dispatch(closeDeleteOrderModal());
        dispatch(setSelectedIds([]));
        dispatch(handleGetOrdersByOrderGroup(props.orderGroup.id));
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeDeleteOrderModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Order</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the selected {orders.length === 1 ? `order` : `orders`}?
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeDeleteOrderModal())} color="primary">
                    Cancel
                </Button>
                <Button disabled={loading} onClick={() => submit()} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}