import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { showLoading, hideLoading } from 'react-redux-loading';
import { getUserInfoFromJwt } from '../../app/utils';
import { getUserWallet, getWalletPayments, getWalletTransactions } from './walletAPI';

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    transactions: [],
    pendingPayments: [],
    status: 'idle',
    topUpModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    pendingPaymentPagination: {},
    pendingPaymentPage: 0,
    pendingPaymentRowsPerPage: 10,
  },
  reducers: {
    openTopUpModal: (state) => {
      state.topUpModalOpen = true;
    },
    closeTopUpModal: (state) => {
      state.topUpModalOpen = false;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getTransactions: (state, action) => {
      state.transactions = action.payload;
      state.status = 'success'
    },
    getPendingPayments: (state, action) => {
      state.pendingPayments = action.payload;
      state.status = 'success'
    },
    setPendingPaymentPage: (state, action) => {
      state.pendingPaymentPage = action.payload;
    },
    setPendingPaymentRowsPerPage: (state, action) => {
      state.pendingPaymentRowsPerPage = action.payload;
    },
    setPendingPaymentPagination: (state, action) => {
      state.pendingPaymentPagination = action.payload;
    },
  },
});

export const { openTopUpModal, closeTopUpModal, setPage, setRowsPerPage, setPagination, getTransactions, getPendingPayments, setPendingPaymentPage, setPendingPaymentPagination, setPendingPaymentRowsPerPage } = walletSlice.actions;

export const handleGetTransactions = (dateRange) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  const [startDate, endDate] = dateRange;

  try {
    const userInfo = await getUserInfoFromJwt();
    const { data: wallet } = await getUserWallet(userInfo.user_id);
    const response = await getWalletTransactions(wallet.id, state.wallet.page, state.wallet.rowsPerPage, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));

    const { content, ...pagination } = response.data;
    let transactions = content;

    dispatch(setPagination(pagination));
    dispatch(getTransactions(transactions));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetPendingPayments = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const userInfo = await getUserInfoFromJwt();
    const { data: wallet } = await getUserWallet(userInfo.user_id);
    const response = await getWalletPayments(wallet.id, state.wallet.page, state.wallet.rowsPerPage, "PENDING");

    const { content, ...pagination } = response.data;
    let pendingPayments = content;

    dispatch(setPendingPaymentPagination(pagination));
    dispatch(getPendingPayments(pendingPayments));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const selectState = state => state.wallet;

export default walletSlice.reducer;
