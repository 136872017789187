import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, Chip, FormControl, InputLabel, OutlinedInput, InputAdornment } from "@material-ui/core";
import { handleGetTransactions } from './walletSlice';
import { renderCreditCard } from '../user/User';
import { handleManageBilling, handleSetupCard } from '../user/userSlice';
import { Close } from "@material-ui/icons";
import styles from './Wallet.module.css';
import { getCreditCards, getUserWallet, topUpWallet } from './walletAPI';
import { dollarToCent, getUserInfoFromJwt } from '../../app/utils';
import addIcon from "../../images/icons/add.png";
import refreshIcon from "../../images/icons/refresh-gradient.png";
import checkIcon from "../../images/icons/check-gradient.png";
import moment from 'moment';

export function TopUp({ closeModal }) {
  const dispatch = useDispatch();
  const suggestedTopUpAmounts = [50, 100, 500];
  const [init, setInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    topUpAmount: 0,
    wallet: "",
    creditCard: null,
    error: false,
    errorMessage: ""
  };
  const [walletState, _setWalletState] = useStateWithCallbackLazy({
    ...initialState
  });
  const walletStateRef = useRef(walletState);

  const setWalletState = data => {
    walletStateRef.current = data;
    _setWalletState(data);
  };

  useEffect(() => {
    async function fetchCreditCards() {
      setIsLoading(true);
      if (!walletState.wallet && !init) {
        setInit(true);
        const userInfo = await getUserInfoFromJwt();
        const { data: wallet } = await getUserWallet(userInfo.user_id);
        const creditCardResponse = await getCreditCards(wallet.id);
        setWalletState({
          ...walletState,
          wallet: { ...wallet },
          creditCard: creditCardResponse.data.find(c => c.selected === true)
        });
      }
      setIsLoading(false);
    }
    fetchCreditCards();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  const onTopUpAmountChange = (value) => {
    setWalletState({
      ...walletState,
      topUpAmount: value,
    })
  }

  const submit = async () => {
    let error = false;
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (walletState.topUpAmount < 20) {
      setWalletState({...walletState, error: true, errorMessage: "Minimum top up amount is RM20"});
      setIsLoading(false);
      return;
    }

    const topUpAmount = Number(dollarToCent(walletState.topUpAmount));
    if (!error) {
      try {
        let walletPayload = {
          method: "STRIPE",
          platform: 'DELIVERIT',
          topUpAmount,
          detail: `Top Up RM${walletState.topUpAmount}`
        }

        await topUpWallet(walletState.wallet.id, walletPayload);
        dispatch(handleGetTransactions([new Date(moment().subtract(30,'d')), new Date()]));
        dispatch(closeModal());
        alert("Top Up Success");
        window.location.reload();
      } catch (error) {
        setWalletState({...walletState, error: true, errorMessage: error?.response?.data?.message || error.message});
      } finally {
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }

  return (
    <Modal
      open={true}
      aria-labelledby="top-up-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeModal())}/>
          <h3 className={styles.subtitle}>Top Up</h3>
          <Fade in={walletState.error} style={{paddingBottom: 5}}>
            <p className={styles.errorMessage}>{walletState.errorMessage}</p>
          </Fade>
          <Grid container spacing={3} style={{marginBottom: 35}}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel className={styles.muiInputLabel} htmlFor="topUpAmount">Amount</InputLabel>
                <OutlinedInput
                  id="topUpAmount"
                  value={walletState.topUpAmount}
                  onChange={e => onTopUpAmountChange(e.target.value)}
                  startAdornment={<InputAdornment position="start">RM</InputAdornment>}
                  label="Amount"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {
                  suggestedTopUpAmounts.map(amount => (
                    <Grid item xs={6} sm={3}>
                      <Chip color='primary' label={`RM${amount}`} variant={walletState.topUpAmount === amount ? 'default' : 'outlined'} className={styles.chip} onClick={() => onTopUpAmountChange(amount)} />
                    </Grid>
                  ))
                }
                <Grid item xs={6} sm={3}>
                  <Chip color='primary' label="Other" variant={suggestedTopUpAmounts.includes(walletState.topUpAmount) ? 'outlined' : 'default'} className={styles.chip} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div onClick={() => dispatch(handleSetupCard())} className={styles.paymentMethodContainer}>
              {
                walletState.creditCard ? (
                  <div className={styles.hstack}>
                    <img alt={walletState.creditCard.brand} className={styles.creditCardIcon} src={renderCreditCard(walletState.creditCard.brand)} />
                    <div className={styles.vstack}>
                      <span><b className={styles.creditCardText}>**** **** **** {walletState.creditCard.last4}</b></span>
                      <span className={styles.creditCardExpText}>{walletState.creditCard.expMonth}/{walletState.creditCard.expYear}</span>
                    </div>
                    <img alt="checked" className={styles.creditCardIcon} src={checkIcon} />
                  </div>
                ) : (
                  <div className={styles.hstack}>
                    <img alt="add" src={addIcon} className={styles.buttonIcon} />
                    <span className={styles.buttonIconText}>Add Credit Card</span>
                  </div>
                )
              }
            </div>
            {
              walletState.creditCard &&
              <div onClick={() => dispatch(handleManageBilling())} className={styles.paymentMethodContainer}>
                <div className={styles.hstack}>
                  <img alt="refresh" src={refreshIcon} className={styles.buttonIcon} />
                  <span className={styles.buttonIconText}>Change Credit Card</span>
                </div>
              </div>
            }
          </Grid>
          <Grid item xs={12}>
            <div className={styles.submitButtonContainer}>
              <Button disabled={isLoading} onClick={() => submit()} className={styles.gradientButton}>Top Up</Button>
            </div>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
