import React  from 'react';
import styles from './Spinner.module.css';

const Spinner = () => {
    return (
        <>
            <div className={styles.loader}></div>
            <p className={styles.loadingText}>Processing... Please do not refresh page or click the "Back" or "Close" button of your browser</p>
            <div className={styles.overlay}></div>
        </>
    );
  };

export default Spinner;