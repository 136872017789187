import React, { useEffect, useState } from 'react';
import styles from './PlaceOrder.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from '@material-ui/core';

import { ordersState, setSelectedSavedDropOffAddr, setSelectedSavedPickUpAddr, setShowSavedDropOffAddrModal, setShowSavedPickUpAddrModal } from './placeOrderSlice';

import closeIcon from "../../images/place_order/close-icon.png";
import locationIcon from "../../images/place_order/location-address-icon.png";
import { incrementAddressUseCount } from '../address/addressAPI';

export const SavedAddressModal = (props) => {
  const dispatch = useDispatch();
  const orderListState = useSelector(ordersState);

  const [allAddress, setAllAddress] = useState([]);
  const [savedAddress, setSavedAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);

  useEffect(() => {
    if (props.type === "PICK_UP") {
      setSavedAddress(orderListState.savedPickUpAddrList);
      setAllAddress(orderListState.savedPickUpAddrList);
    }
    else {
      setSavedAddress(orderListState.savedDropOffAddrList);
      setAllAddress(orderListState.savedDropOffAddrList);
    }
  }, [props.type, orderListState.savedPickUpAddrList, orderListState.savedDropOffAddrList]);

  useEffect(() => {
    if (props.type === "PICK_UP") {
      setSelectedAddress(orderListState.selectedSavedPickUpAddr);
      orderListState.selectedSavedPickUpAddr === "" ? setIsSelectDisabled(true) : setIsSelectDisabled(false);
    } else {
      setSelectedAddress(orderListState.selectedSavedDropOffAddr);
      orderListState.selectedSavedDropOffAddr === "" ? setIsSelectDisabled(true) : setIsSelectDisabled(false);
    }
  }, [props.type, orderListState]);

  const onSelectPickupAddress = async () => {
    props.useSavedAddress(selectedAddress);
    dispatch(setShowSavedPickUpAddrModal(false));
    dispatch(setSelectedSavedPickUpAddr(selectedAddress));
    await incrementAddressUseCount(selectedAddress.id);
  }

  const onSelectDropoffAddress = async () => {
    props.useSavedAddress(selectedAddress);
    dispatch(setShowSavedDropOffAddrModal(false));
    dispatch(setSelectedSavedDropOffAddr(selectedAddress));
    await incrementAddressUseCount(selectedAddress.id);
  }

  const onSearchAddress = (queryString) => {
    const searched = allAddress.filter(addr => addr.location.address.toLowerCase().includes(queryString) || addr.bookmarkName.toLowerCase().includes(queryString));
    console.log(searched);
    setSavedAddress(searched);
  }

  return (
    <>
      <div className={styles.savedAddressModal}>
        <div style={{ display: "flex" }}>
          <p className={styles.tealText} style={{ textAlign: "left", fontWeight: "500" }}>{props.type === "PICK_UP" ? "Pick-up Address Book" : "Drop-off Address Book"}</p>
          <img className={styles.icon} style={{ width: 10, marginLeft: "auto", marginRight: 0, marginTop: -5, cursor: "pointer" }} src={closeIcon} alt="close" onClick={() => props.type === "PICK_UP" ? dispatch(setShowSavedPickUpAddrModal(false)) : dispatch(setShowSavedDropOffAddrModal(false))} />
        </div>

        {/* Search Box */}
        <input
          id="searchBox"
          name="searchBox"
          onChange={(e) => onSearchAddress(e.target.value)}
          placeholder="Search address"
          className={styles.searchText}
        />

        {/* Saved Addresses */}
        <div className={styles.addressesContainer}>
          {
            savedAddress.length > 0 && savedAddress.map((addr, index) => {
              return (
                <>
                  <div className={styles.addressRow}>
                    <img className={styles.locationIcon} src={locationIcon} alt="target" />
                    <div>
                      <p className={styles.blackText}>{addr.bookmarkName} - {addr.location.address}</p>

                      {/* Saved Pick-up */}
                      {
                        props.type === "PICK_UP" && (addr.location.unitNumber !== "" || addr.pickUpContactNumber !== "" || addr.pickUpPersonName !== "") &&
                        <div style={{ marginTop: 8 }}>
                          <p className={styles.greyText}>{addr.location.unitNumber && addr.location.unitNumber + "\u00a0 • \u00a0"}{addr.pickUpContactNumber}&nbsp; • &nbsp;{addr.pickUpPersonName}</p>
                        </div>
                      }

                      {/* Saved Drop-off */}
                      {
                        props.type === "DROP_OFF" && (addr.location.unitNumber !== "" || addr.recipientPhone !== "" || addr.recipientName !== "") &&
                        <div style={{ marginTop: 8 }}>
                          <p className={styles.greyText}>{addr.location.unitNumber && addr.location.unitNumber + "\u00a0 • \u00a0"}{addr.recipientPhone}&nbsp; • &nbsp;{addr.recipientName}</p>
                        </div>
                      }
                    </div>
                    <Radio
                      checked={selectedAddress.id === addr.id ? true : false}
                      onChange={() => { setSelectedAddress(addr); setIsSelectDisabled(false); }}
                      size="small"
                      style={{ paddingBlock: 0, marginLeft: "auto", color: "#1e9299" }}
                    />
                  </div>
                  <div className={styles.horizontalDivider} />
                </>
              )
            })
          }
        </div>

        {/* Select Button */}
        <div className={styles.modalButtonContainer}>
          {
            isSelectDisabled
              ? <div className={styles.modalButton} style={{ backgroundImage: "none", backgroundColor: "#e0e0e0", boxShadow: "none" }}>
                <p className={styles.whiteText} style={{ fontSize: 13 }}>Select</p>
              </div>
              : props.type === "PICK_UP"
                ? <div className={styles.modalButton} onClick={onSelectPickupAddress}>
                  <p className={styles.whiteText} style={{ fontSize: 13 }}>Select</p>
                </div>
                : <div className={styles.modalButton} onClick={onSelectDropoffAddress}>
                  <p className={styles.whiteText} style={{ fontSize: 13 }}>Select</p>
                </div>
          }
        </div>
      </div>
      <div className={styles.overlay} onClick={() => props.type === "PICK_UP" ? dispatch(setShowSavedPickUpAddrModal(false)) : dispatch(setShowSavedDropOffAddrModal(false))}></div>
    </>
  )
}