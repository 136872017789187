import { withGoogleMap, GoogleMap } from "react-google-maps";
import mapStyles from './mapStyle';

export const Map = withGoogleMap(props => {
  return (
      <GoogleMap
          defaultZoom={14}
          options={{ gestureHandling: "greedy", styles: mapStyles }}
          defaultCenter={{ lat: 3.0640394, lng: 101.60916369999995 }}
      >
      </GoogleMap>
  );
});