/*global google*/
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, FormGroup, FormControlLabel, Switch, Typography, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { closeCreateOrderGroupModal } from './orderGroupSlice';
import { getPlaceLongName } from '../order/orderSlice';
import { Close, ChevronLeft } from "@material-ui/icons";
import styles from './OrderGroup.module.css';
import DatePicker from "react-datepicker";
import { setMinutes, setHours } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import carTealIcon from "../../images/car-teal.png";
import carWhiteIcon from "../../images/car-white.png";
import motorWhiteIcon from "../../images/motor-white.png";
import motorTealIcon from "../../images/motor-teal.png";
import { createOrderGroup } from './orderGroupAPI';
import { getNext15MinTimeBlock, getUserInfoFromJwt, phoneUtil } from '../../app/utils';

export function CreateOrderGroup(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      deliveryMethod: "MOTOR",
      pickUpPersonName: "",
      pickUpContactNumber: "",
      pickUpDateTime: getNext15MinTimeBlock(new Date(), 2)[1],
      pickUpLocation: {
        unitNumber: "",
        address: "",
        coordinate:  {
          latitude: "", 
          longitude: "" 
        },
        city: "",
        country: ""
      },
      halal: true,
      user: {
        id: ""
      }
    },
    selectedBookmarkAddress: "",
    bookmarkedAddresses: props.bookmarkedAddresses,
    currentStep: 0,
    isPlaceChanged: false,
    autocompleteInit: false,
    error: false,
    errorMessage: ""
  };
  const [orderGroupState, _setOrderGroupState] = useStateWithCallbackLazy({
    ...initialState
  });
  const orderGroupStateRef = useRef(orderGroupState);

  const setOrderGroupState = data => {
    orderGroupStateRef.current = data;
    _setOrderGroupState(data);
  };

  useEffect(() => {
    async function initFn() {
      if (!orderGroupState.form.user.id) {
        const userInfo = await getUserInfoFromJwt();
        setOrderGroupState({
          ...orderGroupState,
          form: {
            ...orderGroupState.form,
            user: {
              ...orderGroupState.form.user,
              id: userInfo.user_id
            }
          }
        });
      }
    }
    initFn();
  });

  useEffect(() => {
    let pickUpAutocompleteInput = document.getElementById("pickUpAutocomplete");
    if (!orderGroupState.autocompleteInit && pickUpAutocompleteInput !== null) {
      initializeGoogleAutocomplete("pickUpAutocomplete");
      setOrderGroupState({
        ...orderGroupState,
        form: {
          ...orderGroupState.form
        },
        autocompleteInit: true,
      });
    }
  });

  const onPlaceChanged = (autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === "pickUpAutocomplete") {
        setOrderGroupState({
          ...orderGroupStateRef.current,
          isPlaceChanged: true,
          form: {
            ...orderGroupStateRef.current.form,
            pickUpLocation: {
              ...orderGroupStateRef.current.form.pickUpLocation,
              address: `${place.name}, ${place.formatted_address}`,
              coordinate: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              },
              street: getPlaceLongName(place.address_components, "street_address"),
              apartment: getPlaceLongName(place.address_components, "street_address"),
              state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
              postalCode: getPlaceLongName(place.address_components, "postal_code"),
              city: getPlaceLongName(place.address_components, "locality"),
              country: getPlaceLongName(place.address_components, "country")
            }
          }
        });
      }
    }
  }

  const initializeGoogleAutocomplete = (elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my", "sg"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function() {
      onPlaceChanged(autocomplete, elementId);
    });
  }

  const onFormValueChange = (data) => {
    setOrderGroupState({
      ...orderGroupState,
      form: {
        ...orderGroupState.form,
        [data.name]: data.value
      }
    })
  }

  const nextWithBookmarkedAddress = () => {
    let selectedAddressObj = orderGroupState.selectedBookmarkAddress;
    _setOrderGroupState({
      ...orderGroupState,
      currentStep: 1,
      isPlaceChanged: (selectedAddressObj.location && selectedAddressObj.location.address) ? true : false,
      form: {
        ...orderGroupState.form,
        pickUpPersonName: selectedAddressObj.pickUpPersonName,
        pickUpContactNumber: selectedAddressObj.pickUpContactNumber,
        // noteToDriver: selectedAddressObj.deliveryNoteToDriver,
        pickUpLocation: {
          ...orderGroupState.form.pickUpLocation,
          ...selectedAddressObj.location
        }
      }
    });
  }

  const nextWithNewAddress = () => {
    _setOrderGroupState({
      ...initialState,
      currentStep: 1,
    });
  }

  const setCurrentStep = (newStep) => {
    setOrderGroupState({
      ...orderGroupState,
      currentStep: newStep,
      form: {
        ...orderGroupState.form
      }
    })
  }

  const submit = async () => {
    let error = false;

    if (orderGroupState.isPlaceChanged === false) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Please select address from the drop down"});
      return;
    }

    if (new Date(orderGroupState.form.pickUpDateTime) - new Date() < 0) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Pick up date should not be in the past"});
      return;
    }

    for (var key in orderGroupState.form) {
      if (key !== "halal" && key !== "noteToDriver") {
        if (orderGroupState.form[key] === null || orderGroupState.form[key] === "") {
          error = true;
          setOrderGroupState({...orderGroupState, error: true, errorMessage: "All fields are required"});
          return;
        }
      }
    }

    if (orderGroupState.form.pickUpLocation.country === null || orderGroupState.form.pickUpLocation.country === "") {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Please re-select the pick up address from dropdown list"});
      return;
    }

    var formattedPhoneNumber = orderGroupState.form.pickUpContactNumber.replace(/\s/g, '').replace(/-/g, '')

    // make sure phone number start with + sign
    if (!formattedPhoneNumber.startsWith("+")) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Phone number must start with + sign and country code"});
      return;
    }

    // validate phone number using google lib
    try {
      let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
      if (!phoneUtil.isValidNumber(phoneNumberProto)) {
        error = true;
        setOrderGroupState({...orderGroupState, error: true, errorMessage: "Invalid phone number"});
        return;
      }
    } catch (e) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Invalid phone number"});
      return;
    }

    if (!error) {
      try {
        // Convert Datetime to UTC
        let orderGroupPayload = {
          ...orderGroupState.form,
          pickUpContactNumber: formattedPhoneNumber,
          pickUpDateTime: new Date(orderGroupState.form.pickUpDateTime).toISOString()
        }

        const response = await createOrderGroup(orderGroupPayload);
        window.location = `/app/order-groups/${response.data.id}` 
      } catch (error) {
        setOrderGroupState({...orderGroupState, error: true, errorMessage: error.message});
      }
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <Modal
      open={props.createOrderGroupModalOpen}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeCreateOrderGroupModal())}/>
          {
            orderGroupState.currentStep === 0 ? (
              <>
                <h3 className={styles.subtitle}>Select from Bookmarked Pickup Address</h3>
                <Autocomplete
                  autoHighlight
                  id="bookmark-address"
                  value={orderGroupState.selectedBookmarkAddress}
                  onChange={(event, selectedOption) => setOrderGroupState({ ...orderGroupState, selectedBookmarkAddress: selectedOption })}
                  getOptionLabel={(option) => option && option.bookmarkName ? option.bookmarkName + " - " + option.location?.unitNumber + ", " + option.location?.address : ""}
                  options={orderGroupState.bookmarkedAddresses}
                  noOptionsText="No bookmarked address found"
                  renderOption={(option) => <Typography data-id={option.id} noWrap>{option.bookmarkName} - {option.location.unitNumber}, {option.location.address}</Typography>}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Pickup Address" style={{marginBottom: 20}} fullWidth />}
                />
                <Button onClick={() => nextWithBookmarkedAddress()} disabled={orderGroupState.selectedBookmarkAddress ? false : true} className={styles.gradientButton}>Next</Button>
                <hr style={{margin: "30px 0"}}></hr>
                <h3 className={styles.subtitle}>Create with New Pickup Address</h3>
                <Button onClick={() => nextWithNewAddress()} className={styles.gradientButton}>Proceed using New Pickup Address</Button>
              </>
            ) : (<>
              <button className={styles.backButton} onClick={() => setCurrentStep(0)}><ChevronLeft fontSize="small" style={{float: "left"}} /> Back</button>
              <h3 className={styles.subtitle}>Where do you want us to pick up from? <em style={{fontSize: 12}}>(All fields are required)</em></h3>
              <Fade in={orderGroupState.error}>
                <p className={styles.errorMessage}>{orderGroupState.errorMessage}</p>
              </Fade>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <p>Select your vehicle</p>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Paper className={styles.vehicleCardContainer} onClick={() => setOrderGroupState({...orderGroupState, form: { ...orderGroupState.form, deliveryMethod: "MOTOR"}})}>
                        <Grid container spacing={0}>
                          <Grid item xs={4} className={styles.vehicleIconContainer}><img alt='vehicle' src={orderGroupState.form.deliveryMethod === "MOTOR" ? motorTealIcon : motorWhiteIcon} className={styles.vehicleIcon} /></Grid>
                          <Grid item xs={8} className={orderGroupState.form.deliveryMethod === "MOTOR" ? styles.selectedVehicleCardContentContainer : styles.vehicleCardContentContainer}>
                            <p className={styles.vehicleCardTitle}>BIKE</p>
                            <p className={styles.vehicleCardText}>Suitable for Small to Medium deliveries</p>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper className={styles.vehicleCardContainer} onClick={() => setOrderGroupState({...orderGroupState, form: { ...orderGroupState.form, deliveryMethod: "CAR"}})}>
                        <Grid container spacing={0}>
                          <Grid item xs={4} className={styles.vehicleIconContainer}><img alt='vehicle' src={orderGroupState.form.deliveryMethod === "CAR" ? carTealIcon : carWhiteIcon} className={styles.vehicleIcon} /></Grid>
                          <Grid item xs={8} className={orderGroupState.form.deliveryMethod === "CAR" ? styles.selectedVehicleCardContentContainer : styles.vehicleCardContentContainer}>
                            <p className={styles.vehicleCardTitle}>CAR</p>
                            <p className={styles.vehicleCardText}>Suitable for Small to Large deliveries</p>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  <p>Sender</p>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="unitNumber"
                      name="unitNumber"
                      value={orderGroupState.form.pickUpLocation.unitNumber}
                      onChange={e => setOrderGroupState({
                        ...orderGroupState,
                        form: {
                          ...orderGroupState.form,
                          pickUpLocation: {
                            ...orderGroupState.form.pickUpLocation,
                            unitNumber: e.target.value
                          }
                        }
                      })}
                      placeholder="Unit Number"
                      className={styles.textInput}
                    />
                  </Paper>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="pickUpAutocomplete"
                      name="pickUpAddress"
                      value={orderGroupState.form.pickUpLocation.address}
                      onChange={e => setOrderGroupState({
                        ...orderGroupState,
                        isPlaceChanged: false,
                        form: {
                          ...orderGroupState.form,
                          pickUpLocation: {
                            ...orderGroupState.form.pickUpLocation,
                            address: e.target.value
                          }
                        }
                      })}
                      placeholder="Pick Up Address"
                      className={styles.textInput}
                    />
                  </Paper>
                  <Paper className={styles.textInputContainer}>
                    <DatePicker
                      selected={orderGroupState.form.pickUpDateTime}
                      onChange={date => {
                        if (date.getHours() === 0) {
                          date.setHours(9);
                        }
                        onFormValueChange({name: "pickUpDateTime", value: date})
                      }}
                      showTimeSelect
                      dateFormat="Pp"
                      minDate={new Date()}
                      minTime={setHours(setMinutes(new Date(), 0), 6)}
                      maxTime={setHours(setMinutes(new Date(), 0), 23)}
                      filterTime={filterPassedTime}
                      timeIntervals={15}
                      className={styles.textInput}
                      placeholderText={"Pickup Date Time"}
                    />
                  </Paper>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="pickUpPersonName"
                      name="pickUpPersonName"
                      value={orderGroupState.form.pickUpPersonName}
                      onChange={e => onFormValueChange({name: "pickUpPersonName", value: e.target.value})}
                      placeholder="Pickup Person Name"
                      className={styles.textInput}
                    />
                  </Paper>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="pickUpContactNumber"
                      name="pickUpContactNumber"
                      value={orderGroupState.form.pickUpContactNumber}
                      onChange={e => onFormValueChange({name: "pickUpContactNumber", value: e.target.value})}
                      placeholder="Pickup Contact Number"
                      className={styles.textInput}
                    />
                  </Paper>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Switch checked={orderGroupState.form.halal} onChange={e => onFormValueChange({name: "halal", value: e.target.checked })} name="halal" color="primary" />}
                      label="Delivery Items are Halal"
                    />
                  </FormGroup>
                  <div className={styles.submitButtonContainer}>
                    <Button onClick={() => submit()} className={styles.gradientButton}>Create</Button>
                  </div>
                </Grid>
              </Grid>
            </>)
          }
        </div>
      </Paper>
    </Modal>
  );
}
