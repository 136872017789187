/*global google*/
import { withGoogleMap, GoogleMap, DirectionsRenderer, Marker, InfoWindow } from "react-google-maps";
import { CustomInfoWindow } from '../CustomInfoWindow';
import { setShowDropOffInfoWindow, setShowPickUpInfoWindow } from '../placeOrderSlice';
import styles from '../PlaceOrder.module.css';
import mapStyles from '../../order/mapStyle';

import redMapMarker from "../../../images/place_order/red-map-marker.png";
import tealMapMarker from "../../../images/place_order/teal-map-marker.png";

export const PlaceOrderMap = withGoogleMap(props => {
  return (
    <GoogleMap
      defaultZoom={14}
      options={{ gestureHandling: "greedy", styles: mapStyles }}
      defaultCenter={{ lat: 3.0640394, lng: 101.60916369999995 }}
    >
      {
        props.directions.length > 0 && props.directions.map((direction, directionIndex) => {
          return (
            <>
              <DirectionsRenderer
                directions={direction}
                options={{
                  polylineOptions: {
                    strokeColor: "#227c97"
                  },
                  suppressMarkers: true
                }}
              />
              {
                direction.routes[0].legs.map((leg, legIndex) => {
                  var index = legIndex;
                  if (directionIndex !== 0) {
                    for (let i = 1; i <= directionIndex; i++) {
                      index += props.directions[i - 1].routes[0].legs.length
                    }
                  }

                  return (
                    <>
                      {
                        index === 0 &&
                        <>
                          <Marker
                            key="start"
                            icon={{
                              url: redMapMarker,
                              scaledSize: new google.maps.Size(35, 50),
                            }}
                            position={{
                              lat: Number(leg.start_location.lat()),
                              lng: Number(leg.start_location.lng())
                            }}
                            onMouseOver={() => {
                              props.dispatch(setShowPickUpInfoWindow(true));
                              props.dispatch(setShowDropOffInfoWindow({ idx: index, show: false }));
                            }}>
                            {
                              props.orderListState.showPickUpInfoWindow &&
                              <InfoWindow
                                onCloseClick={() => props.dispatch(setShowPickUpInfoWindow(false))}>
                                <CustomInfoWindow
                                  index={index}
                                  isPublicEstimate={false}
                                  location={props.orderListState.orderGroup.pickUpLocation}
                                  contactNumber={props.orderListState.orderGroup.pickUpContactNumber}
                                  name={props.orderListState.orderGroup.pickUpPersonName}
                                  halal={props.orderListState.orderGroup.halal}
                                  currency={props.orderListState.orderGroup.currency}
                                  showEditModal={props.showPickUpModal}
                                />
                              </InfoWindow>
                            }
                          </Marker>
                        </>
                      }
                      {
                        index !== 0 &&
                        <>
                          <Marker
                            key="end"
                            icon={{
                              url: tealMapMarker,
                              scaledSize: new google.maps.Size(32, 40),
                            }}
                            position={{
                              lat: Number(leg.start_location.lat()),
                              lng: Number(leg.start_location.lng())
                            }}
                            label={{
                              className: `${styles.mapMarkerOrdinal}`,
                              text: `${index}`,
                              color: "#227c97",
                              fontWeight: "500",
                            }}
                            onMouseOver={() => {
                              props.dispatch(setShowPickUpInfoWindow(false));
                              props.dispatch(setShowDropOffInfoWindow({ idx: index - 1, show: true }));
                            }}>
                            {
                              props.orderListState.orderGroup.orders.length > 0 && props.orderListState.showDropOffInfoWindow[index - 1] &&
                              <InfoWindow
                                onCloseClick={() => props.dispatch(setShowDropOffInfoWindow({ idx: index - 1, show: false }))}>
                                <CustomInfoWindow
                                  index={index}
                                  isPublicEstimate={false}
                                  location={props.orderListState.orderGroup.orders[index - 1].dropOffLocation}
                                  contactNumber={props.orderListState.orderGroup.orders[index - 1].recipientPhone}
                                  name={props.orderListState.orderGroup.orders[index - 1].recipientName}
                                  doorToDoor={props.orderListState.orderGroup.orders[index - 1].doorToDoor}
                                  doorToDoorFee={props.orderListState.orderGroup.orders[index - 1].doorToDoorFee}
                                  codAmount={props.orderListState.orderGroup.orders[index - 1].codAmount}
                                  deliveryFee={props.orderListState.orderGroup.orders[index - 1].deliveryFee}
                                  currency={props.orderListState.orderGroup.currency}
                                  showEditModal={props.showDropOffModal}
                                />
                              </InfoWindow>
                            }
                          </Marker>
                        </>
                      }
                      {
                        legIndex === (direction.routes[0].legs.length - 1) &&
                        <>
                          <Marker
                            key="end"
                            icon={{
                              url: tealMapMarker,
                              scaledSize: new google.maps.Size(32, 40),
                            }}
                            position={{
                              lat: Number(leg.end_location.lat()),
                              lng: Number(leg.end_location.lng())
                            }}
                            label={{
                              className: `${styles.mapMarkerOrdinal}`,
                              text: `${index + 1}`,
                              color: "#227c97",
                              fontWeight: "500",
                            }}
                            onMouseOver={() => {
                              props.dispatch(setShowPickUpInfoWindow(false));
                              props.dispatch(setShowDropOffInfoWindow({ idx: index, show: true }));
                            }}>
                            {
                              props.orderListState.orderGroup.orders.length > 0 && props.orderListState.showDropOffInfoWindow[index] &&
                              <InfoWindow
                                onCloseClick={() => props.dispatch(setShowDropOffInfoWindow({ idx: index, show: false }))}>
                                <CustomInfoWindow
                                  index={index + 1}
                                  isPublicEstimate={false}
                                  location={props.orderListState.orderGroup.orders[index].dropOffLocation}
                                  contactNumber={props.orderListState.orderGroup.orders[index].recipientPhone}
                                  name={props.orderListState.orderGroup.orders[index].recipientName}
                                  doorToDoor={props.orderListState.orderGroup.orders[index].doorToDoor}
                                  doorToDoorFee={props.orderListState.orderGroup.orders[index].doorToDoorFee}
                                  codAmount={props.orderListState.orderGroup.orders[index].codAmount}
                                  deliveryFee={props.orderListState.orderGroup.orders[index].deliveryFee}
                                  currency={props.orderListState.orderGroup.currency}
                                  showEditModal={props.showDropOffModal}
                                />
                              </InfoWindow>
                            }
                          </Marker>
                        </>
                      }
                    </>
                  )
                })
              }
            </>
          )
        })
      }
    </GoogleMap>
  );
});