import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Checkbox, Toolbar, Typography, Breadcrumbs, Link, Tooltip, IconButton, Chip } from "@material-ui/core";
import { withGoogleMap, GoogleMap, DirectionsRenderer } from "react-google-maps";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import ReactPixel from 'react-facebook-pixel';
import Moment from 'moment';
import { CreateOrder } from './CreateOrder';
import { ImportOrder } from './ImportOrder';
import { EditOrder } from './EditOrder';
import { ConfirmDelete } from './ConfirmDelete';
import { BookmarkAddress } from './BookmarkAddress';
import { EditOrderGroup } from '../order_group/EditOrderGroup';
import { TableToolbar } from './TableToolbar';
import { openCreateOrderModal, openImportOrderModal, openEditOrderModal, openDeleteOrderModal, openBookmarkAddressModal, handleGetOrdersByOrderGroup, handleConfirmOrders, handleOnSortOrder, handleGetDefaultDeliveryRateProfile, handleGetBookmarkedAddresses, selectState, setSelectedId, setOrders, setConfirmOrderPayload, setSortingDisable, openTopUpModal, closeTopUpModal, setSelectedIds, closeSelectVoucherModal, openSelectVoucherModal, handleGetVendor } from './orderSlice';
import { handleGetCreditCards, selectState as userSelectState } from '../user/userSlice';
import { openEditOrderGroupModal, selectState as orderGroupSelectState } from '../order_group/orderGroupSlice';
import { selectState as homeSelectState } from '../home/homeSlice';
import mapStyles from './mapStyle';
import styles from './Order.module.css';
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import doneIcon from "../../images/icons/done.png";
import creditCardIcon from "../../images/icons/creditcard.png";
import infoIcon from "../../images/icons/info.png";
import noteIcon from "../../images/icons/note.png";
import paymentIcon from "../../images/icons/payment.png";
import secureIcon from "../../images/icons/secure.png";
import voucherIcon from "../../images/icons/voucher.png";
import { centToDollar, FACEBOOK_PIXEL_ID, isJSON } from '../../app/utils';
import { TopUp } from '../wallet/TopUp';
import { handleGetUserWallet } from '../home/homeSlice';
import { SelectVoucher } from '../voucher/SelectVoucher';
import { Topbar } from '../../components/topbar/Topbar';

const getTotalDeliveryCharges = (unfilteredOrders) => {
  let totalCharges = 0;
  let orders = unfilteredOrders.filter(o => o.status !== "FAILED");

  if (orders && orders.length > 0) {
    if (orders.length > 1) {
      totalCharges = orders.map(o => (o.deliveryFee + (o.doorToDoorFee ? o.doorToDoorFee : 0)) / 100).reduce((a, b) => a + b).toFixed(2);
    } else {
      let doorToDoorFee = orders[0].doorToDoorFee ? orders[0].doorToDoorFee : 0
      totalCharges = (orders[0].deliveryFee + doorToDoorFee) / 100;
    }
  }

  return totalCharges;
}

const SortableItem = SortableElement(({ order, dispatch, state }) =>
  <TableRow
    key={order.id}
    hover
    onClick={() => dispatch(setSelectedIds(state.selectedIds.includes(order.id) ? state.selectedIds.filter(id => id !== order.id) : [...state.selectedIds, order.id]))}
    role="checkbox"
    aria-checked={state.selectedIds.includes(order.id)}
    selected={state.selectedIds.includes(order.id)}
    style={{ cursor: "move" }}
  >
    <TableCell padding="checkbox">
      <Checkbox
        color="primary"
        checked={state.selectedIds.includes(order.id)}
      />
    </TableCell>
    <TableCell align="left" className={styles.tableCellNarrow}>{order.id}</TableCell>
    <TableCell align="left">{order.deliverDateTime}</TableCell>
    <TableCell align="left">{order.recipientName}</TableCell>
    <TableCell align="left">{order.recipientPhone}</TableCell>
    <TableCell align="left">{order.recipientEmail}</TableCell>
    <TableCell align="left">{order.dropOffLocation.unitNumber}</TableCell>
    <TableCell align="left">{order.dropOffLocation.address}</TableCell>
    <TableCell align="left">{order.product}</TableCell>
    <TableCell align="left">{order.receiptNumber}</TableCell>
    <TableCell align="left">{(order.deliveryRange / 1000).toFixed(2)}KM</TableCell>
    <TableCell align="left">RM{(order.doorToDoorFee ? order.doorToDoorFee / 100 : 0)}</TableCell>
    <TableCell align="left">RM{order.deliveryFee / 100}</TableCell>
    <TableCell align="left">{order.codAmount ? `RM${order.codAmount / 100}` : "-"}</TableCell>
    <TableCell align="left">{order.noteToDriver}</TableCell>
  </TableRow>
);

const SortableList = SortableContainer(({ orders, selectedVoucher, dispatch, state, refetchVoucher, homeState }) => {

  const checkConfirmDisabled = () => {
    let result = false;

    if (state.confirmButtonIsDisabled === true) {
      result = true;
    }

    return result;
  }

  const calculateDiscount = () => {
    let result = 0;
    let delFee = getTotalDeliveryCharges(orders);
    if (delFee && selectedVoucher) {
      const { promoCode } = selectedVoucher;
      if (promoCode.discountPercentage) {
        result = (delFee * promoCode.discountPercentage / 100).toFixed(2);
      } else {
        result = centToDollar(promoCode.discountAmount);
      }
    }
    return result;
  }
  const removeVoucher = () => {
    localStorage.removeItem('selectedVoucher');
    refetchVoucher();
  }

  return (
    <TableBody>
      {[...orders].sort((a, b) => a.ordinal - b.ordinal).map((order, index) => (
        <SortableItem key={`order-${order.id}`} index={index} order={order} dispatch={dispatch} state={state} disabled={state.sortingDisable} />
      ))}
      <TableRow key="total">
        <TableCell colSpan={11}></TableCell>
        <TableCell colSpan={2}>Total Charges <p style={{ fontSize: 12, margin: 0 }}>(including tax and door to door)</p></TableCell>
        <TableCell>RM{getTotalDeliveryCharges(orders)}</TableCell>
        <TableCell colSpan={3}></TableCell>
      </TableRow>
      <TableRow key="paymentmethod">
        <TableCell colSpan={3} style={{ padding: 20, verticalAlign: "top" }}>
          <div style={{ borderRight: "1px solid #BFBFBF" }}>
            <p className={styles.labelParagraph}><img alt='note' src={noteIcon} className={styles.labelIcon} />Pickup Note to driver/rider</p>
            <textarea id="noteToRider" name="noteToRider" className={styles.textArea} value={state.confirmOrderPayload.noteToDriver} onChange={e => dispatch(setConfirmOrderPayload({ ...state.confirmOrderPayload, noteToDriver: e.target.value }))} placeholder="Landmarks close to address, amount of goods to be collected or any important instructions"></textarea>
          </div>
        </TableCell>
        <TableCell colSpan={3} style={{ padding: 20, paddingLeft: 0, verticalAlign: "top" }}>
          <div style={{ borderRight: "1px solid #BFBFBF" }}>
            <div style={{ position: "relative" }}>
              <p className={styles.securePaymentContainer}><img alt='secure' src={secureIcon} className={styles.labelSmallIcon} />SECURE PAYMENT</p>
            </div>
            <p className={styles.labelParagraph}><img alt='payment' src={paymentIcon} className={styles.labelIcon} />Payment</p>
            <div className={styles.selectPaymentMethodContainer}>
              <div style={{ flex: 1 }}>
                <p className={styles.paymentMethodTitle}>Wallet</p>
                <p style={{ position: "relative", margin: 0, left: 0 }} className={styles.securePaymentContainer}>Balance • RM{centToDollar(homeState.wallet.balanceAmount)}</p>
                <div className={styles.addCreditCardContainer}>
                  <Button onClick={() => dispatch(openTopUpModal())} className={styles.plainButton}><img alt='creditcard' src={creditCardIcon} className={styles.labelSmallIcon} style={{ marginRight: 10 }} />Top Up Wallet</Button>
                  <Tooltip title="Where the delivery is confirmed, the delivery fees will be taken from your wallet and held until successful delivery. The delivery fees will be deducted from the amount held when the delivery is successful. However, a refund of the delivery fees shall apply to your wallet when the delivery fails." placement="right-start">
                    <img alt='info' src={infoIcon} className={styles.infoIcon} />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell colSpan={3} style={{ padding: 20, paddingLeft: 0, verticalAlign: "top" }}>
          <div style={{ borderRight: "1px solid #BFBFBF", minHeight: 145 }}>
            <p className={styles.labelParagraph} style={{ marginBottom: selectedVoucher ? 10 : 20 }}><img alt='voucher' src={voucherIcon} className={styles.labelIcon} />Select voucher</p>
            {
              selectedVoucher &&
              <div style={{ position: "relative" }}>
                <p className={styles.discountTitle}>{selectedVoucher.title ?? "Applied Voucher"}</p>
                <p className={styles.discountText}>-RM {calculateDiscount()}</p>
                <CloseIcon className={styles.removeVoucherIcon} onClick={removeVoucher} />
              </div>
            }
            <Button onClick={() => dispatch(openSelectVoucherModal())} className={styles.plainButton}>{selectedVoucher ? "Change Voucher" : "Select Voucher"}</Button>
          </div>
        </TableCell>
        <TableCell colSpan={2} valign="bottom">
          {
            orders.length > 0 &&
            <Button onClick={() => dispatch(handleConfirmOrders())} disabled={checkConfirmDisabled()} className={styles.gradientButton} style={{ float: 'right', marginBottom: 20 }}><img alt='done' src={doneIcon} className={styles.labelSmallIcon} />Confirm Orders</Button>
          }
        </TableCell>
        <TableCell colSpan={1}></TableCell>
      </TableRow>
    </TableBody>
  );
});

const Map = withGoogleMap(props => {
  return (
    <GoogleMap
      defaultZoom={14}
      options={{ gestureHandling: "greedy", styles: mapStyles }}
      defaultCenter={
        props.pickUpLocation
          ? { lat: Number(props.pickUpLocation.coordinate.latitude), lng: Number(props.pickUpLocation.coordinate.longitude) }
          : props.defaultLocation
      }
    >
    </GoogleMap>
  );
});

const MapWithDirections = withGoogleMap(props => {
  return (
    <GoogleMap
      defaultZoom={14}
      options={{ gestureHandling: "greedy", styles: mapStyles }}
      defaultCenter={
        props.pickUpLocation
          ? { lat: Number(props.pickUpLocation.coordinate.latitude), lng: Number(props.pickUpLocation.coordinate.longitude) }
          : props.defaultLocation
      }
    >
      {
        props.directions.length > 0 && props.directions.map((direction) => (
          <DirectionsRenderer directions={direction} />
        ))
      }
    </GoogleMap>
  );
});

export function Order() {
  const state = useSelector(selectState);
  const userState = useSelector(userSelectState);
  const orderGroupState = useSelector(orderGroupSelectState);
  const homeState = useSelector(homeSelectState);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetOrdersByOrderGroup(id));
      dispatch(handleGetDefaultDeliveryRateProfile());
      dispatch(handleGetVendor());
      dispatch(handleGetBookmarkedAddresses());
      dispatch(handleGetCreditCards());
      let authedUser = JSON.parse(localStorage.user);
      ReactPixel.init(FACEBOOK_PIXEL_ID, authedUser ? { em: authedUser.userName, fn: authedUser.firstName, ln: authedUser.lastName, ph: authedUser.contactNumber } : {}, { debug: false, autoConfig: false });
      ReactPixel.pageView();
      ReactPixel.fbq('track', 'PageView');
    }
  });

  const loadSelectedVoucher = () => {
    if (localStorage.selectedVoucher && isJSON(localStorage.selectedVoucher)) {
      let selectedVoucherStorage = JSON.parse(localStorage.selectedVoucher);
      setSelectedVoucher(selectedVoucherStorage);
    } else {
      setSelectedVoucher(null);
    }
  }

  useEffect(() => {
    loadSelectedVoucher();
  }, []);

  useEffect(() => {
    if (homeState.status === 'idle') {
      dispatch(handleGetUserWallet());
    }
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // disable sorting
    dispatch(setSortingDisable(true))

    if (oldIndex !== newIndex) {
      let orders = [...state.orderGroup.orders].sort((a, b) => a.ordinal - b.ordinal);
      if (newIndex > oldIndex) {
        for (let i = oldIndex; i <= newIndex; i++) {
          let newOrdinal = i === oldIndex ? newIndex : i - 1;
          orders[i] = { ...orders[i], ordinal: newOrdinal };
        }
      } else {
        for (let i = oldIndex; i >= newIndex; i--) {
          let newOrdinal = i === oldIndex ? newIndex : i + 1;
          orders[i] = { ...orders[i], ordinal: newOrdinal };
        }
      }

      // Update Backend & Calculate New Pricing
      dispatch(handleOnSortOrder(oldIndex, newIndex));
      // Update local state for smoother UX
      dispatch(setOrders(orders));
    }
  };

  const discountedRate = (rate, discount) => {
    return rate *= 1 - discount
  }

  return (
    <div className={styles.container}>
      <Topbar page="Orders" />

      <div style={{ marginTop: 70, paddingTop: 30, paddingInline: 40 }}>
        {
          state.orderGroup &&
          <>
            {
              state.directions.length > 0 ? (
                <MapWithDirections
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: "50vh", marginBottom: 20 }} />}
                  mapElement={<div style={{ height: `100%`, borderRadius: 5 }} />}
                  directions={state.directions}
                  pickUpLocation={state.orderGroup.pickUpLocation}
                  defaultLocation={{ lat: 3.0640394, lng: 101.60916369999995 }}
                />
              ) : (
                <Map
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: "50vh", marginBottom: 20 }} />}
                  mapElement={<div style={{ height: `100%`, borderRadius: 5 }} />}
                  directions={state.directions}
                  pickUpLocation={state.orderGroup.pickUpLocation}
                  defaultLocation={{ lat: 3.0640394, lng: 101.60916369999995 }}
                />
              )
            }
            <Breadcrumbs separator="›" aria-label="breadcrumb" className={styles.breadcrumb}>
              {
                state.orderGroup.status === "OPEN" ? (
                  <Link color="inherit" href="/app/order-groups">
                    Order Placements
                  </Link>
                ) : state.orderGroup.status === "COMPLETED" ? (
                  <Link color="inherit" href="/app/confirmed-order-groups">
                    Completed Orders
                  </Link>
                ) : (
                  <Link color="inherit" href="/app/confirmed-order-groups">
                    Confirmed Orders
                  </Link>
                )
              }
              <Typography color="textPrimary">View Orders</Typography>
            </Breadcrumbs>
          </>
        }
        <TableContainer component={Paper} className={styles.denseTable}>
          <Toolbar>
            <Typography variant="h6" id="tableTitle" component="div" style={{ flex: '1 1 100%' }}>
              Order Group
              {
                state.orderGroup && (state.orderGroup.status === "SUCCESS" || state.orderGroup.status === "PARTIAL_SUCCESS") &&
                <Chip label={state.orderGroup.status} style={{ backgroundColor: "green", color: "#fff", marginRight: "20px", marginLeft: "20px" }} />
              }
            </Typography>
            {
              state.orderGroup && state.orderGroup.status === "OPEN" &&
              <Tooltip title="Edit Pickup Time" onClick={() => dispatch(openEditOrderGroupModal())}>
                <IconButton aria-label="edit">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
          </Toolbar>
          <Table size="small">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell align="left" className={styles.tableHeaderNarrow}>Order Group Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Date & Time</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Delivery Charges</TableCell>
                {state.orderGroup && state.orderGroup.status !== "OPEN" && <TableCell align="left" className={styles.tableHeader}>Tracking Link</TableCell>}
                <TableCell align="left" className={styles.tableHeader}>Pickup Person Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Contact Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Halal</TableCell>
                {state.orderGroup && state.orderGroup.status !== "OPEN" && <TableCell align="left" className={styles.tableHeader}>Note To Driver</TableCell>}
              </TableRow>
            </TableHead>
            {
              state.orderGroup &&
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={styles.tableCellNarrow}>{state.orderGroup.id}</TableCell>
                  <TableCell align="left">{state.orderGroup.pickUpDateTime}</TableCell>
                  <TableCell align="left">{state.orderGroup.pickUpLocation.unitNumber}</TableCell>
                  <TableCell align="left">{state.orderGroup.pickUpLocation.address}</TableCell>
                  <TableCell align="left">RM{getTotalDeliveryCharges(state.orderGroup.orders)}</TableCell>
                  {state.orderGroup.status !== "OPEN" && state.orderGroup.assigned && <TableCell align="left" onClick={() => window.open(state.orderGroup.trackingLink, '_blank')} style={{ cursor: "pointer" }} className={styles.tableCellNarrow}>{state.orderGroup.trackingLink}</TableCell>}
                  {state.orderGroup.status !== "OPEN" && !state.orderGroup.assigned && <TableCell align="left">awaiting rider assignment</TableCell>}
                  <TableCell align="left">{state.orderGroup.pickUpPersonName}</TableCell>
                  <TableCell align="left">{state.orderGroup.pickUpContactNumber}</TableCell>
                  <TableCell align="left">{state.orderGroup.halal === true ? "Yes" : "No"}</TableCell>
                  {state.orderGroup.status !== "OPEN" && <TableCell align="left">{state.orderGroup.noteToDriver}</TableCell>}
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        {
          state.orderGroup &&
          <TableContainer component={Paper} className={styles.denseTable}>
            <TableToolbar
              numSelected={state.orderGroup.status === "OPEN" ? (state.selectedIds.length) : (state.selectedId === null ? 0 : 1)}
              orderGroup={state.orderGroup}
              createOrder={() => dispatch(openCreateOrderModal())}
              importOrder={() => dispatch(openImportOrderModal())}
              editOrder={() => dispatch(openEditOrderModal())}
              deleteOrder={() => dispatch(openDeleteOrderModal())}
              bookmarkAddress={() => dispatch(openBookmarkAddressModal())}
            />
            <Table size="small">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  {
                    state.orderGroup.status === "OPEN" ? (
                      <>
                        <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                        <TableCell align="left" className={styles.tableHeaderNarrow}>Order Number</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Delivery Date & Time</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Recipient Name</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Phone No.</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Delivery Address</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Product</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Receipt Number</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Distance from previous location</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Door To Door Charges</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Delivery Charges</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Cash on Delivery Amount</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Note To Driver</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Order Number</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Delivery Date & Time</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Assigned Driver</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Driver Contact</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Recipient Name</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Phone No.</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Delivery Address</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Distance from previous location</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Tracking Link</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Door To Door Charges</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Delivery Charges</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Cash on Delivery Amount</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Proof of delivery</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Product</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Receipt Number</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Completion Time</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Ratings</TableCell>
                      </>
                    )
                  }
                </TableRow>
              </TableHead>
              {
                state.orderGroup.orders.length > 0 ? (
                  state.orderGroup.status === "OPEN" ? (
                    <SortableList orders={state.orderGroup.orders} selectedVoucher={selectedVoucher} refetchVoucher={loadSelectedVoucher} onSortEnd={onSortEnd} dispatch={dispatch} state={state} userState={userState} homeState={homeState} />
                  ) : (
                    <TableBody>
                      {
                        state.orderGroup.orders.map((order) => (
                          <TableRow
                            key={order.id}
                            hover
                            onClick={() => dispatch(setSelectedId(state.selectedId === order.id ? null : order.id))}
                            role="checkbox"
                            aria-checked={state.selectedId === order.id ? true : false}
                            selected={state.selectedId === order.id ? true : false}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={state.selectedId === order.id ? true : false}
                              />
                            </TableCell>
                            <TableCell align="left" className={styles.tableCellNarrow}>{order.id}</TableCell>
                            <TableCell align="left">{order.deliverDateTime}</TableCell>
                            <TableCell align="left">{order.assignedDriver}</TableCell>
                            <TableCell align="left">{order.assignedDriverContactNumber}</TableCell>
                            <TableCell align="left">{order.recipientName}</TableCell>
                            <TableCell align="left">{order.recipientPhone}</TableCell>
                            <TableCell align="left">{order.dropOffLocation.unitNumber}</TableCell>
                            <TableCell align="left" style={{ minWidth: 250 }}>{order.dropOffLocation.address}</TableCell>
                            <TableCell align="left">{(order.deliveryRange / 1000).toFixed(2)}KM</TableCell>
                            {
                              !!order.assigned ? (
                                <TableCell align="left" onClick={() => window.open(order.trackingLink, '_blank')} style={{ cursor: "pointer" }} className={styles.tableCellNarrow}>{order.trackingLink}</TableCell>
                              ) : (
                                <TableCell align="left">Awaiting rider assignment</TableCell>
                              )
                            }
                            <TableCell align="left">{order.status}</TableCell>
                            <TableCell align="left">RM{order.doorToDoorFee ? order.doorToDoorFee / 100 : 0}</TableCell>
                            <TableCell align="left">RM{order.deliveryFee / 100}</TableCell>
                            <TableCell align="left">{order.codAmount ? `RM${order.codAmount / 100}` : "-"}</TableCell>
                            <TableCell align="left" onClick={() => window.open(order.proofOfDelivery, '_blank')} style={{ cursor: "pointer" }} className={styles.tableCellNarrow}>{order.proofOfDelivery}</TableCell>
                            <TableCell align="left">{order.product}</TableCell>
                            <TableCell align="left">{order.receiptNumber}</TableCell>
                            <TableCell align="left">{order.completionTime ? Moment(order.completionTime).format('YYYY-MM-DD HH:mm') : ""}</TableCell>
                            <TableCell align="left">{order.ratings}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  )
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={17} align="center">
                        <p>No Orders</p>
                        <div style={{ display: "flex", marginBottom: 20, justifyContent: "center" }}>
                          <Button onClick={() => dispatch(openCreateOrderModal())} className={styles.gradientButton}><AddIcon /> Create order</Button>
                          <Button onClick={() => dispatch(openImportOrderModal())} className={styles.gradientButton} style={{ marginLeft: 20 }}><PublishIcon /> Import orders</Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }
            </Table>
          </TableContainer>
        }
        {
          orderGroupState.editOrderGroupModalOpen &&
          <EditOrderGroup orderGroupType="OPEN" orderGroup={state.orderGroup} />
        }
        {
          state.createOrderModalOpen &&
          <CreateOrder createOrderModalOpen={state.createOrderModalOpen} orderGroup={state.orderGroup} bookmarkedAddresses={state.bookmarkedAddresses} />
        }
        {
          state.importOrderModalOpen &&
          <ImportOrder importOrderModalOpen={state.importOrderModalOpen} orderGroup={state.orderGroup} />
        }
        {
          state.editOrderModalOpen &&
          <EditOrder order={state.selectedIds.length > 0 ? state.orderGroup.orders.find(order => order.id === state.selectedIds[0]) : null} orderGroup={state.orderGroup} />
        }
        {
          state.deleteOrderModalOpen &&
          <ConfirmDelete orders={state.selectedIds.length > 0 ? state.orderGroup.orders.filter(order => state.selectedIds.includes(order.id)) : []} orderGroup={state.orderGroup} />
        }
        {
          state.bookmarkAddressModalOpen &&
          <BookmarkAddress order={state.selectedIds.length > 0 ? state.orderGroup.orders.find(order => order.id === state.selectedIds[0]) : null} orderGroup={state.orderGroup} />
        }
        {
          state.topUpModalOpen &&
          <TopUp closeModal={closeTopUpModal} />
        }
        {
          state.selectVoucherModalOpen &&
          <SelectVoucher closeModal={closeSelectVoucherModal} onApplyVoucher={loadSelectedVoucher} />
        }
        {
          state.orderGroup && state.defaultDeliveryRateProfile &&
          <TableContainer component={Paper} className={styles.denseTable}>
            {
              state.orderGroup.deliveryMethod === "CAR" ? (
                <>
                  <Toolbar>
                    <Avatar><DirectionsCarIcon /></Avatar>
                    <Typography variant="h6" id="tableTitle" component="div" style={{ marginLeft: 20 }}>
                      Car Rates
                    </Typography>
                  </Toolbar>
                  <Table size="small">
                    <TableHead className={styles.tableHeader}>
                      <TableRow>
                        <TableCell align="left" className={styles.tableHeaderNarrow}>Base Fare</TableCell>
                        <TableCell align="left" className={styles.tableHeader} colSpan={3}>Per KM Rate</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Additional Stop</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Minimum Fare Per Delivery</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Door To Door Fee Per Order</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.vendor &&
                        <TableRow>
                          <TableCell align="left" className={styles.tableCellNarrow}><span className={styles.rateBigFont}>RM <b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.carRate.base, state.vendor.deliveryRateProfile.carRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.carRate.base, state.defaultDeliveryRateProfile.carRate.discount).toFixed(2)}</b></span></TableCell>
                          <TableCell align="left"><span className={styles.rateSmallFont}>First 15KM</span> <br /> <span className={styles.rateBigFont}>RM <b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.carRate.tier1, state.vendor.deliveryRateProfile.carRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.carRate.tier1, state.defaultDeliveryRateProfile.carRate.discount).toFixed(2)}</b><sup className={styles.rateSmallFont}>/km</sup></span></TableCell>
                          <TableCell align="left"><span className={styles.rateSmallFont}>Second 15KM</span> <br /> <span className={styles.rateBigFont}>RM <b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.carRate.tier2, state.vendor.deliveryRateProfile.carRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.carRate.tier2, state.defaultDeliveryRateProfile.carRate.discount).toFixed(2)}</b><sup className={styles.rateSmallFont}>/km</sup></span></TableCell>
                          <TableCell align="left"><span className={styles.rateSmallFont}>Subsequent KM</span> <br /> <span className={styles.rateBigFont}>RM <b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.carRate.next, state.vendor.deliveryRateProfile.carRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.carRate.next, state.defaultDeliveryRateProfile.carRate.discount).toFixed(2)}</b><sup className={styles.rateSmallFont}>/km</sup></span></TableCell>
                          <TableCell align="left">RM <span className={styles.rateBigFont}><b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.carRate.additionalStop, state.vendor.deliveryRateProfile.carRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.carRate.additionalStop, state.defaultDeliveryRateProfile.carRate.discount).toFixed(2)}</b><sup className={styles.rateSmallFont}>/stop</sup></span></TableCell>
                          <TableCell align="left">RM <span className={styles.rateBigFont}><b>{state.vendor.deliveryRateProfile ? state.vendor.deliveryRateProfile.carRate.min.toFixed(2) : state.defaultDeliveryRateProfile.carRate.min.toFixed(2)}</b></span></TableCell>
                          <TableCell align="left">RM <span className={styles.rateBigFont}><b>{state.vendor.deliveryRateProfile ? Number(centToDollar(state.vendor.deliveryRateProfile.carRate.doorToDoor ? state.vendor.deliveryRateProfile.carRate.doorToDoor : 200)) : Number(centToDollar(state.defaultDeliveryRateProfile.carRate.doorToDoor ? state.defaultDeliveryRateProfile.carRate.doorToDoor : 200))}</b></span></TableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </>
              ) : (
                <>
                  <Toolbar>
                    <Avatar><MotorcycleIcon /></Avatar>
                    <Typography variant="h6" id="tableTitle" component="div" style={{ marginLeft: 20 }}>
                      Motor Rates
                    </Typography>
                  </Toolbar>
                  <Table size="small">
                    <TableHead className={styles.tableHeader}>
                      <TableRow>
                        <TableCell align="left" className={styles.tableHeaderNarrow}>Base Fare</TableCell>
                        <TableCell align="left" className={styles.tableHeader} colSpan={3}>Per KM Rate</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Additional Stop</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Minimum Fare Per Delivery</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>Door To Door Fee Per Order</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.vendor &&
                        <TableRow>
                          <TableCell align="left" className={styles.tableCellNarrow}><span className={styles.rateBigFont}>RM <b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.motorRate.base, state.vendor.deliveryRateProfile.motorRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.motorRate.base, state.defaultDeliveryRateProfile.motorRate.discount).toFixed(2)}</b></span></TableCell>
                          <TableCell align="left"><span className={styles.rateSmallFont}>First 15KM</span> <br /> <span className={styles.rateBigFont}>RM <b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.motorRate.tier1, state.vendor.deliveryRateProfile.motorRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.motorRate.tier1, state.defaultDeliveryRateProfile.motorRate.discount).toFixed(2)}</b><sup className={styles.rateSmallFont}>/km</sup></span></TableCell>
                          <TableCell align="left"><span className={styles.rateSmallFont}>Second 15KM</span> <br /> <span className={styles.rateBigFont}>RM <b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.motorRate.tier2, state.vendor.deliveryRateProfile.motorRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.motorRate.tier2, state.defaultDeliveryRateProfile.motorRate.discount).toFixed(2)}</b><sup className={styles.rateSmallFont}>/km</sup></span></TableCell>
                          <TableCell align="left"><span className={styles.rateSmallFont}>Subsequent KM</span> <br /> <span className={styles.rateBigFont}>RM <b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.motorRate.next, state.vendor.deliveryRateProfile.motorRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.motorRate.next, state.defaultDeliveryRateProfile.motorRate.discount).toFixed(2)}</b><sup className={styles.rateSmallFont}>/km</sup></span></TableCell>
                          <TableCell align="left">RM <span className={styles.rateBigFont}><b>{state.vendor.deliveryRateProfile ? discountedRate(state.vendor.deliveryRateProfile.motorRate.additionalStop, state.vendor.deliveryRateProfile.motorRate.discount).toFixed(2) : discountedRate(state.defaultDeliveryRateProfile.motorRate.additionalStop, state.defaultDeliveryRateProfile.motorRate.discount).toFixed(2)}</b><sup className={styles.rateSmallFont}>/stop</sup></span></TableCell>
                          <TableCell align="left">RM <span className={styles.rateBigFont}><b>{state.vendor.deliveryRateProfile ? state.vendor.deliveryRateProfile.motorRate.min.toFixed(2) : state.defaultDeliveryRateProfile.motorRate.min.toFixed(2)}</b></span></TableCell>
                          <TableCell align="left">RM <span className={styles.rateBigFont}><b>{state.vendor.deliveryRateProfile ? Number(centToDollar(state.vendor.deliveryRateProfile.motorRate.doorToDoor ? state.vendor.deliveryRateProfile.motorRate.doorToDoor : 200)) : Number(centToDollar(state.defaultDeliveryRateProfile.motorRate.doorToDoor ? state.defaultDeliveryRateProfile.motorRate.doorToDoor : 200))}</b></span></TableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </>
              )
            }
          </TableContainer>
        }
      </div>
    </div>
  );
}
