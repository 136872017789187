import React, { createRef, useEffect, useRef } from 'react';
import styles from './PlaceOrder.module.css';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { CSVReader } from 'react-papaparse';
import Geocoder from 'react-native-geocoding';
import Moment from 'moment';

import { dollarToCent, phoneUtil } from '../../app/utils';
import { createOrder } from './orderAPI';
import { handleGetOrdersByOrderGroup, ordersState, setLoading, setShowImportModal } from './placeOrderSlice';

import closeIcon from "../../images/place_order/close-icon.png";
import downloadIcon from "../../images/place_order/download-icon.png";
import uploadIcon from "../../images/place_order/upload-icon.png";
import uploadedIcon from "../../images/place_order/uploaded-icon.png";

const buttonRef = createRef();

export function ImportModal() {
    const dispatch = useDispatch();
    const orderListState = useSelector(ordersState);

    const initialState = {
        orders: [],
        geocoderInit: false,
        isSubmitDisabled: true,
        error: false,
        errorMessage: ""
    };

    const [importState, _setImportState] = useStateWithCallbackLazy({
        ...initialState
    });

    const importStateRef = useRef(importState);

    const setImportState = data => {
        importStateRef.current = data;
        _setImportState(data);
    };

    useEffect(() => {
        if (!importState.geocoderInit) {
            Geocoder.init("AIzaSyAqnJ6BHC7MyO0pjruTLr4r9K5F6eww4Gg", {
                types: ["geocode", "establishment"],
                componentRestrictions: { country: ["my", "sg"] }
            });
            setImportState({ ...importState, geocoderInit: true });
        }
    });

    const geoCodeAddress = async (address) => {
        try {
            const json = await Geocoder.from(address);
            var location = json.results[0].geometry.location;
            return { latitude: location.lat, longitude: location.lng };
        } catch (err) {
            setImportState({ ...importState, error: true, errorMessage: `Error while geocoding address: ${address}.` });
            return { latitude: "", longitude: "" }
        }
    }

    const validateOrders = (orders) => {
        if (orders.length <= 0) {
            setImportState({ ...importState, error: true, errorMessage: "No orders found in the CSV file." });
            return false;
        }

        if (orders.filter(o => o.errors.length > 0).length > 0) {
            setImportState({ ...importState, error: true, errorMessage: "Error reading CSV file, please try again." });
            return false;
        }

        return true;
    }

    const calculateDistanceAndSubmit = async () => {
        dispatch(setLoading(true));
        let sortedOrders = [...importState.orders].sort((a, b) => a.ordinal - b.ordinal);

        if (sortedOrders.length > 0) {
            sortedOrders.reduce(async (previousOrder, order, i) => {
                // wait for the previous order to finish processing
                await previousOrder;

                try {
                    // convert Datetime to UTC
                    let orderPayload = {
                        ...order,
                        deliverDateTime: order.deliverDateTime.toISOString(),
                        codAmount: order.codAmount ? Number(dollarToCent(order.codAmount)) : ""
                    }

                    await createOrder(orderListState.orderGroup.id, orderPayload);
                    if (i === sortedOrders.length - 1) {
                        dispatch(setLoading(false));
                        dispatch(setShowImportModal(false));
                        dispatch(handleGetOrdersByOrderGroup(orderListState.orderGroup.id));
                    }
                } catch (error) {
                    setImportState({ ...importState, error: true, errorMessage: error.message });
                }
            }, Promise.resolve());
        }

        dispatch(setShowImportModal(false));
    }

    const submit = async () => {
        let error = false;

        for (let order of importState.orders) {
            let orderRecipientName = order.recipientName

            if (!Moment(order.deliverDateTime, "MM/DD/YYYY HH:MM", true).isValid()) {
                error = true;
                setImportState({ ...importState, error: true, errorMessage: `Invalid delivery date time for ${orderRecipientName}, please follow format MM/DD/YYYY HH:MM.` });
                return
            }
            if (new Date(order.deliverDateTime) - new Date() < 0) {
                error = true;
                setImportState({ ...importState, error: true, errorMessage: `Delivery date is in the past for ${orderRecipientName}.` });
                return;
            }
            if (new Date(order.deliverDateTime) - new Date(orderListState.orderGroup.pickUpDateTime) < 0) {
                error = true;
                setImportState({ ...importState, error: true, errorMessage: `Delivery date is earlier than pickup date for ${orderRecipientName}.` });
                return;
            }
            for (var key in order) {
                if (key !== "recipientEmail" && key !== "ordinal" && key !== "codAmount" && key !== "deliveryRange" && key !== "assigned" && key !== "doorToDoor" && key !== "noteToDriver") {
                    if (order[key] === null || order[key] === "") {
                        error = true;
                        setImportState({ ...importState, error: true, errorMessage: `Some mandatory fields have empty values for ${orderRecipientName}.` });
                        return;
                    }
                }
            }
            if (order.dropOffLocation.city === null || order.dropOffLocation.city === "" || order.dropOffLocation.country === null || order.dropOffLocation.country === "") {
                error = true;
                setImportState({ ...importState, error: true, errorMessage: `Missing city/country for ${orderRecipientName}, please download the new CSV template for reference.` });
                return;
            }
            // make sure phone number start with + sign
            if (!order.recipientPhone.startsWith("+")) {
                error = true;
                setImportState({ ...importState, error: true, errorMessage: `${orderRecipientName}'s contact number must start with the + sign followed by the country code (eg. +60).` });
                return;
            }
            // make sure phone number not dash or space
            if (order.recipientPhone.includes("-") || order.recipientPhone.includes(" ")) {
                error = true;
                setImportState({ ...importState, error: true, errorMessage: `Please remove any dash (-) and space in ${orderRecipientName}'s contact number.` });
                return;
            }
            // validate phone number using google lib
            try {
                let phoneNumberProto = phoneUtil.parse(order.recipientPhone, "")
                if (!phoneUtil.isValidNumber(phoneNumberProto)) {
                    error = true;
                    setImportState({ ...importState, error: true, errorMessage: `${orderRecipientName}'s contact number is invalid.` });
                    return;
                }
            } catch (e) {
                error = true;
                setImportState({ ...importState, error: true, errorMessage: `${orderRecipientName}'s contact number is invalid.` });
                return;
            }
        }

        if (!error) {
            calculateDistanceAndSubmit();
        }
    }

    const handleUploadFile = (e) => {
        // note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    }

    const handleRemoveFile = (e) => {
        // note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
        }

        setImportState({ ...importStateRef, isSubmitDisabled: true, error: false, errorMessage: "" });
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log('handleOnError', err, reason)
    }

    const handleOnFileLoad = async (data) => {
        // remove CSV Header
        let dataRows = data.slice(1);

        if (validateOrders(dataRows)) {
            let orders = [];

            // filter empty value rows
            let filteredDataRow = dataRows.filter(r => r.data.filter(v => v !== '' && v !== null).length >= 6);
            dispatch(setLoading(true));

            for (const [i, row] of filteredDataRow.entries()) {
                if (row.data.length > 1) {
                    let order = {
                        ordinal: i,
                        recipientName: row.data[0],
                        recipientEmail: "",
                        deliverDateTime: new Date(row.data[5]),
                        recipientPhone: row.data[1],
                        dropOffLocation: {
                            unitNumber: row.data[3],
                            address: row.data[4],
                            coordinate: await geoCodeAddress(row.data[4]),
                            city: row.data[7],
                            country: row.data[8]
                        },
                        product: row.data[2],
                        codAmount: row.data[6],
                        deliveryRange: "",
                        noteToDriver: row.data[9],
                        doorToDoor: (row.data[10].trim().toLowerCase() === 'true' || row.data[10].trim().toLowerCase() === 'yes') ? true : false
                    }
                    orders.push(order);
                }
            }
            
            setImportState({ ...importStateRef.current, orders: orders, isSubmitDisabled: false });
            dispatch(setLoading(false));
        }
    }

    const handleOnFileRemove = () => {
        setImportState({ ...importState, orders: [], isSubmitDisabled: true });
    }

    return (
        <>
            <div className={styles.importModal}>
                <div style={{ display: "flex" }}>
                    <p className={styles.tealText} style={{ textAlign: "left", fontWeight: "500" }}>Import Bulk Order from CSV</p>
                    <img className={styles.icon} style={{ width: 10, marginLeft: "auto", marginRight: 0, marginTop: -5, cursor: "pointer" }} src={closeIcon} alt="close" onClick={() => dispatch(setShowImportModal(false))} />
                </div>

                {/* Bulk Order CSV Template */}
                <a
                    href="https://parkitstatic.s3.ap-southeast-1.amazonaws.com/deliverit/DeliverIt+Bulk+Order+Upload+2.csv"
                    download
                    style={{ display: "flex", alignItems: "center", marginTop: 15, cursor: "pointer", color: "#979797" }}>
                    <img className={styles.icon} style={{ width: 15, marginLeft: 0 }} src={downloadIcon} alt="download" />
                    <p className={styles.greyText}>Download Bulk Order CSV Template</p>
                </a>

                {/* Error Alert */}
                {
                    importState.error &&
                    <Alert
                        severity="error"
                        style={{ marginTop: 10, borderRadius: 10, textAlign: "left" }}
                        onClose={() => setImportState({
                            ...importState,
                            error: false,
                            errorMessage: ""
                        })}>
                        {importState.errorMessage}
                    </Alert>
                }

                {/* Upload Bulk Order */}
                <CSVReader
                    ref={buttonRef}
                    onFileLoad={handleOnFileLoad}
                    onRemoveFile={handleOnFileRemove}
                    onError={handleOnError}
                    noClick
                    noDrag
                >
                    {({ file }) => (
                        <aside className={styles.uploadContainer}>
                            {
                                file
                                    ? <>
                                        <img className={styles.uploadIcon} src={uploadedIcon} alt="uploaded" />
                                        <p className={styles.tealText} style={{ marginBlock: 10, fontWeight: "500" }}>{file.name}</p>
                                        <div className={styles.removeButton} onClick={handleRemoveFile}>
                                            <p className={styles.tealText} style={{ fontSize: 11 }}>Remove</p>
                                        </div>
                                    </>
                                    : <>
                                        <img className={styles.uploadIcon} src={uploadIcon} alt="upload" />
                                        <p className={styles.tealText} style={{ marginBlock: 10, fontWeight: "500" }}>Upload your CSV file here</p>
                                        <div className={styles.uploadButton} onClick={handleUploadFile}>
                                            <p className={styles.whiteText} style={{ fontSize: 11 }}>Upload</p>
                                        </div>
                                    </>
                            }
                        </aside>
                    )}
                </CSVReader>

                {/* Submit Button */}
                <div className={styles.modalButtonContainer}>
                    {
                        importState.isSubmitDisabled || importState.error
                            ? <div className={styles.modalButton} style={{ backgroundImage: "none", backgroundColor: "#e0e0e0", boxShadow: "none" }}>
                                <p className={styles.whiteText} style={{ fontSize: 13 }}>Submit</p>
                            </div>
                            : <div className={styles.modalButton} onClick={() => submit()}>
                                <p className={styles.whiteText} style={{ fontSize: 13 }}>Submit</p>
                            </div>
                    }
                </div>
            </div>
            <div className={styles.overlay} onClick={() => dispatch(setShowImportModal(false))}></div>
        </>
    )
}