import React, { useEffect, useMemo, useState } from 'react';
import styles from './PlaceOrder.module.css';
import { useDispatch } from 'react-redux';
import { Radio } from '@material-ui/core';

import { claimVoucher, getPromoCodes, getUserVouchers } from '../voucher/voucherAPI';
import { getEstimatedTotal, setSelectedVoucher, setShowVoucherModal } from './placeOrderSlice';

import closeIcon from "../../images/place_order/close-icon.png";
import dollarVoucher from "../../images/place_order/dollar-voucher.png";
import percentVoucher from "../../images/place_order/percent-voucher.png";

export const VoucherModal = () => {
  const dispatch = useDispatch();

  const [init, setInit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedVoucherId, setSelectedVoucherId] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);

  useEffect(() => {
    const handleGetPromoCodes = async () => {
      try {
        const response = await getPromoCodes();
        const { content } = response.data;

        const claimedPromoCode = vouchers.map(v => v.promoCode.code);
        const filteredContent = content.filter(p => !claimedPromoCode.includes(p.code));
        setPromoCodes(filteredContent);
      } catch (error) {
        console.log(error.message);
      }
    }

    const handleGetVouchers = async () => {
      try {
        const userUnappliedVouchersResponse = await getUserVouchers();
        setVouchers(userUnappliedVouchersResponse.data);
      } catch (error) {
        console.log(error.message);
      }
    }

    async function initFn() {
      if (!init) {
        await handleGetVouchers();
        await handleGetPromoCodes();
        setInit(true);
      }
    }
    initFn();
  }, [init, vouchers, promoCodes]);

  const isButtonDisabled = useMemo(
    () => (!selectedVoucherId || submitting),
    [selectedVoucherId, submitting],
  );

  const applyVoucher = async () => {
    try {
      setSubmitting(true);
      if (selectedVoucherId) {
        const selectedVoucher = promoCodes.find(v => v.id === selectedVoucherId);
        if (selectedVoucher) {
          const claimVoucherResponse = await claimVoucher({
            promoCode: {
              id: selectedVoucher.id
            }
          });
          dispatch(setSelectedVoucher(claimVoucherResponse.data));
        }

        const selectedClaimedVoucher = vouchers.find(v => v.id === selectedVoucherId);
        if (selectedClaimedVoucher) {
          dispatch(setSelectedVoucher(selectedClaimedVoucher));
        }
      }
      dispatch(getEstimatedTotal());
      dispatch(setShowVoucherModal(false));
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      } else {
        alert("Something went wrong when applying the voucher.");
      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <div className={styles.voucherModal}>
        <div style={{ display: "flex" }}>
          <p className={styles.tealText} style={{ textAlign: "left", fontWeight: "500" }}>Vouchers</p>
          <img className={styles.icon} style={{ width: 10, marginLeft: "auto", marginRight: 0, marginTop: -5, cursor: "pointer" }} src={closeIcon} alt="close" onClick={() => dispatch(setShowVoucherModal(false))} />
        </div>

        <div className={styles.vouchersContainer}>
          {/* Vouchers */}
          {
            vouchers.length > 0 && vouchers.map((voucher, index) => {
              const { promoCode } = voucher;
              const expiryTime = promoCode.expiryDate === undefined ? undefined : new Date(new Date(new Date(new Date(promoCode.expiryDate).setHours(23)).setMinutes(59)).setSeconds(59));

              return (
                <div className={styles.voucherRow} style={{ opacity: (expiryTime <= new Date()) ? "50%" : "100%" }} key={`voucher-${index}`} onClick={() => (expiryTime > new Date() || expiryTime === undefined) && setSelectedVoucherId(voucher.id)}>
                  <img className={styles.voucherImg} src={promoCode.discountAmount ? dollarVoucher : percentVoucher} alt="voucher" />
                  <div style={{ flex: 1, marginLeft: 15, textAlign: "left" }}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <p className={styles.blackText} style={{ fontSize: 14 }}>{(promoCode.title === "" || promoCode.title === undefined) ? "Voucher" : promoCode.title}</p>
                      <Radio
                        checked={voucher.id === selectedVoucherId ? true : false}
                        onChange={() => (expiryTime > new Date() || expiryTime === undefined) && setSelectedVoucherId(voucher.id)}
                        size="small"
                        style={{ marginLeft: "auto", marginBlock: 0, paddingBlock: 0, color: "#1e9299" }}
                      />
                    </div>
                    <div className={styles.codeContainer}>
                      <p className={styles.tealText} style={{ fontSize: 15 }}>{promoCode.code}</p>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      {
                        promoCode.expiryDate &&
                        <p className={styles.greyText} style={{ fontSize: 10 }}>Valid till: {promoCode.expiryDate}</p>
                      }
                      <p className={styles.tealText} style={{ marginLeft: "auto", marginRight: 10, fontSize: 10, fontWeight: "500", cursor: "pointer" }} onClick={() => window.open("/voucher-terms", "_blank")}>T&C</p>
                    </div>
                  </div>
                </div>
              )
            })
          }

          {/* Promo Codes */}
          {
            promoCodes.length > 0 && promoCodes.map((promoCode, index) => {
              const expiryTime = promoCode.expiryDate === undefined ? undefined : new Date(new Date(new Date(new Date(promoCode.expiryDate).setHours(23)).setMinutes(59)).setSeconds(59));

              return (
                <div className={styles.voucherRow} style={{ opacity: (expiryTime <= new Date()) ? "50%" : "100%" }} key={`promoCode-${index}`} onClick={() => (expiryTime > new Date() || expiryTime === undefined) && setSelectedVoucherId(promoCode.id)}>
                  <img className={styles.voucherImg} src={promoCode.discountAmount ? dollarVoucher : percentVoucher} alt="promo-code" />
                  <div style={{ flex: 1, marginLeft: 15, textAlign: "left" }}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <p className={styles.blackText} style={{ fontSize: 14 }}>{(promoCode.title === "" || promoCode.title === undefined) ? "Promo Code" : promoCode.title}</p>
                      <Radio
                        checked={promoCode.id === selectedVoucherId ? true : false}
                        onChange={() => (expiryTime > new Date() || expiryTime === undefined) && setSelectedVoucherId(promoCode.id)}
                        size="small"
                        style={{ marginLeft: "auto", marginBlock: 0, paddingBlock: 0, color: "#1e9299" }}
                      />
                    </div>
                    <div className={styles.codeContainer}>
                      <p className={styles.tealText} style={{ fontSize: 15 }}>{promoCode.code}</p>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      {
                        promoCode.expiryDate &&
                        <p className={styles.greyText} style={{ fontSize: 10 }}>Valid till: {promoCode.expiryDate}</p>
                      }
                      <p className={styles.tealText} style={{ marginLeft: "auto", marginRight: 10, fontSize: 10, fontWeight: "500", cursor: "pointer" }} onClick={() => window.open("/voucher-terms", "_blank")}>T&C</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        {/* OK Button */}
        <div className={styles.modalButtonContainer}>
          {
            isButtonDisabled
              ? <div className={styles.modalButton} style={{ backgroundImage: "none", backgroundColor: "#e0e0e0", boxShadow: "none" }}>
                <p className={styles.whiteText} style={{ fontSize: 13 }}>Select</p>
              </div>
              : <div className={styles.modalButton} onClick={() => applyVoucher()}>
                <p className={styles.whiteText} style={{ fontSize: 13 }}>Select</p>
              </div>
          }
        </div>
      </div>
      <div className={styles.overlay} onClick={() => dispatch(setShowVoucherModal(false))}></div>
    </>
  )
}