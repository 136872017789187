import axiosApiInstance from "../../app/axios";
import { API_BASE_URL, getJwtToken } from "../../app/utils";

export const getUserAddresses = async (vendorId, jobType, page, size) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/address-bookmarks?vendorId=${vendorId}&jobType=${jobType}&page=${page}&size=${size}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const createAddress = async (data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/address-bookmarks`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const updateAddress = async (data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/deliverit/v1/address-bookmarks/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const deleteAddress = async (id) =>
  axiosApiInstance.delete(
    `${API_BASE_URL}/deliverit/v1/address-bookmarks/${id}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const incrementAddressUseCount = async (id) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/address-bookmarks/${id}/increment-use-count`,
    {},
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );