import axiosApiInstance from "../../app/axios";
import { API_BASE_URL, getJwtToken } from "../../app/utils";

export const getInvoice = async (walletId, paymentId) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/payments/${paymentId}/invoice?page=0&size=10`,
    {
      responseType: 'blob',
      headers: { 
        "Authorization": `Bearer ${await getJwtToken()}`,
        "Content-Type": "application/pdf"
      }
    }
  );

export const getTopUpInvoice = async (walletId, topUpId) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/top-up/${topUpId}/invoice?page=0&size=10`,
    {
      responseType: 'blob',
      headers: { 
        "Authorization": `Bearer ${await getJwtToken()}`,
        "Content-Type": "application/pdf"
      }
    }
  );