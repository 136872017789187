/*global google*/
import { withGoogleMap, GoogleMap, DirectionsRenderer, Marker, InfoWindow } from "react-google-maps";
import { CustomInfoWindow } from '../CustomInfoWindow';
import styles from '../PlaceOrder.module.css';
import mapStyles from '../../order/mapStyle';

import redMapMarker from "../../../images/place_order/red-map-marker.png";
import tealMapMarker from "../../../images/place_order/teal-map-marker.png";

export const PublicEstimateMap = withGoogleMap(props => {
  const {
    showPickUpInfoWindow,
    showDropOffInfoWindow,
    setShowPickUpInfoWindow,
    setShowDropOffInfoWindow,
    pickUpLocation,
    orders,
    directions
  } = props;

  return (
    <GoogleMap
      defaultZoom={14}
      options={{ gestureHandling: "greedy", styles: mapStyles }}
      defaultCenter={{ lat: 3.0640394, lng: 101.60916369999995 }}
    >
      {
        directions.length > 0 && directions.map((direction) => {
          return (
            <>
              <DirectionsRenderer
                directions={direction}
                options={{
                  polylineOptions: {
                    strokeColor: "#227c97"
                  },
                  suppressMarkers: true
                }}
              />
              {
                direction.routes[0].legs.map((leg, index) => {
                  return (
                    <>
                      {
                        index === 0 &&
                        <>
                          <Marker
                            key="start"
                            icon={{
                              url: redMapMarker,
                              scaledSize: new google.maps.Size(35, 50),
                            }}
                            position={{
                              lat: Number(leg.start_location.lat()),
                              lng: Number(leg.start_location.lng())
                            }}
                            onMouseOver={() => {
                              setShowPickUpInfoWindow(true);
                              setShowDropOffInfoWindow(index, false);
                            }}>
                            {
                              showPickUpInfoWindow &&
                              <InfoWindow onCloseClick={() => setShowPickUpInfoWindow(false)}>
                                <CustomInfoWindow
                                  index={index}
                                  isPublicEstimate={true}
                                  location={pickUpLocation}
                                />
                              </InfoWindow>
                            }
                          </Marker>
                        </>
                      }
                      {
                        index !== 0 &&
                        <>
                          <Marker
                            key="end"
                            icon={{
                              url: tealMapMarker,
                              scaledSize: new google.maps.Size(32, 40),
                            }}
                            position={{
                              lat: Number(leg.start_location.lat()),
                              lng: Number(leg.start_location.lng())
                            }}
                            label={{
                              className: `${styles.mapMarkerOrdinal}`,
                              text: `${index}`,
                              color: "#227c97",
                              fontWeight: "500",
                            }}
                            onMouseOver={() => {
                              setShowPickUpInfoWindow(false);
                              setShowDropOffInfoWindow(index - 1, true);
                            }}>
                            {
                              orders.length > 0 && showDropOffInfoWindow[index - 1] &&
                              <InfoWindow
                                onCloseClick={() => setShowDropOffInfoWindow(index - 1, false)}>
                                <CustomInfoWindow
                                  index={index}
                                  isPublicEstimate={true}
                                  location={orders[index - 1].dropOffLocation}
                                  deliveryFee={orders[index - 1].deliveryFee}
                                />
                              </InfoWindow>
                            }
                          </Marker>
                        </>
                      }
                      {
                        index === (direction.routes[0].legs.length - 1) &&
                        <>
                          <Marker
                            key="end"
                            icon={{
                              url: tealMapMarker,
                              scaledSize: new google.maps.Size(32, 40),
                            }}
                            position={{
                              lat: Number(leg.end_location.lat()),
                              lng: Number(leg.end_location.lng())
                            }}
                            label={{
                              className: `${styles.mapMarkerOrdinal}`,
                              text: `${index + 1}`,
                              color: "#227c97",
                              fontWeight: "500",
                            }}
                            onMouseOver={() => {
                              setShowPickUpInfoWindow(false);
                              setShowDropOffInfoWindow(index, true);
                            }}>
                            {
                              orders.length > 0 && showDropOffInfoWindow[index] &&
                              <InfoWindow
                                onCloseClick={() => setShowDropOffInfoWindow(index, false)}>
                                <CustomInfoWindow
                                  index={index + 1}
                                  isPublicEstimate={true}
                                  location={orders[index].dropOffLocation}
                                  deliveryFee={orders[index].deliveryFee}
                                />
                              </InfoWindow>
                            }
                          </Marker>
                        </>
                      }
                    </>
                  )
                })
              }
            </>
          )
        })
      }
    </GoogleMap>
  );
});
