import React, { useEffect, useState } from 'react';
import styles from '../place_order/PlaceOrder.module.css';
import { useDispatch } from 'react-redux';

import { getPromoCodes, getUserVouchers } from '../voucher/voucherAPI';
import { setShowVoucherModal } from './dashboardSlice';

import closeIcon from "../../images/place_order/close-icon.png";
import dollarVoucher from "../../images/place_order/dollar-voucher.png";
import percentVoucher from "../../images/place_order/percent-voucher.png";

export const VoucherModal = () => {
    const dispatch = useDispatch();

    const [init, setInit] = useState(false);
    const [vouchers, setVouchers] = useState([]);
    const [promoCodes, setPromoCodes] = useState([]);

    useEffect(() => {
        const handleGetPromoCodes = async () => {
            try {
                const response = await getPromoCodes();
                const { content } = response.data;

                const claimedPromoCode = vouchers.map(v => v.promoCode.code);
                const filteredContent = content.filter(p => !claimedPromoCode.includes(p.code));
                setPromoCodes(filteredContent);
            } catch (error) {
                console.log(error.message);
            }
        }

        const handleGetVouchers = async () => {
            try {
                const userUnappliedVouchersResponse = await getUserVouchers();
                setVouchers(userUnappliedVouchersResponse.data);
            } catch (error) {
                console.log(error.message);
            }
        }

        async function initFn() {
            if (!init) {
                await handleGetVouchers();
                await handleGetPromoCodes();
                setInit(true);
            }
        }
        initFn();
    }, [init, vouchers, promoCodes]);

    return (
        <>
            <div className={styles.voucherModal}>
                <div style={{ display: "flex" }}>
                    <p className={styles.tealText} style={{ textAlign: "left", fontWeight: "500" }}>Vouchers</p>
                    <img className={styles.icon} style={{ width: 10, marginLeft: "auto", marginRight: 0, marginTop: -5, cursor: "pointer" }} src={closeIcon} alt="close" onClick={() => dispatch(setShowVoucherModal(false))} />
                </div>

                <div className={styles.vouchersContainer} style={{ height: "95%" }}>
                    {/* Vouchers */}
                    {
                        vouchers.length > 0 && vouchers.map((voucher, index) => {
                            const { promoCode } = voucher;
                            const expiryTime = promoCode.expiryDate === undefined ? undefined : new Date(new Date(new Date(new Date(promoCode.expiryDate).setHours(23)).setMinutes(59)).setSeconds(59));

                            return (
                                <div className={styles.voucherRow} style={{ opacity: (expiryTime <= new Date()) ? "50%" : "100%" }} key={`voucher-${index}`}>
                                    <img className={styles.voucherImg} src={promoCode.discountAmount ? dollarVoucher : percentVoucher} alt="voucher" />
                                    <div style={{ flex: 1, marginLeft: 15, textAlign: "left" }}>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            <p className={styles.blackText} style={{ fontSize: 14 }}>{promoCode.title ?? "Voucher"}</p>
                                        </div>
                                        <div className={styles.codeContainer}>
                                            <p className={styles.tealText} style={{ fontSize: 15 }}>{promoCode.code}</p>
                                        </div>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            {
                                                promoCode.expiryDate &&
                                                <p className={styles.greyText} style={{ fontSize: 10 }}>Valid till: {promoCode.expiryDate}</p>
                                            }
                                            <p className={styles.tealText} style={{ marginLeft: "auto", marginRight: 10, fontSize: 10, fontWeight: "500", cursor: "pointer" }} onClick={() => window.open("/voucher-terms", "_blank")}>T&C</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {/* Promo Codes */}
                    {
                        promoCodes.length > 0 && promoCodes.map((promoCode, index) => {
                            const expiryTime = promoCode.expiryDate === undefined ? undefined : new Date(new Date(new Date(new Date(promoCode.expiryDate).setHours(23)).setMinutes(59)).setSeconds(59));

                            return (
                                <div className={styles.voucherRow} style={{ opacity: (expiryTime <= new Date()) ? "50%" : "100%" }} key={`promoCode-${index}`}>
                                    <img className={styles.voucherImg} src={promoCode.discountAmount ? dollarVoucher : percentVoucher} alt="promo-code" />
                                    <div style={{ flex: 1, marginLeft: 15, textAlign: "left" }}>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            <p className={styles.blackText} style={{ fontSize: 14 }}>{promoCode.title ?? "Promo Code"}</p>
                                        </div>
                                        <div className={styles.codeContainer}>
                                            <p className={styles.tealText} style={{ fontSize: 15 }}>{promoCode.code}</p>
                                        </div>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            {
                                                promoCode.expiryDate &&
                                                <p className={styles.greyText} style={{ fontSize: 10 }}>Valid till: {promoCode.expiryDate}</p>
                                            }
                                            <p className={styles.tealText} style={{ marginLeft: "auto", marginRight: 10, fontSize: 10, fontWeight: "500", cursor: "pointer" }} onClick={() => window.open("/voucher-terms", "_blank")}>T&C</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={styles.overlay} onClick={() => dispatch(setShowVoucherModal(false))}></div>
        </>
    )
}