import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import { closeBookmarkAddressModal, handleGetBookmarkedAddresses } from './orderSlice';
import { Close } from "@material-ui/icons";
import styles from './Order.module.css';
import { createAddress } from '../address/addressAPI';

export function BookmarkAddress(props) {
  const { order } = props;
  const dispatch = useDispatch();

  const initialState = {
    form: {
      bookmarkName: "",
      jobType: "DROP_OFF",
      location: {
        ...order.dropOffLocation
      },
      recipientName: order.recipientName,
      recipientPhone: order.recipientPhone,
      recipientEmail: order.recipientEmail,
      deliveryNoteToDriver: order.deliveryNoteToDriver,
    },
    error: false,
    errorMessage: ""
  };
  
  const [bookmarkAddressState, _setBookmarkAddressState] = useStateWithCallbackLazy({
    ...initialState
  });
  const bookmarkAddressStateRef = useRef(bookmarkAddressState);

  const setBookmarkAddressState = data => {
    bookmarkAddressStateRef.current = data;
    _setBookmarkAddressState(data);
  };

  const onFormValueChange = (data) => {
    setBookmarkAddressState({
      ...bookmarkAddressState,
      form: {
        ...bookmarkAddressState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async () => {
    let error = false;

    if (bookmarkAddressState.form.bookmarkName === null || bookmarkAddressState.form.bookmarkName === "") {
      error = true;
      setBookmarkAddressState({...bookmarkAddressState, error: true, errorMessage: "Bookmark name is required"});
      return;
    }

    if (!error) {
      try {
        await createAddress(bookmarkAddressState.form);
        dispatch(handleGetBookmarkedAddresses());
        dispatch(closeBookmarkAddressModal());
      } catch (error) {
        setBookmarkAddressState({...bookmarkAddressState, error: true, errorMessage: error.message});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="bookmark-address-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeBookmarkAddressModal())}/>
          <h3 className={styles.subtitle}>Bookmark Dropoff Details</h3>
          <Fade in={bookmarkAddressState.error}>
            <p className={styles.errorMessage}>{bookmarkAddressState.errorMessage}</p>
          </Fade>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <p>Unit Number: <br/><b>{order.dropOffLocation?.unitNumber}</b></p>
              <p>Address: <br/><b>{order.dropOffLocation?.address}</b></p>
            </Grid>
            <Grid item xs={12}>
              <p>Bookmark Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bookmarkName"
                  name="bookmarkName"
                  value={bookmarkAddressState.form.bookmarkName}
                  onChange={e => onFormValueChange({name: "bookmarkName", value: e.target.value})}
                  placeholder="Bookmark Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Recipient Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientName"
                  name="recipientName"
                  value={bookmarkAddressState.form.recipientName}
                  onChange={e => onFormValueChange({name: "recipientName", value: e.target.value})}
                  placeholder="Recipient Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Recipient Phone</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientPhone"
                  name="recipientPhone"
                  value={bookmarkAddressState.form.recipientPhone}
                  onChange={e => onFormValueChange({name: "recipientPhone", value: e.target.value})}
                  placeholder="Recipient Phone"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Recipient Email</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientEmail"
                  name="recipientEmail"
                  value={bookmarkAddressState.form.recipientEmail}
                  onChange={e => onFormValueChange({name: "recipientEmail", value: e.target.value})}
                  type="email"
                  placeholder="Recipient Phone"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <p>Dropoff Note to driver/rider </p>
              <Paper className={styles.textInputContainer}>
                <textarea 
                  id="deliveryNoteToDriver" 
                  name="deliveryNoteToDriver" 
                  className={styles.textArea} 
                  value={bookmarkAddressState.form.deliveryNoteToDriver} 
                  onChange={e => onFormValueChange({name: "deliveryNoteToDriver", value: e.target.value})}
                  placeholder="Landmarks close to address, amount of goods to be collected or any important instructions">
                </textarea>
              </Paper>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Confirm</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
