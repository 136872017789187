import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import { getUserAddresses } from './addressAPI';

export const addressSlice = createSlice({
  name: 'address',
  initialState: {
    addresses: [],
    status: 'idle',
    createAddressModalOpen: false,
    editAddressModalOpen: false,
    deleteAddressModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openCreateAddressModal: (state) => {
      state.createAddressModalOpen = true;
    },
    closeCreateAddressModal: (state) => {
      state.createAddressModalOpen = false;
    },
    openEditAddressModal: (state) => {
      state.editAddressModalOpen = true;
    },
    closeEditAddressModal: (state) => {
      state.editAddressModalOpen = false;
    },
    openDeleteAddressModal: (state) => {
      state.deleteAddressModalOpen = true;
    },
    closeDeleteAddressModal: (state) => {
      state.deleteAddressModalOpen = false;
      state.selectedId = null;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getAddresses: (state, action) => {
      state.addresses = action.payload.map(o => o.pickUpDateTime ? {...o, pickUpDateTime: new Date(o.pickUpDateTime).toString()} : o);
      state.status = 'success'
    },
  },
});

export const { openCreateAddressModal, closeCreateAddressModal, openEditAddressModal, closeEditAddressModal, openDeleteAddressModal, closeDeleteAddressModal, setSelectedId, setPage, setRowsPerPage, setPagination, getAddresses } = addressSlice.actions;

export const handleGetAddresses = (selectedTab) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  let jobType = selectedTab === 0 ? "PICK_UP" : "DROP_OFF";
  try {
    const response = await getUserAddresses(localStorage.vendorId, jobType, state.address.page, state.address.rowsPerPage);

    const { content, ...pagination } = response.data;
    let addresses = content;

    dispatch(setPagination(pagination));
    dispatch(getAddresses(addresses));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const getPlaceLongName = (address_components, type) => {
  let component = address_components.find(c => c.types.includes(type));
  if (component) {
    return component.long_name
  }
  return null
}

export const selectState = state => state.address;

export default addressSlice.reducer;
