/*global google*/
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import { closeEditOrderModal, handleGetOrdersByOrderGroup, getPlaceLongName } from './orderSlice';
import { Close } from "@material-ui/icons";
import styles from './Order.module.css';
import DatePicker from "react-datepicker";
import { setMinutes, setHours } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { updateOrder } from './orderAPI';
import { dollarToCent, phoneUtil } from '../../app/utils';

export function EditOrder(props) {
  const { order } = props;
  const dispatch = useDispatch();
  const [orderState, _setOrderState] = useStateWithCallbackLazy({
    form: {
      ...order
    },
    error: false,
    init: false,
    isCodEnabled: order.codAmount ? true : false,
    isPlaceChanged: true,
    autocompleteInit: false,
    errorMessage: "",
    submitButtonDisable: false
  });

  const orderStateRef = useRef(orderState);

  const setOrderState = data => {
    orderStateRef.current = data;
    _setOrderState(data);
  };

  useEffect(() => {
    if (order && !orderState.init) {
      const {version, ...orderForm} = {...order};
      setOrderState({
        ...orderState,
        init: true,
        form: { 
          ...orderForm,
          deliverDateTime: new Date(order.deliverDateTime),
          codAmount: order.codAmount / 100
        }
      });
    }

    let dropOffAutocompleteInput = document.getElementById("dropOffAutocomplete");
    if (!orderState.autocompleteInit && dropOffAutocompleteInput !== null) {
      initializeGoogleAutocomplete("dropOffAutocomplete");
      setOrderState({
        ...orderState,
        autocompleteInit: true,
      });
    }
  });

  const onPlaceChanged = (autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === "dropOffAutocomplete") {
        _setOrderState({
          ...orderStateRef.current,
          isPlaceChanged: true,
          form: {
            ...orderStateRef.current.form,
            dropOffLocation: {
              ...orderStateRef.current.form.dropOffLocation,
              address: `${place.name}, ${place.formatted_address}`,
              coordinate: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              },
              street: getPlaceLongName(place.address_components, "street_address"),
              apartment: getPlaceLongName(place.address_components, "street_address"),
              state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
              postalCode: getPlaceLongName(place.address_components, "postal_code"),
              city: getPlaceLongName(place.address_components, "locality"),
              country: getPlaceLongName(place.address_components, "country")
            }
          }
        });
      }
    }
  }

  const initializeGoogleAutocomplete = (elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my", "sg"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function() {
      onPlaceChanged(autocomplete, elementId);
    });
  }

  const onValueChange = (data) => {
    setOrderState({
      ...orderState,
      [data.name]: data.value,
      form: {
        ...orderState.form
      }
    })
  }

  const onFormValueChange = (data) => {
    setOrderState({
      ...orderState,
      form: {
        ...orderState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async () => {
    let error = false;

    setOrderState({...orderState, submitButtonDisable: true});
    
    if (orderState.isPlaceChanged === false) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Please select address from the drop down"});
      return;
    }

    if (new Date(orderState.form.deliverDateTime) - new Date() < 0) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Delivery date should not be in the past"});
      return;
    }

    if (new Date(orderState.form.deliverDateTime) - new Date(props.orderGroup.pickUpDateTime) < 0) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Delivery date should not earlier than pickup date"});
      return;
    }

    for (var key in orderState.form) {
      if (key !== "recipientEmail" && key !== "ordinal" && key !== "product" && key !== "hadDelivered" && key !== "codAmount" && key !== "assigned" && key !== "receiptNumber" && key !== "deliveryRange" && key !== "doorToDoor" && key !== "noteToDriver" && key !== "doorToDoorFee") {
        if (orderState.form[key] === null || orderState.form[key] === "") {
          error = true;
          setOrderState({...orderState, error: true, errorMessage: "All fields are required"});
          return;
        }
      }
    }

    if (orderState.isCodEnabled && (orderState.form.codAmount === null || isNaN(orderState.form.codAmount) || orderState.form.codAmount <= 0)) {
      setOrderState({...orderState, error: true, errorMessage: "COD Amount is required"});
      return;
    }

    if (orderState.form.dropOffLocation.country === null || orderState.form.dropOffLocation.country === "") {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Please re-select the dropoff address from dropdown list"});
      return;
    }

    var formattedPhoneNumber = orderState.form.recipientPhone.replace(/\s/g, '').replace(/-/g, '')

    // make sure phone number start with + sign
    if (!formattedPhoneNumber.startsWith("+")) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Phone number must start with + sign and country code"});
      return;
    }

    // validate phone number using google lib
    try {
      let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
      if (!phoneUtil.isValidNumber(phoneNumberProto)) {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Invalid phone number"});
        return;
      }
    } catch (e) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Invalid phone number"});
      return;
    }

    if (!error) {
      try {
        // Convert Datetime to UTC
        let orderPayload = {
          ...orderState.form,
          recipientPhone: formattedPhoneNumber,
          deliverDateTime: orderState.form.deliverDateTime.toISOString(),
          codAmount: orderState.isCodEnabled ? Number(dollarToCent(orderState.form.codAmount)) : ""
        }
        await updateOrder(props.orderGroup.id, orderPayload, order.id); 
        dispatch(closeEditOrderModal());
        dispatch(handleGetOrdersByOrderGroup(props.orderGroup.id));
        setOrderState({...orderState, submitButtonDisable: false});
      } catch (error) {
        setOrderState({...orderState, error: true, errorMessage: error.message, submitButtonDisable: false});
      }
    } else {
      setOrderState({...orderState, submitButtonDisable: false});
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const isPickupDate = (pickupDate, deliveryDate) => {
    return deliveryDate.getDate() === pickupDate.getDate() && deliveryDate.getMonth() === pickupDate.getMonth() && deliveryDate.getFullYear() === pickupDate.getFullYear()
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditOrderModal())}/>
          <h3 className={styles.subtitle}>What do you want us to deliver? <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={orderState.error}>
            <p className={styles.errorMessage}>{orderState.errorMessage}</p>
          </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p>Recipient</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientName"
                  name="recipientName"
                  value={orderState.form.recipientName}
                  onChange={e => onFormValueChange({name: "recipientName", value: e.target.value})}
                  placeholder="Recipient Name"
                  className={styles.textInput}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientPhone"
                  name="recipientPhone"
                  type="tel"
                  value={orderState.form.recipientPhone}
                  onChange={e => onFormValueChange({name: "recipientPhone", value: e.target.value})}
                  placeholder="Phone Number"
                  className={styles.textInput}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientEmail"
                  name="recipientEmail"
                  type="email"
                  value={orderState.form.recipientEmail}
                  onChange={e => onFormValueChange({name: "recipientEmail", value: e.target.value})}
                  placeholder="Recipient Email (optional)"
                  className={styles.textInput}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <DatePicker
                  selected={orderState.form.deliverDateTime}
                  onChange={date => {
                    if (date.getHours() === 0) {
                      date.setHours(9);
                    }
                    onFormValueChange({name: "deliverDateTime", value: date})
                  }}
                  showTimeSelect
                  dateFormat="Pp"
                  minDate={(new Date()).getTime() > (new Date(props.orderGroup.pickUpDateTime)).getTime() ? new Date() : new Date(props.orderGroup.pickUpDateTime)}
                  minTime={
                    orderState.form.deliverDateTime === "" ||  isPickupDate(new Date(props.orderGroup.pickUpDateTime), new Date(orderState.form.deliverDateTime)) 
                    ? setHours(setMinutes(new Date(), new Date(props.orderGroup.pickUpDateTime).getMinutes()), new Date(props.orderGroup.pickUpDateTime).getHours())
                    : setHours(setMinutes(new Date(), 0), 6)
                  }
                  maxTime={setHours(setMinutes(new Date(), 0), 23)}
                  filterTime={filterPassedTime}
                  timeIntervals={15}
                  className={styles.textInput}
                  placeholderText={"Delivery Date Time"}
                />
              </Paper>
              <p>Product (Optional)</p>
              <Paper className={styles.textInputContainer}>
                <textarea
                  id="product"
                  name="product"
                  value={orderState.form.product}
                  onChange={e => onFormValueChange({name: "product", value: e.target.value})}
                  placeholder="Product"
                  className={styles.textInput}
                />
              </Paper>
              <p>Receipt Number (Optional)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="receiptNumber"
                  name="receiptNumber"
                  value={orderState.form.receiptNumber}
                  onChange={e => onFormValueChange({name: "receiptNumber", value: e.target.value})}
                  placeholder="Receipt Number"
                  className={styles.textInput}
                />
              </Paper>
              <p>Drop-off address</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="unitNumber"
                  name="unitNumber"
                  value={orderState.form.dropOffLocation.unitNumber}
                  onChange={e => setOrderState({
                    ...orderState,
                    form: {
                      ...orderState.form,
                      dropOffLocation: {
                        ...orderState.form.dropOffLocation,
                        unitNumber: e.target.value
                      }
                    }
                  })}
                  placeholder="Unit Number"
                  className={styles.textInput}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <input
                  id="dropOffAutocomplete"
                  name="dropOffAddress"
                  value={orderState.form.dropOffLocation.address}
                  onChange={e => setOrderState({
                    ...orderState,
                    isPlaceChanged: false,
                    form: {
                      ...orderState.form,
                      dropOffLocation: {
                        ...orderState.form.dropOffLocation,
                        address: e.target.value
                      }
                    }
                  })}
                  placeholder="Delivery Address"
                  className={styles.textInput}
                />
              </Paper>
              <p>Dropoff Note to driver/rider </p>
              <textarea 
                id="noteToDriver" 
                name="noteToDriver" 
                className={styles.textArea} 
                value={orderState.form.noteToDriver} 
                onChange={e => onFormValueChange({name: "noteToDriver", value: e.target.value})}
                placeholder="Landmarks close to address, amount of goods to be collected or any important instructions">
              </textarea>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={orderState.isCodEnabled} onChange={e => onValueChange({name: "isCodEnabled", value: e.target.checked })} name="isCodEnabled" color="primary" />}
                  label="Cash on Delivery"
                />
              </FormGroup>
              {
                orderState.isCodEnabled &&
                <Paper className={styles.textInputContainer}>
                  <input
                    id="codAmount"
                    name="codAmount"
                    type="number"
                    value={orderState.form.codAmount}
                    onChange={e => onFormValueChange({name: "codAmount", value: e.target.value})}
                    placeholder="Cash on Delivery Amount (RM)"
                    className={styles.textInput}
                  />
                </Paper>
              }
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={orderState.form.doorToDoor} onChange={e => onFormValueChange({name: "doorToDoor", value: e.target.checked })} name="doorToDoor" color="primary" />}
                  label="Door To Door Service"
                />
                <div style={{position: "relative", width: "300px", display: "table"}}>
                  <span style={{fontSize: "12px", display: "table-cell", verticalAlign: "middle"}}>*Extra charges will add into delivery fee</span>
                </div>
              </FormGroup>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton} disabled={orderState.submitButtonDisable}>Submit</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
