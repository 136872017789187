import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import ReactPixel from 'react-facebook-pixel';
import { handleLogin } from '../login/loginSlice';
import { requestOtp, signUp } from "./signupAPI";
import { FACEBOOK_PIXEL_ID, phoneUtil } from '../../app/utils';

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    form: {
      userName: "",
      password: "",
      user: {
        firstName: "",
        lastName: "",
        company: "",
        contactNumber: "",
        product: "FOOD_AND_BEVERAGE",
      }
    },
    showVerification: false,
    submitting: false,
    confirmPassword: "",
    otpRequestSuccess: false,
    otpVerification: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    onValueChange: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    onFormValueChange: (state, action) => {
      state.form[action.payload.name] = action.payload.value;
    },
    onFormUserValueChange: (state, action) => {
      state.form.user[action.payload.name] = action.payload.value;
    },
    validateForm: (state) => {
      for (var key in state.form) {
        if (state.form[key] === null || state.form[key] === "") {
          state.error = true;
          state.errorMessage = "All fields are required.";
          state.submitting = false;
          return;
        }
      }

      for (var key in state.form.user) {
        if (state.form.user[key] === null || state.form.user[key] === "") {
          state.error = true;
          state.errorMessage = "All fields are required.";
          state.submitting = false;
          return;
        }
      }

      var formattedPhoneNumber = "+" + state.form.user.contactNumber.replace(/\s/g, '').replace(/-/g, '').replace('+', '');
      state.form.user.contactNumber = formattedPhoneNumber;

      // make sure phone number start with + sign
      if (!formattedPhoneNumber.startsWith("+")) {
        state.error = true;
        state.errorMessage = "Contact number must start with the + sign followed by the country code (eg. +60).";
        state.submitting = false;
        return;
      }

      // validate phone number using google lib
      try {
        let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
        if (!phoneUtil.isValidNumber(phoneNumberProto)) {
          state.error = true;
          state.errorMessage = "Invalid phone number.";
          state.submitting = false;
          return;
        }
      } catch (e) {
        state.error = true;
        state.errorMessage = "Invalid phone number.";
        state.submitting = false;
        return;
      }

      if (state.form.password !== state.confirmPassword) {
        state.error = true;
        state.errorMessage = "Passwords do not match.";
        state.submitting = false;
        return;
      }

      state.error = false;
      state.errorMessage = "";
      state.submitting = true;
    },
    setShowVerification: (state, action) => {
      state.showVerification = action.payload;
      if (action.payload) {
        state.submitting = false;
      }
    },
    setOtpRequestSuccess: (state, action) => {
      state.otpRequestSuccess = action.payload;
    },
    setOtpVerification: (state, action) => {
      state.otpVerification = action.payload;
    },
    showError: (state, action) => {
      state.error = true;
      state.errorMessage = action.payload;
      state.submitting = false;
    },
  },
});

export const { onValueChange, onFormValueChange, onFormUserValueChange, validateForm, setShowVerification, setOtpRequestSuccess, setOtpVerification, showError } = signupSlice.actions;

export const handleRequestOtp = () => async (dispatch, getState) => {
  dispatch(setOtpRequestSuccess(false));
  dispatch(validateForm());
  const state = getState();

  if (!state.signup.error) {
    dispatch(setShowVerification(true));

    try {
      let payload = {
        userName: state.signup.form.userName,
        contactNumber: state.signup.form.user.contactNumber,
        otpType: "REGISTER",
        platform: "DELIVERIT"
      }
      const otpResp = await requestOtp(payload);
      if (otpResp.status === 204) {
        dispatch(setOtpRequestSuccess(true));
      }
    } catch (error) {
      dispatch(showError(error.response?.data?.message || error.message));
    }
  }
}

export const handleSignUp = (history, otp, handleResetOtp) => async (dispatch, getState) => {
  dispatch(setOtpVerification(true));
  dispatch(showLoading());
  const state = getState();

  try {
    let payload = {
      ...state.signup.form,
      otpCode: otp,
      user: state.signup.form.user
    }

    await signUp(payload);

    let loginRequest = {
      username: state.signup.form.userName,
      password: state.signup.form.password
    };

    ReactPixel.init(FACEBOOK_PIXEL_ID, {}, { debug: false, autoConfig: false });
    ReactPixel.track('CompleteRegistration', {
      content_name: "email",
      value: loginRequest.username
    });

    await dispatch(handleLogin(loginRequest));
    history.push(`/`);
  } catch (error) {
    dispatch(showError(error.response?.data?.message || error.message));
  } finally {
    dispatch(setOtpVerification(false));
    handleResetOtp();
    dispatch(hideLoading());
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.signup.value)`
export const selectState = state => state.signup;

export default signupSlice.reducer;
