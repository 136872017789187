import React, { useEffect, useRef, useState } from 'react';
import styles from './Dashboard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getUserWallet } from '../wallet/walletAPI';
import { getPromoCodes, getUserVouchers } from '../voucher/voucherAPI';
import { createOrderGroup, getUserOrderGroupsBetweenDates } from '../place_order/orderGroupAPI';
import { createOrder } from '../place_order/orderAPI';
import { dashboardState, getRecentOrders, handleGetOrderPerformance, setShowTopUpModal, setShowVoucherModal } from './dashboardSlice';
import { Topbar } from '../../components/topbar/Topbar';
import { VoucherModal } from './VoucherModal';
import { TopUpModal } from '../place_order/TopUpModal';
import { LoadingOverlay } from '../place_order/common';

import scheduledIcon from "../../images/dashboard/scheduled-icon.png";
import inProgressIcon from "../../images/dashboard/in-progress-icon.png";
import completedIcon from "../../images/dashboard/completed-icon.png";
import deliveritLogo from "../../images/dashboard/deliverit-logo.png";
import chevronRightIcon from "../../images/dashboard/chevron-right-icon.png";
import pickUpPin from "../../images/dashboard/pick-up-pin.png";
import dropOffPin from "../../images/dashboard/drop-off-pin.png";

export const Dashboard = () => {
    const detailsState = useSelector(dashboardState);
    const dispatch = useDispatch();

    const [walletBalance, setWalletBalance] = useState(0);
    const [scheduledOrders, setScheduledOrders] = useState([]);
    const [inProgressOrders, setInProgressOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [allCodes, setAllCodes] = useState([]);
    const [disableReorder, setDisableReorder] = useState(false);
    const [loading, setLoading] = useState(false);

    const dateRangeRef = useRef();
    const [dateRange, setDateRange] = useState([new Date(Moment().subtract(30, 'd')), new Date()]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        getWalletBalance();
    }, [detailsState.showTopUpModal]);

    useEffect(() => {
        getAllDetails();
    }, []);

    const getWalletBalance = async () => {
        // get wallet balance
        if (localStorage.user) {
            let user = JSON.parse(localStorage.user);
            const walletResp = await getUserWallet(user.id);
            setWalletBalance(walletResp.data.balanceAmount);
        }
    }

    const getAllDetails = async () => {
        // get wallet balance
        getWalletBalance();

        // get all order groups within date range
        getOrderGroupsBetweenDate(startDate, endDate);

        // get performance for summary graph
        dispatch(handleGetOrderPerformance(localStorage.getItem('orderPerformanceType'), startDate, endDate));

        // get recent orders
        dispatch(getRecentOrders());

        // get vouchers
        const userUnappliedVouchersResponse = await getUserVouchers();
        const vouchers = userUnappliedVouchersResponse.data;

        // get promo codes
        const promoCodeResp = await getPromoCodes();
        const claimedPromoCode = vouchers.map(v => v.promoCode.code);
        const filteredContent = promoCodeResp.data.content.filter(p => !claimedPromoCode.includes(p.code));
        const promoCodes = filteredContent;

        // sort vouchers and promo codes in descending order based on discount amount
        const voucherCodes = vouchers.map(voucher => voucher.promoCode);
        const codes = voucherCodes.concat(promoCodes).sort((a, b) => b.discountAmount - a.discountAmount);
        setAllCodes(codes);

        setLoading(false);
    }

    const getOrderGroupsBetweenDate = async (startDate, endDate) => {
        const start = Moment(startDate).format('YYYY-MM-DD');
        const end = Moment(endDate).format('YYYY-MM-DD');
        let params = `vendorId=${localStorage.vendorId}&startDate=${start}&endDate=${end}`;

        const orderGroupResp = await getUserOrderGroupsBetweenDates(params);
        setScheduledOrders(orderGroupResp.data.filter(order => order.status === "CONFIRMED" || order.status === "ASSIGNED"));
        setInProgressOrders(orderGroupResp.data.filter(order => order.status === "IN_TRANSIT"));
        setCompletedOrders(orderGroupResp.data.filter(order => order.status === "SUCCESS" || order.status === "PARTIAL_SUCCESS"));
        setAllOrders(orderGroupResp.data.filter(order => order.status !== "OPEN"));
    }

    const onDateRangeChange = (dates) => {
        setDateRange(dates);

        if (dates[0] != null && dates[1] != null) {
            getOrderGroupsBetweenDate(dates[0], dates[1]);
            dispatch(handleGetOrderPerformance(localStorage.getItem('orderPerformanceType'), dates[0], dates[1]));
        }
    };

    const generateNewDate = (date) => {
        const now = new Date();
        const currentDate = now.getDate();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
        const oldDate = new Date(date);

        var newDate = new Date(oldDate.setDate(currentDate));
        if (newDate - now < 0) {
            newDate = new Date(newDate.setDate(currentDate + 1));
        }
        if (newDate.getFullYear() - currentYear < 0) {
            newDate = new Date(newDate.setFullYear(currentYear));
        }
        if (newDate.getMonth() - currentMonth < 0) {
            newDate = new Date(newDate.setMonth(currentMonth));
        }

        return newDate.toISOString();
    }

    const handleReorder = async (orderGroup) => {
        if (!disableReorder) {
            setDisableReorder(true);

            const orderGroupPayload = {
                deliveryMethod: orderGroup.deliveryMethod,
                pickUpDateTime: generateNewDate(orderGroup.pickUpDateTime),
                pickUpPersonName: orderGroup.pickUpPersonName,
                pickUpContactNumber: orderGroup.pickUpContactNumber,
                pickUpLocation: orderGroup.pickUpLocation,
                halal: orderGroup.halal,
                noteToDriver: orderGroup.noteToDriver
            }

            const orderGroupResp = await createOrderGroup(orderGroupPayload);
            const orderGroupId = orderGroupResp.data.id;

            for (let i = 0; i < orderGroup.orders.length; i++) {
                const order = orderGroup.orders[i];
                const orderPayload = {
                    ordinal: order.ordinal,
                    deliverDateTime: generateNewDate(order.deliverDateTime),
                    recipientName: order.recipientName,
                    recipientPhone: order.recipientPhone,
                    dropOffLocation: order.dropOffLocation,
                    doorToDoor: order.doorToDoor,
                    codAmount: order.codAmount ? order.codAmount : ""
                }

                await createOrder(orderGroupId, orderPayload);
            }

            setDisableReorder(false);
            window.open(`/main-order/${orderGroupId}`);
        }
    }

    const getBackgroundColor = (status) => {
        switch (status) {
            case "CONFIRMED":
                return "#e6f4ff";
            case "ASSIGNED":
                return "#e9e6ff";
            case "IN_TRANSIT":
                return "#ffdbf1";
            case "SUCCESS":
                return "#cbffd0";
            case "PARTIAL_SUCCESS":
                return "#cbffd0";
            case "CANCELLED":
                return "#fcf5e9";
            case "FAILED":
                return "#fef5f6";
        }
    }

    const getFontColor = (status) => {
        switch (status) {
            case "CONFIRMED":
                return "#4b82db";
            case "ASSIGNED":
                return "#5848ba";
            case "IN_TRANSIT":
                return "#e644a5";
            case "SUCCESS":
                return "#1e9984";
            case "PARTIAL_SUCCESS":
                return "#1e9984";
            case "CANCELLED":
                return "#ff9436";
            case "FAILED":
                return "#f96379";
        }
    }

    const getStatusString = (status) => {
        switch (status) {
            case "CONFIRMED":
                return "Confirmed";
            case "ASSIGNED":
                return "Assigned";
            case "IN_TRANSIT":
                return "In Transit";
            case "SUCCESS":
                return "Completed";
            case "PARTIAL_SUCCESS":
                return "Completed";
            case "CANCELLED":
                return "Cancelled";
            case "FAILED":
                return "Failed";
        }
    }

    const graphData = {
        labels: detailsState.orderPerformance.map(x => x.orderDate),
        datasets: [
            {
                label: 'Completed',
                data: detailsState.orderPerformance.map(x => x.successOrders),
                backgroundColor: "#3ac873",
                barThickness: 8,
                borderRadius: 3
            },
            {
                label: 'Failed',
                data: detailsState.orderPerformance.map(x => x.failOrders),
                backgroundColor: "#ee6d6d",
                barThickness: 8,
                borderRadius: 3
            }
        ],
    }

    const graphOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: "rectRounded",
                    font: {
                        size: 10
                    }
                }
            },
            title: {
                display: true,
                color: "black",
                font: {
                    size: 14
                },
                text: 'Summary',
                position: "top",
                align: "start"
            }
        }
    }

    return (
        <>
            {loading && <LoadingOverlay />}
            {detailsState.showVoucherModal && <VoucherModal />}
            {detailsState.showTopUpModal && <TopUpModal closeModal={() => dispatch(setShowTopUpModal(false))} />}

            <Topbar page="Dashboard" />

            <div className={styles.mainContainer}>
                <div className={styles.filterRow}>
                    <div className={styles.datepickerContainer}>
                        <DatePicker
                            className={styles.datepicker}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onDateRangeChange}
                            selectsRange={true}
                            ref={dateRangeRef}
                            dateFormat="d MMM yyyy"
                        />
                        <i class="fas fa-caret-down" style={{ position: "absolute", marginTop: 5, marginLeft: 180, color: "#606060", fontSize: 15, cursor: "pointer" }} onClick={() => dateRangeRef.current.setOpen(true)} />
                    </div>
                    <i class="fas fa-refresh" style={{ color: "#606060", marginTop: 5, marginLeft: 20, fontSize: 15, cursor: "pointer" }} onClick={() => { setLoading(true); getAllDetails(); }} />
                </div>

                <div className={styles.hContainer}>
                    <div className={styles.leftContainer}>
                        {/* Order Summary Cards */}
                        <div className={styles.row} style={{ flexWrap: "wrap" }}>
                            <div className={styles.orderSummaryCard} style={{ background: "linear-gradient(to right, #ffb92d, #f57e5a)" }}>
                                <div style={{ marginTop: "auto" }}>
                                    <img className={styles.orderSummaryIcon} src={scheduledIcon} />
                                </div>
                                <div style={{ marginLeft: "auto", textAlign: "right" }}>
                                    <p className={styles.largeWhiteText}>{scheduledOrders.length}</p>
                                    <p className={styles.smallWhiteText}>Scheduled</p>
                                </div>
                            </div>
                            <div className={styles.orderSummaryCard} style={{ background: "linear-gradient(to right, #eb4988, #cf2dae)" }}>
                                <div style={{ marginTop: "auto" }}>
                                    <img className={styles.orderSummaryIcon} src={inProgressIcon} />
                                </div>
                                <div style={{ marginLeft: "auto", textAlign: "right" }}>
                                    <p className={styles.largeWhiteText}>{inProgressOrders.length}</p>
                                    <p className={styles.smallWhiteText}>In Progress</p>
                                </div>
                            </div>
                            <div className={styles.orderSummaryCard} style={{ background: "linear-gradient(to right, #46c5f1, #6592da)" }}>
                                <div style={{ marginTop: "auto" }}>
                                    <img className={styles.orderSummaryIcon} src={completedIcon} />
                                </div>
                                <div style={{ marginLeft: "auto", textAlign: "right" }}>
                                    <p className={styles.largeWhiteText}>{completedOrders.length}</p>
                                    <p className={styles.smallWhiteText}>Completed</p>
                                </div>
                            </div>
                        </div>

                        {/* Order List Table */}
                        <div className={styles.orderListTable}>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead className={styles.tableHeader}>
                                        <TableRow>
                                            <TableCell align="center" className={styles.tableCellNarrow}>{" "}</TableCell>
                                            <TableCell align="left" className={styles.tableCellTitle}>Pick-up Person</TableCell>
                                            {
                                                window.innerWidth > 550 &&
                                                <TableCell align="left" className={styles.tableCellTitle}>Pick-up Time</TableCell>
                                            }
                                            <TableCell align="left" className={styles.tableCellTitle}>Pick-up Status</TableCell>
                                            <TableCell align="left" className={styles.tableCellTitle}>Drop-off Recipient</TableCell>
                                            {
                                                window.innerWidth > 550 &&
                                                <TableCell align="left" className={styles.tableCellTitle}>Delivery Time</TableCell>
                                            }
                                            <TableCell align="left" className={styles.tableCellTitle}>Drop-off Status</TableCell>
                                            <TableCell align="center" className={styles.tableCellNarrow} style={{ minWidth: 15, maxWidth: 15 }}>{" "}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            allOrders.map((orderGroup, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell align="center" className={styles.tableCellNarrow}>{index + 1}</TableCell>
                                                        <TableCell align="left" className={styles.tableCellContent}>{orderGroup.pickUpPersonName}</TableCell>
                                                        {
                                                            window.innerWidth > 550 &&
                                                            <TableCell align="left" className={styles.tableCellContent}>{Moment(orderGroup.pickUpDateTime).format("LT")}</TableCell>
                                                        }
                                                        <TableCell align="left" className={styles.tableCellContent}>
                                                            <div className={styles.statusContainer} style={{ backgroundColor: getBackgroundColor(orderGroup.status), color: getFontColor(orderGroup.status) }}>
                                                                {getStatusString(orderGroup.status)}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left" className={styles.tableCellContent}>{orderGroup.orders[0].recipientName}</TableCell>
                                                        {
                                                            window.innerWidth > 550 &&
                                                            <TableCell align="left" className={styles.tableCellContent}>{Moment(orderGroup.orders[0].deliverDateTime).format("LT")}</TableCell>
                                                        }
                                                        <TableCell align="left" className={styles.tableCellContent}>
                                                            <div className={styles.statusContainer} style={{ backgroundColor: getBackgroundColor(orderGroup.orders[0].status), color: getFontColor(orderGroup.orders[0].status) }}>
                                                                {getStatusString(orderGroup.orders[0].status)}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left" className={styles.tableCellNarrow} style={{ minWidth: 15, maxWidth: 15, cursor: "pointer" }} onClick={() => window.open(`/app/order-history/${orderGroup.id}`)}>
                                                            <img className={styles.icon} src={chevronRightIcon} />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                    <div className={styles.rightContainer}>
                        <div className={styles.row} style={{ flexWrap: "wrap" }}>
                            {/* Voucher Card */}
                            <div className={styles.miscCard} style={{ background: "linear-gradient(to right, #5848ba, #875ec0)" }}>
                                <div style={{ textAlign: "left" }}>
                                    <p className={styles.smallWhiteText}>Exclusive for your next order</p>
                                    <p className={styles.largeWhiteText}>{allCodes.length > 0 ? allCodes[0].code : "-"}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "auto" }}>
                                    <div className={styles.miscButton} onClick={() => dispatch(setShowVoucherModal(true))}>
                                        <p className={styles.largeWhiteText} style={{ fontSize: 13, fontWeight: 500 }}>View Vouchers</p>
                                    </div>
                                    <div className={styles.deliveritContainer}>
                                        <img className={styles.deliveritLogo} src={deliveritLogo} />
                                    </div>
                                </div>
                            </div>

                            {/* Wallet Card */}
                            <div className={styles.miscCard} style={{ background: "linear-gradient(to right, #227c97, #1e9299" }}>
                                <div style={{ textAlign: "left" }}>
                                    <p className={styles.smallWhiteText}>Wallet Balance</p>
                                    <p className={styles.largeWhiteText}>MYR {(walletBalance / 100).toFixed(2)}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "auto" }}>
                                    <div className={styles.miscButton} onClick={() => dispatch(setShowTopUpModal(true))}>
                                        <p className={styles.largeWhiteText} style={{ fontSize: 13, fontWeight: 500 }}>Top Up</p>
                                    </div>
                                    <div className={styles.deliveritContainer}>
                                        <img className={styles.deliveritLogo} src={deliveritLogo} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Summary Graph */}
                        <div className={styles.summaryGraph}>
                            <Bar options={graphOptions} data={graphData} />
                        </div>

                        {/* Recent Completed Orders */}
                        <div className={styles.recentOrders}>
                            <p className={styles.largeBlackText}>Recent Completed Orders</p>
                            {
                                detailsState.recentOrders.map((orderGroup, index) => {
                                    return (
                                        <>
                                            <div className={styles.locationRow}>
                                                <div>
                                                    <div style={{ display: "flex" }}>
                                                        <img className={styles.locationPinIcon} src={pickUpPin} alt="location-pin" />
                                                        <div>
                                                            <p className={styles.smallBlackText}>{orderGroup.pickUpLocation.address}</p>
                                                            <p className={styles.greyText}>{orderGroup.pickUpLocation.unitNumber && orderGroup.pickUpLocation.unitNumber + "\u00a0 • \u00a0"}{orderGroup.pickUpContactNumber}&nbsp; • &nbsp;{orderGroup.pickUpPersonName}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: 12 }}>
                                                        <img className={styles.locationPinIcon} src={dropOffPin} alt="location-pin" />
                                                        <div>
                                                            <p className={styles.smallBlackText}>{orderGroup.orders[0].dropOffLocation.address}</p>
                                                            <p className={styles.greyText}>{orderGroup.orders[0].dropOffLocation.unitNumber && orderGroup.orders[0].dropOffLocation.unitNumber + "\u00a0 • \u00a0"}{orderGroup.orders[0].recipientPhone}&nbsp; • &nbsp;{orderGroup.orders[0].recipientName}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.orderButton} onClick={() => handleReorder(orderGroup)}>
                                                    <p className={styles.smallWhiteText} style={{ fontSize: 11, fontWeight: "500" }}>Reorder</p>
                                                </div>
                                            </div>
                                            <div className={styles.horizontalDivider} />
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}