import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import ReactPixel from 'react-facebook-pixel';
import { getUserInfoFromJwt, stripePromise } from '../../app/utils';
import { getCreditCards, getSetupCardSession, getUserWallet, manageBilling } from '../wallet/walletAPI';
import { getUser } from './userAPI';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: localStorage.user ? JSON.parse(localStorage.user) : null,
    editUserModalOpen: false,
    changePasswordModalOpen: false,
    status: 'idle',
  },
  reducers: {
    openEditUserModal: (state) => {
      state.editUserModalOpen = true;
    },
    closeEditUserModal: (state) => {
      state.editUserModalOpen = false;
    },
    openChangePasswordModal: (state) => {
      state.changePasswordModalOpen = true;
    },
    closeChangePasswordModal: (state) => {
      state.changePasswordModalOpen = false;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.status = 'success'
    },
    getCreditCard: (state, action) => {
      state.user = {
        ...state.user,
        creditCard: action.payload
      };
      state.status = 'success'
    },
  },
});

export const { openEditUserModal, closeEditUserModal, openChangePasswordModal, closeChangePasswordModal, setUser, getCreditCard } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectState = state => state.user;

export const handleGetCreditCards = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const userInfo = await getUserInfoFromJwt();
    const { data: wallet } = await getUserWallet(userInfo.user_id);
    const response = await getCreditCards(wallet.id);
    if (response.data.length > 0) {
      dispatch(getCreditCard(response.data.find(c => c.selected === true)));
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleSetupCard = () => async (dispatch) => {
  dispatch(showLoading());

  try {
    const userInfo = await getUserInfoFromJwt();
    const { data: wallet } = await getUserWallet(userInfo.user_id);
    const response = await getSetupCardSession(
      wallet.id,
      {
        redirectSuccess: window.location.href,
        redirectCancel: window.location.href
      }
    );

    let authedUser = JSON.parse(localStorage.user);
    ReactPixel.track('AddPaymentInfo', {
      content_category: 'Vendor',
      content_ids: [userInfo.user_id],
      contents: [{"email": authedUser.email, "contactNumber": authedUser.contactNumber, "firstName": authedUser.firstName, "lastName": authedUser.lastName}]
    });
      
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: response.data.sessionId
    });
      
    if (error) {
      throw error;
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
}

export const handleManageBilling = () => async () => {
  const userInfo = await getUserInfoFromJwt();
  const { data: wallet } = await getUserWallet(userInfo.user_id);
  const response = await manageBilling(wallet.id, {
    returnUrl: window.location.href
  });
  window.open(response.data.url, '_self');
}

export const handleGetUser = () => async (dispatch) => {
  try {
    const userInfo = await getUserInfoFromJwt();
    const response = await getUser(userInfo.user_id);
    dispatch(setUser({ ...response.data }));
    localStorage.setItem('user', JSON.stringify(response.data));
  } catch (error) {
    console.log(error.message);
  }
}

export default userSlice.reducer;
