import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import { updateUserProfile } from './vendorAPI';
import { closeEditUserModal, handleGetCreditCards, handleGetUser } from './userSlice';
import { Close } from "@material-ui/icons";
import styles from './User.module.css';
import "react-datepicker/dist/react-datepicker.css";

export function EditUser(props) {
  const { user } = props;
  const dispatch = useDispatch();
  const [userState, _setUserState] = useStateWithCallbackLazy({
    form: {
      ...user
    },
    error: false,
    init: false,
    errorMessage: ""
  });

  const userStateRef = useRef(userState);

  const setUserState = data => {
    userStateRef.current = data;
    _setUserState(data);
  };

  useEffect(() => {
    if (user && !userState.init) {
      const {version, role, ...userForm} = {...user};
      setUserState({
        ...userState,
        init: true,
        form: { 
          ...userForm
        }
      });
    }
  });

  const onFormValueChange = (data) => {
    setUserState({
      ...userState,
      form: {
        ...userState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async (props) => {
    let error = false;
    
    for (var key in userState.form) {
      if (userState.form[key] === null || userState.form[key] === "") {
        error = true;
        setUserState({...userState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

    let userPayload = {
      ...userState.form
    }
    
    // Remove credit card attribute from payload
    delete userPayload.creditCard;

    if (!error) {
      try {
        const res = await updateUserProfile(userPayload, user.id);
        if(res.error) throw res.error;
        dispatch(closeEditUserModal());
        dispatch(handleGetUser());
        dispatch(handleGetCreditCards());
      } catch (err) {
        setUserState({...userState, error: true, errorMessage: err.message});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditUserModal())}/>
          <h3 className={styles.subtitle}>Update Profile <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={userState.error}>
            <p className={styles.errorMessage}>{userState.errorMessage}</p>
          </Fade>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>Email</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="userName"
                  name="userName"
                  value={user.userName}
                  disabled={true}
                  placeholder="Email"
                  type="email"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>First Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="firstName"
                  name="firstName"
                  value={userState.form.firstName}
                  onChange={e => onFormValueChange({name: "firstName", value: e.target.value})}
                  placeholder="First Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>Last Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="lastName"
                  name="lastName"
                  value={userState.form.lastName}
                  onChange={e => onFormValueChange({name: "lastName", value: e.target.value})}
                  placeholder="Last Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>Contact Number</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="contactNumber"
                  name="contactNumber"
                  value={userState.form.contactNumber}
                  onChange={e => onFormValueChange({name: "contactNumber", value: e.target.value})}
                  placeholder="Contact Number"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>Company</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="company"
                  name="company"
                  value={userState.form.company}
                  onChange={e => onFormValueChange({name: "company", value: e.target.value})}
                  placeholder="Company"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>Product</p>
              <Paper className={styles.textInputContainer}>
                <select name="product" id="product" className={styles.selectInput} onChange={e => onFormValueChange({name: "product", value: e.target.value})}>
                  <option value="FOOD_AND_BEVERAGE">Food & Beverage</option>
                  <option value="CAFES_OR_DESSERTS">Cafes / Desserts</option>
                  <option value="HEALTH_CARE">Health Care</option>
                  <option value="PARCELS">Parcels</option>
                  <option value="OTHER">Others</option>
                </select>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit(props)} className={styles.gradientButton}>Update</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
