import React, { useState } from 'react';
import styles from './OrderList.module.css';
import { useDispatch } from 'react-redux';

import { closeDeleteOrderGroupModal, handleGetOrderGroups, setSelectedIds } from '../order_group/orderGroupSlice';
import { cancelOrderGroup, deleteOrderGroup } from '../order_group/orderGroupAPI';
import { CircularProgress } from '@material-ui/core';

export const RemoveOrderModal = (props) => {
    const { orderGroups } = props;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const submit = async () => {
        setLoading(true);
        try {
            await Promise.all(
                await orderGroups.map(async orderGroup => {
                    if (orderGroup.status === "OPEN") {
                        await deleteOrderGroup(orderGroup.id);
                    } else if (orderGroup.status === "CONFIRMED") {
                        await cancelOrderGroup(orderGroup.id);
                    } else {
                        alert(`Sorry order group ${orderGroup.id} cannot be canceled`);
                    }
                })
            );
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
            dispatch(closeDeleteOrderGroupModal());
            dispatch(setSelectedIds([]));
            dispatch(handleGetOrderGroups(props.orderGroupType));
        }
    }

    return (
        <>
            <div className={styles.removeOrderModal}>
                <p className={styles.blackText} style={{ marginTop: 30, marginBottom: 35 }}>Are you sure you want to {orderGroups[0].status === "OPEN" ? "delete" : "cancel"} selected order {orderGroups.length === 1 ? `group` : `groups`}?</p>

                <div className={styles.buttonsContainer} style={{ marginLeft: 0 }}>
                    <div className={styles.whiteButton} style={{ width: 100, paddingBlock: 12 }} onClick={() => dispatch(closeDeleteOrderGroupModal(false))}>
                        <p className={styles.tealText}>Cancel</p>
                    </div>
                    {
                        !loading
                            ? <div className={styles.modalTealButton} style={{ background: "linear-gradient(to right, #45b0b6, #227c97", width: 100, paddingBlock: 12 }} onClick={() => submit()}>
                                <p className={styles.whiteText}>Confirm</p>
                            </div>
                            : <div className={styles.modalTealButton} style={{ backgroundColor: "#bfbfbf", width: 100, paddingBlock: 12, borderColor: "#bfbfbf" }}>
                                <p className={styles.whiteText}>Confirm</p>
                            </div>
                    }
                </div>

                {
                    loading &&
                    <div style={{ marginTop: -80 }}>
                        <CircularProgress size={20} />
                    </div>
                }
            </div>
            <div className={styles.overlay} onClick={() => dispatch(closeDeleteOrderGroupModal(false))}></div>
        </>
    )
}