import axiosApiInstance from "../../app/axios";
import { API_BASE_URL, getJwtToken } from "../../app/utils";

export const createVendor = async (data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/vendors`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const updateVendor = async (data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/deliverit/v1/vendors/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getVendorByUsername = async (userName) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/vendors?userName=${userName}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const updateUserProfile = async (data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/user-store/v1/users/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );