import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import LoadingBar from "react-redux-loading";
import { Helmet } from "react-helmet";
import { Layout } from './components/layout/Layout';
import { Landing } from './features/landing/Landing';
import { MainOrder } from './features/place_order/MainOrder';
import { LoginPage } from './features/login/LoginPage';
import { Signup } from './features/signup/Signup';
import { ForgotPassword } from './features/forgot_password/ForgotPassword';
import './App.css';

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest} 
      render={props => (
        localStorage.getItem('refresh_token') ? (React.createElement(component, props)) : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
      )}
    />
  );
};

const PublicRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest} 
      render={props => (
        localStorage.getItem('refresh_token') ? (<Redirect to={{pathname: '/'}} />) : (React.createElement(component, props))
      )}
    />
  );
};

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>DeliverIt - You want it, we DeliverIt</title>
        <meta name="description" content="You want it, we DeliverIt!" />
        <meta name="keywords" content="deliverit malaysia,deliverit,deliver it malaysia,deliver it,deliveritmy,delivery solution malaysia,delivery near me" />
        <meta property="og:title" content="DeliverIt - You want it, we DeliverIt" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://parkitstatic.s3-ap-southeast-1.amazonaws.com/media/logo/parkit-logo-horizontal.png" />
        <meta property="og:url" content="https://www.deliverit.com" />
        <link rel="canonical" href="https://www.deliverit.com" />
      </Helmet>
      <Router>
        <Fragment>
          <LoadingBar style={{ backgroundColor: "#e86c6d", position: "fixed" }} />
          <Switch>
            <Route exact path="/app" render={() => <Redirect to="/app/home" />} />
            <Route exact path="/" component={Landing} />
            <Route exact path="/main-order" component={MainOrder} />
            <PublicRoute exact path="/login" component={LoginPage} />
            <PublicRoute exact path="/signup" component={Signup} />
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <PrivateRoute path="/" component={Layout} />
          </Switch>
        </Fragment>
      </Router>
    </div>
  );
}

export default App;

