/*global google*/

import { withGoogleMap, GoogleMap, DirectionsRenderer, Marker } from "react-google-maps";
import styles from './OrderDetails.module.css';
import mapStyles from '../order/mapStyle';

import redMapMarker from "../../images/place_order/red-map-marker.png";
import tealMapMarker from "../../images/place_order/teal-map-marker.png";

export const OrderDetailsMap = withGoogleMap(props => {
    return (
        <GoogleMap
            defaultZoom={14}
            options={{ gestureHandling: "greedy", styles: mapStyles }}
            defaultCenter={{ lat: 3.0640394, lng: 101.60916369999995 }}
        >
            {
                props.directions.length > 0 && props.directions.map((direction, directionIndex) => {
                    return (
                        <>
                            <DirectionsRenderer
                                directions={direction}
                                options={{
                                    polylineOptions: {
                                        strokeColor: "#227c97"
                                    },
                                    suppressMarkers: true
                                }}
                            />
                            {
                                direction.routes[0].legs.map((leg, legIndex) => {
                                    var index = legIndex;
                                    if (directionIndex !== 0) {
                                        for (let i = 1; i <= directionIndex; i++) {
                                            index += props.directions[i - 1].routes[0].legs.length
                                        }
                                    }

                                    return (
                                        <>
                                            {
                                                index === 0 &&
                                                <>
                                                    <Marker
                                                        key="start"
                                                        icon={{
                                                            url: redMapMarker,
                                                            scaledSize: new google.maps.Size(35, 50),
                                                        }}
                                                        position={{
                                                            lat: Number(leg.start_location.lat()),
                                                            lng: Number(leg.start_location.lng())
                                                        }}>
                                                    </Marker>
                                                </>
                                            }
                                            {
                                                index !== 0 &&
                                                <>
                                                    <Marker
                                                        key="end"
                                                        icon={{
                                                            url: tealMapMarker,
                                                            scaledSize: new google.maps.Size(32, 40),
                                                        }}
                                                        position={{
                                                            lat: Number(leg.start_location.lat()),
                                                            lng: Number(leg.start_location.lng())
                                                        }}
                                                        label={{
                                                            className: `${styles.mapMarkerOrdinal}`,
                                                            text: `${index}`,
                                                            color: "#227c97",
                                                            fontWeight: "500",
                                                        }}>

                                                    </Marker>
                                                </>
                                            }
                                            {
                                                legIndex === (direction.routes[0].legs.length - 1) &&
                                                <>
                                                    <Marker
                                                        key="end"
                                                        icon={{
                                                            url: tealMapMarker,
                                                            scaledSize: new google.maps.Size(32, 40),
                                                        }}
                                                        position={{
                                                            lat: Number(leg.end_location.lat()),
                                                            lng: Number(leg.end_location.lng())
                                                        }}
                                                        label={{
                                                            className: `${styles.mapMarkerOrdinal}`,
                                                            text: `${index + 1}`,
                                                            color: "#227c97",
                                                            fontWeight: "500",
                                                        }}>
                                                    </Marker>
                                                </>
                                            }
                                        </>
                                    )
                                })
                            }
                        </>
                    )
                })
            }
        </GoogleMap>
    );
});