import React from 'react';
import styles from '../login/Login.module.css';

import { Login } from './Login';

export const LoginModal = (props) => {
    return (
        <>
            <div className={styles.loginModal}>
                <Login useWindow={true} />
            </div>
            <div className={styles.overlay} onClick={() => props.onClose()}></div>
        </>
    );
}