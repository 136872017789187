import axiosApiInstance from "../../app/axios";
import { API_BASE_URL, getJwtToken } from "../../app/utils";

export const getOrdersByOrderGroup = async (id) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const createOrder = async (orderGroupId, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/order-groups/${orderGroupId}/orders`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const updateOrder = async (orderGroupId, data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/deliverit/v1/order-groups/${orderGroupId}/orders/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const deleteOrder = async (orderGroupId, id) =>
  axiosApiInstance.delete(
    `${API_BASE_URL}/deliverit/v1/order-groups/${orderGroupId}/orders/${id}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const confirmOrders = async (orderGroupId, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/order-groups/${orderGroupId}/confirm`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getDefaultDeliveryRateProfile = async () =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/delivery-rate-profiles?name=Default`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );