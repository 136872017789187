import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Hidden } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { onValueChange, handleLoginFormSubmit, selectState } from './loginSlice';
import styles from './Login.module.css';
import accountIcon from "../../images/icons/account-gradient.png";
import passwordIcon from "../../images/icons/lock-gradient.png";

export function Login(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <Hidden smDown>
        <div className={styles.leftContainer}></div>
      </Hidden>
      <form className={styles.rightContainer} style={{ paddingTop: state.error ? 35 : 90 }}>
        {
          state.error &&
          <Alert
            severity="error"
            style={{ marginInline: 5, marginTop: -5, marginBottom: 12, borderRadius: 10, textAlign: "left" }}
          >
            {state.errorMessage}
          </Alert>
        }

        <div className={styles.textInputContainer}>
          <div className={styles.textInputIconContainer}>
            <img alt='email' src={accountIcon} className={styles.textInputIcon} />
          </div>
          <input
            id="email"
            name="email"
            value={state.userName}
            onChange={e => dispatch(onValueChange({ name: "userName", value: e.target.value }))}
            placeholder="Email Address"
            type="email"
            className={styles.textInput}
          />
        </div>
        <div className={styles.textInputContainer}>
          <div className={styles.textInputIconContainer}>
            <img alt='password' src={passwordIcon} className={styles.textInputIcon} />
          </div>
          <input
            id="password"
            name="password"
            value={state.password}
            onChange={e => dispatch(onValueChange({ name: "password", value: e.target.value }))}
            placeholder="Password"
            type="password"
            className={styles.textInput}
          />
        </div>
        <p className={styles.forgotPassword}><Link to="/forgot-password" style={{ textDecoration: "none", color: "rgb(21, 31, 40, 0.5)" }}>Forgot password?</Link></p>

        <Button
          onClick={() => dispatch(handleLoginFormSubmit(history, props.useWindow))}
          className={styles.gradientButton}
        >
          Login
        </Button>
        <p style={{ fontSize: 12, fontWeight: "500" }}>Don't have an account? <Link to="/signup" style={{ color: "#387596" }}><b>Sign Up</b></Link></p>
      </form>
    </>
  );
}