import React from 'react';
import { Topbar } from '../../components/topbar/Topbar';
import styles from './StaticPage.module.css';

export function Terms() {
  return (
    <div className={styles.container}>
      <Topbar />

      <div style={{ marginTop: 70, paddingTop: 30, paddingInline: 40 }}>
        <div className={styles.titleBanner}>
          <h3 className={styles.title}>Terms & Condition</h3>
        </div>
        <ol>
          <li>
            <h3>Introduction</h3>
            <ol>
              <li>Please read these Terms &amp; Conditions carefully. By using the Service (as defined), you agree that you have read and understood the Terms &amp; Conditions. These terms &amp; conditions (as defined) constitute a legally binding agreement (&ldquo;Agreement&rdquo;) between you and DeliverIt (as defined). The Agreement applies to your use of the Service (as defined) provided by DeliverIt. If you do not agree to the Terms &amp; Conditions, please do not use or continue using the Service.<br /><br /></li>
              <li>DeliverIt may, at its sole discretion, amend the Terms &amp; Conditions in the Agreement at any time as deemed necessary. Such amendments shall be effective once they are posted on DeliverIt website, mobile website and Platform (as defined). It is your responsibility to review the Terms &amp; Conditions regularly. Your continued use of the Service after any such amendments, whether or not reviewed by you, shall constitute your agreement to be bound by such amendments.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Definitions</h3>
            In these Terms &amp; Conditions, the following words shall have the meanings ascribed below:<br /><br />
            <ol>
              <li>&ldquo;Service&rdquo; means the linking of Driver or Drivers through DeliverIt&rsquo;s website, mobile website and Platform (collectively known as &ldquo;Sites&rdquo;);<br /><br /></li>
              <li>&ldquo;Driver&rdquo; or &ldquo;Drivers&rdquo; means an independent third party driver or rider who have agreed to accept bookings from the Customer and provides transportation, logistical and/or delivery services through DeliverIt&rsquo;s Platform;<br /><br /></li>
              <li>&ldquo;Customer&rdquo; means any person who utilises the Service;<br /><br /></li>
              <li>&ldquo;Platform&rdquo; means the technological platform in whatsoever and howsoever form as DeliverIt may from time to time determine whereby the Customer can arrange and/or schedule the Service;<br /><br /></li>
              <li>&ldquo;DeliverIt&rdquo; means Parkit Solutions Sdn Bhd, a company incorporated in Malaysia with its registered office at B-06-02, Sunway Geo Avenue, Jalan Lagoon Selatan, Bandar Sunway, 47500 Subang Jaya, Selangor.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Licence Grant &amp; Restrictions</h3>
            <br />
            <ol>
              <li>DeliverIt and its licensors, where applicable, grant you a revocable, limited, non-exclusive, non-assignable and non-sublicensable license to use and access the Platform to use the Service, subject to the Terms &amp; Conditions of this Agreement. All rights not expressly granted to you are reserved by DeliverIt and its licensors,<br /><br /></li>
              <li>
                You shall not:<br /><br />
                <ol>
                  <li>Rent, lease, sublicense, distribute or transfer copies of the Platform or the license for the use of the Platform to any third parties or otherwise commercially exploit the Platform other than for arranging and/or scheduling transportation, logistical and/or delivery services;<br /><br /></li>
                  <li>Modify, adapt, reverse engineer, decompile, disassemble, translate the Platform or create derivative works based on the Platform;<br /><br /></li>
                  <li>Upload or disseminate any kinds of computer viruses, worms, trojans, or malicious codes;<br /><br /></li>
                  <li>Post, distribute or reproduce any information or material on the Platform which is offensive, defamatory, libellous, vexatious obscene, threatening or which is otherwise unlawful;<br /><br /></li>
                  <li>Remove any copyright, trademark or other proprietary rights notices contained on the Platform; or<br /><br /></li>
                  <li>Engage in any unlawful or illegal activity which is in contravention of any applicable laws and regulations.<br /><br /><br /></li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h3>Representations and Warranties</h3>
            <br />
            <ol>
              <li>
                By using the Service and/or Platform, you represent, warrant and undertake that:<br /><br />
                <ol>
                  <li>You have the legal capacity to enter into the Agreement;<br /><br /></li>
                  <li>You will provide true, accurate, not misleading, current and complete information as required for the Service and undertake the responsibility to maintain and update your information in a timely manner to keep it true, accurate, not misleading, current and complete at all times during the term of the Agreement. You agree that DeliverIt may rely on your information as true, accurate, not misleading, current and complete.<br /><br /></li>
                  <li>You will only use the Platform and Service for their intended and lawful purpose;<br /><br /></li>
                  <li>You agree to notify us immediately of any breach of security from your end;<br /><br /></li>
                  <li>You will not use the Service for the delivery of any unlawful material or for fraudulent purpose;<br /><br /></li>
                  <li>When using the Service, you agree to comply with all laws applicable to you and/or your use of the Service;<br /><br /></li>
                  <li>You agree that the Service is provided on a reasonable-effort basis;<br /><br /></li>
                  <li>You agree that your use of the Service will be subject to DeliverIt&rsquo;s Privacy Policy;<br /><br /></li>
                  <li>You agree to assist DeliverIt with any internal or external investigations as may be required by DeliverIt in complying with any prevailing laws or regulations in place.<br /><br /><br /></li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h3>Disclaimer</h3>
            <br />
            <ol>
              <li>The Sites, its contents and any related service(s) is provided to you on an &ldquo;as is&rdquo; basis. Although DeliverIt makes reasonable efforts to keep the Sites up-to-date, we make no representations, warranties or guarantees, whether express or implied, that such information is accurate, complete or up to date. We shall not be liable for any direct, indirect or consequent loss arising from the usage, modification or amendments to the Sites or Terms &amp; Conditions.<br /><br /></li>
              <li>DeliverIt does not make any representation or warranty in respect of the quality and timeliness of any transportation, logistical and/or delivery services provided by the Drivers. You agree that you shall bear all risk arising out of your use of the Service and any service by the Driver and you shall have no claim against DeliverIt.<br /><br /></li>
              <li>You acknowledge and agree that DeliverIt has no responsibility or liability to you or any third party in relation to any goods intended to be transported and/or delivered by a Driver.<br /><br /></li>
              <li>The Service and Sites may be subject to limitations, delays and other problems inherent in the use of the internet and electronic communications including but not limited to the device used by you or the Driver being faulty, not connected, out of range, switched off or not functioning. DeliverIt is not responsible for any delays, delivery failures, damages or losses resulting from such problems.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Payment on the Service</h3>
            <br />
            <ol>
              <li>In consideration of the provision of the Service provided by DeliverIt, including Corporate Billing, DeliverIt may charge a service or administration fee and charges (the &ldquo;Fees&rdquo;) as determined by DeliverIt from time to time. You shall pay DeliverIt all charges and the Fees set forth below.<br /><br /></li>
              <li>All fees shall be exclusive of Value Added Tax (VAT). VAT includes Goods and Services Tax (GST) and/or similar indirect taxes as required by the laws of Malaysia. If GST is chargeable on any Fees, you shall pay the VAT amount to DeliverIt when remitting payment of the Fees.<br /><br /></li>
              <li>Charges include transportation, logistical and/or delivery services provided by Drivers and/or any additional charges ancillary to the provision of transportation, logistical and/or delivery services.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Confidentiality</h3>
            <br />
            <ol>
              <li>You shall maintain in confidence all information and data relating to DeliverIt, its services, products, business affairs, marketing and promotion plans or other operations which are disclosed to you by DeliverIt (whether orally or in writing and whether before, on or after the date of this Agreement) or which are otherwise directly or indirectly acquired by you from DeliverIt or created in the course of this Agreement. You shall further ensure that you only use such confidential information in order to use the Service, and shall not without DeliverIt&rsquo;s prior written consent, disclose such information to any third party nor use it for any other purpose.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Data Privacy and Personal Data Protection Policy</h3>
            <br />
            <ol>
              <li>You consent to DeliverIt using your personal data (including but not limited to name, pickup and dropoff addresses and contact information) as provided by you to DeliverIt from time to time when you use the Service.<br /><br /></li>
              <li>You consent to DeliverIt collecting, using, keeping and updating your personal data to such extent, for and at such time period as may be necessary when DeliverIt provides the Service to you, and you confirm that the personal data so provided by you are true, correct and up to date.<br /><br /></li>
              <li>You further expressly consent that DeliverIt is entitled to disclose your personal data to the Driver as may be necessary for the fulfilment of the Service.<br /><br /></li>
              <li>You acknowledge that DeliverIt may disclose personal data of other individuals to you in the course of your use of the Service. You represent and warrant that you will only use such Personal Data for the purpose for which it was disclosed to you by DeliverIt, and for any other unauthorised purposes.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Indemnification</h3>
            <br />
            <ol>
              <li>
                By agreeing to the Terms &amp; Conditions upon using the Service, you agree that you shall indemnify and hold DeliverIt, its licensors and each such party&rsquo;s affiliates, officers, directors, members, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys&rsquo; fees and costs and/or regulatory action) arising out of or in connection with:<br /><br />
                <ol>
                  <li>Your use of the Service and Platform in your dealings with Drivers (as the case may be); or<br /><br /></li>
                  <li>Your violation or breach of any of the Terms &amp; Conditions or any applicable law or regulations, whether or not reference herein; or<br /><br /></li>
                  <li>Your violation of any rights of any third party, including Drivers arranged via the Service; or<br /><br /></li>
                  <li>Your use of misuse of the Service and/or the Platform.<br /><br /><br /></li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h3>Limitation of Liability</h3>
            <br />
            <ol>
              <li>You acknowledge and agree that your access and use of the Platform and procurement of any transportation, logistics and/or delivery services is at your own risk and DeliverIt shall not be responsible for any lost, stolen, damaged or destroyed goods. You hereby agree that if you are desirous of insuring your goods, you shall procure your own insurance coverage.<br /><br /></li>
              <li>Where you elect not to use any insurance service, DeliverIt shall nevertheless reimburse to you the cost for any lost, stolen, damaged or destroyed goods transported by a Driver subject to a maximum amount of up to RM100.00 (for non-corporate clients) or up to RM400.00 (for corporate clients that have a corporate account with DeliverIt) per delivery, subject to the provision of proof of evidence to the satisfaction of DeliverIt such as receipts, invoices and photographs of any damaged items.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Dispute Resolution</h3>
            <br />
            <ol>
              <li>This Agreement shall be governed by Malaysian law, without regard to the choice or conflicts of law provision, and any disputes, actions or causes of action arising out of or in connection with the Terms &amp; Conditions or the Service shall be referred to the Asian International Arbitration Centre (&ldquo;AIAC&rdquo;), in accordance with the Rules of the AIAC as modified or amended from time to time (the &ldquo;Rules&rdquo;) by a sole arbitrator appointed by the mutual agreement of you and DeliverIt (the &ldquo;Arbitrator&rdquo;). If you and DeliverIt are unable to agree on an Arbitrator, the Arbitrator shall be appointed by the President of AIAC in accordance with the Rules. The seat and venue of the arbitration shall be Kuala Lumpur, in the English language and the fees of the Arbitrator shall be borne equally by you and DeliverIt, provided that the Arbitrator may require that such fees be borne in such other manner as the Arbitrator determines is required in order for this Arbitration clause to be enforceable under applicable law.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Relationship</h3>
            <br />
            <ol>
              <li>Nothing contained in these Terms &amp; Conditions shall be construed as creating any agency, partnership, or other forms of joint enterprise with DeliverIt.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Severability</h3>
            <br />
            <ol>
              <li>If any provision of the Terms &amp; Conditions is held to be invalid or unenforceable, the legality, validity and enforceability of the remaining provisions shall not be affected or impaired.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>No Waiver</h3>
            <br />
            <ol>
              <li>The failure of DeliverIt to enforce any right or provision in the Terms &amp; Conditions shall not constitute a waiver of such right or provision.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Entire Agreement</h3>
            <br />
            <ol>
              <li>This Agreement comprises the entire agreement between you and DeliverIt and supersedes any prior or contemporaneous negotiations or discussions.<br /><br /><br /></li>
            </ol>
          </li>
          <li>
            <h3>Governing Laws</h3>
            <br />
            <ol>
              <li>These Terms &amp; Conditions shall be governed by and construed in accordance with the laws of Malaysia.<br /><br /></li>
              <li>You submit to the non-exclusive jurisdiction of the courts of Malaysia.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
}
