import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination, TableFooter } from "@material-ui/core";
import ReactPixel from 'react-facebook-pixel';
import { CreateOrderGroup } from './CreateOrderGroup';
import { EditOrderGroup } from './EditOrderGroup';
import { ConfirmDelete } from './ConfirmDelete';
import { BookmarkAddress } from './BookmarkAddress';
import { DownloadReport } from './DownloadReport';
import { TableToolbar } from './TableToolbar';
import { OrderGroupCollapsibleRow } from './OrderGroupCollapsibleRow';
import { openCreateOrderGroupModal, openEditOrderGroupModal, openDeleteOrderGroupModal, openBookmarkAddressModal, handleGetOrderGroups, handleGetBookmarkedAddresses, openDownloadOrderGroupModal, selectState, setPage, setRowsPerPage } from './orderGroupSlice';
import TablePaginationActions from './TablePaginationActions';
import { FACEBOOK_PIXEL_ID } from '../../app/utils';
import styles from './OrderGroup.module.css';
import Spinner from '../../components/common/Spinner';
import { Topbar } from '../../components/topbar/Topbar';

export function OrderGroup(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  useEffect(() => {
    async function initFn() {
      if (state.status === 'idle') {
        dispatch(handleGetOrderGroups(props.orderGroupType));
        dispatch(handleGetBookmarkedAddresses());
        let authedUser = localStorage.user ? JSON.parse(localStorage.user) : null;
        ReactPixel.init(FACEBOOK_PIXEL_ID, authedUser ? { em: authedUser.userName, fn: authedUser.firstName, ln: authedUser.lastName, ph: authedUser.contactNumber } : {}, { debug: false, autoConfig: false });
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
      }
    }
    initFn();
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetOrderGroups(props.orderGroupType));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetOrderGroups(props.orderGroupType));
  };

  return (
    <div className={styles.container}>
      <Topbar page="Orders" />

      <div className={styles.tableContainer}>
        <TableContainer component={Paper} className={styles.denseTable}>
          <TableToolbar
            numSelected={state.selectedIds.length}
            createOrderGroup={() => dispatch(openCreateOrderGroupModal())}
            editOrderGroup={() => dispatch(openEditOrderGroupModal())}
            deleteOrderGroup={() => dispatch(openDeleteOrderGroupModal())}
            downloadOrderGroups={() => dispatch(openDownloadOrderGroupModal())}
            bookmarkAddress={() => dispatch(openBookmarkAddressModal())}
            orderGroupType={props.orderGroupType}
          />
          <Table size="small">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="center" className={styles.tableHeaderExtraNarrow}>Vehicle</TableCell>
                <TableCell align="left" className={styles.tableHeaderExtraNarrow}>Order Group Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Date & Time</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>First Dropoff Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Order Count</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Delivery Charges</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Halal</TableCell>
                <TableCell align="left" className={styles.tableHeader}></TableCell>
                {props.orderGroupType === "COMPLETED" && <TableCell align="left" className={styles.tableHeader}></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                state.orderGroups.length > 0 ? (
                  state.orderGroups.map((orderGroup) => (
                    <OrderGroupCollapsibleRow state={state} orderGroup={orderGroup} orderGroupType={props.orderGroupType} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={14} align="center">
                      <p>No Order Groups</p>
                      {
                        props.orderGroupType === "OPEN" &&
                        <Button onClick={() => dispatch(openCreateOrderGroupModal())} className={styles.gradientButton} style={{ marginBottom: 20 }}>Create Order Group</Button>
                      }
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={7}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {
        state.createOrderGroupModalOpen &&
        <CreateOrderGroup createOrderGroupModalOpen={state.createOrderGroupModalOpen} bookmarkedAddresses={state.bookmarkedAddresses} />
      }
      {
        state.editOrderGroupModalOpen &&
        <EditOrderGroup orderGroupType={props.orderGroupType} orderGroup={state.selectedIds.length > 0 ? state.orderGroups.find(orderGroup => orderGroup.id === state.selectedIds[0]) : null} />
      }
      {
        state.deleteOrderGroupModalOpen &&
        <ConfirmDelete orderGroupType={props.orderGroupType} orderGroups={state.selectedIds.length > 0 ? state.orderGroups.filter(orderGroup => state.selectedIds.includes(orderGroup.id)) : []} />
      }
      {
        state.downloadOrderGroupModalOpen &&
        <DownloadReport orderGroupType={props.orderGroupType} isUnpaid={false} />
      }
      {
        state.bookmarkAddressModalOpen &&
        <BookmarkAddress orderGroup={state.selectedIds.length > 0 ? state.orderGroups.find(orderGroup => orderGroup.id === state.selectedIds[0]) : null} />
      }
      {
        state.isLoading === true &&
        <Spinner />
      }
    </div>
  );
}
