import React, { useEffect, useState } from 'react';
import styles from './Signup.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { Button, Hidden } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { onValueChange, onFormValueChange, handleSignUp, selectState, onFormUserValueChange, setShowVerification, handleRequestOtp } from './signupSlice';

import logo from "../../images/logo-white.png";
import accountIcon from "../../images/icons/account-gradient.png";
import passwordIcon from "../../images/icons/lock-gradient.png";
import emailIcon from "../../images/icons/email-gradient.png";
import productIcon from "../../images/icons/product-gradient.png";
import companyIcon from "../../images/icons/company-gradient.png";

export function Signup() {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const history = useHistory();

  const [counter, setCounter] = React.useState(120);
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: ""
  });

  useEffect(() => {
    state.showVerification && state.otpRequestSuccess && counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [state.showVerification, state.otpRequestSuccess, counter]);

  const validateFields = () => {
    if (state.submitting === true) {
      return true;
    }

    for (var key in state.form) {
      if (state.form[key] === null || state.form[key] === "") {
        return true;
      }
    }

    for (var key in state.form.user) {
      if (state.form.user[key] === null || state.form.user[key] === "") {
        return true;
      }
    }

    return false;
  }

  const handleResetOtp = () => {
    setOtp({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" });
  }

  const handleOtpChange = (name, value) => {
    setOtp({
      ...otp,
      [name]: value
    })

    if (value !== "") {
      const nextIndex = Number(name.substring(3)) + 1;
      const nextInput = document.getElementById(`otp${nextIndex}`);
      if (nextInput !== null) {
        nextInput.focus();
      } else {
        if (otp.otp1 !== "" && otp.otp2 !== "" && otp.otp3 !== "" && otp.otp4 !== "" && otp.otp5 !== "" && value !== "") {
          const otpCode = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + value;
          dispatch(handleSignUp(history, otpCode, handleResetOtp));
        }
      }
    }
  }

  const handleResendOtp = () => {
    dispatch(handleRequestOtp());
    setCounter(120);
  }

  return (
    <div className={styles.container}>
      <div>
        <img src={logo} alt="logo" className={styles.logo} />
      </div>
      <div className={styles.cardContainer}>
        <Hidden smDown>
          <div display={{ xs: 'none' }} className={styles.leftContainer}></div>
        </Hidden>
        <div className={styles.rightContainer}>
          <form className={styles.formContainer}>
            <p style={{ marginLeft: 5, fontSize: 15, fontWeight: "500" }}>Register a new account</p>
            {
              state.error &&
              <Alert
                severity="error"
                style={{ marginInline: 5, marginTop: -5, marginBottom: 12, borderRadius: 10, textAlign: "left" }}
              >
                {state.errorMessage}
              </Alert>
            }
            <div className={styles.textRow}>
              <div className={styles.textInputContainer}>
                <div className={styles.textInputIconContainer}>
                  <img alt='name' src={accountIcon} className={styles.textInputIcon} />
                </div>
                <input
                  id="firstName"
                  name="firstName"
                  value={state.form.user.firstName}
                  onChange={e => dispatch(onFormUserValueChange({ name: "firstName", value: e.target.value }))}
                  placeholder="First Name"
                  className={styles.textInput}
                  disabled={state.showVerification}
                />
              </div>
              <div className={styles.textInputContainer}>
                <div className={styles.textInputIconContainer}>
                  <img alt='name' src={accountIcon} className={styles.textInputIcon} />
                </div>
                <input
                  id="lastName"
                  name="lastName"
                  value={state.form.user.lastName}
                  onChange={e => dispatch(onFormUserValueChange({ name: "lastName", value: e.target.value }))}
                  placeholder="Last Name"
                  className={styles.textInput}
                  disabled={state.showVerification}
                />
              </div>
            </div>
            <div style={{ marginBottom: 12, marginInline: 5 }}>
              <PhoneInput
                id="contactNumber"
                name="contactNumber"
                country={'my'}
                countryCodeEditable={false}
                preferredCountries={['my', 'sg', 'au', 'us']}
                autoFormat={false}
                value={state.form.user.contactNumber}
                onChange={e => dispatch(onFormUserValueChange({ name: "contactNumber", value: e }))}
                type="tel"
                disabled={state.showVerification}
                inputProps={{
                  required: true
                }}
                inputStyle={{ backgroundColor: "#F3FAFC", height: 40, fontSize: 12, fontWeight: "500", border: "1px solid #227C97", borderRadius: 8 }}
              />
            </div>
            <div className={styles.textInputContainer}>
              <div className={styles.textInputIconContainer}>
                <img alt='email' src={emailIcon} style={{ width: 18, height: 14 }} className={styles.textInputIcon} />
              </div>
              <input
                id="userName"
                name="userName"
                value={state.form.userName}
                onChange={e => dispatch(onFormValueChange({ name: "userName", value: e.target.value }))}
                placeholder="Email Address"
                type="email"
                className={styles.textInput}
                disabled={state.showVerification}
              />
            </div>
            <div className={styles.textRow}>
              <div className={styles.textInputContainer}>
                <div className={styles.textInputIconContainer}>
                  <img alt='password' src={passwordIcon} className={styles.textInputIcon} />
                </div>
                <input
                  id="password"
                  name="password"
                  value={state.form.password}
                  onChange={e => dispatch(onFormValueChange({ name: "password", value: e.target.value }))}
                  placeholder="Password"
                  type="password"
                  className={styles.textInput}
                  disabled={state.showVerification}
                />
              </div>
              <div className={styles.textInputContainer}>
                <div className={styles.textInputIconContainer}>
                  <img alt='password' src={passwordIcon} className={styles.textInputIcon} />
                </div>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  value={state.confirmPassword}
                  onChange={e => dispatch(onValueChange({ name: "confirmPassword", value: e.target.value }))}
                  placeholder="Confirm Password"
                  type="password"
                  className={styles.textInput}
                  disabled={state.showVerification}
                />
              </div>
            </div>
            <div className={styles.textRow}>
              <div className={styles.textInputContainer}>
                <div className={styles.textInputIconContainer}>
                  <img alt='company' src={companyIcon} className={styles.textInputIcon} />
                </div>
                <input
                  id="company"
                  name="company"
                  value={state.form.user.company}
                  onChange={e => dispatch(onFormUserValueChange({ name: "company", value: e.target.value }))}
                  placeholder="Company Name"
                  className={styles.textInput}
                  disabled={state.showVerification}
                />
              </div>
              <div className={styles.textInputContainer}>
                <div className={styles.textInputIconContainer}>
                  <img alt='product' src={productIcon} style={{ width: 20, height: 16 }} className={styles.textInputIcon} />
                </div>
                <select name="product" id="product" className={styles.selectInput} onChange={e => dispatch(onFormUserValueChange({ name: "product", value: e.target.value }))} disabled={state.showVerification}>
                  <option value="FOOD_AND_BEVERAGE">Food & Beverage</option>
                  <option value="CAFES_OR_DESSERTS">Cafes / Desserts</option>
                  <option value="HEALTH_CARE">Health Care</option>
                  <option value="PARCELS">Parcels</option>
                  <option value="OTHER">Others</option>
                </select>
              </div>
            </div>

            {
              !state.showVerification
                ? <>
                  <div style={{ marginLeft: 5, marginTop: state.error ? 55 : 110 }}>
                    <Button
                      disabled={validateFields()}
                      onClick={() => dispatch(handleRequestOtp())}
                      className={styles.gradientButton}
                    >
                      Sign Up
                    </Button>
                  </div>
                  <p style={{ marginLeft: 5, fontSize: 12, fontWeight: "500" }}>Already have an account? Login <Link to="/login" style={{ color: "#387596" }}>here</Link></p>

                </>
                : <>
                  <p style={{ marginLeft: 5, marginBottom: 5, fontSize: 15, fontWeight: "500" }}>Verification</p>
                  <div style={{ display: "flex" }}>
                    <p className={styles.tinyText} style={{ marginLeft: 5, color: "#929292" }}>Enter OTP code sent to {state.form.user.contactNumber}.&nbsp;</p>
                    <p className={styles.tinyText} style={{ color: "#058483", textDecoration: "underline", cursor: "pointer" }} onClick={() => { dispatch(setShowVerification(false)); setCounter(120) }}>Edit details</p>
                  </div>
                  <div style={{ display: "flex", marginLeft: 2 }}>
                    <input
                      id="otp1"
                      name="otp1"
                      maxLength={1}
                      value={otp.otp1}
                      onChange={e => handleOtpChange("otp1", e.target.value)}
                      className={styles.otpInput}
                      required
                    />
                    <input
                      id="otp2"
                      name="otp2"
                      maxLength={1}
                      value={otp.otp2}
                      onChange={e => handleOtpChange("otp2", e.target.value)}
                      className={styles.otpInput}
                      required
                    />
                    <input
                      id="otp3"
                      name="otp3"
                      maxLength={1}
                      value={otp.otp3}
                      onChange={e => handleOtpChange("otp3", e.target.value)}
                      className={styles.otpInput}
                      required
                    />
                    <input
                      id="otp4"
                      name="otp4"
                      maxLength={1}
                      value={otp.otp4}
                      onChange={e => handleOtpChange("otp4", e.target.value)}
                      className={styles.otpInput}
                      required
                    />
                    <input
                      id="otp5"
                      name="otp5"
                      maxLength={1}
                      value={otp.otp5}
                      onChange={e => handleOtpChange("otp5", e.target.value)}
                      className={styles.otpInput}
                      required
                    />
                    <input
                      id="otp6"
                      name="otp6"
                      maxLength={1}
                      value={otp.otp6}
                      onChange={e => handleOtpChange("otp6", e.target.value)}
                      className={styles.otpInput}
                      required
                    />
                  </div>
                  {
                    !state.otpVerification && counter === 0
                      ? <p className={styles.tinyText} style={{ marginLeft: 5, color: "#058483", textDecoration: "underline", cursor: "pointer" }} onClick={() => handleResendOtp()}>Resend OTP</p>
                      : !state.otpVerification && state.otpRequestSuccess
                        ? <div style={{ display: "flex" }}>
                          <p className={styles.tinyText} style={{ marginLeft: 5, color: "#ffa12b" }}>Code expires in ({counter})&nbsp;</p>
                          <p className={styles.tinyText} style={{ color: "#929292", textDecoration: "underline" }}>Resend OTP</p>
                        </div>
                        : <></>
                  }
                  {
                    state.otpVerification && <p className={styles.tinyText} style={{ marginLeft: 5, color: "#058483", fontWeight: "bold" }}>Verifying OTP...</p>
                  }
                </>
            }
          </form>
        </div>
      </div>
      <div className={styles.poweredBy}><span>Powered by </span><b><a className={styles.poweredByLink} href='https://www.parkitmy.com/' target='_blank' rel='noreferrer'>PARKIT</a></b></div>
    </div>
  );
}
