import axios from "axios";
import { KEYCLOAK_BASE_URL, KEYCLOAK_CLIENT_ID } from "../../app/utils";

export const login = data =>
  axios.post(
    `${KEYCLOAK_BASE_URL}/realms/parkit/protocol/openid-connect/token`,
    new URLSearchParams({
      ...data,
      grant_type: "password",
      client_id: KEYCLOAK_CLIENT_ID,
    })
  );