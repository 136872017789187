import React from 'react';
import styles from './Login.module.css';
import logo from "../../images/logo-white.png";

import { Login } from './Login';

export function LoginPage() {
  return (
    <div className={styles.container}>
      <div>
        <img src={logo} alt="logo" className={styles.logo} />
      </div>
      <div className={styles.cardContainer}>
        <Login useWindow={false} />
      </div>
      <div className={styles.poweredBy}><span>Powered by </span><b><a className={styles.poweredByLink} href='https://www.parkitmy.com/' target='_blank' rel="noreferrer">PARKIT</a></b></div>
    </div>
  );
}
