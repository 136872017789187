import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    is_authenticated: false,
    access_token: "",
    expires_in: "",
    refresh_token: "",
    scope: "profile email",
    token_type: "Bearer",
  },
  reducers: {
    setAuthState: (state, action) => {
      state = {
        ...action.payload
      };
    },
    setIsAuthenticated: (state, action) => {
      state.is_authenticated = action.payload;
    },
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
  },
});

export const { setAuthState, setIsAuthenticated, setAccessToken } = authSlice.actions;

export const selectState = state => state.auth;

export default authSlice.reducer;
