/*global google*/

import React, { useEffect, useState } from 'react';
import styles from './OrderDetails.module.css';
import { useParams } from 'react-router-dom';
import Moment from 'moment';

import { Topbar } from '../../components/topbar/Topbar';
import { Map } from '../../components/map/Map';
import { OrderDetailsMap } from './OrderDetailsMap';
import { createOrder, getOrdersByOrderGroup } from '../place_order/orderAPI';
import { createOrderGroup } from '../place_order/orderGroupAPI';
import { getPaymentObject } from '../wallet/walletAPI';

import locationPinIcon from "../../images/place_order/location-pin-icon.png";
import mapMarkerIcon from "../../images/place_order/map-marker-icon.png";
import deliveritLogo from "../../images/translucent-deliverit-logo.png";

export const OrderDetails = () => {
  const { id } = useParams();

  const [orderGroup, setOrderGroup] = useState("");
  const [currency, setCurrency] = useState("RM");
  const [subtotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [directions, setDirections] = useState([]);
  const [disableReorder, setDisableReorder] = useState(false);

  useEffect(() => {
    const getOrders = async () => {
      const response = await getOrdersByOrderGroup(id);
      const ogResp = response.data;
      setOrderGroup(ogResp);

      let total = 0;
      ogResp.orders.forEach((order) => {
        total += (order.deliveryFee + order.doorToDoorFee);
      });
      setSubTotal(total);

      if (ogResp.walletId !== undefined && ogResp.paymentId !== undefined) {
        const paymentResp = await getPaymentObject(ogResp.walletId, ogResp.paymentId);
        const discountAmount = paymentResp.data.voucher?.promoCode?.discountAmount;
        if (discountAmount) {
          total -= discountAmount;
          setDiscount(discountAmount);
        }
      }

      if (total < 0) { total = 0; }
      setTotal(total);

      if (ogResp.orders[0].currency !== "MYR") { setCurrency(ogResp.orders[0].currency); }
    }
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GOOGLE_DIRECTION_WAYPOINT_LIMIIT = 25;
  useEffect(() => {
    const handleDirection = async () => {

      if (orderGroup !== "") {
        const DirectionsService = new google.maps.DirectionsService();

        // initiate locations with pickup location as origin
        let locations = [new google.maps.LatLng(orderGroup.pickUpLocation.coordinate.latitude, orderGroup.pickUpLocation.coordinate.longitude)];
        let sortedOrders = [...orderGroup.orders].sort((a, b) => a.ordinal - b.ordinal);

        if (sortedOrders.length > 0) {
          for (let i = 0; i < sortedOrders.length; i++) {
            locations.push(
              new google.maps.LatLng(sortedOrders[i].dropOffLocation.coordinate.latitude, sortedOrders[i].dropOffLocation.coordinate.longitude)
            );
          }

          // divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
          let waypointBlocks = [];
          for (let i = 0, max = GOOGLE_DIRECTION_WAYPOINT_LIMIIT - 1; i < locations.length; i = i + max) {
            waypointBlocks.push(locations.slice(i, i + max + 1));
          }

          let finalDirectionResults = [];
          // send requests to service to get route (for stations count <= GOOGLE_DIRECTION_WAYPOINT_LIMIIT only one request will be sent)
          for (let i = 0; i < waypointBlocks.length; i++) {
            // waypoints does not include first station (origin) and last station (destination)
            var waypoints = [];
            for (var j = 1; j < waypointBlocks[i].length - 1; j++) {
              waypoints.push({ location: waypointBlocks[i][j], stopover: true });
            }

            await DirectionsService.route({
              origin: waypointBlocks[i][0],
              destination: waypointBlocks[i][waypointBlocks[i].length - 1],
              waypoints: waypoints,
              travelMode: google.maps.TravelMode.DRIVING
            }, (result, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                finalDirectionResults.push(result);
              } else {
                console.error(`error fetching directions ${result}`);
                alert(`Error fetching directions in map`);
              }
            });
          }

          setDirections(finalDirectionResults);
        }
      }
    }
    handleDirection();
  }, [orderGroup]);

  const getOrderGroupStatus = (status) => {
    switch (status) {
      case "CONFIRMED":
        return "Ongoing";
      case "ASSIGNED":
        return "Ongoing";
      case "IN_TRANSIT":
        return "Ongoing";
      case "SUCCESS":
        return "Completed";
      case "PARTIAL_SUCCESS":
        return "Partially completed";
      case "FAILED":
        return "Failed";
      case "CANCELLED":
        return "Cancelled";
      default:
        return status;
    }
  }

  const generateNewDate = (date) => {
    const now = new Date();
    const currentDate = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const oldDate = new Date(date);

    var newDate = new Date(oldDate.setDate(currentDate));
    if (newDate - now < 0) {
      newDate = new Date(newDate.setDate(currentDate + 1));
    }
    if (newDate.getFullYear() - currentYear < 0) {
      newDate = new Date(newDate.setFullYear(currentYear));
    }
    if (newDate.getMonth() - currentMonth < 0) {
      newDate = new Date(newDate.setMonth(currentMonth));
    }

    return newDate.toISOString();
  }

  const handleReorder = async () => {
    if (!disableReorder) {
      setDisableReorder(true);

      const orderGroupPayload = {
        deliveryMethod: orderGroup.deliveryMethod,
        pickUpDateTime: generateNewDate(orderGroup.pickUpDateTime),
        pickUpPersonName: orderGroup.pickUpPersonName,
        pickUpContactNumber: orderGroup.pickUpContactNumber,
        pickUpLocation: orderGroup.pickUpLocation,
        halal: orderGroup.halal,
        noteToDriver: orderGroup.noteToDriver
      }

      const orderGroupResp = await createOrderGroup(orderGroupPayload);
      const orderGroupId = orderGroupResp.data.id;

      for (let i = 0; i < orderGroup.orders.length; i++) {
        const order = orderGroup.orders[i];
        const orderPayload = {
          ordinal: order.ordinal,
          deliverDateTime: generateNewDate(order.deliverDateTime),
          recipientName: order.recipientName,
          recipientPhone: order.recipientPhone,
          dropOffLocation: order.dropOffLocation,
          doorToDoor: order.doorToDoor,
          codAmount: order.codAmount ? order.codAmount : ""
        }

        await createOrder(orderGroupId, orderPayload);
      }

      setDisableReorder(false);
      window.open(`/main-order/${orderGroupId}`);
    }
  }

  return (
    <>
      <Topbar page="Orders" />

      <div className={styles.mainContainer}>
        <p className={styles.title}>Order Details</p>

        <div className={styles.hContainer}>
          {/* Details Container */}
          {orderGroup !== "" &&
            <div className={styles.detailsContainer}>
              {
                (orderGroup.status === "SUCCESS" || orderGroup.status === "PARTIAL_SUCCESS" || orderGroup.status === "FAILED" || orderGroup.status === "CANCELLED") &&
                <div style={{ display: "flex", marginLeft: "auto" }} onClick={() => handleReorder()}>
                  <div className={styles.trackingButton} style={{ backgroundColor: "#1cadb4", marginTop: -12, marginRight: 8, border: "1px solid #1cadb4" }}>
                    <p className={styles.whiteText} style={{ fontSize: 10, fontWeight: "bold" }}>Reorder</p>
                  </div>
                </div>
              }

              <div className={styles.detailsSubcontainer}>
                <div className={styles.row} style={{ marginBottom: 30 }}>
                  <div>
                    <p className={styles.tealText} style={{ whiteSpace: "nowrap" }}>Order ID</p>
                    <p className={styles.tealText}>Type</p>
                    <p className={styles.tealText}>Status</p>
                    <p className={styles.tealText}>Halal</p>
                    {
                      orderGroup.noteToDriver && <p className={styles.tealText} style={{ whiteSpace: "nowrap" }}>Notes to Rider</p>
                    }
                  </div>
                  <div style={{ marginLeft: 40 }}>
                    <p className={styles.blackText}>{orderGroup.id}</p>
                    <p className={styles.blackText}>{orderGroup.deliveryMethod === "CAR" ? "Car" : "Bike"}</p>
                    <p className={styles.blackText}>{getOrderGroupStatus(orderGroup.status)}</p>
                    <p className={styles.blackText}>{orderGroup.halal ? "Yes" : "No"}</p>
                    {
                      orderGroup.noteToDriver && <p className={styles.blackText}>{orderGroup.noteToDriver}</p>
                    }
                  </div>
                </div>

                {/* Pick-up Location */}
                <div className={styles.locationContainer}>
                  <img className={styles.icon} src={locationPinIcon} alt="location-pin" />

                  <div style={{ marginLeft: 15, textAlign: "left" }}>
                    <div style={{ display: "flex", marginTop: -2, flexWrap: "wrap" }}>
                      <p className={styles.tealText} style={{ marginRight: 10 }}>Pick-up Location</p>
                      {
                        ((orderGroup.status === "CONFIRMED" || orderGroup.status === "ASSIGNED" || orderGroup.status === "IN_TRANSIT") && !orderGroup.hadPickedUp) &&
                        <>
                          {
                            orderGroup.trackingLink
                              ? <div className={styles.trackingButton} onClick={() => window.open(`${orderGroup.trackingLink}`)}>
                                <p className={styles.whiteText}>Track pick-up item(s)</p>
                              </div>
                              : <div className={styles.disabledTrackingButton}>
                                <p className={styles.whiteText}>Track pick-up item(s)</p>
                              </div>
                          }
                        </>
                      }
                    </div>
                    <p className={styles.blackText}>{orderGroup.pickUpLocation.address}</p>
                    <p className={styles.greyText}>{orderGroup.pickUpLocation.unitNumber !== "" && orderGroup.pickUpLocation.unitNumber + "\u00a0 • \u00a0"}{orderGroup.pickUpContactNumber}&nbsp; • &nbsp;{orderGroup.pickUpPersonName}&nbsp; • &nbsp;{Moment(orderGroup.pickUpDateTime).format("DD/MM/YYYY, LT")}</p>
                    {
                      orderGroup.hadPickedUp &&
                      <div className={styles.trackingContainer}>
                        <p className={styles.regularTealText} style={{ fontSize: 9, color: "#1e9299" }}>Item(s) picked up {orderGroup.completionTime ? " at " + Moment(orderGroup.completionTime).format("LT") : ""}</p>
                      </div>
                    }
                  </div>
                </div>

                {/* Drop-off Locations */}
                {
                  orderGroup.orders.map((order, index) => {
                    return (
                      <div className={styles.locationContainer}>
                        <div style={{position: 'relative'}}>
                          <img className={styles.icon} src={mapMarkerIcon} alt="map-marker" />
                          <div className={styles.markerIconIndex}>{index + 1}</div>
                        </div>

                        <div style={{ marginLeft: 15, textAlign: "left" }}>
                          <div style={{ display: "flex", marginTop: -2, flexWrap: "wrap" }}>
                            <p className={styles.tealText} style={{ marginRight: 10 }}>Drop-Off Location {index + 1}</p>
                            {
                              (order.status === "IN_TRANSIT" && !order.hadDelivered)
                                ? <>
                                  {
                                    order.trackingLink
                                      ? <div className={styles.trackingButton} onClick={() => window.open(`${order.trackingLink}`)}>
                                        <p className={styles.whiteText}>Track drop-off item(s)</p>
                                      </div>
                                      : <div className={styles.disabledTrackingButton}>
                                        <p className={styles.whiteText}>Track drop-off item(s)</p>
                                      </div>
                                  }
                                </>
                                : order.hadDelivered
                                  ? <div className={styles.trackingButton} onClick={() => window.open(`${order.proofOfDelivery}`)}>
                                    <p className={styles.whiteText}>Proof of Delivery</p>
                                  </div>
                                  : <></>
                            }
                          </div>
                          <p className={styles.blackText}>{order.dropOffLocation.address}</p>
                          <p className={styles.greyText}>{order.dropOffLocation.unitNumber !== "" && order.dropOffLocation.unitNumber + "\u00a0 • \u00a0"}{order.recipientPhone}&nbsp; • &nbsp;{order.recipientName}&nbsp; • &nbsp;{Moment(order.deliverDateTime).format("DD/MM/YYYY, LT")}</p>
                          {
                            order.doorToDoor &&
                            <p className={styles.regularTealText} style={{ color: "#1e9299" }}>Door-to-Door {currency}{(order.doorToDoorFee / 100).toFixed(2)}</p>
                          }
                          {
                            order.codAmount &&
                            <p className={styles.regularTealText} style={{ color: "#1e9299" }}>COD {currency}{(order.codAmount / 100).toFixed(2)}</p>
                          }
                          <div className={styles.deliveryFeeContainer}>
                            <p className={styles.blackText} style={{ fontSize: 10 }}>{currency}{(order.deliveryFee / 100).toFixed(2)}</p>
                          </div>
                          <div>
                            {
                              order.hadDelivered
                                ? <div className={styles.trackingContainer}>
                                  <p className={styles.regularTealText} style={{ fontSize: 9, color: "#1e9299" }}>Item(s) delivered {order.completionTime ? " at " + Moment(order.completionTime).format("LT") : ""}</p>
                                </div>
                                : (order.status === "FAILED" || order.status === "CANCELLED")
                                  ? <div className={styles.failedContainer}>
                                    <p className={styles.regularTealText} style={{ fontSize: 9, color: "#f9827f" }}>{order.status.substring(0, 1)}{order.status.substring(1).toLowerCase()}</p>
                                  </div>
                                  : <></>
                            }
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              {/* Total Container */}
              <div className={styles.bottomContainer}>
                <img className={styles.deliveritLogo} src={deliveritLogo} alt="deliverit-logo" />
                <div className={styles.totalContainer}>
                  <div className={styles.row}>
                    <div>
                      <p className={styles.regularTealText}>Subtotal</p>
                      {
                        discount !== 0 &&
                        <p className={styles.regularTealText} style={{ whiteSpace: "nowrap" }}>Voucher Applied</p>
                      }
                      <p className={styles.blackText}>Total</p>
                    </div>
                    <div style={{ marginLeft: 60 }}>
                      <p className={styles.regularTealText} style={{ textAlign: "right" }}>{currency}{(subtotal / 100).toFixed(2)}</p>
                      {
                        discount !== 0 &&
                        <p className={styles.regularTealText} style={{ textAlign: "right", whiteSpace: "nowrap" }}>-{currency}{(discount / 100).toFixed(2)}</p>
                      }
                      <p className={styles.blackText} style={{ fontSize: 13, textAlign: "right" }}>{currency}{(total / 100).toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {/* Map */}
          {
            window.innerWidth > 850 &&
            <div className={styles.map}>
              {
                directions.length > 0
                  ? <OrderDetailsMap
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: "100%" }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    directions={directions}
                  />
                  : <Map
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: "100%" }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
              }
            </div>
          }
        </div>
      </div>
    </>
  )
}