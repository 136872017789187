import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import { closeBookmarkAddressModal, handleGetBookmarkedAddresses } from './orderGroupSlice';
import { Close } from "@material-ui/icons";
import styles from './OrderGroup.module.css';
import { createAddress } from '../address/addressAPI';

export function BookmarkAddress(props) {
  const { orderGroup } = props;
  const dispatch = useDispatch();

  const initialState = {
    form: {
      bookmarkName: "",
      jobType: "PICK_UP",
      location: {
        ...orderGroup.pickUpLocation
      },
      pickUpPersonName: orderGroup.pickUpPersonName,
      pickUpContactNumber: orderGroup.pickUpContactNumber,
      deliveryNoteToDriver: orderGroup.deliveryNoteToDriver,
    },
    error: false,
    errorMessage: ""
  };
  
  const [bookmarkAddressState, _setBookmarkAddressState] = useStateWithCallbackLazy({
    ...initialState
  });
  const bookmarkAddressStateRef = useRef(bookmarkAddressState);

  const setBookmarkAddressState = data => {
    bookmarkAddressStateRef.current = data;
    _setBookmarkAddressState(data);
  };

  const onFormValueChange = (data) => {
    setBookmarkAddressState({
      ...bookmarkAddressState,
      form: {
        ...bookmarkAddressState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async () => {
    let error = false;

    if (bookmarkAddressState.form.bookmarkName === null || bookmarkAddressState.form.bookmarkName === "") {
      error = true;
      setBookmarkAddressState({...bookmarkAddressState, error: true, errorMessage: "Bookmark name is required"});
      return;
    }

    if (!error) {
      try {
        await createAddress(bookmarkAddressState.form);
        dispatch(handleGetBookmarkedAddresses());
        dispatch(closeBookmarkAddressModal());
      } catch (error) {
        setBookmarkAddressState({...bookmarkAddressState, error: true, errorMessage: error.message});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="bookmark-address-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeBookmarkAddressModal())}/>
          <h3 className={styles.subtitle}>Bookmark Pickup Details</h3>
          <Fade in={bookmarkAddressState.error}>
            <p className={styles.errorMessage}>{bookmarkAddressState.errorMessage}</p>
          </Fade>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <p>Unit Number: <br/><b>{orderGroup.pickUpLocation?.unitNumber}</b></p>
              <p>Address: <br/><b>{orderGroup.pickUpLocation?.address}</b></p>
            </Grid>
            <Grid item xs={12}>
              <p>Bookmark Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bookmarkName"
                  name="bookmarkName"
                  value={bookmarkAddressState.form.bookmarkName}
                  onChange={e => onFormValueChange({name: "bookmarkName", value: e.target.value})}
                  placeholder="Bookmark Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Pickup Person Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="pickUpPersonName"
                  name="pickUpPersonName"
                  value={bookmarkAddressState.form.pickUpPersonName}
                  onChange={e => onFormValueChange({name: "pickUpPersonName", value: e.target.value})}
                  placeholder="Pickup Person Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Pickup Person Contact</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="pickUpContactNumber"
                  name="pickUpContactNumber"
                  value={bookmarkAddressState.form.pickUpContactNumber}
                  onChange={e => onFormValueChange({name: "pickUpContactNumber", value: e.target.value})}
                  placeholder="Pickup Contact Number"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <p>Pickup Note to driver/rider </p>
              <Paper className={styles.textInputContainer}>
                <textarea 
                  id="deliveryNoteToDriver" 
                  name="deliveryNoteToDriver" 
                  className={styles.textArea} 
                  value={bookmarkAddressState.form.deliveryNoteToDriver} 
                  onChange={e => onFormValueChange({name: "deliveryNoteToDriver", value: e.target.value})}
                  placeholder="Landmarks close to address, amount of goods to be collected or any important instructions">
                </textarea>
              </Paper>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Confirm</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
