import React from 'react';
import styles from './Landing.module.css';

import { Topbar } from '../../components/topbar/Topbar';

import topBannerImage from "../../images/landing/top-banner-image.png";
import businessServicesIcon from "../../images/landing/business-services-icon.png";
import d2dServicesIcon from "../../images/landing/d2d-services-icon.png";
import personalServicesIcon from "../../images/landing/personal-services-icon.png";
import beepLogo from "../../images/landing/beep-logo.png";
import tinyTemptressLogo from "../../images/landing/tiny-temptress-logo.png";
import craftenerLogo from "../../images/landing/craftener-logo.png";
import wholesomeLogo from "../../images/landing/wholesome-logo.png";
import kejuLogo from "../../images/landing/keju-logo.png";
import dokanLogo from "../../images/landing/24dokan-logo.png";
import kekCoLogo from "../../images/landing/kek-co-logo.png";
import openInvertedComma from "../../images/landing/open-inverted-comma.png";
import starsIcon from "../../images/landing/stars-icon.png";
import mapMarkerIcon from "../../images/landing/map-marker-icon.png";
import clockIcon from "../../images/landing/clock-icon.png";
import mailIcon from "../../images/landing/mail-icon.png";
import facebookLogo from "../../images/landing/facebook-logo.png";
import instagramLogo from "../../images/landing/instagram-logo.png";
import whatsappLogo from "../../images/landing/whatsapp-logo.png";
import deliveritLogo from "../../images/deliverit-logo.png";
import appStoreLogo from "../../images/landing/app-store-logo.png";
import playStoreLogo from "../../images/landing/play-store-logo.png";

import deliveryHowGif from "../../images/gifs/deliverit-how.gif";
import deliveryProofGif from "../../images/gifs/delivery-proof.gif";
import deliveritExtraGif from "../../images/gifs/deliverit-extra.gif";
import deliverEverywhereGif from "../../images/gifs/deliver-everywhere.gif";

const servicesData = [
  {
    icon: businessServicesIcon,
    title: "Business Service",
    description: ["Complete and reliable delivery solutions for your company.", "Full responsibility for all deliveries.", "Covers corporate goods, outlet groceries, and event goods."]
  },
  {
    icon: personalServicesIcon,
    title: "Personal Service",
    description: ["Personal service for safe delivery of sensitive items.", "Guaranteed delivery within a specific timeframe.", "Trusted handling of gift deliveries, document deliveries, and parcel deliveries.", "Emphasis on care and efficiency."]
  },
  {
    icon: d2dServicesIcon,
    title: "Customer Service",
    description: ["Customer service managed by a team of professionals.", "Dedicated to providing personalized support throughout the delivery process.", "Prompt response to inquiries within 5 minutes or less.", "Provides proof of delivery for peace of mind."]
  },
]

const testimonialData = [
  {
    name: "Ed Tan",
    review: "Lower deliver fees, responsive, customer service and also trusted!"
  },
  {
    name: "Nur Iman",
    review: "My experience with Deliver it has always been met with prompt and courteous service from your staff. I never doubt that our most important deliveries won't be handled appropriately. We look forward to utilizing Deliverit's services for years to come."
  },
  {
    name: "YumiPiPi",
    review: "喜欧他们家的服务，主要是司机也很好，后台容服也很棒，好就好在有预先系统下单通知都会安排的很好，紧急时刻他们家会是我的第一选择👍"
  },
  {
    name: "Xin Yi Chan",
    review: "Reliable and responsive delivery team that attends to my queries promptly."
  },
  {
    name: "Andra",
    review: "Great team and super reliable! Use them every time."
  }
]

export function Landing() {
  return (
    <div className={styles.container}>
      <Topbar page="Home" />

      {/* Top Banner */}
      <div className={styles.topBannerContainer}>
        <div className={styles.copywriteContainer}>
          <p className={styles.header} style={{ fontWeight: "bold" }}>DeliverIt</p>
          <p className={styles.header}>Your reliable delivery partner</p>
          <p className={styles.description}>We deliver your products safely to your destination in a reasonable time.</p>
        </div>
        <img className={styles.image} src={topBannerImage} alt="topbanner" />
      </div>


      {/* Services Banner */}
      <div className={styles.servicesBannerContainer}>
        <div className={styles.row} style={{ justifyContent: "center" }}>
          <p className={styles.title} style={{ color: "black" }}>We can</p>
          <p className={styles.title}>&nbsp; Deliver It &nbsp;</p>
          <p className={styles.title} style={{ color: "black" }}>for you</p>
        </div>
        <div className={styles.servicesContainer}>
          {
            servicesData.map((service, index) => {
              return (
                <div className={styles.serviceContainer} key={index}>
                  <img className={styles.serviceIcon} src={service.icon} alt="serviceIcon" />
                  <p className={styles.serviceTitle}>{service.title}</p>
                  {
                    service.description.map((points) => {
                      return (
                        <div className={styles.serviceRow}>
                          <i className="fas fa-circle" style={{ marginTop: 12, marginRight: 12, color: "#227c97", fontSize: 5 }} />
                          <p className={styles.serviceDescription} style={{ marginBottom: 5 }}>{points}</p>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>

      {/* GIF Banner */}
      <div className={styles.gifBannerContainer}>
        <img className={styles.gifImage} src={deliveryHowGif} alt="banner" />
        <img className={styles.gifImage} src={deliveryProofGif} alt="banner" />
        <img className={styles.gifImage} src={deliveritExtraGif} alt="banner" />
      </div>

      {/* Client Banner */}
      <div className={styles.clientBannerContainer}>
        <div style={{ marginInline: 20 }}>
          <img className={styles.clientLogo} src={beepLogo} onClick={() => window.open("http://bit.ly/3m7LIB9")} alt="clientlogo" />
          <img className={styles.clientLogo} src={tinyTemptressLogo} onClick={() => window.open("http://bit.ly/3IYsvLo")} alt="clientlogo" />
          <img className={styles.clientLogo} src={craftenerLogo} onClick={() => window.open("http://bit.ly/3IwZ8OQ")} alt="clientlogo" />
          <img className={styles.clientLogo} src={wholesomeLogo} onClick={() => window.open("http://bit.ly/41rspD8")} alt="clientlogo" />
          <img className={styles.clientLogo} src={kejuLogo} onClick={() => window.open("http://bit.ly/3Zj7ikq")} alt="clientlogo" />
          <img className={styles.clientLogo} src={dokanLogo} onClick={() => window.open("https://bit.ly/3EHwoSi")} alt="clientlogo" />
          <img className={styles.clientLogo} src={kekCoLogo} onClick={() => window.open("http://bit.ly/3KC8n2R")} alt="clientlogo" />
        </div>
      </div>

      {/* Testimonial Banner */}
      <div className={styles.testimonialBannerContainer}>
        <div className={styles.row}>
          <img className={styles.openInvertedCommaImage} src={openInvertedComma} alt="comma" />
          <div style={{ marginTop: 10, marginLeft: 40 }}>
            <p className={styles.title}>TESTIMONIAL</p>
            <p className={styles.title} style={{ color: "black" }}>Our Awesome Clients</p>
          </div>
        </div>
        <div className={styles.testimonialRow}>
          {
            testimonialData.map((testimonial, index) => {
              return (
                <div className={styles.testimonialContainer} key={index}>
                  <p className={styles.testimonialReview}>{testimonial.review}</p>
                  <div style={{ display: "flex", flexDirection: "row", marginTop: "auto" }}>
                    <img className={styles.starsIcon} src={starsIcon} alt="stars" />
                    <div style={{ marginLeft: "auto" }}>
                      <p className={styles.testimonialName}>{testimonial.name}</p>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>

      {/* Be Our Driver Banner */}
      <div className={styles.beDriverBannerContainer}>
        <p className={styles.title}>Create Impact With Us!</p>
        <p className={styles.title} style={{ color: "black" }}>Make the most of your time and earn more</p>
        <div className={styles.button} style={{ marginTop: 30, paddingInline: 100 }} onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdss4UZJKs-kEke7MrFkkPWA_-Jn5Sc1WT-Lsh-xBhECjDmfQ/viewform")}>
          <p className={styles.beOurDriverText}>Be Our Driver!</p>
        </div>

        <p className={styles.title} style={{ marginTop: 200 }}>Our Drivers Are Everywhere</p>
        <p className={styles.title} style={{ color: "black" }}>We can deliver it for you everyday</p>
        <div className={styles.contactUsRow}>
          <img className={styles.deliverEverywhereGif} src={deliverEverywhereGif} alt="deliver" />
          <div className={styles.contactContainer}>
            <p className={styles.serviceTitle} style={{ marginBottom: 10, fontWeight: "bold", textAlign: "left" }}>Contact Us</p>
            <div className={styles.row}>
              <img className={styles.contactIcon} src={mapMarkerIcon} alt="mapmarker" />
              <p className={styles.serviceDescription}>A-02-01 Pacific Place, Jalan PJU 1a/4, <br /> Ara Damansara, 47301 Petaling Jaya, Selangor</p>
            </div>
            <div className={styles.row}>
              <img className={styles.contactIcon} src={clockIcon} alt="clock" />
              <p className={styles.serviceDescription}>Monday to Sunday</p>
            </div>
            <div className={styles.row}>
              <img className={styles.contactIcon} src={mailIcon} alt="mail" />
              <p className={styles.serviceDescription}>deliveritsupport@parkitmy.com</p>
            </div>
            <div className={styles.row} style={{ marginTop: 40, marginBottom: 20 }}>
              <img className={styles.socmedLogo} src={facebookLogo} alt="facebook" onClick={() => window.open("https://www.facebook.com/deliveritbyparkit")} />
              <img className={styles.socmedLogo} src={instagramLogo} alt="instagram" onClick={() => window.open("https://www.instagram.com/deliveritmy")} />
            </div>
            <div className={styles.button} onClick={() => window.open("http://wa.me/+60129157275")}>
              <img className={styles.whatsappLogo} src={whatsappLogo} alt="whatsapp" />
              <p className={styles.buttonText} style={{ fontWeight: 500 }}>WhatsApp us at +60129157275</p>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Banner */}
      <div className={styles.bottomBannerContainer}>
        <div className={styles.deliveritContainer}>
          <img className={styles.deliveritLogo} src={deliveritLogo} alt="deliveritlogo" />
          <p className={styles.serviceDescription} style={{ marginTop: 10, fontSize: 11 }}>A platform that provides professional delivery services by connecting users with drivers.</p>
        </div>
        <div className={styles.downloadContainer}>
          <img className={styles.storeLogo} src={appStoreLogo} alt="appstore" onClick={() => window.open("https://apps.apple.com/my/app/deliverit-malaysia/id1584330080")} />
          <img className={styles.storeLogo} src={playStoreLogo} alt="playstore" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.deliveritmy.app")} />
        </div>
      </div>
    </div>
  );
}
