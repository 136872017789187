import axiosApiInstance from "../../app/axios";
import { API_BASE_URL, getJwtToken } from "../../app/utils";

export const getUserWallet = async (userId) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets?userId=${userId}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const createUserWallet = async (data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const topUpWallet = async (walletId, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/top-up`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getWalletPayments = async (walletId, page, size, status) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/payments?page=${page}&size=${size}&status=${status}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getWalletTransactions = async (walletId, page, size, startDate, endDate) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/transactions?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getPaymentObject = async (walletId, paymentId) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/payments/${paymentId}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getCreditCards = async (walletId) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/stripe/credit-cards`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getSetupCardSession = async (walletId, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/stripe/setup-card`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const manageBilling = async (walletId, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/stripe/billing-portal`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );