import React, { useState } from 'react';
import styles from './OrderList.module.css';
import { useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";

import { closeDownloadOrderGroupModal, handleDownloadOrderGroups } from '../order_group/orderGroupSlice';

export const DownloadReportModal = (props) => {
    const [reportMonthYear, setReportMonthYear] = useState(new Date());
    const dispatch = useDispatch();

    const downloadReport = () => {
        let year = reportMonthYear.getFullYear();
        let month = reportMonthYear.getMonth() + 1;

        dispatch(handleDownloadOrderGroups(props.orderGroupType, month, year, props.isUnpaid));
    }

    return (
        <>
            <div className={styles.downloadReportModal}>
                <p className={styles.blackText} style={{ marginBottom: 12, fontSize: 16, textAlign: "left" }}>Download {props.orderGroupType.substring(0, 1)}{props.orderGroupType.substring(1).toLowerCase()} Order Group Report</p>

                <DatePicker
                    selected={reportMonthYear}
                    onChange={date => setReportMonthYear(date)}
                    showMonthYearPicker
                    dateFormat="MMMM, yyyy"
                    className={styles.textInput}
                    placeholderText={"Select Report Month"}
                />

                <div className={styles.buttonsContainer} style={{ marginLeft: 0, marginTop: 55, justifyContent: "center" }}>
                    <div className={styles.whiteButton} style={{ width: 100, paddingBlock: 12 }} onClick={() => dispatch(closeDownloadOrderGroupModal(false))}>
                        <p className={styles.tealText}>Cancel</p>
                    </div>
                    <div className={styles.modalTealButton} style={{ background: "linear-gradient(to right, #45b0b6, #227c97", width: 100, paddingBlock: 12 }} onClick={() => downloadReport()}>
                        <p className={styles.whiteText}>Download</p>
                    </div>
                </div>

            </div>
            <div className={styles.overlay} onClick={() => dispatch(closeDownloadOrderGroupModal(false))}></div>
        </>
    )
}