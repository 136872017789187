import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import { getNext15MinTimeBlock, sleep } from '../../app/utils';
import { getUserAddresses } from '../address/addressAPI';
import { createOrder } from '../order/orderAPI';
import { createOrderGroup, getOrderGroupReport, getUnpaidOrderGroupReport, getUserOrderGroups, getUserUnpaidOrderGroups } from './orderGroupAPI';

export const orderGroupSlice = createSlice({
  name: 'orderGroup',
  initialState: {
    orderGroups: [],
    status: 'idle',
    createOrderGroupModalOpen: false,
    editOrderGroupModalOpen: false,
    deleteOrderGroupModalOpen: false,
    downloadOrderGroupModalOpen: false,
    bookmarkAddressModalOpen: false,
    bookmarkedAddresses: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    selectedIds: [],
    isLoading: false,
  },
  reducers: {
    openCreateOrderGroupModal: (state) => {
      state.createOrderGroupModalOpen = true;
    },
    closeCreateOrderGroupModal: (state) => {
      state.createOrderGroupModalOpen = false;
    },
    openEditOrderGroupModal: (state) => {
      state.editOrderGroupModalOpen = true;
    },
    closeEditOrderGroupModal: (state) => {
      state.editOrderGroupModalOpen = false;
    },
    openDeleteOrderGroupModal: (state) => {
      state.deleteOrderGroupModalOpen = true;
    },
    closeDeleteOrderGroupModal: (state) => {
      state.deleteOrderGroupModalOpen = false;
      state.selectedId = null;
    },
    openDownloadOrderGroupModal: (state) => {
      state.downloadOrderGroupModalOpen = true;
    },
    closeDownloadOrderGroupModal: (state) => {
      state.downloadOrderGroupModalOpen = false;
    },
    openBookmarkAddressModal: (state) => {
      state.bookmarkAddressModalOpen = true;
    },
    closeBookmarkAddressModal: (state) => {
      state.bookmarkAddressModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getBookmarkedAddresses: (state, action) => {
      state.bookmarkedAddresses = [...action.payload];
    },
    getOrderGroups: (state, action) => {
      state.orderGroups = action.payload.map(o => o.pickUpDateTime ? {...o, pickUpDateTime: new Date(o.pickUpDateTime).toString()} : o);
      state.status = 'success'
    },
  },
});

export const { openCreateOrderGroupModal, closeCreateOrderGroupModal, openEditOrderGroupModal, closeEditOrderGroupModal, openDeleteOrderGroupModal, closeDeleteOrderGroupModal, openDownloadOrderGroupModal, closeDownloadOrderGroupModal, openBookmarkAddressModal, closeBookmarkAddressModal, setSelectedId, setSelectedIds, setPage, setRowsPerPage, setPagination, setIsLoading, getOrderGroups, getBookmarkedAddresses } = orderGroupSlice.actions;

export const handleGetOrderGroups = (orderGroupType) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  let status = orderGroupType === 'OPEN'
    ? 'OPEN'
    : orderGroupType === 'HISTORY'
    ? 'SUCCESS,PARTIAL_SUCCESS,FAILED,CANCELLED'
    : 'CONFIRMED,IN_TRANSIT,ASSIGNED';
  
  try {
    const response = await getUserOrderGroups(localStorage.vendorId, state.orderGroup.page, state.orderGroup.rowsPerPage, status);
    
    const { content, ...pagination } = response.data;
    let orderGroups = content;

    dispatch(setPagination(pagination));
    dispatch(getOrderGroups(orderGroups));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleDownloadOrderGroups = (orderGroupType, month, year, isUnpaid) => async (dispatch, getState) => {
  dispatch(showLoading());

  let status = isUnpaid
    ? 'SUCCESS,PARTIAL_SUCCESS,CONFIRMED,IN_TRANSIT,ASSIGNED'
    : orderGroupType === 'HISTORY'
    ? 'SUCCESS,PARTIAL_SUCCESS,CANCELLED'
    : 'CONFIRMED,ASSIGNED,IN_TRANSIT'
  try {
    const response = isUnpaid ? await getUnpaidOrderGroupReport(localStorage.vendorId, month, year, status) : await getOrderGroupReport(localStorage.vendorId, month, year, status);
    
    let report = await response.data;
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement('a');
    link.href = url;
    // link.setAttribute('download', `${orderGroupType}-ordergroup-report.csv`);
    link.setAttribute('download', `ordergroup-report.csv`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetUnpaidOrderGroups = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  let status = 'SUCCESS,PARTIAL_SUCCESS,CONFIRMED,IN_TRANSIT,ASSIGNED';
  try {
    const response = await getUserUnpaidOrderGroups(localStorage.vendorId, state.orderGroup.page, state.orderGroup.rowsPerPage, status);
    
    const { content, ...pagination } = response.data;
    let orderGroups = content;

    dispatch(setPagination(pagination));
    dispatch(getOrderGroups(orderGroups));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetBookmarkedAddresses = () => async (dispatch) => {
  try {
    const response = await getUserAddresses(localStorage.vendorId, "PICK_UP", 0, 500);

    dispatch(getBookmarkedAddresses(response.data.content));
  } catch (error) {
    console.log(error.message);
  }
}

export const handleReorderOrderGroup = (orderGroup) => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  const {
    deliveryMethod,
    pickUpPersonName,
    pickUpContactNumber,
    pickUpLocation,
    halal,
    user,
  } = orderGroup;

  // Convert Datetime to UTC
  let orderGroupPayload = {
    deliveryMethod,
    pickUpPersonName,
    pickUpContactNumber,
    pickUpLocation,
    halal,
    user,
    pickUpDateTime: getNext15MinTimeBlock(new Date(), 2)[1].toISOString()
  }

  try {
    const orderGroupResponse = await createOrderGroup(orderGroupPayload);
    const orderGroupId = orderGroupResponse.data.id;
    for (const [index, order] of orderGroup.orders.entries()) {
      await sleep(2000 * index);
      console.log(index);
      const {
        ordinal,
        recipientName,
        recipientEmail,
        recipientPhone,
        dropOffLocation,
        noteToDriver,
        product,
        codAmount,
        doorToDoor,
        deliveryRange,
        receiptNumber
      } = order;
      let orderPayload = {
        ordinal,
        recipientName,
        recipientEmail,
        recipientPhone,
        dropOffLocation,
        noteToDriver,
        product,
        codAmount,
        doorToDoor,
        deliveryRange,
        receiptNumber,
        deliverDateTime: getNext15MinTimeBlock(new Date(), 4)[3].toISOString()
      }

      await createOrder(orderGroupId, orderPayload);
    }

    window.location = `/app/order-groups/${orderGroupId}`;
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(setIsLoading(false));
  }
}


export const selectState = state => state.orderGroup;

export default orderGroupSlice.reducer;
