import React, { useEffect, useRef, createRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import Spinner from "../../components/common/Spinner"
import { closeImportOrderModal, handleGetOrdersByOrderGroup } from './orderSlice';
import Geocoder from 'react-native-geocoding';
import { CSVReader } from 'react-papaparse';
import { Close } from "@material-ui/icons";
import styles from './Order.module.css';
import "react-datepicker/dist/react-datepicker.css";
import GetAppIcon from '@material-ui/icons/GetApp';
import Moment from 'moment';
import { createOrder } from './orderAPI';
import { dollarToCent, phoneUtil } from '../../app/utils';

const buttonRef = createRef();

export function ImportOrder(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    orders: [],
    geocoderInit: false,
    error: false,
    errorMessage: "",
    isSubmitDisabled: true
  };
  const [orderState, _setOrderState] = useStateWithCallbackLazy({
    ...initialState
  });
  const orderStateRef = useRef(orderState);

  const setOrderState = data => {
    orderStateRef.current = data;
    _setOrderState(data);
  };

  useEffect(() => {
    if (!orderState.geocoderInit) {
      Geocoder.init("AIzaSyAqnJ6BHC7MyO0pjruTLr4r9K5F6eww4Gg", {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my", "sg"] }
      });
      setOrderState({
        ...orderState,
        geocoderInit: true,
      });
    }
  });

  const geoCodeAddress = async (address) => {
    try {
      const json = await Geocoder.from(address);
      var location = json.results[0].geometry.location;
      return { latitude: location.lat, longitude: location.lng };
    } catch (err) {
      console.log(err);
      alert("Error while geocoding address: " + address);
      return { latitude: "", longitude: "" }
    }
  }

  const validateOrders = (orders) => {
    if (orders.length <= 0) {
      alert("No orders found in the CSV");
      return false;
    }

    if (orders.filter(o => o.errors.length > 0).length > 0) {
      alert("Error while reading CSV, please re-export CSV and try again.");
      return false;
    }

    return true;
  }

  const handleOpenFileUpload = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }

  const handleOnFileLoad = async (data) => {
    // Remove CSV Header
    let dataRows = data.slice(1);
    if (validateOrders(dataRows)) {
      let orders = [];
      // Filter empty value rows
      let filteredDataRow = dataRows.filter(r => r.data.filter(v => v !== '' && v !== null).length >= 6);
      setIsLoading(true);

      for (const [i, row] of filteredDataRow.entries()) {
        if (row.data.length > 1) {
          let order = {
            ordinal: i,
            recipientName: row.data[0],
            recipientEmail: "",
            deliverDateTime: new Date(row.data[5]),
            recipientPhone: row.data[1],
            dropOffLocation: {
              unitNumber: row.data[3],
              address: row.data[4],
              coordinate: await geoCodeAddress(row.data[4]),
              city: row.data[7],
              country: row.data[8]
            },
            product: row.data[2],
            codAmount: row.data[6],
            deliveryRange: "",
            noteToDriver: row.data[9],
            doorToDoor: (row.data[10].trim().toLowerCase() === 'true' || row.data[10].trim().toLowerCase() === 'yes') ? true : false
          }
          orders.push(order);
        }
      }
      setOrderState({ ...orderState, orders: orders, isSubmitDisabled: false });
      setIsLoading(false);
    }
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log('handleOnError', err, reason)
  }

  const handleOnRemoveFile = (data) => {
    setOrderState({...orderState, orders: [], isSubmitDisabled: true});
  }

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  }

  const calculateDistanceAndSubmit = async () => {
    setIsLoading(true);
    let sortedOrders = [...orderState.orders].sort((a, b) => a.ordinal - b.ordinal);
    if (sortedOrders.length > 0) {
      sortedOrders.reduce(async (previousOrder, order, i) => {
        // Wait for the previous order to finish processing
        await previousOrder;
        
        try {
          // Convert Datetime to UTC
          let orderPayload = {
            ...order,
            deliverDateTime: order.deliverDateTime.toISOString(),
            codAmount: order.codAmount ? Number(dollarToCent(order.codAmount)) : ""
          }
  
          await createOrder(props.orderGroup.id, orderPayload);
          if (i === sortedOrders.length - 1) {
            setIsLoading(false);
            dispatch(closeImportOrderModal());
            dispatch(handleGetOrdersByOrderGroup(props.orderGroup.id));
          }
        } catch (error) {
          setOrderState({...orderState, error: true, errorMessage: error.message});
        }
      }, Promise.resolve());
    }
  }

  const submit = async () => {
    let error = false;
    
    for (let order of orderState.orders) {
      let orderRecipientName = order.recipientName

      if (!Moment(order.deliverDateTime, "MM/DD/YYYY HH:MM", true).isValid()) {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Invalid delivery date time for " + orderRecipientName + ", please follow format MM/DD/YYYY HH:MM"});
        return
      }
      if (new Date(order.deliverDateTime) - new Date() < 0) {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Delivery date is in the past for " + orderRecipientName});
        return;
      }
      if (new Date(order.deliverDateTime) - new Date(props.orderGroup.pickUpDateTime) < 0) {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Delivery date is earlier than pickup date for " + orderRecipientName});
        return;
      }
      for (var key in order) {
        if (key !== "recipientEmail" && key !== "ordinal" && key !== "codAmount" && key !== "deliveryRange" && key !== "assigned" && key !== "doorToDoor" && key !== "noteToDriver") {
          if (order[key] === null || order[key] === "") {
            error = true;
            setOrderState({...orderState, error: true, errorMessage: "Some mandatory fields has empty value for " + orderRecipientName});
            return;
          }
        }
      }
      if (order.dropOffLocation.city === null || order.dropOffLocation.city === "" || order.dropOffLocation.country === null || order.dropOffLocation.country === "") {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Missing city/country, please download new CSV template for reference for " + orderRecipientName});
        return;
      }
      // make sure phone number start with + sign
      if (!order.recipientPhone.startsWith("+")) {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Phone number must start with + sign and country code for " + orderRecipientName});
        return;
      }
      // make sure phone number not dash or space
      if (order.recipientPhone.includes("-") || order.recipientPhone.includes(" ")) {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Please remove any dash (-) and space in phone number for " + orderRecipientName});
        return;
      }
      // validate phone number using google lib
      try {
        let phoneNumberProto = phoneUtil.parse(order.recipientPhone, "")
        if (!phoneUtil.isValidNumber(phoneNumberProto)) {
          error = true;
          setOrderState({...orderState, error: true, errorMessage: "Invalid phone number for " + orderRecipientName});
          return;
        }
      } catch (e) {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Invalid phone number for " + orderRecipientName});
        return;
      }
    }
    
    if (!error) {
      calculateDistanceAndSubmit();
    }
  }

  return (
    <>
      <Modal
        open={props.importOrderModalOpen}
        aria-labelledby="add-order-modal"
        className={styles.modal}
      >
        <Paper className={styles.modalContainer}>
          <div className={styles.container}>
            <Close className={styles.closeButton} onClick={() => dispatch(closeImportOrderModal())}/>
            <h3 className={styles.subtitle}>Import Bulk Orders From CSV</h3>
            <Fade in={orderState.error}>
              <p className={styles.errorMessage}>{orderState.errorMessage}</p>
            </Fade>
            <Grid container spacing={5}>
              <a className={styles.downloadLink} href="https://parkitstatic.s3.ap-southeast-1.amazonaws.com/deliverit/DeliverIt+Bulk+Order+Upload+2.csv" download><GetAppIcon className={styles.downloadLinkIcon} /> Click here to download Bulk Order CSV Template</a>
              <Grid item xs={12}>
                <CSVReader
                  ref={buttonRef}
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  noDrag
                  onRemoveFile={handleOnRemoveFile}
                >
                  {({ file }) => (
                    <aside className={styles.fileUploadContainer}>
                      <button type='button' onClick={handleOpenFileUpload} className={styles.fileUploadGradientButton}>Browse file</button>
                      <div className={styles.fileUploadInput}>
                        {file && file.name}
                      </div>
                      <button className={styles.fileRemoveButton} onClick={handleRemoveFile}>
                        Remove
                      </button>
                    </aside>
                  )}
                </CSVReader>
                <div className={styles.submitButtonContainer}>
                  <Button onClick={() => submit()} className={styles.gradientButton} disabled={orderState.isSubmitDisabled}>Submit</Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
      {
        isLoading === true &&
        <Spinner/>
      }
    </>
  );
}
