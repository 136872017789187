import axios from "axios";
import axiosApiInstance from "../../app/axios";
import { API_BASE_URL, getJwtToken } from "../../app/utils";

export const getUser = async (id) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/user-store/v1/users/${id}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const forgotPassword = async (data) =>
  axios.post(
    `${API_BASE_URL}/user-store/v1/users/forgot-password`,
    data
  );

export const resetPassword = async (id, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/user-store/v1/users/${id}/change-password`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );