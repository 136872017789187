import { createSlice } from "@reduxjs/toolkit";
import Moment from 'moment';
import { getUserOrderGroups, getUserOrderGroupsBetweenDates } from "../place_order/orderGroupAPI";

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        recentOrders: [],
        orderPerformanceType: 'daily',
        orderPerformance: [],
        timeliness: {
            ontime: { percentage: 0, count: 0 },
            delay15: { percentage: 0, count: 0 },
            delay30: { percentage: 0, count: 0 },
            delay30More: { percentage: 0, count: 0 }
        },
        showVoucherModal: false,
        showTopUpModal: false,
    },
    reducers: {
        setRecentOrders: (state, action) => {
            state.recentOrders = action.payload
        },
        setOrderPerformanceType: (state, action) => {
            state.orderPerformanceType = action.payload;
            state.status = 'success'
        },
        setOrderPerformance: (state, action) => {
            state.orderPerformance = action.payload;
            state.status = 'success'
        },
        setTimeliness: (state, action) => {
            state.timeliness = action.payload;
            state.status = 'success'
        },
        setShowVoucherModal: (state, action) => {
            state.showVoucherModal = action.payload;
        },
        setShowTopUpModal: (state, action) => {
            state.showTopUpModal = action.payload;
        }
    }
});

export const { setRecentOrders, setOrderPerformanceType, setOrderPerformance, setTimeliness, setShowVoucherModal, setShowTopUpModal } = dashboardSlice.actions;

export const getRecentOrders = () => async (dispatch) => {
    try {
        const response = await getUserOrderGroups(localStorage.vendorId, 0, 10, "SUCCESS");
        dispatch(setRecentOrders(response.data.content));
    } catch (error) {
        console.log(error.message);
    }
};

export const handleGetOrderPerformance = (orderPerformanceType, startDate, endDate) => async (dispatch, getState) => {
    let status = 'SUCCESS,PARTIAL_SUCCESS,FAILED';

    const start = Moment(startDate).format('YYYY-MM-DD');
    const end = Moment(endDate).format('YYYY-MM-DD');

    try {
        let params = `vendorId=${localStorage.vendorId}&startDate=${start}&endDate=${end}&status=${status}`;
        const response = await getUserOrderGroupsBetweenDates(params);

        // add empty object at the first of array so we can ignore first object in reduce() and having initial aggregate object 
        response.data.unshift({ statsPerDay: {}, onTimeCount: 0, delay15Count: 0, delay30Count: 0, delay30MoreCount: 0, totalCount: 0 })
        // extract order count per day & timeliness count
        let stats = response.data.reduce((result, x) => {
            x.orders.forEach(element => {
                let orderDate
                if (orderPerformanceType === 'daily') {
                    orderDate = Moment(element.deliverDateTime).format('D MMM')
                } else {
                    let orderStartDate = Moment(element.deliverDateTime).startOf('week').format('D MMM')
                    let orderEndDate = Moment(element.deliverDateTime).endOf('week').format('D MMM')
                    orderDate = orderStartDate + "-" + orderEndDate
                }

                if (result.statsPerDay[orderDate] === undefined) {
                    result.statsPerDay[orderDate] = { successOrders: 0, failOrders: 0 }
                }
                if (element.status === 'SUCCESS') {
                    result.statsPerDay[orderDate].successOrders++
                } else if (element.status === 'FAILED') {
                    result.statsPerDay[orderDate].failOrders++
                }

                let msDiff = new Date(element.completionTime).getTime() - new Date(element.deliverDateTime).getTime()
                if (msDiff === NaN || msDiff <= 0) {
                    result.onTimeCount++
                } else if (msDiff <= 900000) { // 15 min
                    result.delay15Count++
                } else if (msDiff <= 1800000) { // 30 min
                    result.delay15Count++
                } else {
                    result.delay30MoreCount++
                }
                result.totalCount++
            });
            return result
        })
        // fill up days which zero orders
        // Array(numOfDays).fill(0).forEach((_, i) => {
        //   let orderDate = new Date(startDate)
        //   orderDate.setDate(orderDate.getDate() + i)
        //   orderDate = Moment(orderDate).format('YYYY/MM/DD')
        //   if (stats.statsPerDay[orderDate] === undefined) {
        //     stats.statsPerDay[orderDate] = {successOrders: 0, failOrders: 0}
        //   }
        // });
        // transform object to array
        let orderPerformance = Object.keys(stats.statsPerDay).map(k => {
            return { orderDate: k, successOrders: stats.statsPerDay[k].successOrders, failOrders: stats.statsPerDay[k].failOrders }
        })
        // build timeliness data
        let timeliness = {
            ontime: {
                percentage: Math.round(stats.onTimeCount / stats.totalCount * 100),
                count: stats.onTimeCount
            },
            delay15: {
                percentage: Math.round(stats.delay15Count / stats.totalCount * 100),
                count: stats.delay15Count
            },
            delay30: {
                percentage: Math.round(stats.delay30Count / stats.totalCount * 100),
                count: stats.delay30Count
            },
            delay30More: {
                percentage: Math.round(stats.delay30MoreCount / stats.totalCount * 100),
                count: stats.delay30MoreCount
            }
        }
        dispatch(setOrderPerformanceType(orderPerformanceType))
        dispatch(setOrderPerformance(orderPerformance))
        dispatch(setTimeliness(timeliness))
    } catch (error) {
        console.log(error.message);
    }
};

export const dashboardState = state => state.dashboard;

export default dashboardSlice.reducer;