/*global google*/

import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './PlaceOrder.module.css';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, setHours } from "date-fns";

import { InfoTooltip, TealSwitch } from './common';
import { OrderGroupContext } from './PlaceOrder';
import { filterPassedTime, getPlaceLongName, handleGetOrdersByOrderGroup, ordersState, setDisableUpdateOrderGroup, setOrderGroup, setShowSavedPickUpAddrModal } from './placeOrderSlice';
import { createOrderGroup, updateOrderGroup } from './orderGroupAPI';

import bikeIcon from "../../images/place_order/bike-icon.png";
import carIcon from "../../images/place_order/car-icon.png";
import clockIcon from "../../images/place_order/clock-icon.png";
import halalIcon from "../../images/place_order/halal-icon.png";
import locationPinIcon from "../../images/place_order/location-pin-icon.png";
import targetIcon from "../../images/place_order/target-icon.png";
import savedAddressIcon from "../../images/place_order/saved-address-icon.png";

export function OrderGroup(props) {
  const dispatch = useDispatch();
  const orderListState = useSelector(ordersState);
  const orderGroupContext = useContext(OrderGroupContext);

  const { id } = useParams();
  const { handlePickUpLocation, handlePickUpDateTime, handleError, handleErrorMsg } = props;

  const [pickUpForm, setPickUpForm] = useState({
    deliveryMethod: "MOTOR",
    pickUpPersonName: "",
    pickUpContactNumber: "",
    pickUpDateTime: "",
    pickUpLocation: {
      unitNumber: "",
      address: "",
      coordinate: {
        longitude: "",
        latitude: ""
      },
      city: "",
      country: ""
    },
    halal: false,
  });
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null
  });
  const [locationEnabled, setLocationEnabled] = useState(true);
  const [autocompleteInit, setAutocompleteInit] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setPickUpForm(prevState => ({
      ...prevState,
      pickUpPersonName: orderGroupContext.pickUpForm.pickUpPersonName,
      pickUpContactNumber: orderGroupContext.pickUpForm.pickUpContactNumber,
      pickUpLocation: {
        ...prevState.pickUpLocation,
        ...orderGroupContext.pickUpForm.pickUpLocation,
      }
    }));

    if (
      orderListState.orderGroup.id !== null && (
        pickUpForm.pickUpPersonName !== orderGroupContext.pickUpForm.pickUpPersonName ||
        pickUpForm.pickUpContactNumber !== orderGroupContext.pickUpForm.pickUpContactNumber ||
        JSON.stringify(pickUpForm.pickUpLocation) !== JSON.stringify(orderGroupContext.pickUpForm.pickUpLocation)
      )
    ) {
      setFormSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderGroupContext.pickUpForm]);

  const handleUseCurrentLocation = () => {
    if (!locationEnabled) {
      handleError(true);
      handleErrorMsg("Please enable your location services to retrieve your current location.");
      return;
    }

    if (currentLocation.lat == null) {
      handleError(true);
      handleErrorMsg("Retrieving your current location... Please try again after 10 seconds.");
      return;
    }

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: currentLocation }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          let autocompleteInput = document.getElementById("pickUpAutocomplete");
          autocompleteInput.value = results[0].formatted_address;

          const location = {
            address: `${results[0].formatted_address}`,
            coordinate: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            },
            street: getPlaceLongName(results[0].address_components, "street_address"),
            apartment: getPlaceLongName(results[0].address_components, "street_address"),
            state: getPlaceLongName(results[0].address_components, "administrative_area_level_1"),
            postalCode: getPlaceLongName(results[0].address_components, "postal_code"),
            city: getPlaceLongName(results[0].address_components, "locality"),
            country: getPlaceLongName(results[0].address_components, "country")
          };

          setPickUpForm(prevPickUpForm => ({
            ...prevPickUpForm,
            pickUpLocation: {
              ...prevPickUpForm.pickUpLocation,
              ...location
            }
          }));

          handleError(false);
          handleErrorMsg('');

          handlePickUpLocation(location);
        } else {
          handleError(true);
          handleErrorMsg("Error retrieving your current location.");
        }
      } else {
        handleError(true);
        handleErrorMsg("Error retrieving your current location.");
      }
    });
  };

  const onPlaceChanged = useCallback((autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === "pickUpAutocomplete") {
        try {
          const location = {
            address: `${place.name}, ${place.formatted_address}`,
            coordinate: {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            },
            street: getPlaceLongName(place.address_components, "street_address"),
            apartment: getPlaceLongName(place.address_components, "street_address"),
            state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
            postalCode: getPlaceLongName(place.address_components, "postal_code"),
            city: getPlaceLongName(place.address_components, "locality"),
            country: getPlaceLongName(place.address_components, "country")
          };

          setPickUpForm(prevPickUpForm => ({
            ...prevPickUpForm,
            pickUpLocation: {
              ...prevPickUpForm.pickUpLocation,
              ...location
            }
          }));

          handlePickUpLocation(location);
          if (orderListState.orderGroup.orders && orderListState.orderGroup.orders.length > 0) {
            setFormSubmitted(false)
          }
        } catch (error) {
          handleError(true);
          handleErrorMsg("Please select a pick-up address from the drop-down list.");
        }
      }
    }
  }, [handlePickUpLocation, handleError, handleErrorMsg, orderListState]);

  const initializeGoogleAutocomplete = useCallback((elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my", "sg"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function () {
      onPlaceChanged(autocomplete, elementId);
    });
  }, [onPlaceChanged]);

  const validatePickUpDateTime = useCallback((date) => {
    if ((date.getHours() === 0) || (new Date(date) - new Date() < 0)) {
      const currDate = new Date();
      const currHour = currDate.getHours();
      const currMin = currDate.getMinutes();

      if (currMin < 15) {
        date.setMinutes(15);
      } else if (currMin < 30) {
        date.setMinutes(30);
      } else if (currMin < 45) {
        date.setMinutes(45);
      } else {
        date.setMinutes(0);
        date.setHours(currHour + 1);
      }
      date.setHours(currHour);
    }

    let error = false;
    if (new Date(date) - new Date() < 0) {
      error = true;
      handleError(true);
      handleErrorMsg("Pick-up date should not be in the past.");
      return error;
    }

    if (!error) {
      setPickUpForm((prevPickUpForm) => ({
        ...prevPickUpForm,
        pickUpDateTime: date
      }));
      handlePickUpDateTime(date);
    }
  }, [handlePickUpDateTime, handleError, handleErrorMsg,]);

  const validateFields = useCallback(() => {
    let error = false;

    if (pickUpForm.pickUpLocation.country === null || pickUpForm.pickUpLocation.country === "") {
      error = true;
      handleError(true);
      handleErrorMsg("Please re-select the pick-up address from drop-down list.");
      return error;
    }

    for (var key in pickUpForm) {
      if (key !== "halal" && key !== "noteToDriver") {
        if (pickUpForm[key] === null || pickUpForm[key] === "") {
          error = true;
          handleError(true);
          handleErrorMsg("Please complete all the fields.");
          return error;
        }
      }
    }

    return error;
  }, [pickUpForm, handleError, handleErrorMsg]);

  const submitOrderGroup = useCallback(async () => {
    let error = validateFields();
    if (error) { return; }

    error = validatePickUpDateTime(pickUpForm.pickUpDateTime);

    // validate all delivery dates are later than pick-up dates
    if (new Date(orderGroupContext.dropOffForm.deliverDateTime) - new Date(pickUpForm.pickUpDateTime) < 0) {
      error = true;
      handleError(true);
      handleErrorMsg("Pick-up date should not be later than delivery date(s).");
      return;
    }

    if (orderListState.orderGroup.orders && orderListState.orderGroup.orders.length > 0) {
      orderListState.orderGroup.orders.forEach((order) => {
        if (new Date(order.deliverDateTime) - new Date(pickUpForm.pickUpDateTime) < 0) {
          error = true;
          handleError(true);
          handleErrorMsg("Pick-up date should not be later than delivery date(s).");
          return;
        }
      });
    }

    if (!error) {
      try {
        if (orderListState.orderGroup.id === null) {
          // Create order group
          let orderGroupPayload = {
            ...pickUpForm,
            pickUpDateTime: new Date(pickUpForm.pickUpDateTime).toISOString()
          }

          const response = await createOrderGroup(orderGroupPayload);
          dispatch(setOrderGroup(response.data));
          handleError(false);
          handleErrorMsg('');
          window.location = `/app/order-groups/${response.data.id}`;
        } else if (!orderListState.disableUpdateOrderGroup) {
          dispatch(setDisableUpdateOrderGroup(true));

          // Update order group
          let orderGroupPayload = {
            ...pickUpForm,
            pickUpDateTime: new Date(pickUpForm.pickUpDateTime).toISOString(),
            orders: [
              ...orderListState.orderGroup.orders.map(o => o.deliverDateTime ? { ...o, deliverDateTime: new Date(o.deliverDateTime).toISOString() } : o)
            ]
          }

          await updateOrderGroup(orderGroupPayload, orderListState.orderGroup.id);
          dispatch(setDisableUpdateOrderGroup(false));

          dispatch(handleGetOrdersByOrderGroup(orderListState.orderGroup.id));
          handleError(false);
          handleErrorMsg('');
        }
      } catch (error) {
        handleError(true);
        handleErrorMsg(error.message);
      }
    }
  }, [dispatch, orderListState, pickUpForm, validateFields, validatePickUpDateTime, handleError, handleErrorMsg, orderGroupContext.dropOffForm.deliverDateTime]);

  const onFormValueChange = useCallback((data) => {
    setPickUpForm((prevPickUpForm) => ({
      ...prevPickUpForm,
      [data.name]: data.value
    }));

    if (orderListState.orderGroup.id !== null) {
      setFormSubmitted(false);
    }
  }, [orderListState]);

  useEffect(() => {
    if (id && orderListState.orderGroup.id !== null) {
      setPickUpForm({
        ...pickUpForm,
        deliveryMethod: orderListState.orderGroup.deliveryMethod,
        pickUpDateTime: orderListState.orderGroup.pickUpDateTime,
        pickUpLocation: orderListState.orderGroup.pickUpLocation,
        halal: orderListState.orderGroup.halal
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orderListState]);

  useEffect(() => {
    let pickUpAutocompleteInput = document.getElementById("pickUpAutocomplete");
    if (
      (!id || (id && orderListState?.orderGroup?.id)) &&
      !autocompleteInit &&
      pickUpAutocompleteInput !== null
    ) {
      initializeGoogleAutocomplete("pickUpAutocomplete");
      setAutocompleteInit(true);
    }
  }, [initializeGoogleAutocomplete, id, autocompleteInit, orderListState]);

  useEffect(() => {
    if (
      !formSubmitted
      && pickUpForm.pickUpPersonName !== ""
      && pickUpForm.pickUpContactNumber !== ""
      && pickUpForm.pickUpDateTime !== ""
      && pickUpForm.pickUpLocation.coordinate.longitude !== ""
      && pickUpForm.pickUpLocation.coordinate.latitude !== ""
    ) {
      if (
        orderListState.orderGroup.id !== null && (
          pickUpForm.pickUpPersonName !== orderGroupContext.pickUpForm.pickUpPersonName ||
          pickUpForm.pickUpContactNumber !== orderGroupContext.pickUpForm.pickUpContactNumber ||
          JSON.stringify(pickUpForm.pickUpLocation) !== JSON.stringify(orderGroupContext.pickUpForm.pickUpLocation)
        )
      ) {
        // Make sure pickupForm state is same as orderGroupContext only submit the form
        return;
      }
      setFormSubmitted(true);
      submitOrderGroup();
    }
  }, [orderListState, orderGroupContext, formSubmitted, pickUpForm, submitOrderGroup]);

  useEffect(() => {
    navigator.geolocation.watchPosition(async (position) => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setLocationEnabled(true);
    }, async () => {
      if (locationEnabled) {
        setLocationEnabled(false);
      }
    });
  });


  return (
    <>
      <div className={styles.pickUpContainer}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={styles.row} style={{ flex: 1, cursor: 'pointer' }} onClick={() => onFormValueChange({ name: "deliveryMethod", value: "MOTOR" })}>
            <img className={styles.icon} src={bikeIcon} alt="bike" />
            <p className={styles.blackText}>Bike</p>
            <Radio
              checked={pickUpForm.deliveryMethod === "MOTOR" ? true : false}
              onChange={() => onFormValueChange({ name: "deliveryMethod", value: "MOTOR" })}
              value="MOTOR"
              size="small"
              style={{ paddingBlock: 0, marginLeft: "auto", color: "#1e9299" }}
            />
          </div>
          <div className={styles.row} style={{ flex: 1, cursor: 'pointer' }} onClick={() => onFormValueChange({ name: "deliveryMethod", value: "CAR" })}>
            <div className={styles.verticalDivider} />
            <img className={styles.icon} style={{ marginLeft: 20 }} src={carIcon} alt="car" />
            <p className={styles.blackText}>Car</p>
            <Radio
              checked={pickUpForm.deliveryMethod === "CAR" ? true : false}
              onChange={() => onFormValueChange({ name: "deliveryMethod", value: "CAR" })}
              value="CAR"
              size="small"
              style={{ paddingBlock: 0, marginLeft: "auto", color: "#1e9299" }}
            />
          </div>
        </div>
        <div className={styles.horizontalDivider} />
        <div className={styles.row} style={{ paddingBlock: 0 }}>
          <img className={styles.icon} src={clockIcon} alt="clock" />
          <DatePicker
            className={styles.pickUpDatepicker}
            selected={pickUpForm.pickUpDateTime}
            onChange={date => {
              validatePickUpDateTime(date);
              if (orderListState.orderGroup.id !== null) {
                setFormSubmitted(false)
              }
            }}
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            minTime={setHours(setMinutes(new Date(), 0), 6)}
            maxTime={setHours(setMinutes(new Date(), 0), 23)}
            filterTime={filterPassedTime}
            timeIntervals={15}
            placeholderText={"Preferred pick-up time"}
          />
          <i className="fas fa-chevron-right" style={{ marginTop: 2, marginLeft: "auto", marginRight: 16, color: "#1e9299", fontSize: 15, zIndex: 1 }} />
        </div>

        <div className={styles.horizontalDivider} />
        <div className={styles.row}>
          <img className={styles.icon} src={halalIcon} alt="halal" />
          <p className={styles.blackText}>Halal</p>
          <div style={{ marginLeft: "auto", marginRight: 10 }}>
            <TealSwitch
              checked={pickUpForm.halal}
              onChange={() => onFormValueChange({ name: "halal", value: !pickUpForm.halal })}
              size="small"
            />
          </div>
        </div>
      </div>

      <div className={styles.pickUpAddressContainer}>
        <div className={styles.row} style={{ paddingBlock: 12 }}>
          <img className={styles.icon} src={locationPinIcon} alt="location-pin" />
          <input
            className={styles.autocomplete}
            id="pickUpAutocomplete"
            name="pickUpAddress"
            value={pickUpForm.pickUpLocation.address}
            onChange={(e) => setPickUpForm({
              ...pickUpForm,
              pickUpLocation: {
                ...pickUpForm.pickUpLocation,
                address: e.target.value
              }
            })}
            placeholder="Enter pick-up location"
          />
          {
            orderListState.orderGroup.id === null &&
            <InfoTooltip
              title="Click to use your current location."
              placement="top"
              arrow
            >
              <img className={styles.icon} style={{ marginLeft: "auto", cursor: "pointer" }} src={targetIcon} alt="target" onClick={() => handleUseCurrentLocation()} />
            </InfoTooltip>
          }
          {
            orderListState.orderGroup.id === null &&
            <InfoTooltip
              title="Click to view saved pick-up addresses."
              placement="top"
              arrow
            >
              <img className={styles.icon} style={{ marginLeft: "auto", cursor: "pointer" }} src={savedAddressIcon} alt="saved-addr" onClick={() => { props.closePickUpModal(); dispatch(setShowSavedPickUpAddrModal(true)); }} />
            </InfoTooltip>
          }
        </div>
        <div className={styles.descriptionRow} style={{ cursor: "pointer" }}
          onClick={() => props.showPickUpModal()}>
          {
            pickUpForm.pickUpPersonName
              ? <>
                <i className="far fa-edit" style={{ color: "#979797", fontSize: 15, marginRight: 10 }} />
                <p className={styles.greyText}>{pickUpForm.pickUpLocation.unitNumber && pickUpForm.pickUpLocation.unitNumber + "\u00a0 • \u00a0"}{pickUpForm.pickUpContactNumber}&nbsp; • &nbsp;{pickUpForm.pickUpPersonName}</p>
              </>
              : <p className={styles.greyText}>Enter details...</p>
          }
        </div>
        <div className={styles.horizontalDivider} />
      </div>
    </>
  )
}