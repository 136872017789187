/*global google*/

import { createSlice } from "@reduxjs/toolkit";
import Moment from 'moment';
import ReactPixel from 'react-facebook-pixel';

import { FACEBOOK_PIXEL_ID } from '../../app/utils';
import { confirmOrders, getOrdersByOrderGroup, updateOrder } from "./orderAPI";
import { getUserAddresses, createAddress } from "../address/addressAPI";

export const placeOrderSlice = createSlice({
  name: 'orders',
  initialState: {
    orderGroup: {
      id: null,
      nextOrdinal: 0,
      orders: [],
      currency: "RM",
      subtotal: 0,
      discountAmount: 0,
      estimatedTotal: 0
    },
    directions: [],
    savedPickUpAddrList: [],
    savedDropOffAddrList: [],
    selectedSavedPickUpAddr: "",
    selectedSavedDropOffAddr: "",
    selectedVoucher: null,
    disableUpdateOrderGroup: false,
    disableCreateOrder: false,
    disableConfirmOrderGroup: false,
    showSavedPickUpAddrModal: false,
    showSavedDropOffAddrModal: false,
    showPickUpInfoWindow: false,
    showDropOffInfoWindow: [],
    showImportModal: false,
    showVoucherModal: false,
    showPaymentModal: false,
    showTopUpModal: false,
    loading: false
  },
  reducers: {
    setOrderGroup: (state, action) => {
      state.orderGroup = action.payload;
    },
    setDirections: (state, action) => {
      state.directions = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSubtotal: (state, action) => {
      state.orderGroup = {
        ...state.orderGroup,
        subtotal: action.payload
      }
    },
    setDiscountAmount: (state, action) => {
      state.orderGroup = {
        ...state.orderGroup,
        discountAmount: action.payload
      }
    },
    setEstimatedTotal: (state, action) => {
      state.orderGroup = {
        ...state.orderGroup,
        estimatedTotal: action.payload
      }
    },
    setSavedPickUpAddrList: (state, action) => {
      state.savedPickUpAddrList = action.payload;
    },
    setSavedDropOffAddrList: (state, action) => {
      state.savedDropOffAddrList = action.payload;
    },
    setSelectedSavedPickUpAddr: (state, action) => {
      state.selectedSavedPickUpAddr = action.payload;
    },
    setSelectedSavedDropOffAddr: (state, action) => {
      state.selectedSavedDropOffAddr = action.payload;
    },
    setSelectedVoucher: (state, action) => {
      state.selectedVoucher = action.payload;
    },
    setDisableUpdateOrderGroup: (state, action) => {
      state.disableUpdateOrderGroup = action.payload;
    },
    setDisableCreateOrder: (state, action) => {
      state.disableCreateOrder = action.payload;
    },
    setDisableConfirmOrderGroup: (state, action) => {
      state.disableConfirmOrderGroup = action.payload;
    },
    setShowSavedPickUpAddrModal: (state, action) => {
      state.showSavedPickUpAddrModal = action.payload;
    },
    setShowSavedDropOffAddrModal: (state, action) => {
      state.showSavedDropOffAddrModal = action.payload;
    },
    setShowPickUpInfoWindow: (state, action) => {
      state.showPickUpInfoWindow = action.payload;
    },
    setShowDropOffInfoWindow: (state, action) => {
      let showInfoWindow = [];
      for (let i = 0; i < state.orderGroup.orders.length; i++) {
        showInfoWindow.push(false);
      }
      state.showDropOffInfoWindow = showInfoWindow;
      state.showDropOffInfoWindow[action.payload.idx] = action.payload.show;
    },
    setShowImportModal: (state, action) => {
      state.showImportModal = action.payload;
    },
    setShowVoucherModal: (state, action) => {
      state.showVoucherModal = action.payload;
    },
    setShowPaymentModal: (state, action) => {
      state.showPaymentModal = action.payload;
    },
    setShowTopUpModal: (state, action) => {
      state.showTopUpModal = action.payload;
    },
    setOrders: (state, action) => {
      state.orderGroup = {
        ...state.orderGroup,
        orders: [...action.payload]
      };
    },
    getOrders: (state, action) => {
      state.orderGroup = {
        ...action.payload,
        pickUpDateTime: (action.payload.pickUpDateTime ? new Date(action.payload.pickUpDateTime) : action.payload.pickUpDateTime),
        nextOrdinal: action.payload.orders.length === 0 ? 0 : ((action.payload.orders.sort((a, b) => b.ordinal - a.ordinal))[0].ordinal + 1),
        orders: [
          ...action.payload.orders.sort((a, b) => a.ordinal - b.ordinal).map(o => o.deliverDateTime ? { ...o, deliverDateTime: new Date(o.deliverDateTime) } : o)
        ],
        currency: (action.payload.orders && action.payload.orders.length === 0) ? "RM" : (action.payload.orders[0].currency === "MYR") ? "RM" : action.payload.orders[0].currency,
      };

      let showInfoWindow = [];
      for (let i = 0; i < action.payload.orders.length; i++) {
        showInfoWindow.push(false);
      }
      state.showDropOffInfoWindow = showInfoWindow;
      state.showPickUpInfoWindow = false;
    },
  },
});

export const { setOrderGroup, setDirections, setLoading, setSubtotal, setDiscountAmount, setEstimatedTotal, setSavedPickUpAddrList, setSavedDropOffAddrList, setSelectedSavedPickUpAddr, setSelectedSavedDropOffAddr, setSelectedVoucher, setDisableUpdateOrderGroup, setDisableCreateOrder, setDisableConfirmOrderGroup, setShowSavedPickUpAddrModal, setShowSavedDropOffAddrModal, setShowPickUpInfoWindow, setShowDropOffInfoWindow, setShowImportModal, setShowVoucherModal, setShowPaymentModal, setShowTopUpModal, setOrders, getOrders } = placeOrderSlice.actions;

export const handleGetOrdersByOrderGroup = (id) => async (dispatch, getState) => {
  dispatch(setOrders([]));
  dispatch(setLoading(true));

  try {
    const response = await getOrdersByOrderGroup(id);
    dispatch(getOrders(response.data));
    dispatch(getEstimatedTotal());
    dispatch(getDirectionRoutes());
    dispatch(setSelectedSavedPickUpAddr(""));
    dispatch(setSelectedSavedDropOffAddr(""));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(setLoading(false));
  }
}

const handleUpdateOrders = async (orderGroupId, order, newOrdinal) => {
  try {
    let orderPayload = {
      ...order,
      ordinal: newOrdinal,
      deliverDateTime: (new Date(order.deliverDateTime)).toISOString()
    }
    await updateOrder(orderGroupId, orderPayload, order.id);
  } catch (error) {
    alert(error?.response?.data?.message || error);
  }
}

export const handleOnSortOrder = (oldIndex, newIndex) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const state = getState();

  try {
    let oldSortedOrders = [...state.orders.orderGroup.orders].sort((a, b) => a.ordinal - b.ordinal);
    if (newIndex > oldIndex) {
      // order moved from top to bottom
      for (let i = oldIndex; i <= newIndex; i++) {
        let newOrdinal = i === oldIndex ? newIndex : i - 1;
        let order = oldSortedOrders[i];
        await handleUpdateOrders(state.orders.orderGroup.id, order, newOrdinal);
      }
    } else {
      // order moved from bottom to top
      for (let i = oldIndex; i >= newIndex; i--) {
        let newOrdinal = i === oldIndex ? newIndex : i + 1;
        let order = oldSortedOrders[i];
        await handleUpdateOrders(state.orders.orderGroup.id, order, newOrdinal);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(function () {
      dispatch(handleGetOrdersByOrderGroup(state.orders.orderGroup.id));
      dispatch(setLoading(false));
    }, 1000);
  }
};

export const getEstimatedTotal = () => async (dispatch, getState) => {
  const state = getState();

  let total = 0;
  state.orders.orderGroup.orders.forEach((order) => {
    total += (order.deliveryFee + order.doorToDoorFee);
  });
  dispatch(setSubtotal(total));

  if (state.orders.selectedVoucher) {
    if (state.orders.selectedVoucher.promoCode.discountPercentage) {
      const discountAmount = total * state.orders.selectedVoucher.promoCode.discountPercentage / 100;
      total -= discountAmount;
      dispatch(setDiscountAmount(discountAmount));
    } else {
      const discountAmount = (state.orders.selectedVoucher.promoCode.discountAmount < total) ? state.orders.selectedVoucher.promoCode.discountAmount : total;
      total -= discountAmount;
      dispatch(setDiscountAmount(discountAmount));
    }
  }

  dispatch(setEstimatedTotal(total));
}

const validateOrders = (orderGroup) => {
  let result = true;
  orderGroup.orders.forEach((order) => {
    let pickUpTime = Moment(orderGroup.pickUpDateTime);
    let orderLocalTime = Moment(order.deliverDateTime);
    let minimumDropOffTime = Moment().add(5, "minutes")
    if (orderLocalTime.isBefore(minimumDropOffTime) || orderLocalTime.isBefore(pickUpTime)) {
      result = false;
    }
  });

  return result;
}

export const handleConfirmOrders = (noteToDriver) => async (dispatch, getState) => {
  const state = getState();

  if (Moment(state.orders.orderGroup.pickUpDateTime).isBefore(Moment().add(5, "minutes"))) {
    alert("Pick-up date should not be in the past and within 5 minutes from now.");
    return;
  }

  let authedUser = JSON.parse(localStorage.user);
  ReactPixel.init(FACEBOOK_PIXEL_ID, { em: authedUser.userName, fn: authedUser.firstName, ln: authedUser.lastName, ph: authedUser.contactNumber }, { debug: false, autoConfig: false });
  ReactPixel.track('InitiateCheckout', {
    content_category: 'Vendor',
    content_ids: [state.orders.orderGroup.id],
    contents: [{ "user": authedUser.userName, "orderGroupId": state.orders.orderGroup.id, "type": "Confirm Order Group" }],
    currency: "MYR"
  });

  if (validateOrders(state.orders.orderGroup) === true) {
    dispatch(setDisableConfirmOrderGroup(true));
    dispatch(setLoading(true));

    try {
      let voucher = state.orders.selectedVoucher;
      let confirmOrderPayload = {
        noteToDriver: noteToDriver,
        voucherId: voucher?.id ?? null
      }

      await confirmOrders(state.orders.orderGroup.id, confirmOrderPayload);

      // auto-save addresses
      const pickUpAddr = state.orders.savedPickUpAddrList.filter(addr => addr.location.address === state.orders.orderGroup.pickUpLocation.address);
      if (pickUpAddr.length === 0) {
        const orderGroup = state.orders.orderGroup;
        const pickUpAddrPayload = {
          jobType: "PICK_UP",
          bookmarkName: orderGroup.pickUpPersonName,
          pickUpContactNumber: orderGroup.pickUpContactNumber,
          pickUpPersonName: orderGroup.pickUpPersonName,
          location: orderGroup.pickUpLocation
        }
        await createAddress(pickUpAddrPayload);
      }

      for (let i = 0; i < state.orders.orderGroup.orders.length; i++) {
        const order = state.orders.orderGroup.orders[i];
        const dropOffAddr = state.orders.savedDropOffAddrList.filter(addr => addr.location.address === order.dropOffLocation.address);
        if (dropOffAddr.length === 0) {
          const dropOffAddrPayload = {
            jobType: "DROP_OFF",
            bookmarkName: order.recipientName,
            recipientPhone: order.recipientPhone,
            recipientName: order.recipientName,
            location: order.dropOffLocation
          }
          await createAddress(dropOffAddrPayload);
        }
      }

      ReactPixel.track('Purchase', {
        content_ids: [state.orders.orderGroup.id],
        content_type: "Confirm Order Group",
        contents: [state.orders.orderGroup.orders],
        currency: "MYR",
        num_items: state.orders.orderGroup.orders.length,
        value: state.orders.orderGroup.estimatedTotal
      });

      window.location = `/app/confirmed-order-groups/${state.orders.orderGroup.id}`;
    } catch (error) {
      alert(error.message);
    } finally {
      dispatch(setLoading(false));
      dispatch(setDisableConfirmOrderGroup(false));
    }
  } else {
    alert("Your orders contain delivery datetime which is in the past. Please change them to a future date time and try again.")
  };
};

export const getAllSavedAddresses = () => async (dispatch) => {
  const pickUpResp = await getUserAddresses(localStorage.vendorId, "PICK_UP", 0, 500);
  dispatch(setSavedPickUpAddrList(pickUpResp.data.content));

  const dropOffResp = await getUserAddresses(localStorage.vendorId, "DROP_OFF", 0, 500);
  dispatch(setSavedDropOffAddrList(dropOffResp.data.content));
}

const GOOGLE_DIRECTION_WAYPOINT_LIMIIT = 25;
const getDirectionRoutes = () => async (dispatch, getState) => {
  dispatch(setDirections([]));
  const state = getState();
  const DirectionsService = new google.maps.DirectionsService();
  // Initiate locations with pickup location as origin
  let locations = [new google.maps.LatLng(state.orders.orderGroup.pickUpLocation.coordinate.latitude, state.orders.orderGroup.pickUpLocation.coordinate.longitude)];
  let sortedOrders = [...state.orders.orderGroup.orders].sort((a, b) => a.ordinal - b.ordinal);

  if (sortedOrders.length > 0) {
    for (let i = 0; i < sortedOrders.length; i++) {
      locations.push(
        new google.maps.LatLng(sortedOrders[i].dropOffLocation.coordinate.latitude, sortedOrders[i].dropOffLocation.coordinate.longitude)
      );
    }

    // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
    let waypointBlocks = [];
    for (let i = 0, max = GOOGLE_DIRECTION_WAYPOINT_LIMIIT - 1; i < locations.length; i = i + max) {
      waypointBlocks.push(locations.slice(i, i + max + 1));
    }

    let finalDirectionResults = [];
    // Send requests to service to get route (for stations count <= GOOGLE_DIRECTION_WAYPOINT_LIMIIT only one request will be sent)
    for (let i = 0; i < waypointBlocks.length; i++) {
      // Waypoints does not include first station (origin) and last station (destination)
      var waypoints = [];
      for (var j = 1; j < waypointBlocks[i].length - 1; j++) {
        waypoints.push({ location: waypointBlocks[i][j], stopover: true });
      }

      await DirectionsService.route({
        origin: waypointBlocks[i][0],
        destination: waypointBlocks[i][waypointBlocks[i].length - 1],
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          finalDirectionResults.push(result);
        } else {
          console.error(`error fetching directions ${result}`);
          alert(`Error fetching directions in map`);
        }
      });
    }

    dispatch(setDirections(finalDirectionResults));
  }
}

export const getPlaceLongName = (address_components, type) => {
  let component = address_components.find(c => c.types.includes(type));
  if (component) {
    return component.long_name
  }
  return null
}

export const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
}

export const ordersState = state => state.orders;

export default placeOrderSlice.reducer;