import React, { useEffect, useState } from 'react';
import styles from './OrderList.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import ReactPixel from 'react-facebook-pixel';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableFooter } from "@material-ui/core";

import { FACEBOOK_PIXEL_ID } from '../../app/utils';
import { createOrderGroup } from '../place_order/orderGroupAPI';
import { createOrder } from '../place_order/orderAPI';
import { Topbar } from '../../components/topbar/Topbar';
import { openDeleteOrderGroupModal, handleGetOrderGroups, handleGetBookmarkedAddresses, openDownloadOrderGroupModal, selectState, setPage, setRowsPerPage } from '../order_group/orderGroupSlice';
import TablePaginationActions from '../order_group/TablePaginationActions';
import { RemoveOrderModal } from './RemoveOrderModal';
import { DownloadReportModal } from './DownloadReportModal';

import downloadIcon from "../../images/icons/download-icon.png";
import pickUpPin from "../../images/dashboard/pick-up-pin.png";
import dropOffPin from "../../images/dashboard/drop-off-pin.png";

export function OrderList(props) {
  const dispatch = useDispatch();
  const state = useSelector(selectState);

  const [selectedId, setSelectedId] = useState("");
  const [disableReorder, setDisableReorder] = useState(false);

  useEffect(() => {
    async function initFn() {
      if (state.status === 'idle') {
        dispatch(handleGetOrderGroups(props.orderGroupType));
        dispatch(handleGetBookmarkedAddresses());
        let authedUser = localStorage.user ? JSON.parse(localStorage.user) : null;
        ReactPixel.init(FACEBOOK_PIXEL_ID, authedUser ? { em: authedUser.userName, fn: authedUser.firstName, ln: authedUser.lastName, ph: authedUser.contactNumber } : {}, { debug: false, autoConfig: false });
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
      }
    }
    initFn();
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetOrderGroups(props.orderGroupType));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetOrderGroups(props.orderGroupType));
  };

  const getTitle = () => {
    switch (props.orderGroupType) {
      case "OPEN":
        return "Draft Orders";
      case "CONFIRMED":
        return "Confirmed Orders";
      case "HISTORY":
        return "Order History";
      default:
        return "";
    }
  }

  const getBackgroundColor = (status) => {
    switch (status) {
      case "SUCCESS":
        return "#cbffd0";
      case "CANCELLED":
        return "#fcf5e9";
      case "FAILED":
        return "#fef5f6";
      default:
        return "";
    }
  }

  const getFontColor = (status) => {
    switch (status) {
      case "SUCCESS":
        return "#1e9984";
      case "CANCELLED":
        return "#ff9436";
      case "FAILED":
        return "#f96379";
      default:
        return "";
    }
  }

  const getOrderUrl = (orderGroupId) => {
    let url = '';
    switch (props.orderGroupType) {
      case 'OPEN':
        url = '/app/order-groups/' + orderGroupId
        break;
      case 'COMPLETED':
        url = '/app/order-history/' + orderGroupId
        break;
      default:
        url = '/app/confirmed-order-groups/' + orderGroupId
        break;
    }

    return url;
  }

  const getTotalDeliveryCharges = (unfilteredOrders) => {
    let totalCharges = 0;
    let orders = unfilteredOrders.filter(o => o.status !== "FAILED");

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => (o.deliveryFee + (o.doorToDoorFee ? o.doorToDoorFee : 0)) / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        let doorToDoorFee = orders[0].doorToDoorFee ? orders[0].doorToDoorFee : 0
        totalCharges = (orders[0].deliveryFee + doorToDoorFee) / 100;
      }
    }

    return Number(totalCharges).toFixed(2);
  }

  const generateNewDate = (date) => {
    const now = new Date();
    const currentDate = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const oldDate = new Date(date);

    var newDate = new Date(oldDate.setDate(currentDate));
    if (newDate - now < 0) {
      newDate = new Date(newDate.setDate(currentDate + 1));
    }
    if (newDate.getFullYear() - currentYear < 0) {
      newDate = new Date(newDate.setFullYear(currentYear));
    }
    if (newDate.getMonth() - currentMonth < 0) {
      newDate = new Date(newDate.setMonth(currentMonth));
    }

    return newDate.toISOString();
  }

  const handleReorder = async (orderGroup) => {
    if (!disableReorder) {
      setDisableReorder(true);

      const orderGroupPayload = {
        deliveryMethod: orderGroup.deliveryMethod,
        pickUpDateTime: generateNewDate(orderGroup.pickUpDateTime),
        pickUpPersonName: orderGroup.pickUpPersonName,
        pickUpContactNumber: orderGroup.pickUpContactNumber,
        pickUpLocation: orderGroup.pickUpLocation,
        halal: orderGroup.halal,
        noteToDriver: orderGroup.noteToDriver
      }

      const orderGroupResp = await createOrderGroup(orderGroupPayload);
      const orderGroupId = orderGroupResp.data.id;

      for (let i = 0; i < orderGroup.orders.length; i++) {
        const order = orderGroup.orders[i];
        const orderPayload = {
          ordinal: order.ordinal,
          deliverDateTime: generateNewDate(order.deliverDateTime),
          recipientName: order.recipientName,
          recipientPhone: order.recipientPhone,
          dropOffLocation: order.dropOffLocation,
          doorToDoor: order.doorToDoor,
          codAmount: order.codAmount ? order.codAmount : ""
        }

        await createOrder(orderGroupId, orderPayload);
      }

      setDisableReorder(false);
      window.open(`/main-order/${orderGroupId}`);
    }
  }

  return (
    <>
      <Topbar page="Orders" />

      {state.deleteOrderGroupModalOpen && <RemoveOrderModal orderGroupType={props.orderGroupType} orderGroups={selectedId} />}
      {state.downloadOrderGroupModalOpen && <DownloadReportModal orderGroupType={props.orderGroupType} isUnpaid={false} />}

      <div className={styles.mainContainer}>
        <div className={styles.row}>
          <p className={styles.title}>{getTitle()}</p>
          {
            props.orderGroupType !== "OPEN" &&
            <img className={styles.downloadIcon} src={downloadIcon} alt="download" onClick={() => dispatch(openDownloadOrderGroupModal())} />
          }
        </div>

        {/* Table */}
        {
          window.innerWidth > 800
            ? <div className={styles.tableContainer}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={styles.tableHeaderNarrow} style={{ minWidth: 70, maxWidth: 70 }}>Order ID</TableCell>
                      <TableCell align="center" className={styles.tableHeaderExtraNarrow}>Type</TableCell>
                      <TableCell align="center" className={styles.tableHeaderNarrow} style={{ minWidth: 90, maxWidth: 90 }}>Pick-up Date</TableCell>
                      {
                        props.orderGroupType === "HISTORY" &&
                        <TableCell align="center" className={styles.tableHeaderNarrow} style={{ minWidth: 70, maxWidth: 70 }}>Status</TableCell>
                      }
                      <TableCell align="left" className={styles.tableHeader}>Pick-up Location</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Drop-off Location</TableCell>
                      {
                        props.orderGroupType !== "HISTORY" &&
                        <TableCell align="center" className={styles.tableHeaderNarrow}>Drop-off(s)</TableCell>
                      }
                      <TableCell align="left" className={styles.tableHeaderNarrow} style={{ minWidth: 90, maxWidth: 90 }}>Total Charges</TableCell>
                      {
                        (props.orderGroupType === "OPEN" || props.orderGroupType === "CONFIRMED")
                          ? <TableCell align="center" className={styles.tableHeader} style={{ minWidth: 180, maxWidth: 180 }}></TableCell>
                          : <TableCell align="center" className={styles.tableHeader} style={{ minWidth: 190, maxWidth: 190 }}></TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      state.orderGroups && state.orderGroups.length > 0 ? (
                        state.orderGroups.map((orderGroup) => (
                          <TableRow>
                            <TableCell align="left" className={styles.tableCellNarrow} style={{ minWidth: 70, maxWidth: 70 }}>{orderGroup.id}</TableCell>
                            <TableCell align="center" className={styles.tableCellExtraNarrow}>{orderGroup.deliveryMethod === "CAR" ? "Car" : "Bike"}</TableCell>
                            <TableCell align="center" className={styles.tableCellNarrow} style={{ minWidth: 90, maxWidth: 90 }}>{Moment(orderGroup.pickUpDateTime).format("YYYY-MM-DD LT")}</TableCell>
                            {
                              props.orderGroupType === "HISTORY" &&
                              <TableCell align="center" className={styles.tableCellNarrow} style={{ minWidth: 70, maxWidth: 70 }}>
                                <div className={styles.statusContainer} style={{ backgroundColor: getBackgroundColor(orderGroup.status), color: getFontColor(orderGroup.status) }}>
                                  {orderGroup.status.substring(0, 1)}{orderGroup.status.substring(1).toLowerCase()}
                                </div>
                              </TableCell>
                            }
                            <TableCell align="left" className={styles.tableCell}>{orderGroup.pickUpLocation.address}</TableCell>
                            <TableCell align="left" className={styles.tableCell}>{orderGroup.orders.length > 0 ? orderGroup.orders[0]?.dropOffLocation?.address : "N/A"}</TableCell>
                            {
                              props.orderGroupType !== "HISTORY" &&
                              <TableCell align="center" className={styles.tableCellNarrow}>{orderGroup.orders ? orderGroup.orders.length : 0}</TableCell>
                            }
                            <TableCell align="right" className={styles.tableCellNarrow} style={{ minWidth: 90, maxWidth: 90 }}>RM{getTotalDeliveryCharges(orderGroup.orders)}</TableCell>
                            {
                              (props.orderGroupType === "OPEN" || props.orderGroupType === "CONFIRMED")
                                ? <TableCell align="center" className={styles.tableCell} style={{ minWidth: 180, maxWidth: 180 }}>
                                  <div style={{ display: "flex", marginLeft: 8 }}>
                                    <div className={styles.tealButton} onClick={() => window.open(getOrderUrl(orderGroup.id))}>
                                      <p className={styles.tealText}>View Order</p>
                                    </div>
                                    <div className={styles.redButton} onClick={() => { dispatch(openDeleteOrderGroupModal()); setSelectedId([orderGroup]) }}>
                                      <p className={styles.redText}>{props.orderGroupType === "OPEN" ? "Delete" : "Cancel"}</p>
                                    </div>
                                  </div>
                                </TableCell>
                                : <TableCell align="center" className={styles.tableCell} style={{ minWidth: 190, maxWidth: 190 }}>
                                  <div style={{ display: "flex", marginLeft: 8 }}>
                                    <div className={styles.tealButton} onClick={() => window.open(getOrderUrl(orderGroup.id))}>
                                      <p className={styles.tealText}>View Order</p>
                                    </div>
                                    <div className={styles.lightTealButton} onClick={() => handleReorder(orderGroup)}>
                                      <p className={styles.lightTealText} >Reorder</p>
                                    </div>
                                  </div>
                                </TableCell>
                            }
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={14} align="center">
                            <p>No Order Groups</p>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        colSpan={7}
                        count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                        rowsPerPage={state.rowsPerPage}
                        page={state.page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
            : <div className={styles.responsiveContainer}>
              {
                state.orderGroups && state.orderGroups.length > 0 &&
                state.orderGroups.map((orderGroup) => {
                  return (
                    <div className={styles.responsiveOrderCard}>
                      <div style={{ paddingInline: 15 }}>
                        <div className={styles.row}>
                          <p className={styles.tealText} style={{ fontSize: 13 }}>Order No.</p>
                          <p className={styles.lightBlackText}>&nbsp;{orderGroup.id}</p>
                          {
                            props.orderGroupType === "HISTORY" &&
                            <div className={styles.statusContainer} style={{ backgroundColor: getBackgroundColor(orderGroup.status), color: getFontColor(orderGroup.status), fontSize: 10, marginLeft: "auto" }}>
                              {orderGroup.status.substring(0, 1)}{orderGroup.status.substring(1).toLowerCase()}
                            </div>
                          }
                        </div>
                        <p className={styles.lightBlackText} style={{ marginBlock: 5 }}>{Moment(orderGroup.pickUpDateTime).format("YYYY-MM-DD LT")}</p>

                        <div style={{ marginBlock: 10 }}>
                          <div className={styles.row}>
                            <img className={styles.icon} src={pickUpPin} alt="pickUpPin" />
                            <p className={styles.greyText}>{orderGroup.pickUpLocation.address}</p>
                          </div>
                          <div>
                            {
                              orderGroup.orders.length > 0
                                ? <>
                                  {
                                    orderGroup.orders.map((order) => {
                                      return (
                                        <div className={styles.row}>
                                          <img className={styles.icon} src={dropOffPin} alt="dropOffPin" />
                                          <p className={styles.greyText}>{order.dropOffLocation?.address}</p>
                                        </div>
                                      )
                                    })
                                  }
                                </>
                                : <div className={styles.row}>
                                  <img className={styles.icon} src={dropOffPin} alt="dropOffPin" />
                                  <p className={styles.greyText}>N/A</p>
                                </div>
                            }
                          </div>
                        </div>
                      </div>

                      <div className={styles.row} style={{ marginTop: -5, paddingInline: 15, paddingBottom: 6 }}>
                        <p className={styles.tealText} style={{ fontSize: 13 }}>{orderGroup.deliveryMethod === "CAR" ? "Car" : "Bike"}</p>
                        <p className={styles.blackText} style={{ marginLeft: "auto", fontWeight: "bold" }}>RM{getTotalDeliveryCharges(orderGroup.orders)}</p>
                      </div>

                      <div className={styles.greyRow}>
                        {
                          (props.orderGroupType === "OPEN" || props.orderGroupType === "CONFIRMED")
                            ? <div style={{ display: "flex", marginLeft: "auto" }}>
                              <div className={styles.tealButton} style={{ border: "1px solid #227c97", boxShadow: "none" }} onClick={() => window.open(getOrderUrl(orderGroup.id))}>
                                <p className={styles.tealText} style={{ fontSize: 10 }}>View Order</p>
                              </div>
                              <div className={styles.redButton} onClick={() => { dispatch(openDeleteOrderGroupModal()); setSelectedId([orderGroup]) }}>
                                <p className={styles.redText} style={{ fontSize: 10 }}>{props.orderGroupType === "OPEN" ? "Delete" : "Cancel"}</p>
                              </div>
                            </div>
                            : <div style={{ display: "flex", marginLeft: "auto" }}>
                              <div className={styles.tealButton} style={{ border: "1px solid #227c97", boxShadow: "none" }} onClick={() => window.open(getOrderUrl(orderGroup.id))}>
                                <p className={styles.tealText} style={{ fontSize: 10 }}>View Order</p>
                              </div>
                              <div className={styles.lightTealButton} onClick={() => handleReorder(orderGroup)}>
                                <p className={styles.lightTealText} style={{ fontSize: 10 }}>Reorder</p>
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                  )
                })
              }
              {
                state.orderGroups && state.orderGroups.length > 0
                  ? <div style={{ display: "flex", justifyContent: "center" }}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={7}
                      count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                      rowsPerPage={state.rowsPerPage}
                      page={state.page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                  : <p className={styles.tealText} style={{ marginTop: 20, fontSize: 16 }}>No order groups.</p>
              }
            </div>
        }
      </div>
    </>
  )
}