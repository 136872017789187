import React, { useEffect, useState } from 'react';
import styles from './Topbar.module.css';
import { useDispatch, useSelector } from 'react-redux';

import { handleGetUserWallet, setShowTopUpModal, topbarState } from './topbarSlice';
import { InfoTooltip } from '../../features/place_order/common';
import { TopUpModal } from '../../features/place_order/TopUpModal';

import whatsappLogo from "../../images/icons/whatsapp-logo.png";
import deliveritLogo from "../../images/deliverit-logo.png";
import walletIcon from "../../images/icons/wallet-icon.png";
import topUpIcon from "../../images/icons/top-up-icon.png";
import profileIcon from "../../images/icons/profile-icon.png";
import myProfileIcon from "../../images/icons/my-profile-icon.png";
import myWalletIcon from "../../images/icons/my-wallet-icon.png";
import addressBookIcon from "../../images/icons/address-book-icon.png";
// import voucherIcon from "../../images/place_order/voucher-icon.png";
import logoutIcon from "../../images/icons/logout-icon.png";

const routesData = [
  {
    page: "Home",
    url: "/"
  },
  {
    page: "Place Order",
    url: "/main-order"
  }
]

const userRoutesData = [
  {
    page: "Home",
    url: "/"
  },
  {
    page: "Dashboard",
    url: "/app/home"
  },
  {
    page: "Place Order",
    url: "/main-order"
  }
]

const orderRoutesData = [
  {
    page: "Draft Orders",
    url: "/app/order-groups"
  },
  {
    page: "Confirmed Orders",
    url: "/app/confirmed-order-groups"
  },
  {
    page: "Order History",
    url: "/app/order-history"
  },
]

export const Topbar = (props) => {
  const state = useSelector(topbarState);
  const dispatch = useDispatch();

  const [name, setName] = useState("");

  useEffect(async () => {
    if (localStorage.refresh_token && localStorage.user) {
      let user = JSON.parse(localStorage.user);
      setName(`${user.firstName} ${user.lastName}`);
      dispatch(handleGetUserWallet());
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("refresh_token");
    window.location = "/";
  }

  return (
    <>
      {state.showTopUpModal && <TopUpModal closeModal={() => dispatch(setShowTopUpModal(false))} />}

      <img className={styles.whatsappLogo} src={whatsappLogo} alt="whatsapp" style={{ marginRight: (props.page === "Place Order") ? 65 : 20, marginBottom: (props.page === "Place Order") ? 24 : 20 }} onClick={() => window.open("http://wa.me/+60129157275")} />
      {
        localStorage.refresh_token
          ? <div className={styles.mainContainer}>
            <img className={styles.deliveritLogo} src={deliveritLogo} alt="logo" onClick={() => window.location.href = '/'} />

            {/* when user is logged in - drop-down menu for device width <= 950px / topbar menu for device width > 950px */}
            {
              window.innerWidth <= 950
                ? <div className={styles.dropdown}>
                  <i className="fas fa-caret-down" style={{ color: "#117f84", fontSize: 22, marginTop: -8, marginLeft: -10 }} />
                  <div className={styles.dropdownContent}>
                    {
                      userRoutesData.map((route, index) => {
                        return (
                          <>
                            <p className={styles.blackText} onClick={() => window.location = route.url}>{route.page}</p>
                            <div className={styles.horizontalDivider} />
                          </>
                        )
                      })
                    }
                    {
                      orderRoutesData.map((route, index) => {
                        return (
                          <>
                            <p className={styles.blackText} onClick={() => window.location = route.url}>{route.page}</p>
                            {index !== (orderRoutesData.length - 1) && <div className={styles.horizontalDivider} />}
                          </>
                        )
                      })
                    }
                  </div>
                </div>
                : <>
                  {
                    userRoutesData.map((route, index) => {
                      return (
                        <div className={(props.page === route.page) ? styles.selectedText : styles.text} onClick={() => window.location = route.url}>{route.page}</div>
                      )
                    })
                  }
                  <div className={styles.dropdown}>
                    <div style={{ display: "flex", cursor: "pointer" }}>
                      <div className={(props.page === "Orders") ? styles.selectedText : styles.text}>Orders</div>
                      <i className="fas fa-caret-down" style={{ color: "#117f84", fontSize: 22, marginTop: -5.5, marginLeft: -10 }} />
                    </div>
                    <div className={styles.dropdownContent} style={{ marginLeft: -100 }}>
                      {
                        orderRoutesData.map((route, index) => {
                          return (
                            <>
                              <p className={styles.blackText} onClick={() => window.location = route.url}>{route.page}</p>
                              {index !== (orderRoutesData.length - 1) && <div className={styles.horizontalDivider} />}
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                </>
            }

            <div className={styles.detailsContainer}>
              {
                window.innerWidth >= 400 &&
                <>
                  <InfoTooltip
                    title="When using a promo code, please note that the discount amount will only be applied upon the completion of order (subject to promo code availability). Keep this in mind when placing your order to avoid confusion."
                    placement="bottom"
                    arrow>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className={styles.tealText} onClick={() => window.location = "/app/wallet"}>MYR {(state.walletBalance / 100).toFixed(2)}</p>
                      <img className={styles.icon} src={walletIcon} style={{ cursor: "pointer" }} onClick={() => window.location = "/app/wallet"} alt="wallet" />
                      <img className={styles.icon} src={topUpIcon} style={{ cursor: "pointer" }} onClick={() => dispatch(setShowTopUpModal(true))} alt="top-up" />
                    </div>
                  </InfoTooltip>
                  <p className={styles.tealText}>{name}</p>
                </>
              }
              <div className={styles.dropdown}>
                <div style={{ display: "flex", cursor: "pointer" }}>
                  <img className={styles.icon} src={profileIcon} style={{ marginRight: 15 }} alt="profile" />
                  <i className="fas fa-caret-down" style={{ color: "#117f84", fontSize: 22, marginTop: (window.innerWidth <= 550) ? 2 : 9, marginRight: 10 }} />
                </div>
                <div className={styles.dropdownContent} style={{ marginLeft: -130 }}>
                  <div className={styles.row}>
                    <img className={styles.dropdownIcon} src={myProfileIcon} alt="profile" />
                    <p className={styles.blackText} onClick={() => window.location = "/app/profile"}>My Profile</p>
                  </div>
                  <div className={styles.row}>
                    <img className={styles.dropdownIcon} src={myWalletIcon} alt="wallet" />
                    <p className={styles.blackText} onClick={() => window.location = "/app/wallet"}>Wallet</p>
                  </div>
                  <div className={styles.row}>
                    <img className={styles.dropdownIcon} src={addressBookIcon} alt="address" />
                    <p className={styles.blackText} onClick={() => window.location = "/app/addresses"}>Saved Address</p>
                  </div>
                  {/* <div className={styles.row}>
                      <img className={styles.dropdownIcon} src={voucherIcon} />
                      <p className={styles.blackText} onClick={() => window.location = "/app/vouchers"}>My Vouchers</p>
                  </div> */}
                  <div className={styles.row}>
                    <img className={styles.dropdownIcon} src={logoutIcon} alt="logout" />
                    <p className={styles.blackText} onClick={() => logout()}>Logout</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : <div className={styles.mainContainer} style={{ paddingTop: 25, paddingBottom: 15 }}>
            <img className={styles.deliveritLogo} src={deliveritLogo} alt="logo" />

            {/* when user is not logged in - drop-down menu for device width <= 950px / topbar menu for device width > 950px */}
            {
              window.innerWidth <= 950
                ? <div className={styles.dropdown}>
                  <i className="fas fa-caret-down" style={{ color: "#117f84", fontSize: 22, marginTop: -8, marginLeft: -10 }} />
                  <div className={styles.dropdownContent}>
                    {
                      routesData.map((route, index) => {
                        return (
                          <>
                            <p className={styles.blackText} onClick={() => window.location = route.url}>{route.page}</p>
                            {index !== (routesData.length - 1) && <div className={styles.horizontalDivider} />}
                          </>
                        )
                      })
                    }
                  </div>
                </div>
                : routesData.map((route, index) => {
                  return (
                    <div className={(props.page === route.page) ? styles.selectedText : styles.text} onClick={() => window.location = route.url}>{route.page}</div>
                  )
                })
            }

            {/* Login + Signup Buttons */}
            <div className={styles.buttonsContainer}>
              <div className={styles.button} onClick={() => window.location = "/login"}>
                <p className={styles.buttonText}>Login</p>
              </div>
              <div className={styles.button} style={{ backgroundColor: "white", border: "1px solid #227c97" }} onClick={() => window.location = "/signup"}>
                <p className={styles.buttonText} style={{ color: "#227c97" }}>Sign Up</p>
              </div>
            </div>
          </div>
      }
    </>
  )
}