import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { showLoading, hideLoading } from 'react-redux-loading';
import { setAuthState } from '../auth/authSlice';
import { handleGetUser } from '../user/userSlice';
import { createVendor, getVendorByUsername } from '../user/vendorAPI';
import { login } from "./loginAPI";

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    userName: "",
    password: "",
    error: false,
    errorMessage: "",
    user: null
  },
  reducers: {
    onValueChange: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    validateForm: (state) => {
      if (state.userName === "" || state.password === "") {
        state.error = true;
        state.errorMessage = "All fields are required";
        return;
      }
      state.error = false;
      state.errorMessage = "";
    },
    loginSuccess: (state, action) => {
      state.user = action.payload.value;
    },
    showError: (state, action) => {
      state.error = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { onValueChange, validateForm, loginSuccess, showError } = loginSlice.actions;

export const handleLogin = (data) => async (dispatch) => {
  try {
    const response = await login(data);
    if(response.status === 200) {
      localStorage.setItem("refresh_token", response.data.refresh_token);
      await dispatch(handleGetVendorInfo(response.data.access_token));
      await dispatch(handleGetUser());
      await dispatch(
        setAuthState({
          ...response.data,
          is_authenticated: true,
        })
      );
      dispatch(loginSuccess(response.data));
    } else {
      dispatch(showError("Login failed"));
    }
  } catch (error) {
    dispatch(showError(error.response?.data?.error_description || error.message));
  }
};

export const handleGetVendorInfo = (accessToken) => async (dispatch, getState) => {
  dispatch(showLoading());
  try {
    const userInfo = jwtDecode(accessToken);
    const response = await getVendorByUsername(userInfo.email);
    localStorage.setItem("vendorId", response.data.id);
  } catch (error) {
    if (error.response.status === 403) {
      // If vendor doesn't exist, create one
      const response = await createVendor({
        defaultPickUpLocation: {
          address: "",
          coordinate: { longitude: 0, latitude: 0 }
        }
      });
      localStorage.setItem("vendorId", response.data.id);
    } else {
      console.log(error.message);
    }
  } finally {
    dispatch(hideLoading());
  }
};


export const handleLoginFormSubmit = (history, useWindow=false) => async (dispatch, getState) => {
  dispatch(showLoading());
  dispatch(validateForm());
  const state = getState();
  if (!state.login.error) {
    await dispatch(handleLogin({
      username: state.login.userName,
      password: state.login.password,
    }));
  }
  dispatch(hideLoading());

  if (!getState().login.error) {
    if (useWindow) {
      window.location = history.location.pathname;
    } else {
      if (history.length > 2) {
        const previousPath = document.referrer;
        window.location = previousPath;
      } else {
        window.location = '/';
      }
    }
  }
};

export const selectState = state => state.login;

export default loginSlice.reducer;
