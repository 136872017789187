import React from 'react';
import { Topbar } from '../../components/topbar/Topbar';
import styles from './StaticPage.module.css';

export function VoucherTerms() {
  return (
    <div className={styles.container}>
      <Topbar />
      
      <div style={{ marginTop: 70, paddingTop: 30, paddingInline: 40 }}>
        <div className={styles.titleBanner}>
          <h3 className={styles.title}>Voucher Terms & Condition</h3>
        </div>
        <ol>
          <li>The voucher is valid for one-time use as specified in Malaysia only.</li>
          <li>The voucher only applies to orders placed via mobile or website.</li>
          <li>The voucher is only applicable if the order value is the same or higher than the discount value.</li>
          <li>The voucher cannot be combined or used with other discount vouchers.</li>
          <li>The voucher must be redeem within the validity period. No extension of the validity period is allowed.</li>
          <li>The voucher is not refundable and is not transferable.</li>
          <li>DeliverIt retains the right to change, vary or amend these terms and conditions at any time without prior notice.</li>
          <li>DeliverIt reserves the right to make any final decision regarding the vouchers</li>
        </ol>
      </div>
    </div>
  );
}
