import { configureStore } from '@reduxjs/toolkit';
import { loadingBarReducer } from 'react-redux-loading'
import authReducer from '../features/auth/authSlice';
import loginReducer from '../features/login/loginSlice';
import signupReducer from '../features/signup/signupSlice';
import forgotPasswordReducer from '../features/forgot_password/forgotPasswordSlice';
import topbarReducer from '../components/topbar/topbarSlice';
import homeReducer from '../features/home/homeSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import orderReducer from '../features/order/orderSlice';
import placeOrderReducer from '../features/place_order/placeOrderSlice';
import orderGroupReducer from '../features/order_group/orderGroupSlice';
import addressReducer from '../features/address/addressSlice';
import savedAddressReducer from '../features/saved_address/savedAddressSlice';
import walletReducer from '../features/wallet/walletSlice';
import invoiceReducer from '../features/invoice/invoiceSlice';
import userReducer from '../features/user/userSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    login: loginReducer,
    signup: signupReducer,
    forgotPassword: forgotPasswordReducer,
    topbar: topbarReducer,
    home: homeReducer,
    dashboard: dashboardReducer,
    order: orderReducer,
    orders: placeOrderReducer,
    orderGroup: orderGroupReducer,
    address: addressReducer,
    savedAddress: savedAddressReducer,
    wallet: walletReducer,
    invoice: invoiceReducer || (() => null),
    user: userReducer,
    loadingBar: loadingBarReducer,
  },
});
