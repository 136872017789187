import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Paper, Grid, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { claimVoucher, getPromoCodes, getUserVouchers } from './voucherAPI';
import PromoCodeCard from './PromoCodeCard';
import VoucherCard from './VoucherCard';
import styles from './Voucher.module.css';
import { isJSON } from '../../app/utils';

export function SelectVoucher(props) {
  const { closeModal, onApplyVoucher } = props;
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedVoucherId, setSelectedVoucherId] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);

  useEffect(()=> {
    if (localStorage.selectedVoucher && isJSON(localStorage.selectedVoucher)) {
      let selectedVoucherStorage = JSON.parse(localStorage.selectedVoucher);
      setSelectedVoucherId(selectedVoucherStorage.id);
    }
  },[]);

  useEffect(() => {
    const handleGetPromoCodes = async () => {
      try {
        const response = await getPromoCodes();
        const { content } = response.data;
        
        const claimedPromoCode = vouchers.map(v => v.promoCode.code);
        const filteredContent = content.filter(p => !claimedPromoCode.includes(p.code));
        setPromoCodes(filteredContent);
      } catch (error) {
        console.log(error.message);
      }
    }
  
    const handleGetVouchers = async () => {
      try {
        const userUnappliedVouchersResponse = await getUserVouchers();
        setVouchers(userUnappliedVouchersResponse.data);
      } catch (error) {
        console.log(error.message);
      }
    }

    async function initFn () {
      if (!init) {
        await handleGetVouchers();
        await handleGetPromoCodes();
        setInit(true)
      }
    }
    initFn();
  }, [init, vouchers, promoCodes]);
  
  const isButtonDisabled = useMemo(
    () => (!selectedVoucherId || submitting),
    [selectedVoucherId, submitting],
  );

  const applyVoucher = async () => {
    try {
      setSubmitting(true);
      if (selectedVoucherId) {
        const selectedVoucher = promoCodes.find(v => v.id === selectedVoucherId);
        if (selectedVoucher) {
          const claimVoucherResponse = await claimVoucher({
            promoCode: {
              id: selectedVoucher.id
            }
          });
          localStorage.setItem('selectedVoucher', JSON.stringify(claimVoucherResponse.data));
        }

        const selectedClaimedVoucher = vouchers.find(v => v.id === selectedVoucherId);
        if (selectedClaimedVoucher) {
          localStorage.setItem('selectedVoucher', JSON.stringify(selectedClaimedVoucher));
        }
        onApplyVoucher();
      }
      dispatch(closeModal());
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      } else {
        alert("Something went wrong when applying voucher");
      }
    } finally {
      setSubmitting(false);
    }
  }


  return (
    <Modal
      open={true}
      aria-labelledby="add-order-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeModal())} />
          <h3 className={styles.subtitle}>Select Voucher</h3>
          <Grid container spacing={1}>
            {
              vouchers.length === 0 && promoCodes.length === 0 &&
              <Grid item xs={12}>
                <p style={{textAlign: "center"}}>No vouchers available</p>
              </Grid>
            }

            {
              <>
                <p><b>Your Vouchers</b></p>
                {
                  vouchers.length > 0 && vouchers.map(voucher => (
                    <Grid item xs={12}>
                      <VoucherCard voucher={voucher} isSelected={voucher.id === selectedVoucherId} onPress={(id) => setSelectedVoucherId(id)} />
                    </Grid>
                  ))
                }
              </>
            }

            {
              <>
                <p><b>New Vouchers</b></p>
                {
                  promoCodes.length > 0 && promoCodes.map(promoCode => (
                    <Grid item xs={12}>
                      <PromoCodeCard promoCode={promoCode} isSelected={promoCode.id === selectedVoucherId} onPress={(id) => setSelectedVoucherId(id)} />
                    </Grid>
                  ))
                }
              </>
            }
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button disabled={isButtonDisabled} onClick={applyVoucher} className={styles.gradientButton} style={{ opacity: isButtonDisabled ? 0.5 : 1}}>
                  Apply
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
