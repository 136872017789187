import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Hidden } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { onValueChange, handleForgotPasswordSubmit, selectState } from './forgotPasswordSlice';
import styles from './ForgotPassword.module.css';
import logo from "../../images/logo-white.png";
import accountIcon from "../../images/icons/account-gradient.png";

export function ForgotPassword() {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div>
        <img src={logo} alt="logo" className={styles.logo} />
      </div>
      <div className={styles.cardContainer}>
        <Hidden smDown>
          <div display={{ xs: 'none' }} className={styles.leftContainer}></div>
        </Hidden>
        <div className={styles.rightContainer}>

          <form className={styles.formContainer}>
            <p style={{ marginLeft: 5, fontSize: 15, fontWeight: "500" }}>Reset Password</p>
            {
              state.error &&
              <Alert
                severity="error"
                style={{ marginInline: 5, marginTop: -5, marginBottom: 12, borderRadius: 10, textAlign: "left" }}
              >
                {state.errorMessage}
              </Alert>
            }
            <div className={styles.textInputContainer}>
              <div className={styles.textInputIconContainer}>
                <img src={accountIcon} className={styles.textInputIcon} alt="account" />
              </div>
              <input
                id="email"
                name="email"
                value={state.userName}
                onChange={e => dispatch(onValueChange({ name: "userName", value: e.target.value }))}
                placeholder="Email Address"
                type="email"
                className={styles.textInput}
              />
            </div>
            <p style={{ fontSize: 12, marginBottom: "15%" }}><Link to="/login" style={{ color: "#387596" }}>Back</Link></p>
            <div style={{ marginLeft: 5, marginTop: state.error ? 160 : 215 }}>
              <Button
                disabled={state.isLoading}
                onClick={() => dispatch(handleForgotPasswordSubmit(history))}
                className={styles.gradientButton}
              >
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.poweredBy}><span>Powered by </span><b><a className={styles.poweredByLink} href='https://www.parkitmy.com/' target='_blank' rel="noreferrer">PARKIT</a></b></div>
    </div>
  );
}
