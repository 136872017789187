import React, { useEffect, useRef, useState } from 'react';
import styles from './PlaceOrder.module.css';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Chip, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import moment from 'moment';

import { dollarToCent, getUserInfoFromJwt } from '../../app/utils';
import { renderCreditCard } from '../user/User';
import { handleManageBilling, handleSetupCard } from '../user/userSlice';
import { getCreditCards, getUserWallet, topUpWallet } from '../wallet/walletAPI';
import { handleGetTransactions } from '../wallet/walletSlice';

import closeIcon from "../../images/place_order/close-icon.png";
import addIcon from "../../images/icons/add.png";
import refreshIcon from "../../images/icons/refresh-gradient.png";
import checkIcon from "../../images/icons/check-gradient.png";

export function TopUpModal({ closeModal }) {
    const dispatch = useDispatch();

    const suggestedTopUpAmounts = [50, 100, 500];

    const [init, setInit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const initialState = {
        topUpAmount: 0,
        wallet: "",
        creditCard: null,
        error: false,
        errorMessage: ""
    };

    const [topUpState, _setTopUpState] = useStateWithCallbackLazy({
        ...initialState
    });

    const topUpStateRef = useRef(topUpState);

    const setTopUpState = data => {
        topUpStateRef.current = data;
        _setTopUpState(data);
    };

    useEffect(() => {
        async function fetchCreditCards() {
            setIsLoading(true);
            if (!topUpState.wallet && !init) {
                setInit(true);
                const userInfo = await getUserInfoFromJwt();
                const { data: wallet } = await getUserWallet(userInfo.user_id);
                const creditCardResponse = await getCreditCards(wallet.id);
                setTopUpState({
                    ...topUpState,
                    wallet: { ...wallet },
                    creditCard: creditCardResponse.data.find(c => c.selected === true)
                });
            }
            setIsLoading(false);
        }
        fetchCreditCards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [init]);

    const onTopUpAmountChange = (value) => {
        setTopUpState({
            ...topUpState,
            topUpAmount: value,
            error: false,
            errorMessage: ""
        })
    }

    const submit = async () => {
        let error = false;
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        if (topUpState.topUpAmount < 20) {
            setTopUpState({ ...topUpState, error: true, errorMessage: "Minimum top up amount is RM20." });
            setIsLoading(false);
            return;
        }

        const topUpAmount = Number(dollarToCent(topUpState.topUpAmount));
        if (!error) {
            try {
                let walletPayload = {
                    method: "STRIPE",
                    platform: 'DELIVERIT',
                    topUpAmount,
                    detail: `Top Up RM${topUpState.topUpAmount}`
                }

                await topUpWallet(topUpState.wallet.id, walletPayload);
                dispatch(handleGetTransactions([new Date(moment().subtract(30, 'd')), new Date()]));
                dispatch(closeModal());
                alert("Top Up Success");
                window.location.reload();
            } catch (error) {
                setTopUpState({ ...topUpState, error: true, errorMessage: error?.response?.data?.message || error.message });
            } finally {
                setIsLoading(false);
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <div className={styles.topUpModal}>
                <div style={{ display: "flex" }}>
                    <p className={styles.tealText} style={{ textAlign: "left", fontWeight: "500" }}>Top Up</p>
                    <img className={styles.icon} style={{ width: 10, marginLeft: "auto", marginRight: 0, marginTop: -5, cursor: "pointer" }} src={closeIcon} alt="close" onClick={() => dispatch(closeModal())} />
                </div>

                {/* Error Alert */}
                {
                    topUpState.error &&
                    <Alert
                        severity="error"
                        style={{ marginTop: 10, borderRadius: 10, textAlign: "left" }}
                        onClose={() => setTopUpState({
                            ...topUpState,
                            error: false,
                            errorMessage: ""
                        })}>
                        {topUpState.errorMessage}
                    </Alert>
                }

                {/* Input Amount */}
                <div style={{ marginTop: 18, marginBottom: 8 }}>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel className={styles.muiInputLabel} htmlFor="topUpAmount">Amount</InputLabel>
                        <OutlinedInput
                            id="topUpAmount"
                            value={topUpState.topUpAmount}
                            onChange={(e) => onTopUpAmountChange(e.target.value)}
                            startAdornment={<InputAdornment position="start">RM</InputAdornment>}
                            label="Amount"
                        />
                    </FormControl>
                </div>

                {/* Amount Chips */}
                <Grid container spacing={1}>
                    {
                        suggestedTopUpAmounts.map(amount => (
                            <Grid item xs={6} sm={3}>
                                <Chip className={styles.chip} color='primary' label={`RM${amount}`} variant={topUpState.topUpAmount === amount ? 'default' : 'outlined'} onClick={() => onTopUpAmountChange(amount)} />
                            </Grid>
                        ))
                    }
                    <Grid item xs={6} sm={3}>
                        <Chip className={styles.chip} color='primary' label="Other" variant={suggestedTopUpAmounts.includes(topUpState.topUpAmount) ? 'outlined' : 'default'} />
                    </Grid>
                </Grid>

                {/* Credit Card */}
                <div style={{ marginTop: 30 }}>
                    <div onClick={() => dispatch(handleSetupCard())} className={styles.creditCardContainer}>
                        {
                            topUpState.creditCard ? (
                                <div className={styles.hstack}>
                                    <img alt={topUpState.creditCard.brand} className={styles.creditCardIcon} src={renderCreditCard(topUpState.creditCard.brand)} />
                                    <div className={styles.vstack}>
                                        <span><b className={styles.creditCardText}>**** **** **** {topUpState.creditCard.last4}</b></span>
                                        <span className={styles.creditCardExpText}>{topUpState.creditCard.expMonth}/{topUpState.creditCard.expYear}</span>
                                    </div>
                                    <img alt="checked" className={styles.creditCardIcon} src={checkIcon} />
                                </div>
                            ) : (
                                <div className={styles.hstack}>
                                    <img alt="add" src={addIcon} className={styles.icon} style={{ marginLeft: 0 }} />
                                    <p className={styles.blackText} style={{ fontWeight: 500 }}>Add Credit Card</p>
                                </div>
                            )
                        }
                    </div>
                    {
                        topUpState.creditCard &&
                        <div onClick={() => dispatch(handleManageBilling())} className={styles.creditCardContainer}>
                            <div className={styles.hstack}>
                                <img alt="refresh" src={refreshIcon} className={styles.icon} style={{ marginLeft: 0 }} />
                                <p className={styles.blackText} style={{ fontWeight: 500 }}>Change Credit Card</p>
                            </div>
                        </div>
                    }
                </div>

                {
                    isLoading &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                        <CircularProgress size={30} />
                    </div>
                }

                {/* Top Up Button */}
                <div className={styles.modalButtonContainer}>
                    {
                        isLoading
                            ? <div className={styles.modalButton} style={{ backgroundImage: "none", backgroundColor: "#e0e0e0", boxShadow: "none" }}>
                                <p className={styles.whiteText} style={{ fontSize: 13 }}>Top Up</p>
                            </div>
                            : <div className={styles.modalButton} onClick={() => submit()}>
                                <p className={styles.whiteText} style={{ fontSize: 13 }}>Top Up</p>
                            </div>
                    }
                </div>
            </div>
            <div className={styles.overlay} onClick={() => dispatch(closeModal())}></div>
        </>
    )
}