import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styles from './Layout.module.css';
import { Invoice } from '../../features/invoice/Invoice';
import { OrderGroup } from '../../features/order_group/OrderGroup';
import { User } from '../../features/user/User';
import { Dashboard } from '../../features/dashboard/Dashboard';
import { Wallet } from '../../features/wallet/Wallet';
import { Terms } from '../../features/static_page/Terms';
import { VoucherTerms } from '../../features/static_page/VoucherTerms';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetUserWallet, selectState as selectHomeState } from '../../features/home/homeSlice';
import { Landing } from '../../features/landing/Landing';
import { MainOrder } from '../../features/place_order/MainOrder';
import { PlaceOrder } from '../../features/place_order/PlaceOrder';
import { Order as OrderV1 } from '../../features/order/Order';
import { OrderList } from '../../features/order_list/OrderList';
import { OrderDetails } from '../../features/order_details/OrderDetails';
import { SavedAddress } from '../../features/saved_address/SavedAddress';

export function Layout() {
	const homeState = useSelector(selectHomeState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (homeState.status === 'idle') {
      dispatch(handleGetUserWallet());
    }
  }, [dispatch, homeState]);

  return (
    <div className={styles.root}>
		<Router>
			<Fragment>
				<div>
					<Switch>
						<Route exact path="/" component={Landing} />
						{/* TODO: rollback new design */}
						<Route exact path="/main-order/:id" component={MainOrder} />
						<Route exact path="/app/order-groups" component={props => (<OrderList {...props} orderGroupType={"OPEN"} />)} />
						<Route exact path="/app/order-groups/:id" component={PlaceOrder} />
						<Route exact path="/main-order-v1" component={props => <OrderGroup {...props} orderGroupType={"OPEN"} />} />
						<Route exact path="/app/order-groups-v1" component={props => (<OrderGroup {...props} orderGroupType={"OPEN"} />)} />
						<Route exact path="/app/order-groups-v1/:id" component={OrderV1} />
						<Route exact path="/main-order-v1/:id" component={OrderV1} />

						<Route exact path="/app/home" component={Dashboard} />
						<Route exact path="/app/order-groups/:id/invoice" component={Invoice} />
						<Route exact path="/app/confirmed-order-groups" component={props => (<OrderList {...props} orderGroupType={"CONFIRMED"} />)} />
						<Route exact path="/app/confirmed-order-groups/:id" component={OrderDetails} />
						<Route exact path="/app/order-history" component={props => (<OrderList {...props} orderGroupType={"HISTORY"} />)} />
						<Route exact path="/app/order-history/:id" component={OrderDetails} />
						<Route exact path="/app/completed-order-groups" component={props => (<OrderGroup {...props} orderGroupType={"COMPLETED"} />)} />
						<Route exact path="/app/completed-order-groups/:id" component={OrderDetails} />
						<Route exact path="/app/cancelled-order-groups" component={props => (<OrderGroup {...props} orderGroupType={"CANCELLED"} />)} />
						<Route exact path="/app/cancelled-order-groups/:id" component={OrderDetails} />
						<Route exact path="/app/addresses" component={SavedAddress} />
						<Route exact path="/app/wallet" component={Wallet} />
						<Route exact path="/app/profile" component={User} />
						<Route exact path="/terms" component={Terms} />
						<Route exact path="/voucher-terms" component={VoucherTerms} />
					</Switch>
				</div>
			</Fragment>
		</Router>
    </div>
  );
}
