import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse, Typography, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Checkbox, Box } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { handleReorderOrderGroup, setSelectedIds } from './orderGroupSlice';
import styles from './OrderGroup.module.css';
import carTealIcon from "../../images/car-teal.png";
import motorTealIcon from "../../images/motor-teal.png";

export function OrderGroupCollapsibleRow(props) {
  const { state, orderGroup } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const getOrderUrl = (orderGroupId) => {
    let url = '';
    switch(props.orderGroupType) {
      case 'OPEN':
        url = '/app/order-groups/' + orderGroupId
        break;
      case 'COMPLETED':
        url = '/app/completed-order-groups/' + orderGroupId
        break;
      default:
        url = '/app/confirmed-order-groups/' + orderGroupId
        break;
    }

    return url;
  }

  const getTotalDeliveryCharges = (unfilteredOrders) => {
    let totalCharges = 0;
    let orders = unfilteredOrders.filter(o => o.status !== "FAILED");

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => (o.deliveryFee + (o.doorToDoorFee ? o.doorToDoorFee : 0)) / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        let doorToDoorFee = orders[0].doorToDoorFee ? orders[0].doorToDoorFee : 0
        totalCharges = (orders[0].deliveryFee + doorToDoorFee) / 100;
      }
    }

    return totalCharges;
  }

  return (
    <React.Fragment>
      <TableRow 
        key={orderGroup.id}
        hover
        onClick={() => dispatch(setSelectedIds(state.selectedIds.includes(orderGroup.id) ? state.selectedIds.filter(id => id !== orderGroup.id) : [...state.selectedIds, orderGroup.id]))}
        role="checkbox"
        aria-checked={state.selectedIds.includes(orderGroup.id)}
        selected={state.selectedIds.includes(orderGroup.id)}
      >        
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open)}
            }
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox 
            color="primary"
            checked={state.selectedIds.includes(orderGroup.id)}
          />
        </TableCell>
        <TableCell align="center" className={styles.tableCellExtraNarrow}>
          <img alt='vehicle' width={40} src={orderGroup.deliveryMethod === "MOTOR" ? motorTealIcon : carTealIcon} />
        </TableCell>
        <TableCell align="left" className={styles.tableCellNarrow}>{orderGroup.id}</TableCell>
        <TableCell align="left" style={{minWidth: 100}}>{orderGroup.pickUpDateTime}</TableCell>
        <TableCell align="left">{orderGroup.pickUpLocation.unitNumber}</TableCell>
        <TableCell align="left" style={{minWidth: 250}}>{orderGroup.pickUpLocation.address}</TableCell>
        <TableCell align="left" style={{minWidth: 250}}>{orderGroup.orders.length > 0 ? orderGroup.orders[0]?.dropOffLocation?.address : "N/A"}</TableCell>
        <TableCell align="left">{orderGroup.orders ? orderGroup.orders.length : 0}</TableCell>
        <TableCell align="left">RM{getTotalDeliveryCharges(orderGroup.orders)}</TableCell>
        <TableCell align="left">{orderGroup.halal === true ? "Yes" : "No"}</TableCell>
        <TableCell align="left">
          <a target="_blank" className={styles.tableLink} href={getOrderUrl(orderGroup.id)} rel="noreferrer">
            View Orders
          </a>
        </TableCell>
        {props.orderGroupType === "COMPLETED" && 
          <TableCell align="left">
            <button className={styles.tableLink} style={{background: "transparent", border: "none", padding: 0}} onClick={() => dispatch(handleReorderOrderGroup(orderGroup))}>
              Reorder
            </button>
          </TableCell>
        }
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" style={{marginTop: 10, marginLeft: 10}}>
                Orders
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className={styles.tableHeaderNarrow}>Order Number</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Delivery Date & Time</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Recipient Name</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Phone No.</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Delivery Address</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Product</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Receipt Number</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Distance from previous location</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Door To Door Charges</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Delivery Charges</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Cash on Delivery Amount</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Note To Driver</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderGroup.orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell align="left" className={styles.tableCellNarrow}>{order.id}</TableCell>
                      <TableCell align="left">{order.deliverDateTime}</TableCell>
                      <TableCell align="left">{order.recipientName}</TableCell>
                      <TableCell align="left">{order.recipientPhone}</TableCell>
                      <TableCell align="left">{order.recipientEmail}</TableCell>
                      <TableCell align="left">{order.dropOffLocation.unitNumber}</TableCell>
                      <TableCell align="left" style={{minWidth: 250}}>{order.dropOffLocation.address}</TableCell>
                      <TableCell align="left">{order.product}</TableCell>
                      <TableCell align="left">{order.receiptNumber}</TableCell>
                      <TableCell align="left">{(order.deliveryRange / 1000).toFixed(2)}KM</TableCell>
                      <TableCell align="left">RM{(order.doorToDoorFee ? order.doorToDoorFee/100 : 0)}</TableCell>
                      <TableCell align="left">RM{order.deliveryFee / 100}</TableCell>
                      <TableCell align="left">{order.codAmount ? `RM${order.codAmount / 100}` : "-"}</TableCell>
                      <TableCell align="left">{order.noteToDriver}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}