import React, { useEffect, useState } from 'react';
import styles from './SavedAddress.module.css';
import { useDispatch, useSelector } from 'react-redux';

import { getAllSavedAddresses, savedAddressState, setSelectedAddr, setShowCreateAddressModal, setShowDeleteAddressModal, setShowEditAddressModal } from './savedAddressSlice';
import { Topbar } from '../../components/topbar/Topbar';
import { CreateAddressModal } from './CreateAddressModal';
import { EditAddressModal } from './EditAddressModal';
import { DeleteAddressModal } from './DeleteAddressModal';

import pickUpIcon from "../../images/place_order/location-pin-icon.png";
import dropOffIcon from "../../images/place_order/location-address-icon.png";

export const SavedAddress = () => {
  const dispatch = useDispatch();
  const sliceState = useSelector(savedAddressState);

  const [addrType, setAddrType] = useState("PICK_UP");
  const [savedAddr, setSavedAddr] = useState([]);

  useEffect(() => {
    dispatch(getAllSavedAddresses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addrType === "PICK_UP") setSavedAddr(sliceState.pickUpAddressList);
    else setSavedAddr(sliceState.dropOffAddressList);
  }, [addrType, sliceState.pickUpAddressList, sliceState.dropOffAddressList]);

  return (
    <>
      {sliceState.showCreateAddressModal && <CreateAddressModal addrType={addrType} />}
      {sliceState.showEditAddressModal && <EditAddressModal addrType={addrType} />}
      {sliceState.showDeleteAddressModal && <DeleteAddressModal addrType={addrType} />}

      <Topbar />

      <div className={styles.mainContainer}>
        <div style={{ display: "flex", paddingTop: 70, flexWrap: "wrap" }}>
          <p className={styles.title}>Saved Address</p>
          <div className={styles.buttonsContainer}>
            <div className={styles.tealButton} onClick={() => dispatch(setShowCreateAddressModal(true))}>
              <i className="fa fa-plus" style={{ marginRight: 6, color: "white", fontSize: 8 }} />
              <p className={styles.whiteText}>{addrType === "PICK_UP" ? "Add Pick-up Address" : "Add Drop-off Address"}</p>
            </div>
          </div>
        </div>

        {/* Responsive UI for address type container */}
        {
          window.innerWidth <= 800 &&
          <div className={styles.addrTypeHContainer}>
            <div className={styles.tabRow} style={{ backgroundColor: addrType === "PICK_UP" ? "#e7f5f6" : "white", justifyContent: "center" }} onClick={() => setAddrType("PICK_UP")}>
              <p className={styles.blackText} style={{textAlign: "center"}}>Pick-up Address</p>
            </div>
            <div className={styles.tabRow} style={{ backgroundColor: addrType === "DROP_OFF" ? "#e7f5f6" : "white", justifyContent: "center" }} onClick={() => setAddrType("DROP_OFF")}>
              <p className={styles.blackText} style={{textAlign: "center"}}>Drop-off Address</p>
            </div>
          </div>
        }

        <div className={styles.hContainer}>
          {
            window.innerWidth > 800 &&
            <div className={styles.addrTypeVContainer}>
              <div className={styles.tabRow} style={{ backgroundColor: addrType === "PICK_UP" ? "#e7f5f6" : "white" }} onClick={() => setAddrType("PICK_UP")}>
                <img className={styles.icon} src={pickUpIcon} alt="pickupIcon" />
                <p className={styles.blackText}>Pick-up Address</p>
              </div>
              <div className={styles.tabRow} style={{ backgroundColor: addrType === "DROP_OFF" ? "#e7f5f6" : "white" }} onClick={() => setAddrType("DROP_OFF")}>
                <img className={styles.icon} src={dropOffIcon} alt="dropoffIcon" />
                <p className={styles.blackText}>Drop-off Address</p>
              </div>
            </div>
          }

          <div className={styles.savedAddrContainer}>
            {
              savedAddr && savedAddr.map((addr, index) => {
                return (
                  <>
                    <div className={styles.locationRow}>
                      <img className={styles.icon} style={{ marginTop: 4, marginLeft: 0 }} src={addrType === "PICK_UP" ? pickUpIcon : dropOffIcon} alt="location-pin" />
                      <div style={{ marginRight: 15 }}>
                        <p className={styles.blackText}>{addr.bookmarkName ? addr.bookmarkName + " - " : ""} {addr.location.address}</p>
                        {
                          addrType === "PICK_UP"
                            ? <p className={styles.greyText}>{addr.location.unitNumber && addr.location.unitNumber + "\u00a0 • \u00a0"}{addr.pickUpContactNumber}&nbsp; • &nbsp;{addr.pickUpPersonName}</p>
                            : <p className={styles.greyText}>{addr.location.unitNumber && addr.location.unitNumber + "\u00a0 • \u00a0"}{addr.recipientPhone}&nbsp; • &nbsp;{addr.recipientName}</p>
                        }
                      </div>
                      {
                        window.innerWidth > 800 &&
                        <div className={styles.buttonsContainer}>
                          <div className={styles.whiteButton} style={{ paddingInline: 12 }} onClick={() => { dispatch(setSelectedAddr(addr)); dispatch(setShowEditAddressModal(true)); }}>
                            <i className="fa fa-pencil" style={{ marginRight: 5, color: "#227c97", fontSize: 11 }} />
                            <p className={styles.tealText}>Edit</p>
                          </div>
                          <div className={styles.whiteButton} onClick={() => { dispatch(setSelectedAddr(addr)); dispatch(setShowDeleteAddressModal(true)); }}>
                            <i className="fa fa-trash" style={{ color: "#227c97", fontSize: 13 }} />
                          </div>
                        </div>
                      }
                    </div>

                    {/* Responsive UI for edit and delete buttons */}
                    {
                      window.innerWidth <= 800 &&
                      <div style={{ display: "flex", marginLeft: "auto", marginTop: -5 }}>
                        <div className={styles.buttonsContainer}>
                          <div className={styles.whiteButton} style={{ paddingInline: 12 }} onClick={() => { dispatch(setSelectedAddr(addr)); dispatch(setShowEditAddressModal(true)); }}>
                            <i className="fa fa-pencil" style={{ marginRight: 5, color: "#227c97", fontSize: 11 }} />
                            <p className={styles.tealText}>Edit</p>
                          </div>
                          <div className={styles.whiteButton} onClick={() => { dispatch(setSelectedAddr(addr)); dispatch(setShowDeleteAddressModal(true)); }}>
                            <i className="fa fa-trash" style={{ color: "#227c97", fontSize: 13 }} />
                          </div>
                        </div>
                      </div>
                    }
                    <div className={styles.horizontalDivider} />
                  </>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}