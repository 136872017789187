import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import { closeChangePasswordModal } from './userSlice';
import { Close } from "@material-ui/icons";
import styles from './User.module.css';
import "react-datepicker/dist/react-datepicker.css";
import { resetPassword } from './userAPI';

export function ChangePassword(props) {
  const { user } = props;
  const dispatch = useDispatch();
	const [userState, setUserState] = useState({
		user: { ...user },
		form: {
			userName: user.userName,
			password: "",
			newPassword: "",
		},
		confirmPassword: "",
		error: false,
    errorMessage: ""
	});

  const onFormValueChange = (data) => {
    setUserState({
      ...userState,
      form: {
        ...userState.form,
        [data.name]: data.value
      }
    })
  }

  const onValueChange = (data) => {
    setUserState({
      ...userState,
			[data.name]: data.value,
      form: {
        ...userState.form
      }
    })
  }

  const submit = async (props) => {
    let error = false;
    
    for (var key in userState.form) {
      if (userState.form[key] === null || userState.form[key] === "") {
        error = true;
        setUserState({...userState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

		if (userState.form.newPassword !== userState.confirmPassword) {
			error = true;
			setUserState({...userState, error: true, errorMessage: "Confirm password don't match new password"});
			return;
		}
    
    let userPayload = {
      ...userState.form
    }
    
    if (!error) {
      try {
        await resetPassword(user.id, userPayload);
        dispatch(closeChangePasswordModal());
      } catch (error) {
        setUserState({...userState, error: true, errorMessage: error.message});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeChangePasswordModal())}/>
          <h3 className={styles.subtitle}>Change Password <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={userState.error}>
            <p className={styles.errorMessage}>{userState.errorMessage}</p>
          </Fade>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p className={styles.textInputLabel}>Current Password</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="password"
                  name="password"
                  value={userState.form.password}
									onChange={e => onFormValueChange({name: "password", value: e.target.value})}
                  placeholder="Current Password"
                  type="password"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <p className={styles.textInputLabel}>New Password</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="newPassword"
                  name="newPassword"
                  value={userState.form.newPassword}
                  onChange={e => onFormValueChange({name: "newPassword", value: e.target.value})}
                  placeholder="New Password"
									type="password"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <p className={styles.textInputLabel}>Confirm New Password</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  value={userState.form.confirmPassword}
                  onChange={e => onValueChange({name: "confirmPassword", value: e.target.value})}
                  placeholder="Confirm Password"
									type="password"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit(props)} className={styles.gradientButton}>Update</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
