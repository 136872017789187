import React from 'react';
import { Radio } from '@material-ui/core';
import DollarVoucher from '../../images/dollar-voucher.png';
import PercentVoucher from '../../images/percent-voucher.png';
import styles from './Voucher.module.css';

const PromoCodeCard = (props) => {
    const { promoCode, isSelected, disabled, onPress } = props;

    return (
      <div onClick={() => onPress(promoCode.id)} className={styles.cardContainer}>
        <img alt='voucher' src={promoCode.discountAmount ? DollarVoucher : PercentVoucher} className={styles.cardIcon} />
        <div className={styles.voucherContent}>
          <p className={styles.cardHeader}>{promoCode.title ?? "Voucher"}</p>
          <div className={styles.voucherCodeContainer}>
            <p className={styles.voucherCodeText}>{promoCode.code}</p>
          </div>
          {
            promoCode.expiryDate &&
            <p className={styles.voucherExpText}>Valid until: {promoCode.expiryDate}</p>
          }
        </div>
        <div style={{textAlign: "right"}}>
          <Radio
            checked={isSelected}
            disabled={disabled}
            color="primary"
            onClick={() => onPress(promoCode.id)}
          />
          <p onClick={() => window.open("/voucher-terms", "_blank")} className={styles.tncText}>T&C</p>
        </div>
      </div>
    )
}

export default PromoCodeCard;