import React from 'react';
import styles from './SavedAddress.module.css';
import { useDispatch, useSelector } from 'react-redux';

import { deleteAddress } from './addressAPI';
import { getSavedAddresses, savedAddressState, setShowDeleteAddressModal } from './savedAddressSlice';

export const DeleteAddressModal = (props) => {
    const dispatch = useDispatch();
    const sliceState = useSelector(savedAddressState);

    const submit = async () => {
        await deleteAddress(sliceState.selectedAddr.id);
        dispatch(setShowDeleteAddressModal(false));
        dispatch(getSavedAddresses(props.addrType));
    }

    return (
        <>
            <div className={styles.deleteAddressModal}>
                <p className={styles.blackText} style={{ marginTop: 30, marginBottom: 35 }}>Confirm removal of address?</p>
                <div className={styles.buttonsContainer} style={{ marginLeft: 0 }}>
                    <div className={styles.whiteButton} style={{ width: 80, paddingBlock: 12 }} onClick={() => dispatch(setShowDeleteAddressModal(false))}>
                        <p className={styles.tealText}>Cancel</p>
                    </div>
                    <div className={styles.tealButton} style={{ background: "linear-gradient(to right, #45b0b6, #227c97", width: 80, paddingBlock: 12 }} onClick={() => submit()}>
                        <p className={styles.whiteText}>Remove</p>
                    </div>
                </div>
            </div>
            <div className={styles.overlay} onClick={() => dispatch(setShowDeleteAddressModal(false))}></div>
        </>
    )
}