/*global google*/
import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import ReactPixel from 'react-facebook-pixel';
import Moment from 'moment';
import { confirmOrders, getDefaultDeliveryRateProfile, getOrdersByOrderGroup, updateOrder } from './orderAPI';
import { getUserAddresses } from '../address/addressAPI';
import { FACEBOOK_PIXEL_ID } from '../../app/utils';
import { getVendorByUsername } from '../user/vendorAPI';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orderGroup: null,
    status: 'idle',
    createOrderModalOpen: false,
    importOrderModalOpen: false,
    editOrderModalOpen: false,
    deleteOrderModalOpen: false,
    topUpModalOpen: false,
    selectVoucherModalOpen: false,
    bookmarkAddressModalOpen: false,
    bookmarkedAddresses: [],
    selectedId: null,
    selectedIds: [],
    confirmButtonIsDisabled: false,
    defaultDeliveryRateProfile: null,
    directions: [],
    confirmOrderPayload: {
      noteToDriver: "",
      paymentMethod: "CREDIT_CARD"
    },
    vendor: {},
    sortingDisable: false
  },
  reducers: {
    openCreateOrderModal: (state) => {
      state.createOrderModalOpen = true;
    },
    closeCreateOrderModal: (state) => {
      state.createOrderModalOpen = false;
    },
    openImportOrderModal: (state) => {
      state.importOrderModalOpen = true;
    },
    closeImportOrderModal: (state) => {
      state.importOrderModalOpen = false;
    },
    openEditOrderModal: (state) => {
      state.editOrderModalOpen = true;
    },
    closeEditOrderModal: (state) => {
      state.editOrderModalOpen = false;
    },
    openDeleteOrderModal: (state) => {
      state.deleteOrderModalOpen = true;
    },
    closeDeleteOrderModal: (state) => {
      state.deleteOrderModalOpen = false;
      state.selectedId = null;
    },
    openTopUpModal: (state) => {
      state.topUpModalOpen = true;
    },
    closeTopUpModal: (state) => {
      state.topUpModalOpen = false;
    },
    openSelectVoucherModal: (state) => {
      state.selectVoucherModalOpen = true;
    },
    closeSelectVoucherModal: (state) => {
      state.selectVoucherModalOpen = false;
    },
    openBookmarkAddressModal: (state) => {
      state.bookmarkAddressModalOpen = true;
    },
    closeBookmarkAddressModal: (state) => {
      state.bookmarkAddressModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
    setConfirmOrderPayload: (state, action) => {
      state.confirmOrderPayload = action.payload;
    },
    setConfirmButtonIsDisabled: (state, action) => {
      state.confirmButtonIsDisabled = action.payload;
    },
    setDirections: (state, action) => {
      state.directions = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setOrders: (state, action) => {
      state.orderGroup = {
        ...state.orderGroup,
        orders: [...action.payload]
      };
    },
    setVendor: (state, action) => {
      state.vendor = { ...action.payload };
    },
    setSortingDisable: (state, action) => {
      state.sortingDisable = action.payload;
    },
    setDefaultDeliveryRateProfile: (state, action) => {
      state.defaultDeliveryRateProfile = { ...action.payload };
    },
    getBookmarkedAddresses: (state, action) => {
      state.bookmarkedAddresses = [...action.payload];
    },
    getOrders: (state, action) => {
      state.orderGroup = {
        ...action.payload,
        pickUpDateTime: (action.payload.pickUpDateTime ? new Date(action.payload.pickUpDateTime).toString() : action.payload.pickUpDateTime),
        orders: [
          ...action.payload.orders.map(o => o.deliverDateTime ? {...o, deliverDateTime: new Date(o.deliverDateTime).toString()} : o)
        ],
      };
      state.confirmOrderPayload = {
        ...state.confirmOrderPayload,
        noteToDriver: action.payload.noteToDriver
      };
    },
  },
});

export const { openCreateOrderModal, closeCreateOrderModal, openImportOrderModal, closeImportOrderModal, openEditOrderModal, closeEditOrderModal, openDeleteOrderModal, closeDeleteOrderModal, openTopUpModal, closeTopUpModal, openSelectVoucherModal, closeSelectVoucherModal, openBookmarkAddressModal, closeBookmarkAddressModal, setSelectedId, setSelectedIds, setConfirmOrderPayload, setConfirmButtonIsDisabled, setDirections, setStatus, setOrders, setVendor, setSortingDisable, getOrders, getBookmarkedAddresses, setDefaultDeliveryRateProfile } = orderSlice.actions;

export const handleGetOrdersByOrderGroup = (id) => async (dispatch, getState) => {
  dispatch(showLoading());
  dispatch(setStatus("success"));
  try {
    const response = await getOrdersByOrderGroup(id);
    dispatch(getOrders(response.data));
    dispatch(getDirectionRoutes());
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

const GOOGLE_DIRECTION_WAYPOINT_LIMIIT = 25;
const getDirectionRoutes = () => async (dispatch, getState) => {
  dispatch(setDirections([]));
  const state = getState();
  const DirectionsService = new google.maps.DirectionsService();
  // Initiate locations with pickup location as origin
  let locations = [new google.maps.LatLng(state.order.orderGroup.pickUpLocation.coordinate.latitude, state.order.orderGroup.pickUpLocation.coordinate.longitude)];
  let sortedOrders = [...state.order.orderGroup.orders].sort((a, b) => a.ordinal - b.ordinal);

  if (sortedOrders.length > 0) {
    for (let i = 0; i < sortedOrders.length; i++) {
      locations.push(
        new google.maps.LatLng(sortedOrders[i].dropOffLocation.coordinate.latitude, sortedOrders[i].dropOffLocation.coordinate.longitude)
      );
    }

    // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
    let waypointBlocks = [];
    for (let i = 0, max = GOOGLE_DIRECTION_WAYPOINT_LIMIIT - 1; i < locations.length; i = i + max) {
      waypointBlocks.push(locations.slice(i, i + max + 1));
    }

    let finalDirectionResults = [];
    // Send requests to service to get route (for stations count <= GOOGLE_DIRECTION_WAYPOINT_LIMIIT only one request will be sent)
    for (let i = 0; i < waypointBlocks.length; i++) {
      // Waypoints does not include first station (origin) and last station (destination)
      var waypoints = [];
      for (var j = 1; j < waypointBlocks[i].length - 1; j++) {
        waypoints.push({location: waypointBlocks[i][j], stopover: true});
      }

      await DirectionsService.route({
        origin: waypointBlocks[i][0],
        destination: waypointBlocks[i][waypointBlocks[i].length - 1],
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          finalDirectionResults.push(result);
        } else {
          console.error(`error fetching directions ${result}`);
          alert(`Error fetching directions in map`);
        }
      });
    }

    dispatch(setDirections(finalDirectionResults));
  }
}

export const handleGetDefaultDeliveryRateProfile = () => async (dispatch) => {
  try {
    const response = await getDefaultDeliveryRateProfile();
    dispatch(setDefaultDeliveryRateProfile(response.data));
  } catch (error) {
    console.log(error.message);
  }
}

export const handleGetVendor = () => async (dispatch, getState) => {
  try {
    let authedUser = JSON.parse(localStorage.user);
    const response = await getVendorByUsername(authedUser.userName);
    console.log(response.data);
    dispatch(setVendor(response.data));
  } catch (error) {
    console.log(error.message);
  }
};

export const handleGetBookmarkedAddresses = () => async (dispatch) => {
  try {
    const response = await getUserAddresses(localStorage.vendorId, "DROP_OFF", 0, 500);

    dispatch(getBookmarkedAddresses(response.data.content));
  } catch (error) {
    console.log(error.message);
  }
}

const handleUpdateOrders = async (orderGroupId, order, newOrdinal) => {
  try {
    let orderPayload = { 
      ...order,
      ordinal: newOrdinal,
      deliverDateTime: (new Date(order.deliverDateTime)).toISOString()
    }

    await updateOrder(orderGroupId, orderPayload, order.id);
  } catch (error) {
    alert(error?.response?.data?.message || error);
  }
}

export const handleOnSortOrder = (oldIndex, newIndex) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    let oldSortedOrders = [...state.order.orderGroup.orders].sort((a, b) => a.ordinal - b.ordinal);
    if (newIndex > oldIndex) {
      // Order moved top to bottom
      for (let i = oldIndex; i <= newIndex; i++) {
        let newOrdinal = i === oldIndex ? newIndex : i - 1;
        let order = oldSortedOrders[i];
        await handleUpdateOrders(state.order.orderGroup.id, order, newOrdinal);
      }
    } else {
      // Order moved bottom to top
      for (let i = oldIndex; i >= newIndex; i--) {
        let newOrdinal = i === oldIndex ? newIndex : i + 1;
        let order = oldSortedOrders[i];
        await handleUpdateOrders(state.order.orderGroup.id, order, newOrdinal);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(function () {
      dispatch(handleGetOrdersByOrderGroup(state.order.orderGroup.id));
      dispatch(hideLoading());
      dispatch(setSortingDisable(false))
    }, 1000);
  }
};

const validateOrders = (orderGroup) => {
  let result = true;
  orderGroup.orders.forEach((order) => {
    let pickUpTime = Moment(orderGroup.pickUpDateTime);
    let orderLocalTime = Moment(order.deliverDateTime);
    let minimumDropOffTime = Moment().add(5, "minutes")
    if (orderLocalTime.isBefore(minimumDropOffTime) || orderLocalTime.isBefore(pickUpTime)) {
      result = false;
    }
  });

  return result;
}

const getTotalDeliveryCharges = (unfilteredOrders) => {
  let totalCharges = 0;
  let orders = unfilteredOrders.filter(o => o.status !== "FAILED");

  if (orders && orders.length > 0) {
    if (orders.length > 1) {
      totalCharges = orders.map(o => o.deliveryFee / 100).reduce((a, b) => a + b).toFixed(2);
    } else {
      totalCharges = orders[0].deliveryFee / 100;
    }
  }

  return totalCharges;
}

export const handleConfirmOrders = () => async (dispatch, getState) => {
  dispatch(setConfirmButtonIsDisabled(true));
  const state = getState();

  if (Moment(state.order.orderGroup.pickUpDateTime).isBefore(Moment().add(5, "minutes"))) {
    dispatch(setConfirmButtonIsDisabled(false));
    alert("Pick up date should not be in the past and within 5 minutes from now.");
    return;
  }

  let authedUser = JSON.parse(localStorage.user);
  ReactPixel.init(FACEBOOK_PIXEL_ID, {em: authedUser.userName, fn: authedUser.firstName, ln: authedUser.lastName, ph: authedUser.contactNumber}, { debug: false, autoConfig: false });
  ReactPixel.track('InitiateCheckout', {
    content_category: 'Vendor',
    content_ids: [state.order.orderGroup.id],
    contents: [{"user": authedUser.userName, "orderGroupId": state.order.orderGroup.id, "type": "Confirm Order Group"}],
    currency: "MYR"
  });

  if (validateOrders(state.order.orderGroup) === true) {
    dispatch(showLoading());
    try {
      let voucher = null;
      if (localStorage.selectedVoucher) {
        voucher = JSON.parse(localStorage.selectedVoucher);
      }

      let confirmOrderPayload = {
        noteToDriver: state.order.confirmOrderPayload.noteToDriver,
        voucherId: voucher?.id ?? null
      }
      await confirmOrders(state.order.orderGroup.id, confirmOrderPayload);

      localStorage.removeItem('selectedVoucher');
      
      ReactPixel.track('Purchase', {
        content_ids: [state.order.orderGroup.id],
        content_type: "Confirm Order Group",
        contents: [state.order.orderGroup.orders],
        currency: "MYR",
        num_items: state.order.orderGroup.orders.length,
        value: getTotalDeliveryCharges(state.order.orderGroup.orders)
      });

      window.location = `/app/confirmed-order-groups/${state.order.orderGroup.id}`;
    } catch (error) {
      dispatch(setConfirmButtonIsDisabled(false));
      dispatch(hideLoading());
      alert(error.message)
    }
  } else {
    dispatch(setConfirmButtonIsDisabled(false));
    alert("Your orders contains deliver datetime which is in the past. Please edit to a correct date time and try again.")
  };
};

export const getPlaceLongName = (address_components, type) => {
  let component = address_components.find(c => c.types.includes(type));
  if (component) {
    return component.long_name
  }
  return null
}

export const selectState = state => state.order;

export default orderSlice.reducer;
