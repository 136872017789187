/*global google*/

import React, { useEffect, useState } from 'react';
import styles from './SavedAddress.module.css';
import { useDispatch } from 'react-redux';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { getPlaceLongName, getSavedAddresses, setShowCreateAddressModal } from './savedAddressSlice';
import { createAddress } from './addressAPI';

import locationPinIcon from "../../images/place_order/location-address-icon.png";
import { phoneUtil } from '../../app/utils';

export const CreateAddressModal = (props) => {
  const dispatch = useDispatch();

  const [bookmarkName, setBookmarkName] = useState("");
  const [personName, setPersonName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [autocompleteInit, setAutocompleteInit] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const initializeGoogleAutocomplete = (elementId) => {
      let autocompleteInput = document.getElementById(elementId);
      let autocomplete = new google.maps.places.Autocomplete(
        autocompleteInput,
        {
          types: ["geocode", "establishment"],
          componentRestrictions: { country: ["my", "sg"] }
        }
      );

      google.maps.event.addListener(autocomplete, "place_changed", function () {
        onPlaceChanged(autocomplete);
      });
    }

    let autocompleteInput = document.getElementById("autocomplete");
    if (!autocompleteInit && autocompleteInput !== null) {
      initializeGoogleAutocomplete("autocomplete");
      setAutocompleteInit(true);
    }
  }, [autocompleteInit]);

  const onPlaceChanged = (autocomplete) => {
    var place = autocomplete.getPlace();
    if (place) {
      try {
        setAddress(`${place.name}, ${place.formatted_address}`);
        setLocation({
          address: `${place.name}, ${place.formatted_address}`,
          coordinate: {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          },
          street: getPlaceLongName(place.address_components, "street_address"),
          apartment: getPlaceLongName(place.address_components, "street_address"),
          state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
          postalCode: getPlaceLongName(place.address_components, "postal_code"),
          city: getPlaceLongName(place.address_components, "locality"),
          country: getPlaceLongName(place.address_components, "country")
        });
      } catch (error) {
        setError(true);
        setErrorMessage("Please select an address from the drop-down list.");
      }
    }
  }

  const submit = async () => {
    let error = false;

    if (bookmarkName === "") {
      error = true;
      setError(true);
      setErrorMessage("Please enter a bookmark name.");
      return;
    }

    if (personName === "") {
      error = true;
      setError(true);
      setErrorMessage("Please enter a contact name.");
      return;
    }

    if (contactNumber === "") {
      error = true;
      setError(true);
      setErrorMessage("Please enter a contact number.");
      return;
    }

    if (address === "") {
      error = true;
      setError(true);
      setErrorMessage("Please select an address.");
      return;
    }

    if (!location.coordinate || !location.coordinate.longitude || location.coordinate.longitude === "" || location.coordinate.longitude === null) {
      error = true;
      setError(true);
      setErrorMessage("Please select an address from the drop-down list.");
      return;
    }

    var formattedPhoneNumber = "+" + contactNumber.replace(/\s/g, '').replace(/-/g, '');

    // make sure phone number start with + sign
    if (!formattedPhoneNumber.startsWith("+")) {
      error = true;
      setError(true);
      setErrorMessage("Contact number must start with the + sign followed by the country code (eg. +60).");
      return;
    }

    // validate phone number using google lib
    try {
      let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
      if (!phoneUtil.isValidNumber(phoneNumberProto)) {
        error = true;
        setError(true);
        setErrorMessage("Contact number is invalid.");
        return;
      }
    } catch (e) {
      error = true;
      setError(true);
      setErrorMessage("Contact number is invalid.");
      return;
    }

    if (!error) {
      try {
        var createAddrPayload;
        if (props.addrType === "PICK_UP") {
          createAddrPayload = {
            jobType: "PICK_UP",
            bookmarkName: bookmarkName,
            pickUpPersonName: personName,
            pickUpContactNumber: formattedPhoneNumber,
            location: {
              ...location,
              unitNumber: unitNumber,
            }
          }
        } else {
          createAddrPayload = {
            jobType: "DROP_OFF",
            bookmarkName: bookmarkName,
            recipientName: personName,
            recipientPhone: formattedPhoneNumber,
            location: {
              ...location,
              unitNumber: unitNumber,
            }
          }
        }
        await createAddress(createAddrPayload);
        dispatch(setShowCreateAddressModal(false));
        dispatch(getSavedAddresses(props.addrType));
      } catch (error) {
        setError(true);
        setErrorMessage(error.message);
      }
    }
  }

  return (
    <>
      <div className={styles.createAddressModal}>
        <div>
          <p className={styles.tealText} style={{ marginBottom: 10, fontSize: 12, fontWeight: 500, textAlign: "left" }}>Add New Address</p>

          {/* Input fields */}
          <div className={styles.inputContainer}>
            {/* Error Alert */}
            {
              error &&
              <Alert
                severity="error"
                style={{ marginBottom: 20, borderRadius: 10, textAlign: "left" }}
                onClose={() => { setError(false); setErrorMessage("") }}>
                {errorMessage}
              </Alert>
            }

            <div className={styles.inputRow}>
              <FormControl fullWidth className={styles.inputArea}>
                <InputLabel className={styles.inputLabel} htmlFor="bookmarkName">Bookmark Name</InputLabel>
                <OutlinedInput
                  id="bookmarkName"
                  label="bookmarkName"
                  value={bookmarkName}
                  onChange={e => setBookmarkName(e.target.value)}
                  startAdornment={<InputAdornment position="start"></InputAdornment>}
                  className={styles.inputText}
                />
              </FormControl>
              <FormControl fullWidth className={styles.inputArea}>
                <InputLabel className={styles.inputLabel} htmlFor="personName">Contact Name</InputLabel>
                <OutlinedInput
                  id="personName"
                  label="personName"
                  value={personName}
                  onChange={e => setPersonName(e.target.value)}
                  startAdornment={<InputAdornment position="start"></InputAdornment>}
                  className={styles.inputText}
                />
              </FormControl>
            </div>
            <div className={styles.inputRow}>
              <FormControl fullWidth className={styles.inputArea}>
                <InputLabel className={styles.contactNumberInputLabel} htmlFor="contactNumber">Contact Number</InputLabel>
                <PhoneInput
                  id="contactNumber"
                  name="contactNumber"
                  country={'my'}
                  countryCodeEditable={false}
                  preferredCountries={['my', 'sg', 'au', 'us']}
                  autoFormat={false}
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e)}
                  inputProps={{
                    required: true
                  }}
                  inputStyle={{ height: 50, fontSize: 12, fontWeight: "500" }}
                />
              </FormControl>
              <FormControl fullWidth className={styles.inputArea}>
                <InputLabel className={styles.inputLabel} htmlFor="unitNumber">Unit Number</InputLabel>
                <OutlinedInput
                  id="unitNumber"
                  label="unitNumber"
                  value={unitNumber}
                  onChange={e => setUnitNumber(e.target.value)}
                  startAdornment={<InputAdornment position="start"></InputAdornment>}
                  className={styles.inputText}
                />
              </FormControl>
            </div>
            <div className={styles.inputRow}>
              <FormControl fullWidth className={styles.inputArea}>
                <InputLabel className={styles.inputLabel} htmlFor="autocomplete">Address</InputLabel>
                <OutlinedInput
                  id="autocomplete"
                  name="autocomplete"
                  label="autocomplete"
                  value={address}
                  placeholder=""
                  onChange={e => setAddress(e.target.value)}
                  startAdornment={<InputAdornment position="start"><img className={styles.icon} alt="icon" style={{ margin: 0 }} src={locationPinIcon} /></InputAdornment>}
                  className={styles.inputText}
                />
              </FormControl>
            </div>
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.buttonsContainer} style={{ marginRight: 10 }}>
            <div className={styles.whiteButton} style={{ width: 80, paddingBlock: 12 }} onClick={() => dispatch(setShowCreateAddressModal(false))}>
              <p className={styles.tealText}>Cancel</p>
            </div>
            <div className={styles.tealButton} style={{ background: "linear-gradient(to right, #45b0b6, #227c97", width: 80, paddingBlock: 12 }} onClick={() => submit()}>
              <p className={styles.whiteText}>Save</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => dispatch(setShowCreateAddressModal(false))}></div>
    </>
  )
}