import { alpha, styled, Switch, Tooltip } from '@material-ui/core';
import styles from '../../components/common/Spinner.module.css';

export const TealSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#1e9299",
    '&:hover': {
      backgroundColor: alpha("#1e9299", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#1e9299",
  },
}));

export const InfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  ['& .MuiTooltip-arrow']: {
    color: "#303334",
  },
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: "#303334",
    paddingBlock: 7,
    paddingInline: 8,
    fontSize: 13,
  },
}));

export const LoadingOverlay = (props) => {
  return (
    <>
      {
        props.confirmingOrder &&
        <p className={styles.loadingDisclaimer}>Confirming your order... this process might take a while if you have a large order. Please do not refresh the page or click the "back" or "close" button of your browser.</p>
      }
      <div className={styles.loader} style={{ margin: "0px auto", top: "50%", fontSize: 15 }}></div>
      <div className={styles.overlay}></div>
    </>
  );
};