import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Card, CardHeader, CardContent, IconButton } from "@material-ui/core";
import { red } from '@material-ui/core/colors';
import { EditUser } from './EditUser';
import { ChangePassword } from './ChangePassword';
import { selectState, openEditUserModal, handleSetupCard, handleGetCreditCards, openChangePasswordModal, handleManageBilling } from './userSlice';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import visaIcon from 'payment-icons/min/flat/visa.svg';
import amexIcon from 'payment-icons/min/flat/amex.svg';
import mastercardIcon from 'payment-icons/min/flat/mastercard.svg';
import dinersIcon from 'payment-icons/min/flat/diners.svg';
import discoverIcon from 'payment-icons/min/flat/discover.svg';
import jcbIcon from 'payment-icons/min/flat/jcb.svg';
import unionpayIcon from 'payment-icons/min/flat/unionpay.svg';
import defaultIcon from 'payment-icons/min/flat/default.svg';
import styles from './User.module.css';
import { Topbar } from '../../components/topbar/Topbar';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export const renderCreditCard = (param) => {
    switch (param) {
        case 'amex':
            return amexIcon;
        case 'cartes_bancaires':
            return defaultIcon;
        case 'diners_club':
            return dinersIcon;
        case 'discover':
            return discoverIcon;
        case 'jcb':
            return jcbIcon;
        case 'mastercard':
            return mastercardIcon;
        case 'visa':
            return visaIcon;
        case 'unionpay':
            return unionpayIcon;
        default:
            return defaultIcon;
    }
}

export function User(props) {
    const state = useSelector(selectState);
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        if (state.status === 'idle') {
            dispatch(handleGetCreditCards());
        }
    });

    const humanizeProduct = (product) => {
        switch (product) {
            case "FOOD_AND_BEVERAGE":
                return "Food & Beverage";
            case "CAFES_OR_DESSERTS":
                return "Cafes / Desserts";
            case "HEALTH_CARE":
                return "Health Care";
            case "PARCELS":
                return "Parcels";
            case "OTHER":
                return "Others";
            default:
                return "";
        }
    }

    return (
        <div className={styles.container}>
            <Topbar />
            <div style={{ marginTop: 70, paddingTop: 30, paddingInline: 40 }}>
                {
                    state.user &&
                    <>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <Card className={classes.root}>
                                    <CardHeader
                                        avatar={<Avatar className={classes.avatar}>{state.user.userName[0].toUpperCase()}</Avatar>}
                                        title={state.user.userName}
                                        action={
                                            <IconButton aria-label="settings" onClick={() => dispatch(openEditUserModal())}>
                                                <EditIcon />
                                            </IconButton>
                                        }
                                    />
                                    <CardContent>
                                        <p>First Name: {state.user.firstName}</p>
                                        <p>Last Name: {state.user.lastName}</p>
                                        <p>Contact Number: {state.user.contactNumber}</p>
                                        <p>Company: {state.user.company}</p>
                                        <p>Product: {humanizeProduct(state.user.product)}</p>
                                        <br />
                                        <Link color="primary" onClick={() => dispatch(openChangePasswordModal())}>Change Password</Link>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <Card className={classes.root}>
                                    <CardHeader
                                        title="Payment Method"
                                        avatar={<Avatar><CreditCardIcon /></Avatar>}
                                        action={
                                            state.user.creditCard ? (
                                                <IconButton aria-label="manage billing" onClick={() => dispatch(handleManageBilling())}>
                                                    <SettingsIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton aria-label="add credit card" onClick={() => dispatch(handleSetupCard())}>
                                                    <AddIcon />
                                                </IconButton>
                                            )
                                        }
                                    />
                                    <CardContent>
                                        {
                                            state.user.creditCard ? (
                                                <div className={styles.hstack}>
                                                    <img alt="creditcard" className={styles.creditCardIcon} src={renderCreditCard(state.user.creditCard.brand)} />
                                                    <div className={styles.vstack}>
                                                        <span><b className={styles.creditCardText}>**** **** **** {state.user.creditCard.last4}</b></span>
                                                        <span className={styles.creditCardExpText}>Expiration: {state.user.creditCard.expMonth}/{state.user.creditCard.expYear}</span>
                                                    </div>
                                                </div>
                                            ) : "No Credit Card"
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    state.editUserModalOpen &&
                    <EditUser user={state.user} />
                }
                {
                    state.changePasswordModalOpen &&
                    <ChangePassword user={state.user} />
                }
            </div>
        </div>
    );
}
