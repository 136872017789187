import React from 'react';
import styles from './PlaceOrder.module.css';

import locationPinIcon from "../../images/place_order/location-pin-icon.png";
import mapMarkerIcon from "../../images/place_order/map-marker-icon.png";

export const CustomInfoWindow = (props) => {
  const { isPublicEstimate } = props;

  return (
    <div className={styles.infoWindowContainer}>
      <div className={styles.row} style={{ marginLeft: -3, padding: 0 }}>
        {
          isPublicEstimate ? (
            <>
              {
                props.index === 0
                  ? <>
                    <img className={styles.icon} style={{ marginLeft: 0, marginRight: 5 }} src={locationPinIcon} alt="location-pin" />
                    <p className={styles.infoTealText} style={{ fontSize: 16 }}>Pick-up</p>
                  </>
                  : <>
                    <img className={styles.icon} style={{ marginLeft: 0, marginRight: 5 }} src={mapMarkerIcon} alt="map-marker" />
                    <div className={styles.infoMarkerIconIndex}>{props.index}</div>
                    <p className={styles.infoTealText} style={{ fontSize: 16 }}>Drop-off</p>
                    {
                      props.deliveryFee &&
                      <div className={styles.infoDeliveryFee}>
                        <p className={styles.blackText} style={{ fontSize: 10 }}>RM {(props.deliveryFee / 100).toFixed(2)}</p>
                      </div>
                    }
                  </>
              }
            </>
          ) : (
            <>
              {
                props.index === 0
                  ? <>
                    <img className={styles.icon} style={{ marginLeft: 0, marginRight: 5 }} src={locationPinIcon} alt="location-pin" />
                    <p className={styles.infoTealText} style={{ fontSize: 16 }}>Pick-up</p>
                  </>
                  : <>
                    <img className={styles.icon} style={{ marginLeft: 0, marginRight: 5 }} src={mapMarkerIcon} alt="map-marker" />
                    <div className={styles.infoMarkerIconIndex}>{props.index}</div>
                    <p className={styles.infoTealText} style={{ fontSize: 16 }}>Drop-off</p></>
              }
            </>
          )
        }
      </div>
      <p className={styles.infoBlackText}>{props.location.address}</p>
      {
        !isPublicEstimate && (
          <>
            <div className={styles.horizontalDivider} style={{ marginBlock: 15 }} />
            {
              props.location.unitNumber !== "" && <p className={styles.infoBlackText}>{props.location.unitNumber}</p>
            }
            <p className={styles.infoBlackText}>{props.contactNumber}</p>
            <p className={styles.infoBlackText}>{props.name}</p>
            {
              props.halal && <p className={styles.infoTealText}>Halal</p>
            }
            {
              props.doorToDoor && <p className={styles.infoTealText}>Door-to-Door {props.currency} {(props.doorToDoorFee / 100).toFixed(2)}</p>
            }
            {
              props.codAmount > 0 && <p className={styles.infoTealText}>COD {props.currency} {(props.codAmount / 100).toFixed(2)}</p>
            }
            {
              props.deliveryFee &&
              <div style={{ display: "flex", flexDirection: "row", marginTop: -30 }}>
                <div className={styles.infoDeliveryFee}>
                  <p className={styles.blackText} style={{ fontSize: 10 }}>{props.currency} {(props.deliveryFee / 100).toFixed(2)}</p>
                </div>
              </div>
            }
            <div className={styles.button} style={{ marginTop: 10, paddingBlock: 3, borderRadius: 5 }} onClick={() => props.showEditModal(props.index - 1)}>
              <p className={styles.infoWhiteText}>Edit</p>
            </div>
          </>
        )
      }
    </div>
  )
}