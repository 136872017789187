import { loadStripe } from '@stripe/stripe-js';
import jwtDecode from 'jwt-decode';
import { setAuthState } from '../features/auth/authSlice';
import { refreshAccessToken } from './axios';
import store from './store';
export const API_BASE_URL = window.REACT_APP_API_GATEWAY_URL;
export const KEYCLOAK_BASE_URL = window.REACT_APP_KEYCLOAK_BASE_URL;
export const KEYCLOAK_CLIENT_ID = "parkit-user";
export const stripePromise = loadStripe(window.REACT_APP_STRIPE_API_KEY);
export const FACEBOOK_PIXEL_ID = window.REACT_APP_FACEBOOK_PIXEL_ID;

export async function getJwtToken() {
  const authState = store.getState().auth;
  if (!authState.is_authenticated && getRefreshToken()) {
    try {
      const res = await refreshAccessToken();
      store.dispatch(
        setAuthState({
          ...res.data,
          is_authenticated: true,
        })
      );
      return res.data.access_token;
    } catch (error) {
      if (error.response.status === 400) {
        logout();
      }
    }
  }
  return authState.access_token;
}

export function getRefreshToken() {
  return localStorage.refresh_token;
}

export async function getUserInfoFromJwt() {
  const jwtToken = await getJwtToken();
  return jwtDecode(jwtToken);
}

export function centToDollar(cent = 0) {
  return (cent / 100).toFixed(2);
}

export function dollarToCent(dollar = 0) {
  return (dollar * 100).toFixed(0);
}

export function isJSON(str) {
  try {
    return (JSON.parse(str) && !!str);
  } catch (e) {
    return false;
  }
}

export const logout = () => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("vendorId");
  localStorage.removeItem("user");
  localStorage.removeItem("selectedVoucher");
  window.location = "/login";
}

export const getNext15MinTimeBlock = (date, num) => {
  let mins = 15;
  // Copy date so don't affect original
  let d = new Date(date);
  // Get start of next block
  let dMins = d.getMinutes();
  let nextMin = Math.ceil(dMins/mins) * mins; 
  // If in first minute of first block, add 1
  if (!(dMins%mins)) nextMin += mins;
    
  d.setMinutes(nextMin, 0, 0);
  let result = [];
  
  while (num--) {
    result.push(new Date(d));
    d.setMinutes(d.getMinutes() + mins);
  }
  
  return result;
}

export const sleep = (time) => {
  return new Promise(resolve => setTimeout(resolve, time));
}

export const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();