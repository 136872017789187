/*global google*/
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import { closeEditOrderGroupModal, handleGetOrderGroups } from './orderGroupSlice';
import { handleGetOrdersByOrderGroup, getPlaceLongName } from '../order/orderSlice';
import { Close } from "@material-ui/icons";
import styles from './OrderGroup.module.css';
import DatePicker from "react-datepicker";
import { setMinutes, setHours } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import carTealIcon from "../../images/car-teal.png";
import carWhiteIcon from "../../images/car-white.png";
import motorWhiteIcon from "../../images/motor-white.png";
import motorTealIcon from "../../images/motor-teal.png";
import { updateOrderGroup } from './orderGroupAPI';
import { phoneUtil } from '../../app/utils';

export function EditOrderGroup(props) {
  const { orderGroup } = props;
  const dispatch = useDispatch();
  const [orderGroupState, _setOrderGroupState] = useStateWithCallbackLazy({
    form: {
      ...orderGroup
    },
    isPlaceChanged: true,
    error: false,
    init: false,
    autocompleteInit: false,
    errorMessage: "",
    isSubmitDisabled: false
  });

  const orderGroupStateRef = useRef(orderGroupState);

  const setOrderGroupState = data => {
    orderGroupStateRef.current = data;
    _setOrderGroupState(data);
  };

  useEffect(() => {
    if (orderGroup && !orderGroupState.init) {
      const {version, ...orderGroupForm} = {...orderGroup};
      setOrderGroupState({
        ...orderGroupState,
        init: true,
        form: { 
          ...orderGroupForm,
          halal: orderGroup.halal === true ? true : false,
          pickUpDateTime: new Date(orderGroup.pickUpDateTime)
        }
      });
    }

    let pickUpAutocompleteInput = document.getElementById("pickUpAutocomplete");
    if (!orderGroupState.autocompleteInit && pickUpAutocompleteInput !== null) {
      initializeGoogleAutocomplete("pickUpAutocomplete");
      setOrderGroupState({
        ...orderGroupState,
        autocompleteInit: true,
      });
    }
  });

  const onPlaceChanged = (autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === "pickUpAutocomplete") {
        _setOrderGroupState({
          ...orderGroupStateRef.current,
          isPlaceChanged: true,
          form: {
            ...orderGroupStateRef.current.form,
            pickUpLocation: {
              ...orderGroupStateRef.current.form.pickUpLocation,
              address: `${place.name}, ${place.formatted_address}`,
              coordinate: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              },
              street: getPlaceLongName(place.address_components, "street_address"),
              apartment: getPlaceLongName(place.address_components, "street_address"),
              state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
              postalCode: getPlaceLongName(place.address_components, "postal_code"),
              city: getPlaceLongName(place.address_components, "locality"),
              country: getPlaceLongName(place.address_components, "country")
            }
          }
        });
      }
    }
  }

  const initializeGoogleAutocomplete = (elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my", "sg"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function() {
      onPlaceChanged(autocomplete, elementId);
    });
  }

  const onFormValueChange = (data) => {
    setOrderGroupState({
      ...orderGroupState,
      form: {
        ...orderGroupState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async (props) => {
    setOrderGroupState({ ...orderGroupState, isSubmitDisabled: true });

    let error = false;

    if (orderGroupState.isPlaceChanged === false) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Please select address from the drop down", isSubmitDisabled: false});
      return;
    }

    if (new Date(orderGroupState.form.pickUpDateTime) - new Date() < 0) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Pick up date should not be in the past", isSubmitDisabled: false});
      return;
    }

    if (orderGroupState.form.deliveryRange === 0) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Pickup and dropoff location are the same", isSubmitDisabled: false});
      return;
    }

    for (var key in orderGroupState.form) {
      if (key !== "halal") {
        if (orderGroupState.form[key] === null || orderGroupState.form[key] === "") {
          error = true;
          setOrderGroupState({...orderGroupState, error: true, errorMessage: "All fields are required", isSubmitDisabled: false});
          return;
        }
      }
    }

    if (orderGroupState.form.pickUpLocation.country === null || orderGroupState.form.pickUpLocation.country === "") {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Please re-select the pick up address from dropdown list", isSubmitDisabled: false});
      return;
    }

    var formattedPhoneNumber = orderGroupState.form.pickUpContactNumber.replace(/\s/g, '').replace(/-/g, '')

    // make sure phone number start with + sign
    if (!formattedPhoneNumber.startsWith("+")) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Phone number must start with + sign and country code"});
      return;
    }

    // validate phone number using google lib
    try {
      let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
      if (!phoneUtil.isValidNumber(phoneNumberProto)) {
        error = true;
        setOrderGroupState({...orderGroupState, error: true, errorMessage: "Invalid phone number"});
        return;
      }
    } catch (e) {
      error = true;
      setOrderGroupState({...orderGroupState, error: true, errorMessage: "Invalid phone number"});
      return;
    }

    let orderGroupPayload = {
      ...orderGroupState.form,
      pickUpContactNumber: formattedPhoneNumber,
      pickUpDateTime: orderGroupState.form.pickUpDateTime.toISOString()
    }
    
    if (props.orderGroup.orders && props.orderGroup.orders.length > 0) {
      // Prevent user changing pickup location if there's existing orders
      orderGroupPayload = {
        ...orderGroupState.form,
        pickUpContactNumber: formattedPhoneNumber,
        pickUpLocation: {
          ...props.orderGroup.pickUpLocation
        },
        pickUpDateTime: orderGroupState.form.pickUpDateTime.toISOString(),
        orders: [
          ...orderGroupState.form.orders.map(o => o.deliverDateTime ? {...o, deliverDateTime: new Date(o.deliverDateTime).toISOString()} : o)
        ]
      }
    }

    if (!error) {
      try {
        await updateOrderGroup(orderGroupPayload, orderGroup.id);
        dispatch(closeEditOrderGroupModal());
        dispatch(handleGetOrderGroups(props.orderGroupType));
        dispatch(handleGetOrdersByOrderGroup(orderGroup.id));
      } catch (error) {
        setOrderGroupState({...orderGroupState, error: true, errorMessage: error.message, isSubmitDisabled: false});
      }
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditOrderGroupModal())}/>
          <h3 className={styles.subtitle}>Where do you want us to pick up from? <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={orderGroupState.error}>
            <p className={styles.errorMessage}>{orderGroupState.errorMessage}</p>
          </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p>Select your vehicle</p>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper className={styles.vehicleCardContainer} onClick={() => setOrderGroupState({...orderGroupState, form: { ...orderGroupState.form, deliveryMethod: "MOTOR"}})}>
                    <Grid container spacing={0}>
                      <Grid item xs={4} className={styles.vehicleIconContainer}><img alt='vehicle' src={orderGroupState.form.deliveryMethod === "MOTOR" ? motorTealIcon : motorWhiteIcon} className={styles.vehicleIcon} /></Grid>
                      <Grid item xs={8} className={orderGroupState.form.deliveryMethod === "MOTOR" ? styles.selectedVehicleCardContentContainer : styles.vehicleCardContentContainer}>
                        <p className={styles.vehicleCardTitle}>BIKE</p>
                        <p className={styles.vehicleCardText}>Suitable for Small to Medium deliveries</p>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={styles.vehicleCardContainer} onClick={() => setOrderGroupState({...orderGroupState, form: { ...orderGroupState.form, deliveryMethod: "CAR"}})}>
                    <Grid container spacing={0}>
                      <Grid item xs={4} className={styles.vehicleIconContainer}><img alt='vehicle' src={orderGroupState.form.deliveryMethod === "CAR" ? carTealIcon : carWhiteIcon} className={styles.vehicleIcon} /></Grid>
                      <Grid item xs={8} className={orderGroupState.form.deliveryMethod === "CAR" ? styles.selectedVehicleCardContentContainer : styles.vehicleCardContentContainer}>
                        <p className={styles.vehicleCardTitle}>CAR</p>
                        <p className={styles.vehicleCardText}>Suitable for Small to Large deliveries</p>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <p>Sender</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="unitNumber"
                  name="unitNumber"
                  value={orderGroupState.form.pickUpLocation.unitNumber}
                  onChange={e => setOrderGroupState({
                    ...orderGroupState,
                    form: {
                      ...orderGroupState.form,
                      pickUpLocation: {
                        ...orderGroupState.form.pickUpLocation,
                        unitNumber: e.target.value
                      }
                    }
                  })}
                  placeholder="Unit Number"
                  className={styles.textInput}
                  disabled={(orderGroup.orders && orderGroup.orders.length > 0) ? true : false}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <input
                  id="pickUpAutocomplete"
                  name="pickUpAddress"
                  value={orderGroupState.form.pickUpLocation.address}
                  onChange={e => setOrderGroupState({
                    ...orderGroupState,
                    isPlaceChanged: false,
                    form: {
                      ...orderGroupState.form,
                      pickUpLocation: {
                        ...orderGroupState.form.pickUpLocation,
                        address: e.target.value
                      }
                    }
                  })}
                  placeholder="Pick Up Address"
                  className={styles.textInput}
                  disabled={(orderGroup.orders && orderGroup.orders.length > 0) ? true : false}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <DatePicker
                  selected={orderGroupState.form.pickUpDateTime}
                  onChange={date => {
                    if (date.getHours() === 0) {
                      date.setHours(9);
                    }
                    onFormValueChange({name: "pickUpDateTime", value: date})
                  }}
                  showTimeSelect
                  dateFormat="Pp"
                  minDate={new Date()}
                  minTime={setHours(setMinutes(new Date(), 0), 6)}
                  maxTime={setHours(setMinutes(new Date(), 0), 23)}
                  filterTime={filterPassedTime}
                  timeIntervals={15}
                  className={styles.textInput}
                  placeholderText={"Delivery Date Time"}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <input
                  id="pickUpPersonName"
                  name="pickUpPersonName"
                  value={orderGroupState.form.pickUpPersonName}
                  onChange={e => onFormValueChange({name: "pickUpPersonName", value: e.target.value})}
                  placeholder="Pickup Person Name"
                  className={styles.textInput}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <input
                  id="pickUpContactNumber"
                  name="pickUpContactNumber"
                  value={orderGroupState.form.pickUpContactNumber}
                  onChange={e => onFormValueChange({name: "pickUpContactNumber", value: e.target.value})}
                  placeholder="Pickup Contact Number"
                  className={styles.textInput}
                />
              </Paper>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={orderGroupState.form.halal} onChange={e => onFormValueChange({name: "halal", value: e.target.checked })} name="halal" color="primary" />}
                  label="Delivery Items are Halal"
                />
              </FormGroup>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit(props)} className={styles.gradientButton} disabled={orderGroupState.isSubmitDisabled}>Update</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
