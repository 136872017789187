/*global google*/

import React, { createContext, useCallback, useEffect, useState } from 'react';
import styles from './PlaceOrder.module.css';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Alert } from '@material-ui/lab';

import { getPublicEstimate } from './orderGroupAPI';
import { getPlaceLongName } from './placeOrderSlice';
import { InfoTooltip, LoadingOverlay } from './common';
import { Topbar } from '../../components/topbar/Topbar';
import { Map } from '../../components/map/Map';
import { LoginModal } from '../login/LoginModal';
import { PublicEstimateMap } from './MapWithDirectons/PublicEstimateMap';

import targetIcon from "../../images/place_order/target-icon.png";
import plusIcon from "../../images/place_order/plus-icon.png";
import locationPinIcon from "../../images/place_order/location-pin-icon.png";
import mapMarkerIcon from "../../images/place_order/map-marker-icon.png";
import closeIcon from "../../images/place_order/close-icon.png";

const SortableItem = SortableElement(({ currIdx, order, removeLocation }) => {
  return (
    <div>
      <div className={styles.horizontalDivider} />
      <div className={styles.row}>
        <i className="fas fa-bars" style={{ color: "#d9d9d9", fontSize: 12, cursor: "pointer" }} />
        <img className={styles.icon} style={{ width: 28, marginLeft: 1, marginRight: 11 }} src={mapMarkerIcon} alt="map-marker" />
        <div className={styles.markerIconIndex}>{currIdx + 1}</div>
        <input
          className={styles.autocomplete}
          id={`dropOffAutocomplete-${currIdx}`}
          name="dropOffAddress"
          value={order.dropOffLocation.address}
          placeholder="Drop-off location"
          disabled
        />
        <img id={`deleteIcon-${currIdx}`} className={styles.icon} style={{ width: 12, marginLeft: "auto", paddingInline: 6, cursor: "pointer" }} src={closeIcon} alt="close" onClick={() => removeLocation(currIdx)} />
      </div>
      <div className={styles.horizontalDivider} />
    </div>
  )
});

const SortableList = SortableContainer(({ orders, removeLocation }) => {
  return (
    <div>
      {
        orders.map((order, index) => (
          <SortableItem
            key={`order-${order.ordinal}`}
            index={index}
            currIdx={index}
            order={order}
            removeLocation={removeLocation}
          />
        ))
      }
    </div>
  )
});

export const OrderGroupContext = createContext();
export const OrderContext = createContext();

export function PublicEstimate() {
  const [pickUpLocation, setPickUpLocation] = useState({
    address: "",
    coordinate: {
      latitude: "",
      longitude: ""
    },
    city: "",
    country: ""
  });
  const [dropOffForm, setDropOffForm] = useState({
    ordinal: "",
    dropOffLocation: {
      address: "",
      coordinate: {
        latitude: "",
        longitude: ""
      },
      city: "",
      country: ""
    },
  });
  const [orders, setOrders] = useState([]);
  const [directions, setDirections] = useState([]);
  const [nextOrdinal, setNextOrdinal] = useState(0);
  const [estimatedTotal, setEstimatedTotal] = useState(0);
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null
  });
  const [locationEnabled, setLocationEnabled] = useState(true);
  const [addStop, setAddStop] = useState(true);
  const [pickUpAutocompleteInit, setPickUpAutocompleteInit] = useState(false);
  const [dropOffAutocompleteInit, setDropOffAutocompleteInit] = useState(false);
  const [showPickUpInfoWindow, setShowPickUpInfoWindow] = useState(false);
  const [showDropOffInfoWindow, setShowDropOffInfoWindow] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const GOOGLE_DIRECTION_WAYPOINT_LIMIIT = 25;
  const getDirectionRoutes = useCallback(async () => {
    setDirections([]);

    const DirectionsService = new google.maps.DirectionsService();
    // Initiate locations with pickup location as origin
    let locations = [new google.maps.LatLng(pickUpLocation.coordinate.latitude, pickUpLocation.coordinate.longitude)];
    let sortedOrders = [...orders].sort((a, b) => a.ordinal - b.ordinal);

    if (sortedOrders.length > 0) {
      for (let i = 0; i < sortedOrders.length; i++) {
        locations.push(
          new google.maps.LatLng(sortedOrders[i].dropOffLocation.coordinate.latitude, sortedOrders[i].dropOffLocation.coordinate.longitude)
        );
      }

      let waypointBlocks = [];
      // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
      for (let i = 0, max = GOOGLE_DIRECTION_WAYPOINT_LIMIIT - 1; i < locations.length; i = i + max) {
        waypointBlocks.push(locations.slice(i, i + max + 1));
      }

      let finalDirectionResults = [];
      // Send requests to service to get route (for stations count <= GOOGLE_DIRECTION_WAYPOINT_LIMIIT only one request will be sent)
      for (let i = 0; i < waypointBlocks.length; i++) {
        // Waypoints does not include first station (origin) and last station (destination)
        var waypoints = [];
        for (var j = 1; j < waypointBlocks[i].length - 1; j++) {
          waypoints.push({ location: waypointBlocks[i][j], stopover: true });
        }

        await DirectionsService.route({
          origin: waypointBlocks[i][0],
          destination: waypointBlocks[i][waypointBlocks[i].length - 1],
          waypoints: waypoints,
          travelMode: google.maps.TravelMode.DRIVING
        }, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            finalDirectionResults.push(result);
          } else {
            console.error(`error fetching directions ${result}`);
            alert(`Error fetching directions in map`);
          }
        });
      }

      setDirections(finalDirectionResults);
    }
  }, [orders, pickUpLocation]);

  const onPlaceChanged = useCallback((autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === "pickUpAutocomplete") {
        try {
          setPickUpLocation({
            ...pickUpLocation,
            address: `${place.name}, ${place.formatted_address}`,
            coordinate: {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            },
            street: getPlaceLongName(place.address_components, "street_address"),
            apartment: getPlaceLongName(place.address_components, "street_address"),
            state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
            postalCode: getPlaceLongName(place.address_components, "postal_code"),
            city: getPlaceLongName(place.address_components, "locality"),
            country: getPlaceLongName(place.address_components, "country")
          })
        } catch (error) {
          setError(true);
          setErrorMessage("Please select a pick-up address from the drop-down list.");
        }
      } else if (addStop && elementId === `dropOffAutocomplete-${nextOrdinal}`) {
        try {
          const newStop = {
            ...dropOffForm,
            ordinal: nextOrdinal,
            dropOffLocation: {
              address: `${place.name}, ${place.formatted_address}`,
              coordinate: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              },
              street: getPlaceLongName(place.address_components, "street_address"),
              apartment: getPlaceLongName(place.address_components, "street_address"),
              state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
              postalCode: getPlaceLongName(place.address_components, "postal_code"),
              city: getPlaceLongName(place.address_components, "locality"),
              country: getPlaceLongName(place.address_components, "country")
            }
          }

          setOrders([
            ...orders,
            newStop,
          ]);

          setDropOffForm(newStop);
        } catch (error) {
          setError(true);
          setErrorMessage("Please select a drop-off address from the drop-down list.");
        }
      }
    }
  }, [addStop, dropOffForm, nextOrdinal, orders, pickUpLocation]);

  const initializeGoogleAutocomplete = useCallback((elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my", "sg"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function () {
      onPlaceChanged(autocomplete, elementId);
    });
  }, [onPlaceChanged]);

  const getEstimate = useCallback(async () => {
    try {
      setLoading(true);

      let payload = {
        pickUpLocation,
        orders,
      };

      const response = await getPublicEstimate(payload);

      setOrders(response.data.orders);

      // calculate estimated total
      let total = 0;
      response.data.orders.forEach((order) => {
        total += order.deliveryFee;
      });

      // reset list for showDropOffInfoWindow
      let showInfoWindow = [];
      for (let i = 0; i < response.data.orders.length; i++) {
        showInfoWindow.push(false);
      }

      setNextOrdinal(nextOrdinal + 1);
      setEstimatedTotal(total);
      setShowDropOffInfoWindow(showInfoWindow);
      setAddStop(false);
      setLoading(false);
      getDirectionRoutes();
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);
    }
  }, [nextOrdinal, pickUpLocation, orders, getDirectionRoutes]);

  useEffect(() => {
    if (
      pickUpLocation.coordinate.latitude &&
      pickUpLocation.coordinate.longitude &&
      dropOffForm.dropOffLocation.coordinate.latitude &&
      dropOffForm.dropOffLocation.coordinate.longitude &&
      pickUpLocation.address &&
      dropOffForm.dropOffLocation.address
    ) {
      getEstimate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpLocation, dropOffForm]);

  useEffect(() => {
    let pickUpAutocompleteInput = document.getElementById("pickUpAutocomplete");
    if (!pickUpAutocompleteInit && pickUpAutocompleteInput !== null) {
      initializeGoogleAutocomplete("pickUpAutocomplete");
      setPickUpAutocompleteInit(true);
    }
  }, [initializeGoogleAutocomplete, pickUpAutocompleteInit]);

  useEffect(() => {
    let dropOffAutocompleteInput = document.getElementById(`dropOffAutocomplete-${nextOrdinal}`);
    if (!dropOffAutocompleteInit && dropOffAutocompleteInput !== null) {
      initializeGoogleAutocomplete(`dropOffAutocomplete-${nextOrdinal}`);
      setDropOffAutocompleteInit(true);
    }
  }, [initializeGoogleAutocomplete, nextOrdinal, dropOffAutocompleteInit]);

  useEffect(() => {
    navigator.geolocation.watchPosition(async (position) => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setLocationEnabled(true);
    }, async () => {
      if (locationEnabled) {
        setLocationEnabled(false);
      }
    });
  }, [locationEnabled]);

  const handleUseCurrentLocation = () => {
    if (!locationEnabled) {
      setError(true);
      setErrorMessage("Please enable your location services to retrieve your current location.");
      return;
    }

    if (currentLocation.lat == null) {
      setError(true);
      setErrorMessage("Retrieving your current location... Please try again after 10 seconds.");
      return;
    }

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: currentLocation }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          let autocompleteInput = document.getElementById("pickUpAutocomplete");
          autocompleteInput.value = results[0].formatted_address;

          setPickUpLocation({
            ...pickUpLocation,
            address: `${results[0].formatted_address}`,
            coordinate: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            },
            street: getPlaceLongName(results[0].address_components, "street_address"),
            apartment: getPlaceLongName(results[0].address_components, "street_address"),
            state: getPlaceLongName(results[0].address_components, "administrative_area_level_1"),
            postalCode: getPlaceLongName(results[0].address_components, "postal_code"),
            city: getPlaceLongName(results[0].address_components, "locality"),
            country: getPlaceLongName(results[0].address_components, "country")
          });

          setError(false);
          setErrorMessage('');
        } else {
          setError(true);
          setErrorMessage("Error retrieving your current location.");
        }
      } else {
        setError(true);
        setErrorMessage("Error retrieving your current location.");
      }
    });
  };



  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let orderList = [...orders].sort((a, b) => a.ordinal - b.ordinal);
      if (newIndex > oldIndex) {
        for (let i = oldIndex; i <= newIndex; i++) {
          let newOrdinal = i === oldIndex ? newIndex : i - 1;
          orderList[i] = { ...orderList[i], ordinal: newOrdinal };
        }
      } else {
        for (let i = oldIndex; i >= newIndex; i--) {
          let newOrdinal = i === oldIndex ? newIndex : i + 1;
          orderList[i] = { ...orderList[i], ordinal: newOrdinal };
        }
      }

      orderList.sort((a, b) => a.ordinal - b.ordinal);
      setOrders(orderList);
      getEstimate();
    }
  }

  return (
    <>
      {loading && <LoadingOverlay />}
      {showLoginModal && <LoginModal onClose={() => { setShowLoginModal(false) }} />}

      <div className={styles.container}>
        <Topbar page="Place Order" />

        {/* Order Menu */}
        <div className={styles.orderMenu}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <p className={styles.whiteTitle}>Ready to deliver?</p>
            <InfoTooltip
              title="Drag and rearrange the drop-off locations to obtain the cheapest delivery rates."
              placement="right"
              arrow>
              <div style={{ marginLeft: "auto" }}>
                <i className="fas fa-info-circle" style={{ color: "#53d9e0", fontSize: 20 }} />
              </div>
            </InfoTooltip>
          </div>

          {/* Error Alert */}
          {
            error &&
            <Alert
              severity="error"
              style={{ marginTop: 5, borderRadius: 10, textAlign: "left" }}
              onClose={() => {
                setError(false);
                setErrorMessage('');
              }}>
              {errorMessage}
            </Alert>
          }

          {/* Pick-Up Location */}
          <div className={styles.pickUpAddressContainer}>
            <div className={styles.row} style={{ paddingBlock: 12 }}>
              <img className={styles.icon} src={locationPinIcon} alt="location-pin" />
              <input
                className={styles.autocomplete}
                id="pickUpAutocomplete"
                name="pickUpAddress"
                placeholder="Enter pick-up location"
              />
              <img className={styles.icon} style={{ marginLeft: "auto", cursor: "pointer" }} src={targetIcon} alt="target" onClick={() => handleUseCurrentLocation()} />
            </div>
          </div>

          {/* Drop-off Location */}
          <div className={styles.dropOffContainer}>
            {/* Sortable Orders */}
            {
              pickUpLocation.coordinate.latitude !== "" && orders && orders.length > 0 &&
              <SortableList
                shouldCancelStart={(e) => {
                  let cancel = false;
                  orders.forEach((order, index) => {
                    if (e.target === document.getElementById(`dropOffAutocomplete-${index}`)
                      || e.target === document.getElementById(`deleteIcon-${index}`)) {
                      cancel = true;
                    }
                  });
                  return cancel;
                }}
                onSortEnd={onSortEnd}
                orders={orders}
                removeLocation={(idx) => {
                  orders.splice(idx, 1);
                  getEstimate();
                }}
              />
            }

            {/* New Order */}
            {
              addStop &&
              <div>
                <div className={styles.horizontalDivider} />
                <div className={styles.row} style={{ paddingBlock: 12 }}>
                  <img className={styles.icon} style={{ width: 28, marginInline: 11 }} src={mapMarkerIcon} alt="map-marker" />
                  {
                    pickUpLocation.coordinate.latitude !== ""
                      ? <div className={styles.markerIconIndex}>{orders.length + 1}</div>
                      : <div className={styles.markerIconIndex}>1</div>
                  }
                  <input
                    className={styles.autocomplete}
                    id={`dropOffAutocomplete-${nextOrdinal}`}
                    name="dropOffAddress"
                    placeholder="Enter drop-off location"
                  />
                  <img id={`deleteIcon-${orders.length + 1}`} className={styles.icon} style={{ width: 12, marginLeft: "auto", paddingInline: 6, cursor: "pointer" }} src={closeIcon} alt="close" onClick={() => setAddStop(false)} />
                </div>
                <div className={styles.horizontalDivider} />
              </div>
            }
            <div className={styles.horizontalDivider} />
            <div className={styles.row} style={{ paddingBlock: 15, cursor: "pointer" }} onClick={() => { setAddStop(true); setDropOffAutocompleteInit(false); }}>
              <img className={styles.icon} src={plusIcon} alt="plus" />
              <p className={styles.tealText}>Add Stop</p>
            </div>
          </div>

          {/* Bottom Container */}
          <div className={styles.bottomContainer}>
            {/* Disclaimer */}
            <div style={{ display: "flex" }}>
              <i className="fas fa-exclamation-circle" style={{ marginRight: 5, color: "#face3e", fontSize: 15 }} />
              <p className={styles.whiteTitle} style={{ fontSize: 12 }}>Order progress will not be saved unless you are logged in.</p>
            </div>

            {/* Estimated Total */}
            <div className={styles.totalContainer}>
              <p className={styles.tealText}>Estimated Total</p>
              <p className={styles.blackText} style={{ marginLeft: "auto", fontSize: 17 }}>RM {(estimatedTotal / 100).toFixed(2)}</p>
            </div>

            {/* Confirm Order */}
            <div className={styles.button} onClick={() => setShowLoginModal(true)}>
              <p className={styles.whiteText}>Login</p>
              <p className={styles.whiteText} style={{ marginTop: 5, fontSize: 11, fontWeight: "400" }}>before placing an order to save your progress.</p>
            </div>
          </div>
        </div>

        {/* Map */}
        {
          window.innerWidth > 850 &&
          <div className={styles.map}>
            {
              directions.length > 0
                ? <PublicEstimateMap
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: "100%" }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  showPickUpInfoWindow={showPickUpInfoWindow}
                  showDropOffInfoWindow={showDropOffInfoWindow}
                  pickUpLocation={pickUpLocation}
                  orders={orders}
                  directions={directions}
                  setShowPickUpInfoWindow={(show) => setShowPickUpInfoWindow(show)}
                  setShowDropOffInfoWindow={(idx, show) => {
                    let showInfoWindow = [];
                    for (let i = 0; i < orders.length; i++) {
                      showInfoWindow.push(false);
                    }
                    showInfoWindow[idx] = show;
                    setShowDropOffInfoWindow(showInfoWindow);
                  }}
                />
                : <Map
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: "100%" }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
            }
          </div>
        }
      </div>
    </>
  )
}