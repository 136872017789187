import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination, TableFooter, Grid } from "@material-ui/core";
import { handleGetTransactions, openTopUpModal, selectState, setPage, setRowsPerPage, closeTopUpModal, handleGetPendingPayments, setPendingPaymentPage, setPendingPaymentRowsPerPage } from './walletSlice';
import { selectState as selectHomeState } from '../home/homeSlice';
import TablePaginationActions from './TablePaginationActions';
import { handleGetInvoice, handleGetTopUpInvoice } from '../invoice/invoiceSlice';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import topUpIcon from "../../images/icons/top-up.png";
import calendarIcon from "../../images/icons/calendar.png";
import styles from './Wallet.module.css';
import { centToDollar } from '../../app/utils';
import moment from 'moment';
import { Topbar } from '../../components/topbar/Topbar';
import { TopUpModal } from '../place_order/TopUpModal';

export function Wallet(props) {
  const state = useSelector(selectState);
  const homeState = useSelector(selectHomeState);
  const dispatch = useDispatch();
  const dateRangeRef = useRef();
  const [dateRange, setDateRange] = useState([new Date(moment().subtract(30, 'd')), new Date()]);
  const [startDate, endDate] = dateRange;
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetTransactions(dateRange));
      dispatch(handleGetPendingPayments());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetTransactions(dateRange));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetTransactions(dateRange));
  };

  const handleChangePendingPaymentPage = (event, newPage) => {
    dispatch(setPendingPaymentPage(newPage));
    dispatch(handleGetPendingPayments());
  };

  const handleChangePendingPaymentRowsPerPage = (event) => {
    dispatch(setPendingPaymentRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPendingPaymentPage(0));
    dispatch(handleGetPendingPayments());
  };

  const renderBalanceAmount = (balanceAmount) => {
    return (balanceAmount && balanceAmount > 0) ? centToDollar(balanceAmount) : '0.00';
  }

  const onDateRangeChange = (dates) => {
    setDateRange(dates);

    if (dates[0] !== null && dates[1] !== null) {
      dispatch(handleGetTransactions(dates));
    }
  };

  return (
    <div className={styles.container}>
      <Topbar />

      <div style={{ marginTop: 70, paddingTop: 30, paddingInline: 40 }}>
        <Grid container spacing={3} style={{ marginBottom: 30 }}>
          <Grid item xs={12} sm={6} lg={8}>
            <Paper variant="outlined" className={styles.filterContainer}>
              <p className={styles.f18}><b>Balance History</b></p>
              <div className={styles.filterInputContainer}>
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onDateRangeChange}
                  className={styles.textInput}
                  selectsRange={true}
                  ref={dateRangeRef}
                />
                <img alt='calendar' onClick={() => dateRangeRef.current.setOpen(true)} src={calendarIcon} className={styles.inputIcon} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={styles.balanceContainer}>
              <div className={styles.hstack}>
                <div className={styles.vstack}>
                  <p style={{ fontSize: 14, marginBottom: 4 }}>Wallet Balance</p>
                  <p style={{ fontSize: 20, marginTop: 0 }}><b>MYR {renderBalanceAmount(homeState.wallet.balanceAmount)}</b></p>
                </div>
                <Button onClick={() => dispatch(openTopUpModal())} className={styles.topUpButton}>
                  <img alt='topup' src={topUpIcon} className={styles.topUpIcon} />Top Up
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className={styles.denseTable}>
          <Table size="small">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell align="left" className={styles.tableHeaderNarrow}>Transaction Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Transaction Time</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Amount(RM)</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Payment Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                state.transactions.length > 0 ? (
                  state.transactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell align="left" className={styles.tableCellNarrow}>{transaction.type}</TableCell>
                      <TableCell align="left">{moment(transaction.createdAt).format("MM/DD/YYYY, hh:mm a")}</TableCell>
                      <TableCell align="left">
                        <span className={transaction.type === "TOPUP" ? styles.greenFont : null}>
                          {transaction.type === "TOPUP" ? "+" + centToDollar(transaction.topUp.topUpAmount) : "-" + centToDollar(transaction.payment.finalPaymentAmount)}
                        </span>
                      </TableCell>
                      <TableCell align="left">{transaction.type === "PAYMENT" ? transaction.payment.status : "SUCCESS"}</TableCell>
                      <TableCell align="left">
                        {
                          transaction.type === "TOPUP" ? (
                            <a className={styles.tableLink} onClick={() => dispatch(handleGetTopUpInvoice(homeState.wallet.id, transaction.topUp.id))}>Receipt</a>
                          ) : ''
                        }
                        {
                          transaction.type === "PAYMENT" ? (
                            <>
                              <a className={styles.tableLink} onClick={() => dispatch(handleGetInvoice(homeState.wallet.id, transaction.payment.id))}>Receipt</a>
                              <a className={`${styles.tableLink} ${styles.ml15p}`} target="_blank" rel="noreferrer" href={`/app/completed-order-groups/${transaction.payment?.metadata?.orderGroupId}`}>View Order</a>
                            </>
                          ) : ''
                        }
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={14} align="center">
                      <p>No Transactions</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={7}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <hr className={styles.hline} />
        <p><b>Pending Payments</b></p>
        <TableContainer component={Paper} className={styles.denseTable}>
          <Table size="small">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell align="left" className={styles.tableHeaderNarrow}>Transaction Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Transaction Time</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Amount(RM)</TableCell>
                <TableCell align="left" className={styles.tableHeader}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                state.pendingPayments.length > 0 ? (
                  state.pendingPayments.map((pendingPayment) => (
                    <TableRow key={pendingPayment.id}>
                      <TableCell align="left" className={styles.tableCellNarrow}>PAYMENT</TableCell>
                      <TableCell align="left">{moment(pendingPayment.createdAt).format("MM/DD/YYYY, hh:mm a")}</TableCell>
                      <TableCell align="left">
                        {"-" + centToDollar(pendingPayment.initialRequestedAmount)}
                      </TableCell>
                      <TableCell align="left">
                        <a className={styles.tableLink} rel="noreferrer" target="_blank" href={`/app/completed-order-groups/${pendingPayment.metadata?.orderGroupId}`}>View Order</a>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={14} align="center">
                      <p>No Pending Payments</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={7}
                  count={state.pendingPaymentPagination ? state.pendingPaymentPagination.totalElements ? state.pendingPaymentPagination.totalElements : 0 : 0}
                  rowsPerPage={state.pendingPaymentRowsPerPage}
                  page={state.pendingPaymentPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePendingPaymentPage}
                  onRowsPerPageChange={handleChangePendingPaymentRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {
          state.topUpModalOpen &&
          <TopUpModal closeModal={closeTopUpModal} />
        }
      </div>
    </div>
  );
}
