import React, { useEffect, createRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import ReactPixel from 'react-facebook-pixel';
import Pdf from "react-to-pdf";
import { selectState, handleGetOrdersByOrderGroup } from './invoiceSlice';
import { FACEBOOK_PIXEL_ID } from '../../app/utils';
import styles from './Invoice.module.css';
import logo from "../../images/logo.png";
import shopIcon from "../../images/icons/shop.png";
import bankTransferIcon from "../../images/icons/bank-transfer-highlight.png";

export function Invoice(props) {
  const state = useSelector(selectState);
  const receiptRef = createRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetOrdersByOrderGroup(id));
      let authedUser = JSON.parse(localStorage.user);
      ReactPixel.init(FACEBOOK_PIXEL_ID, authedUser ? {em: authedUser.userName, fn: authedUser.firstName, ln: authedUser.lastName, ph: authedUser.contactNumber} : {}, { debug: false, autoConfig: false });
      ReactPixel.pageView();
      ReactPixel.fbq('track', 'PageView');
    }
  });

  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString("en-UK", {year: 'numeric', day: 'numeric', month: 'long'});
  }

  const getTotalDeliveryCharges = (unfilteredOrders) => {
    let totalCharges = 0;
    let orders = unfilteredOrders.filter(o => o.status !== "FAILED");

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => (o.deliveryFee + (o.doorToDoorFee ? o.doorToDoorFee : 0)) / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        let doorToDoorFee = orders[0].doorToDoorFee ? orders[0].doorToDoorFee : 0
        totalCharges = (orders[0].deliveryFee + doorToDoorFee) / 100;
      }
    }

    return totalCharges;
  }

  return (
    <div className={styles.container}>
      {
        state.orderGroup &&
        <Grid container component={Paper} spacing={1} className={styles.invoiceContainer} ref={receiptRef}>
          <Grid item xs={12}>
            <p className={styles.title}>INVOICE</p>
          </Grid>
          <Grid item xs={6}>
            <div className={styles.logoContainer}>
              <img src={logo} alt="logo" className={styles.logo} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={styles.logoContainer}>
              <img src={shopIcon} alt="logo" className={styles.shopIcon} />
            </div>
          </Grid>
          <Grid item xs={6} style={{marginBottom: 20}}>
            <p className={styles.subtitle}>ParkIt Solutions Sdn Bhd</p>
            <p className={styles.description}>B-06-02, Sunway Geo Avenue</p>
            <p className={styles.description}>Jalan Lagoon Selatan, Bandar Sunway</p>
            <p className={styles.description}>47500, Subang Jaya</p>
            <p className={styles.description}>Selangor, Malaysia</p>
          </Grid>
          <Grid item xs={6}>
            <div className={styles.floatRight}>
              <p className={styles.subtitle}>{state.orderGroup.user ? `${state.orderGroup.user.firstName} ${state.orderGroup.user.lastName}` : "" }</p>
              <p className={styles.description}>{state.orderGroup.user ? state.orderGroup.user.company : "" }</p>
            </div>
          </Grid>
          <Grid item xs={12} className={styles.mb20}>
            <Grid container spacing={1} className={styles.invoiceNumberContainer}>
              <Grid item xs={8} sm={6} md={4} lg={3}>
                <p className={styles.description}>Invoice Number</p>
                <p className={styles.subtitle} style={{marginTop: 5}}>{state.orderGroup.invoiceId}</p>
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={2}>
                <p className={styles.description}>Invoice Date</p>
                <p className={styles.subtitle} style={{marginTop: 5}}>{formatDate(state.orderGroup.invoiceIssueDate)}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.mb20}>
            <TableContainer>
              <Table size="small">
                <TableHead className={styles.tableHeader}>
                  <TableRow>
                    <TableCell className={styles.tableHeader}>Order ID</TableCell>
                    <TableCell className={styles.tableHeader}>Description</TableCell>
                    <TableCell align="right" className={styles.tableHeader}>Delivery Fees</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    state.orderGroup.orders.map(order => order.status === "FAILED" ? (
                      <Fragment>
                        <TableRow key={order.id}>
                          <TableCell><strike>{order.id}</strike></TableCell>
                          <TableCell><strike>{order.invoiceDescription}</strike></TableCell>
                          <TableCell align="right"><strike>{order.deliveryFee / 100}</strike></TableCell>
                        </TableRow>,
                        {order.doorToDoor && 
                          <TableRow key={order.id}>
                            <TableCell><strike>{order.id}</strike></TableCell>
                            <TableCell><strike>{order.doorToDoorInvoiceDescription}</strike></TableCell>
                            <TableCell align="right"><strike>{order.doorToDoorFee / 100}</strike></TableCell>
                          </TableRow>}
                      </Fragment>
                    ): (
                      <Fragment>
                        <TableRow key={order.id}>
                          <TableCell>{order.id}</TableCell>
                          <TableCell>{order.invoiceDescription}</TableCell>
                          <TableCell align="right">{order.deliveryFee / 100}</TableCell>
                        </TableRow>
                        {order.doorToDoor && 
                          <TableRow key={order.id}>
                            <TableCell>{order.id}</TableCell>
                            <TableCell>{order.doorToDoorInvoiceDescription}</TableCell>
                            <TableCell align="right">{order.doorToDoorFee / 100}</TableCell>
                          </TableRow>}
                      </Fragment>
                      
                    ))
                  }
                  <TableRow key="subtotal">
                    <TableCell colSpan={1}></TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                    <TableCell align="right">RM {getTotalDeliveryCharges(state.orderGroup.orders)}</TableCell>
                  </TableRow>
                  <TableRow key="total">
                    <TableCell colSpan={1}></TableCell>
                    <TableCell align="right"><b>Amount Due</b></TableCell>
                    <TableCell align="right"><b>RM {getTotalDeliveryCharges(state.orderGroup.orders)}</b></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {
            state.orderGroup.paid ? (
              <Grid item xs={12} md={6}>
                <div>
                  <img src={bankTransferIcon} alt="Bank Transfer" className={styles.paymentMethodIcon} />
                  <p className={styles.paidText}>PAID</p>
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} md={6} className={styles.mb20}>
                <p className={styles.dueDateText}>Due Date: {formatDate(state.orderGroup.invoiceDueDate)}</p>
                <div className={styles.mb30}>
                  <img src={bankTransferIcon} alt="Bank Transfer" className={styles.paymentMethodIcon} />
                  <span className={styles.paymentMethodText}>Pay by Online Bank Transfer</span>
                </div>
                <p className={styles.paymentInstructionText}>Please make payment to <b>ParkIt Solutions Sdn Bhd</b></p>
                <p className={styles.paymentInstructionText}>Bank Name: <b>CIMB Bank Berhad</b></p>
                <p className={styles.paymentInstructionText}>Account No.: <b>8008342620</b></p>
              </Grid>
            )
          }
          <Grid item xs={12} className={styles.mb30} style={{marginTop: 40}}>
            <p className={styles.faqText}>If you have any questions, please contact ParkIt Solutions Sdn. Bhd.</p>
            <p className={styles.faqText}>at <b>finance@parkitmy.com</b></p>
          </Grid>
        </Grid>
      }
      {
        state.orderGroup &&
        <Pdf scale={0.7} targetRef={receiptRef} filename={`deliverit-${state.orderGroup.invoiceId}.pdf`} options={{compress: true}}>
          {({toPdf}) => (
            <div className={styles.buttonContainer}>
              <button className={styles.gradientButton} onClick={() => toPdf()}>Save PDF</button>
            </div>
          )}
        </Pdf>
      }
    </div>
  );
}
