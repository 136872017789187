import React from 'react';

import { PlaceOrder } from './PlaceOrder';
import { PublicEstimate } from './PublicEstimate';

export function MainOrder() {
  return (
    <>
      {
        localStorage.refresh_token
          ? <PlaceOrder />
          : <PublicEstimate />
      }
    </>
  )
}