/*global google*/
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, FormGroup, FormControlLabel, Switch, TextField, Typography } from "@material-ui/core";
import { createOrder } from './orderAPI';
import { Autocomplete } from "@material-ui/lab";
import { closeCreateOrderModal, handleGetOrdersByOrderGroup, getPlaceLongName } from './orderSlice';
import { Close, ChevronLeft } from "@material-ui/icons";
import styles from './Order.module.css';
import DatePicker from "react-datepicker";
import { setMinutes, setHours } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { dollarToCent, phoneUtil } from '../../app/utils';

export function CreateOrder(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      ordinal: "",
      recipientName: "",
      recipientEmail: "",
      deliverDateTime: new Date(props.orderGroup.pickUpDateTime),
      recipientPhone: "",
      dropOffLocation: {
        unitNumber: "",
        address: "",
        coordinate:  {
          latitude: "", 
          longitude: "" 
        },
        city: "",
        country: ""
      },
      noteToDriver: "",
      product: "",
      codAmount: "",
      doorToDoor: false,
      deliveryRange: 0,
      receiptNumber: ""
    },
    selectedBookmarkAddress: "",
    bookmarkedAddresses: props.bookmarkedAddresses,
    currentStep: 0,
    isCodEnabled: false,
    isPlaceChanged: false,
    autocompleteInit: false,
    error: false,
    errorMessage: ""
  };
  const [orderState, _setOrderState] = useStateWithCallbackLazy({
    ...initialState
  });
  const orderStateRef = useRef(orderState);

  const setOrderState = data => {
    orderStateRef.current = data;
    _setOrderState(data);
  };

  useEffect(() => {
    let dropOffAutocompleteInput = document.getElementById("dropOffAutocomplete");
    if (!orderState.autocompleteInit && dropOffAutocompleteInput !== null) {
      initializeGoogleAutocomplete("dropOffAutocomplete");
      setOrderState({
        ...orderState,
        form: {
          ...orderState.form
        },
        autocompleteInit: true,
      });
    }
  });

  const onPlaceChanged = (autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === "dropOffAutocomplete") {
        _setOrderState({
          ...orderStateRef.current,
          isPlaceChanged: true,
          form: {
            ...orderStateRef.current.form,
            dropOffLocation: {
              ...orderStateRef.current.form.dropOffLocation,
              address: `${place.name}, ${place.formatted_address}`,
              coordinate: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              },
              street: getPlaceLongName(place.address_components, "street_address"),
              apartment: getPlaceLongName(place.address_components, "street_address"),
              state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
              postalCode: getPlaceLongName(place.address_components, "postal_code"),
              city: getPlaceLongName(place.address_components, "locality"),
              country: getPlaceLongName(place.address_components, "country")
            }
          }
        });
      }
    }
  }

  const initializeGoogleAutocomplete = (elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my", "sg"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function() {
      onPlaceChanged(autocomplete, elementId);
    });
  }

  const onValueChange = (data) => {
    setOrderState({
      ...orderState,
      [data.name]: data.value,
      form: {
        ...orderState.form
      }
    })
  }

  const onFormValueChange = (data) => {
    setOrderState({
      ...orderState,
      form: {
        ...orderState.form,
        [data.name]: data.value
      }
    })
  }

  const nextWithBookmarkedAddress = () => {
    let selectedAddressObj = orderState.selectedBookmarkAddress;
    _setOrderState({
      ...orderState,
      currentStep: 1,
      isPlaceChanged: (selectedAddressObj.location && selectedAddressObj.location.address) ? true : false,
      form: {
        ...orderState.form,
        recipientName: selectedAddressObj.recipientName,
        recipientPhone: selectedAddressObj.recipientPhone,
        recipientEmail: selectedAddressObj.recipientEmail,
        // noteToDriver: selectedAddressObj.deliveryNoteToDriver,
        dropOffLocation: {
          ...orderState.form.dropOffLocation,
          ...selectedAddressObj.location
        }
      }
    });
  }

  const nextWithNewAddress = () => {
    _setOrderState({
      ...initialState,
      currentStep: 1,
    });
  }

  const setCurrentStep = (newStep) => {
    setOrderState({
      ...orderState,
      currentStep: newStep,
      form: {
        ...orderState.form
      }
    })
  }

  const submit = async () => {
    let error = false;

    if (orderState.isPlaceChanged === false) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Please select address from the drop down"});
      return;
    }

    if (new Date(orderState.form.deliverDateTime) - new Date() < 0) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Delivery date should not be in the past"});
      return;
    }

    if (new Date(orderState.form.deliverDateTime) - new Date(props.orderGroup.pickUpDateTime) < 0) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Delivery date should not earlier than pickup date"});
      return;
    }

    for (var key in orderState.form) {
      if (key !== "recipientEmail" && key !== "product" && key !== "ordinal" && key !== "hadDelivered" && key !== "codAmount" && key !== "assigned" && key !== "receiptNumber" && key !== "deliveryRange" && key !== "doorToDoor" && key !== "noteToDriver" && key !== "doorToDoorFee") {
        if (orderState.form[key] === null || orderState.form[key] === "") {
          error = true;
          setOrderState({...orderState, error: true, errorMessage: "All fields are required"});
          return;
        }
      }
    }

    if (orderState.isCodEnabled && (orderState.form.codAmount === null || isNaN(orderState.form.codAmount) || orderState.form.codAmount <= 0)) {
      setOrderState({...orderState, error: true, errorMessage: "COD Amount is required"});
      return;
    }

    if (orderState.form.dropOffLocation.country === null || orderState.form.dropOffLocation.country === "") {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Please re-select the dropoff address from dropdown list"});
      return;
    }

    var formattedPhoneNumber = orderState.form.recipientPhone.replace(/\s/g, '').replace(/-/g, '')

    // make sure phone number start with + sign
    if (!formattedPhoneNumber.startsWith("+")) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Phone number must start with + sign and country code"});
      return;
    }

    // validate phone number using google lib
    try {
      let phoneNumberProto = phoneUtil.parse(formattedPhoneNumber, "")
      if (!phoneUtil.isValidNumber(phoneNumberProto)) {
        error = true;
        setOrderState({...orderState, error: true, errorMessage: "Invalid phone number"});
        return;
      }
    } catch (e) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Invalid phone number"});
      return;
    }

    if (!error) {
      try {
        // Convert Datetime to UTC
        let orderPayload = {
          ...orderState.form,
          recipientPhone: formattedPhoneNumber,
          ordinal: props.orderGroup.orders.length,
          deliverDateTime: orderState.form.deliverDateTime.toISOString(),
          codAmount: orderState.isCodEnabled ? Number(dollarToCent(orderState.form.codAmount)) : ""
        }

        await createOrder(props.orderGroup.id, orderPayload);
        dispatch(closeCreateOrderModal());
        dispatch(handleGetOrdersByOrderGroup(props.orderGroup.id));
      } catch (error) {
        setOrderState({...orderState, error: true, errorMessage: error.message});
      }
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const isPickupDate = (pickupDate, deliveryDate) => {
    return deliveryDate.getDate() === pickupDate.getDate() && deliveryDate.getMonth() === pickupDate.getMonth() && deliveryDate.getFullYear() === pickupDate.getFullYear()
  }

  return (
    <Modal
      open={props.createOrderModalOpen}
      aria-labelledby="add-order-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeCreateOrderModal())}/>
          {
            orderState.currentStep === 0 ? (
              <>
                <h3 className={styles.subtitle}>Select from Bookmarked Dropoff Address</h3>
                <Autocomplete
                  autoHighlight
                  id="bookmark-address"
                  value={orderState.selectedBookmarkAddress}
                  onChange={(event, selectedOption) => setOrderState({ ...orderState, selectedBookmarkAddress: selectedOption })}
                  getOptionLabel={(option) => option && option.bookmarkName ? option.bookmarkName + " - " + option.location?.unitNumber + ", " + option.location?.address : ""}
                  options={orderState.bookmarkedAddresses}
                  noOptionsText="No bookmarked address found"
                  renderOption={(option) => <Typography data-id={option.id} noWrap>{option.bookmarkName} - {option.location.unitNumber}, {option.location.address}</Typography>}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Dropoff Address" style={{marginBottom: 20}} fullWidth />}
                />
                <Button onClick={() => nextWithBookmarkedAddress()} disabled={orderState.selectedBookmarkAddress ? false : true} className={styles.gradientButton}>Next</Button>
                <hr style={{margin: "30px 0"}}></hr>
                <h3 className={styles.subtitle}>Create with New Dropoff Address</h3>
                <Button onClick={() => nextWithNewAddress()} className={styles.gradientButton} style={{width: "auto"}}>Proceed using New Dropoff Address</Button>
              </>
            ) : (
              <>
                <button className={styles.backButton} onClick={() => setCurrentStep(0)}><ChevronLeft fontSize="small" style={{float: "left"}} /> Back</button>
                <h3 className={styles.subtitle}>What do you want us to deliver? <em style={{fontSize: 12}}>(All fields are required)</em></h3>
                <Fade in={orderState.error}>
                  <p className={styles.errorMessage}>{orderState.errorMessage}</p>
                </Fade>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <p>Recipient</p>
                    <Paper className={styles.textInputContainer}>
                      <input
                        id="recipientName"
                        name="recipientName"
                        value={orderState.form.recipientName}
                        onChange={e => onFormValueChange({name: "recipientName", value: e.target.value})}
                        placeholder="Recipient Name"
                        className={styles.textInput}
                      />
                    </Paper>
                    <Paper className={styles.textInputContainer}>
                      <input
                        id="recipientPhone"
                        name="recipientPhone"
                        type="tel"
                        value={orderState.form.recipientPhone}
                        onChange={e => onFormValueChange({name: "recipientPhone", value: e.target.value})}
                        placeholder="Phone Number (please start with +6)"
                        className={styles.textInput}
                      />
                    </Paper>
                    <Paper className={styles.textInputContainer}>
                      <input
                        id="recipientEmail"
                        name="recipientEmail"
                        type="email"
                        value={orderState.form.recipientEmail}
                        onChange={e => onFormValueChange({name: "recipientEmail", value: e.target.value})}
                        placeholder="Recipient Email (optional)"
                        className={styles.textInput}
                      />
                    </Paper>
                    <Paper className={styles.textInputContainer}>
                      <DatePicker
                        selected={orderState.form.deliverDateTime}
                        onChange={date => {
                          if (date.getHours() === 0) {
                            date.setHours(9);
                          }
                          onFormValueChange({name: "deliverDateTime", value: date})
                        }}
                        showTimeSelect
                        dateFormat="Pp"
                        minDate={(new Date()).getTime() > (new Date(props.orderGroup.pickUpDateTime)).getTime() ? new Date() : new Date(props.orderGroup.pickUpDateTime)}
                        minTime={
                          orderState.form.deliverDateTime === "" ||  isPickupDate(new Date(props.orderGroup.pickUpDateTime), new Date(orderState.form.deliverDateTime)) 
                          ? setHours(setMinutes(new Date(), new Date(props.orderGroup.pickUpDateTime).getMinutes()), new Date(props.orderGroup.pickUpDateTime).getHours())
                          : setHours(setMinutes(new Date(), 0), 6)
                        }
                        maxTime={setHours(setMinutes(new Date(), 0), 23)}
                        filterTime={filterPassedTime}
                        timeIntervals={15}
                        className={styles.textInput}
                        placeholderText={"Delivery Date Time"}
                      />
                    </Paper>
                    <p>Product (Optional)</p>
                    <Paper className={styles.textInputContainer}>
                      <textarea
                        id="product"
                        name="product"
                        value={orderState.form.product}
                        onChange={e => onFormValueChange({name: "product", value: e.target.value})}
                        placeholder="Product"
                        className={styles.textInput}
                      />
                    </Paper>
                    <p>Receipt Number (Optional)</p>
                    <Paper className={styles.textInputContainer}>
                      <input
                        id="receiptNumber"
                        name="receiptNumber"
                        value={orderState.form.receiptNumber}
                        onChange={e => onFormValueChange({name: "receiptNumber", value: e.target.value})}
                        placeholder="Receipt Number"
                        className={styles.textInput}
                      />
                    </Paper>
                    <p>Drop-off address</p>
                    <Paper className={styles.textInputContainer}>
                      <input
                        id="unitNumber"
                        name="unitNumber"
                        value={orderState.form.dropOffLocation.unitNumber}
                        onChange={e => setOrderState({
                          ...orderState,
                          form: {
                            ...orderState.form,
                            dropOffLocation: {
                              ...orderState.form.dropOffLocation,
                              unitNumber: e.target.value
                            }
                          }
                        })}
                        placeholder="Unit Number"
                        className={styles.textInput}
                      />
                    </Paper>
                    <Paper className={styles.textInputContainer}>
                      <input
                        id="dropOffAutocomplete"
                        name="dropOffAddress"
                        value={orderState.form.dropOffLocation.address}
                        onChange={e => setOrderState({
                          ...orderState,
                          isPlaceChanged: false,
                          form: {
                            ...orderState.form,
                            dropOffLocation: {
                              ...orderState.form.dropOffLocation,
                              address: e.target.value
                            }
                          }
                        })}
                        placeholder="Delivery Address"
                        className={styles.textInput}
                      />
                    </Paper>
                    <p>Dropoff Note to driver/rider </p>
                    <textarea 
                      id="noteToDriver" 
                      name="noteToDriver" 
                      className={styles.textArea} 
                      value={orderState.form.noteToDriver} 
                      onChange={e => onFormValueChange({name: "noteToDriver", value: e.target.value})}
                      placeholder="Landmarks close to address, amount of goods to be collected or any important instructions">
                    </textarea>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Switch checked={orderState.isCodEnabled} onChange={e => onValueChange({name: "isCodEnabled", value: e.target.checked })} name="isCodEnabled" color="primary" />}
                        label="Cash on Delivery"
                      />
                    </FormGroup>
                    {
                      orderState.isCodEnabled &&
                      <Paper className={styles.textInputContainer}>
                        <input
                          id="codAmount"
                          name="codAmount"
                          type="number"
                          value={orderState.form.codAmount}
                          onChange={e => onFormValueChange({name: "codAmount", value: e.target.value})}
                          placeholder="Cash on Delivery Amount (RM)"
                          className={styles.textInput}
                        />
                      </Paper>
                    }
                    <FormGroup row>
                      <FormControlLabel
                        control={<Switch checked={orderState.form.doorToDoor} onChange={e => onFormValueChange({name: "doorToDoor", value: e.target.checked })} name="doorToDoor" color="primary" />}
                        label="Door To Door Service"
                      />
                      <div style={{position: "relative", width: "300px", display: "table"}}>
                        <span style={{fontSize: "12px", display: "table-cell", verticalAlign: "middle"}}>*Extra charges will add into delivery fee</span>
                      </div>
                    </FormGroup>
                    <div className={styles.submitButtonContainer}>
                      <Button onClick={() => submit()} className={styles.gradientButton}>Submit</Button>
                    </div>
                  </Grid>
                </Grid>
              </>
            )
          }
        </div>
      </Paper>
    </Modal>
  );
}
