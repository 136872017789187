import axiosApiInstance from "../../app/axios";
import { API_BASE_URL, getJwtToken } from "../../app/utils";

export const getPromoCodes = async () =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/promo-codes?page=${0}&size=${500}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const getUserVouchers = async () =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/vouchers?applied=false`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const claimVoucher = async (data) => 
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/vouchers`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );