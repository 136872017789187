import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Paper, Grid, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { closeDownloadOrderGroupModal, handleDownloadOrderGroups } from './orderGroupSlice';
import { Close } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import styles from './OrderGroup.module.css';

export function DownloadReport(props) {
  const [reportMonthYear, setReportMonthYear] = useState(new Date());
  const dispatch = useDispatch();

  const downloadReport = () => {
    let year = reportMonthYear.getFullYear();
    let month = reportMonthYear.getMonth() + 1;

    dispatch(handleDownloadOrderGroups(props.orderGroupType, month, year, props.isUnpaid));
  }

  return (
    <Modal
      open={true}
      aria-labelledby="download-report-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeDownloadOrderGroupModal())}/>
          <h3 className={styles.subtitle}>Download <span style={{textTransform: "capitalize"}}>{props.orderGroupType.toLowerCase()}</span> Order Group Report</h3>
          <Grid container spacing={1}>
            <Paper className={styles.textInputContainer}>
              <DatePicker
                selected={reportMonthYear}
                onChange={date => {
                  setReportMonthYear(date)
                }}
                showMonthYearPicker
                dateFormat="MMMM, yyyy"
                className={styles.textInput}
                placeholderText={"Select Report Month"}
              />
            </Paper>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => downloadReport()} className={styles.gradientButton}>Download</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
