import { Toolbar, Typography, Tooltip, IconButton, Button } from "@material-ui/core";
import { lighten, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import styles from './Order.module.css';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, createOrder, importOrder, editOrder, deleteOrder, orderGroup, bookmarkAddress } = props;
  
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Order List <span style={{fontSize: 10}}>(click and hold an order in the list to rearrange and plan your route)</span>
                </Typography>
            )}

            {
                orderGroup && orderGroup.status === 'OPEN' ? (
                    <>
                    { numSelected > 0 ? (
                        <>
                            {
                                numSelected === 1 &&
                                <>
                                    <Tooltip title="Bookmark Dropoff Details" onClick={bookmarkAddress}>
                                        <IconButton aria-label="bookmark">
                                            <BookmarkBorderIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit" onClick={editOrder}>
                                        <IconButton aria-label="edit">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            <Tooltip title="Delete" onClick={deleteOrder}>
                                <IconButton aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <div style={{display: "flex"}}>
                            <Button onClick={createOrder} className={styles.gradientIconButton}><AddIcon /> Add</Button>
                            {
                                orderGroup && orderGroup.orders.length === 0 &&
                                <Button onClick={importOrder} className={styles.gradientIconButton} style={{marginLeft: 20}}><PublishIcon /> Import</Button>
                            }
                        </div>
                    )}
                    </>
                ) : (
                    <>
                        {
                            numSelected > 0 &&
                            <Tooltip title="Bookmark Dropoff Details" onClick={bookmarkAddress}>
                                <IconButton aria-label="bookmark">
                                    <BookmarkBorderIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            }
        </Toolbar>
    );
};