import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeDeleteOrderGroupModal, handleGetOrderGroups, setSelectedIds } from './orderGroupSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { cancelOrderGroup, deleteOrderGroup } from './orderGroupAPI';

export function ConfirmDelete(props) {
    const { orderGroups } = props;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const submit = async () => {
        setLoading(true);
        try {
            await Promise.all (
                await orderGroups.map(async orderGroup => {
                    if (orderGroup.status === "OPEN") {
                        await deleteOrderGroup(orderGroup.id);
                    } else if (orderGroup.status === "CONFIRMED") {
                        await cancelOrderGroup(orderGroup.id);
                    } else {
                        alert(`Sorry order group ${orderGroup.id} cannot be canceled`);
                    }
                })
            );
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
            dispatch(closeDeleteOrderGroupModal());
            dispatch(setSelectedIds([]));
            dispatch(handleGetOrderGroups(props.orderGroupType));
        }
    }

    return (
        <div>
        <Dialog open={true} onClose={() => !loading && dispatch(closeDeleteOrderGroupModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{orderGroups[0].status === "OPEN" ? "Delete" : "Cancel"} Order Group</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {orderGroups[0].status === "OPEN" ? "delete" : "cancel"} selected order {orderGroups.length === 1 ? `group` : `groups`}?
                    <br/><br/>
                    {loading && <span style={{fontSize: "11px", color: "red"}}>Cancelling... this might take around 1 to 5 minutes</span>}
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeDeleteOrderGroupModal())} color="primary" disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={() => submit()} color="primary" disabled={loading}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}