import { createSlice } from '@reduxjs/toolkit';
import { forgotPassword } from '../user/userAPI';

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    userName: "",
    error: false,
    errorMessage: "",
    isLoading: false,
  },
  reducers: {
    onValueChange: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    validateForm: (state) => {
      if (state.userName === "") {
        state.error = true;
        state.errorMessage = "All fields are required";
        return;
      }
      state.error = false;
      state.errorMessage = "";
    },
    showError: (state, action) => {
      state.error = true;
      state.errorMessage = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { onValueChange, validateForm, showError, setIsLoading } = forgotPasswordSlice.actions;

export const handleForgotPassword = (history, data) => async (dispatch) => {
  try {
    await forgotPassword(data);
    alert("A reset password email has been sent to you.");
    history.push(`/login`);
  } catch (error) {
    dispatch(showError(error?.response?.data?.message || error.message));
  }
};

export const handleForgotPasswordSubmit = (history) => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  dispatch(validateForm());
  const state = getState();
  if (!state.forgotPassword.error) {
    await dispatch(handleForgotPassword(history, {userName: state.forgotPassword.userName}));
  }
  dispatch(setIsLoading(false));
};

export const selectState = state => state.forgotPassword;

export default forgotPasswordSlice.reducer;
